import styled from 'styled-components';
import { size } from 'App/device';

const SignupWrapper = styled.div`
  position: relative;
  margin: 0;
  @media ${size['laptop-max']} .middle-section {
    margin-top: 65px;
  }
`;

export { SignupWrapper };
