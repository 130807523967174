import { Checkbox, Col, Form, Row } from 'antd';
import { FormValidation } from 'App/AppConstant';
import AuthHeader from 'components/AuthHeader';
import AuthWrapper from 'components/AuthWrapper';
import BreadCrumb from 'components/BreadCrumb';
import Footer from 'components/Footer';
import { FormButton as Button, FormGroup, FormItem, FormNewInput as Input, PasswordInput } from 'components/Form';
// import { FormButton as Button, FormGroup, FormItem, FormNewInput as Input, Label, PasswordInput } from 'components/Form';
import { FormStyling, LoginDiv } from 'components/LoginBox/style';
// import { SignupConstant } from 'components/Signup/constant';
// import SocialLogin from 'components/SocialLogin';
import { Formik } from 'formik';
import { onError } from 'modules/errorHandler';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { login } from 'redux/login/actions';
import * as Yup from 'yup';
import { LoginConstant } from './constant';

const loginValidationSchema = Yup.object().shape({
  email: Yup.string().email(FormValidation.emailInvalid).required(FormValidation.emailRequired),
  password: Yup.string().min(6, FormValidation.passwordMin).required(FormValidation.passwordRequired),
});

class Login extends Component {
  componentDidCatch(error) {
    onError(error, 'Login', 'Component render error');
  }
  handleSubmit = (values, { setSubmitting }) => {
    try {
      let payload = {
        email: values.email,
        password: values.password,
      };
      setSubmitting(false);
      this.props.login(payload);
    } catch (error) {
      onError(error, 'login', 'handle submit error');
    }
  };
  render() {
    return (
      <>
        <AuthHeader />
        <AuthWrapper>
          <LoginDiv>
            <Row gutter={30}>
              <Col lg={24} md={24} sm={24} xs={24}>
                <FormStyling>
                  <div className="login-box-main">
                    <BreadCrumb />
                    <h2>{LoginConstant.title}</h2>
                    <p>{LoginConstant.subTitle}</p>
                    <Formik
                      initialValues={{
                        email: '',
                        password: '',
                      }}
                      validationSchema={loginValidationSchema}
                      onSubmit={this.handleSubmit}
                      onClick={this.showHide}
                    >
                      {({ values, errors, touched, handleChange, onBlur, handleSubmit }) => (
                        <Form onSubmit={handleSubmit} noValidate>
                          <FormItem>
                            <div className="form-styling">
                              <FormGroup style={{ marginTop: '15px', marginBottom: '10px' }}>
                                <Input
                                  tabIndex="1"
                                  type="email"
                                  onBlur={onBlur}
                                  placeholder={'Required'}
                                  value={values.email}
                                  handleChange={handleChange}
                                  name="email"
                                  label={LoginConstant.email}
                                  isError={errors.email && touched.email ? true : false}
                                />
                                {errors.email && touched.email ? <div className="form-error">{errors.email}</div> : null}
                              </FormGroup>
                              <FormGroup>
                                <PasswordInput
                                  tabIndex="2"
                                  placeholder={'Required'}
                                  value={values.password}
                                  handleChange={handleChange}
                                  label={LoginConstant.password}
                                  name="password"
                                  isError={errors.password && touched.password ? true : false}
                                />
                                {errors.password && touched.password ? <div className="form-error">{errors.password}</div> : null}
                              </FormGroup>
                              <FormGroup>
                                <div className="d-flex forgot-password-text">
                                  <label className="checkbox1">
                                    <Checkbox>{LoginConstant.keepSignedIn}</Checkbox>
                                  </label>
                                  <div className="forgot-password-link ml-auto">
                                    <Link to="/forgot-password">{LoginConstant.forgotPassword}</Link>
                                  </div>
                                </div>
                              </FormGroup>
                              <div className="btn-main d-flex">
                                <div>
                                  {/* <Label title={SignupConstant.SocialLogin} />
                                  <SocialLogin from="login" className="socail-link-custom" /> */}
                                </div>
                                <div className="ml-auto btn-next">
                                  <Button htmlType="submit" className="new-btn login-btn">
                                    {LoginConstant.buttonText}
                                  </Button>
                                </div>
                              </div>
                            </div>
                          </FormItem>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </FormStyling>
              </Col>
            </Row>
          </LoginDiv>
        </AuthWrapper>
        <Footer />
      </>
    );
  }
}

const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => ({
  login: (payload) => dispatch(login(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
