import styled from 'styled-components';
import { size } from 'App/device';
import { borderRadius } from 'App/mixins';

const FormWrapper = styled.div`
  padding: 0;
  margin: 0;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
  textarea {
    outline: none;
    resize: none;
    height: 60px;
    padding-left: 0 !important;
    padding: 15px 25px;
    background: #fff;
    // border: 0.1rem solid ${props => props.theme.blackColor};
    border: 0.1rem solid #cecece;
    ${borderRadius('0px')};
    font-size: 15px;
    font-weight: 600;
    line-height: 1.5;
    color: ${props => props.theme.primaryColor};
    font-family: ${props => props.theme.secondaryFont}, sans-serif;
    display: block;
    width: 100%;
    padding-left: 5px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    @media ${size['desktop-sm-max']} {
      // height: 131px;
      // padding: 20px;
      height: 50px;
      padding: 10px;
      font-size: 16px;
      padding-left: 5px;
    }
    @media ${size['tablet-max']} {
      height: 80px;
      padding: 15px 20px;
      font-size: 14px;
      padding-left: 5px;
    }
  }
  label.form-control-placeholder.title {
    text-transform: uppercase;
    color: #816E9B;
    font-size: 12px;
  }
  .form-control-placeholder {
    position: absolute;
    top: 0;
    transition: all 300ms;
    // opacity: 0;
    display: block;
    font-weight: 600;
    color: #49465d;
    font-family: 'Montserrat', sans-serif;
    -webkit-letter-spacing: 0.05em;
    -moz-letter-spacing: 0.05em;
    -ms-letter-spacing: 0.05em;
    letter-spacing: 0.05em;
    line-height: 15px;
    // margin-bottom: 8px;
    // margin-top: 10px;
    font-size: 13px;
    // text-transform: uppercase;
  }
  textarea.form-control {
    border-bottom: 0.1rem solid #cecece !important;
    border-right: 10px solid white !important;
    border: none;
    padding-top: 24px;
    padding-bottom: 0;
  }
  .form-control:focus + .form-control-placeholder {
    // transform: translate3d(0, -100%, 0);
    // opacity: 1;
    // top: 0px;
    // font-size: 12px;
    // color: #8f48ff;
  }
  .form-control:focus {
    border-bottom: 1px solid #8f48ff !important;
    &::-webkit-input-placeholder {
      color: transparent;
    }

    &::-moz-placeholder {
      /* Firefox 19+ */
      color: transparent;
    }

    &:-ms-input-placeholder {
      color: transparent;
    }
  }
  &.show {
    .title {
      // transform: translate3d(0, -100%, 0);
    font-size: 12px;
    opacity: 1 !important;
    text-transform: uppercase;
    color: #816E9B;
    }
  }
`;

export { FormWrapper };
