import styled from 'styled-components';
import { size } from 'App/device';
import closeIcon from 'assets/images/close-icon.svg';

const SearchWrapper = styled.div`
  padding: 0;
  margin: 0;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
  &.error-wrapper {
    input.form-control {
      box-shadow: 0px 0px 5px #d6181a !important;
      border-radius: 10px !important;
      border: 1px solid #d6181a !important;
    }
  }
  input[readonly] {
    box-shadow: none;
    background: #f7f7f7 !important;
  }
  .search-bar {
    position: absolute;
    bottom: -60px;
    right: 0;
    // width: 90vw;
    left: 0.5vw;
    @media ${size['tablet-md-max']} {
      bottom: 0;
    }
  }
  .close-btn {
    top: 0px;
    background-image: url(${closeIcon});
    background-size: 15px auto;
    background-repeat: no-repeat;
    background-position: center;
    height: 45px;
    width: 50px;
    position: absolute;
    right: 0;
    margin-top: 15px;
    background-color: transparent;
    border: 0;
  }
  .search-btn-grp {
    margin-left: 10px;
    float: right !important;
    display: flex;
    border: 1.5px solid #ADADAD;
    border-radius: 4.26868px;
    width: 175px;
    form {
      display:flex;
    }
    @media ${size['tablet-md-max']} {
      margin-top: 0 !important;
    }
    .filter-btn {
      margin-left: 20px !important;
    }
    input {
      border: none;
      width: 130px;
      margin-left: 10px;
      font-size: 15px;
      height: 34px;
      font-weight: 600;
      font-family: OpenSans,sans-serif;
      color: #323232;
    }
    .search-btn {
      margin-left: 0;
    }
  }
  .payments-grp {
    position: absolute;
    right: -45px;
  }
`;

export { SearchWrapper };
