import React, { Component } from 'react';
import { Popover } from 'antd';
import { connect } from 'react-redux';
import { FormWrapper } from './style';

class CustomPopover extends Component {
  popOverRef = React.createRef();
  render() {
    const { className, children, trigger, placement, ...props } = this.props;
    return (
      <FormWrapper className={className} ref={this.popOverRef}>
        <Popover
          placement={placement}
          trigger={trigger}
          getPopupContainer={() => this.popOverRef.current}
          {...props}
        >
          {children}
        </Popover>
      </FormWrapper>
    );
  }
}
CustomPopover.defaultProps = {
  className: '',
  trigger: 'click',
  placement: 'top'
};

const mapStateToProps = state => ({});

export default connect(mapStateToProps, {})(CustomPopover);
