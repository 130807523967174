import { Button, DatePicker, TimePicker } from 'antd';
import { FormButton } from 'components/Form';
import isEmpty from 'lodash.isempty';
import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getJob, getQuota, updateJob } from 'redux/booking/actions';
import { hourslist, minuteslist } from './constant';
import { FormWrapper } from './style';
const TimeFormat = 'HH:mm';
const DateFormat = 'YYYY-MM-DD';
moment.locale('en', {
  week: {
    dow: 1
  }
});
moment.locale('en');
moment().weekday(1);
class CustomDateTimePicker extends Component {
  state = {
    pickUpTimeOpen: false,
    delivBeforeTimeOpen: false,
    dropdeadTimeOpen: false,
    pickdeadTimeOpen: false,
    selectedDate: '',
    currentDate: '',
    type: false,
    a: null,
    jobType: 'sameday',
    id: this.props.match.params.jobId,
    samedate: true,
    earliest_pickup_time_changed: false,
    earliest_delivery_time_changed: false,
    pickup_deadline_changed: false,
    delivery_deadline_changed: false,
    Date_Open: false
  };
  componentDidMount() {
    let { quotaDetails, jobDetails } = this.props;
    let today = new Date();
    let dd = String(today.getDate()).padStart(2, '0');
    let mm = String(today.getMonth() + 1).padStart(2, '0');
    let yyyy = today.getFullYear();

    today = yyyy + '-' + mm + '-' + dd;
    this.setState({ currentDate: today });
    if (!isEmpty(jobDetails)) {
      if (!isEmpty(jobDetails.earliest_delivery_time) && !isEmpty(jobDetails.pickup_deadline)) {
        this.setState({ type: true });
      }
    }
  }
  componentDidUpdate(prevProps, prevState) {
    let { type, currentDate } = this.state;
    let { value, timeValue, jobDetails } = this.props;
    if (jobDetails !== prevProps.jobDetails) {
      if (!isEmpty(jobDetails)) {
        if (!isEmpty(jobDetails.earliest_delivery_time) && !isEmpty(jobDetails.pickup_deadline)) {
          this.setState({ type: true });
        }
      }
    }
    if (value !== prevProps.value) {
      if (value !== currentDate) this.setState({ samedate: false });
      else {
        this.setState({ samedate: true });
      }
    }
  }
  handleChange = (ele, dateString) => {
    const { name, onDateTimeChange, timeName } = this.props;
    onDateTimeChange(name, dateString);
    this.props.dateTimeTouched();

    this.setState({ selectedDate: dateString });
  };
  handleDisabledDate = currentDate => {
    return currentDate < moment().subtract('1', 'days');
  };

  handlePickAfterTimeChange = (time, timeString) => {
    this.setState({ a: time });
    const { timeName, onDateTimeChange, timeValue, jobDetails } = this.props;

    onDateTimeChange('Pickup_After', time, timeName);
    this.props.dateTimeTouched();
    if (
      jobDetails.earliest_pickup_time !=
      moment(timeValue.Pickup_After).format('YYYY-MM-DD HH:mm:ss')
    ) {
      this.setState({ earliest_pickup_time_changed: true });
    }
  };

  handlePickAfterTimeOpenChange = open => {
    this.props.dateTimeTouched();
    this.setState({ pickUpTimeOpen: open });
  };
  handlePickdeadTimeChange = (time, timeString) => {
    this.setState({ a: time });
    const { timeName, onDateTimeChange, timeValue, jobDetails } = this.props;

    onDateTimeChange('Pickup_Deadline', time, timeName);
    this.props.dateTimeTouched();
    if (timeValue.Pickup_Deadline !== jobDetails.pickup_deadline) {
      this.setState({ pickup_deadline_changed: true });
    }
  };
  handlePickdeadTimeOpenChange = open => {
    this.props.dateTimeTouched();
    this.setState({ pickdeadTimeOpen: open });
  };

  handleDelivDeadTimeChange = (time, timeString) => {
    this.setState({ a: time });
    const { timeName, onDateTimeChange, timeValue, jobDetails } = this.props;

    onDateTimeChange('Delivery_Deadline', time, timeName);
    this.props.dateTimeTouched();
    if (timeValue.Delivery_Deadline !== jobDetails.delivery_deadline) {
      this.setState({ delivery_deadline_changed: true });
    }
  };
  handleDelivDeadTimeOpenChange = open => {
    this.props.dateTimeTouched();
    this.setState({ dropdeadTimeOpen: open });
  };

  handleDelivBeforTimeChange = (time, timeString) => {
    this.setState({ a: time });
    const { timeName, onDateTimeChange, timeValue, jobDetails } = this.props;

    onDateTimeChange('Delivery_Before', time, timeName);
    this.props.dateTimeTouched();
    if (timeValue.Delivery_Before !== jobDetails.earliest_delivery_time) {
      this.setState({ earliest_delivery_time_changed: true });
    }
  };
  handleDelivBeforTimeOpenChange = open => {
    this.props.dateTimeTouched();
    this.setState({ delivBeforeTimeOpen: open });
  };
  handleDateOpen = open => {
    this.props.dateTimeTouched();
    this.setState({ Date_Open: open });
  };
  handlePickAfterTimeCloseChange = async () => {
    if (!isEmpty(this.props.timeValue.Pickup_After)) {
      let time = moment(this.props.timeValue.Pickup_After).format('HH:mm');
      let { id, jobType } = this.state;
      let payload = {
        job_id: id,
        earliest_pickup_time: this.props.value + ' ' + time
      };
      await this.props.updateJob(payload, jobType);
      await this.props.getQuota({ id: id });
      await this.props.getJob({ id: id });
    }
    this.setState({ pickUpTimeOpen: false, earliest_pickup_time_changed: false });
  };
  changeType = () => {
    this.setState({ type: !this.state.type });
    this.props.type(!this.state.type);
  };
  handlePickdeadTimeCloseChange = async () => {
    if (!isEmpty(this.props.timeValue.Pickup_After)) {
      let time = moment(this.props.timeValue.Pickup_Deadline).format('HH:mm');
      let p_after = moment(this.props.timeValue.Pickup_After).format('HH:mm');
      let d_before = moment(this.props.timeValue.Delivery_Deadline).format('HH:mm');
      let { id, jobType } = this.state;
      let payload = {
        job_id: id,
        pickup_deadline: this.props.value + ' ' + time,
        earliest_pickup_time: this.props.value + ' ' + p_after
      };
      await this.props.updateJob(payload, jobType);
      await this.props.getQuota({ id: id });
      await this.props.getJob({ id: id });
    }
    this.setState({ pickdeadTimeOpen: false, pickup_deadline_changed: false });
  };

  handleDelivDeadTimeCloseChange = async () => {
    if (!isEmpty(this.props.timeValue.Delivery_Deadline)) {
      let time = moment(this.props.timeValue.Delivery_Deadline).format('HH:mm');
      let { id, jobType } = this.state;
      let payload = {
        job_id: id,
        delivery_deadline: this.props.value + ' ' + time
      };
      await this.props.updateJob(payload, jobType);
      await this.props.getQuota({ id: id });
      await this.props.getJob({ id: id });
    }
    this.setState({ dropdeadTimeOpen: false, delivery_deadline_changed: false });
  };

  handleDelivBeforTimeCloseChange = async () => {
    let { type } = this.state;
    if (!isEmpty(this.props.timeValue.Delivery_Before)) {
      let time = moment(this.props.timeValue.Delivery_Before).format('HH:mm');
      let p_after = this.props.jobDetails.earliest_pickup_time;
      let p_before = this.props.jobDetails.pickup_deadline;
      let d_before = this.props.jobDetails.delivery_deadline;
      let { id, jobType } = this.state;
      let payload = {
        job_id: id,
        earliest_delivery_time: this.props.value + ' ' + time
      };
      if (type) {
        payload.pickup_deadline = p_before;
        payload.earliest_pickup_time = p_after;
        payload.delivery_deadline = d_before;
      }
      await this.props.updateJob(payload, jobType);
      await this.props.getQuota({ id: id });
      await this.props.getJob({ id: id });
    }
    this.setState({ delivBeforeTimeOpen: false, earliest_delivery_time_changed: false });
  };

  Pickup_AfterDisabledHours = () => {
    const { workingHours, quotaDetails } = this.props;
    let { type, samedate } = this.state;
    let hours = [];
    let timestamp;
    if (type) {
      timestamp = Number(quotaDetails.realistic_time_needed_pickup);
    } else {
      timestamp = Number(quotaDetails.realistic_time_needed);
    }
    let real_hrs = parseInt(Math.floor(timestamp / 3600));
    let real_mins = parseInt(Math.floor((timestamp % 3600) / 60));
    if (samedate) {
      for (var i = 0; i < moment().hour(); i++) {
        hours.push(i);
      }
    }
    if (real_mins > 55) {
      real_hrs += 1;
      real_mins -= 55;
    } else {
      {
        real_mins -= real_mins;
      }
    }
    if (!isEmpty(workingHours)) {
      if (samedate) {
        for (var i = 0; i < parseInt(workingHours.start_time); i++) {
          hours.push(i);
        }
      } else {
        for (var i = 0; i < 6; i++) {
          hours.push(i);
        }
      }
      hourslist.map(h => {
        if (h > parseInt(workingHours.stop_time) - real_hrs) {
          hours.push(h);
        }
      });
    }
    return hours;
  };
  Pickup_DeadlineDisabledHours = () => {
    const { workingHours, quotaDetails, timeValue } = this.props;
    const timestamp = Number(quotaDetails.realistic_time_needed_pickup);
    let real_hrs = parseInt(Math.floor(timestamp / 3600));
    let real_mins = parseInt(Math.floor((timestamp % 3600) / 60));
    let pick_hrs = parseInt(moment(timeValue.Pickup_After).format('HH'));
    let deli_hrs = parseInt(moment(timeValue.Delivery_Before).format('HH'));
    let hours = [];
    if (real_mins > 55) {
      real_hrs += 1;
      real_mins -= 55;
    } else {
      {
        real_mins -= real_mins;
      }
    }
    pick_hrs += real_hrs;
    for (var i = 0; i < moment().hour(); i++) {
      hours.push(i);
    }
    hourslist.map(h => {
      if (!isEmpty(workingHours)) {
        if (h <= pick_hrs) {
          hours.push(h);
        }
        if (h > parseInt(workingHours.stop_time)) {
          hours.push(h);
        }
        if (h > deli_hrs) {
          hours.push(h);
        }
      }
    });
    return hours;
  };
  Delivery_BeforeDisabledHours = () => {
    const { workingHours, timeValue } = this.props;
    let hours = [];
    let pick_hrs = parseInt(moment(timeValue.Pickup_Deadline).format('HH'));
    let pick_mins = parseInt(moment(timeValue.Pickup_Deadline).format('mm'));
    if (pick_mins < 55) {
      pick_hrs -= 1;
    }
    if (pick_mins > 55) {
      pick_hrs += 1;
      pick_mins -= 55;
    } else {
      {
        pick_hrs += 1;
        pick_mins -= pick_mins;
      }
    }
    for (var i = 0; i < moment().hour(); i++) {
      hours.push(i);
    }
    if (!isEmpty(workingHours)) {
      for (i = 0; i < hourslist.length; i++) {
        if (hourslist[i] < pick_hrs) {
          hours.push(i);
        }
      }
      for (var i = 0; i <= parseInt(workingHours.start_time); i++) {
        hours.push(i);
      }
      hourslist.map(h => {
        if (h > parseInt(workingHours.stop_time)) {
          hours.push(h);
        }
      });
    }
    return hours;
  };
  Delivery_DeadlineDisabledHours = () => {
    const { workingHours, quotaDetails, timeValue } = this.props;
    const { type } = this.state;
    const timestamp = Number(
      type ? quotaDetails.realistic_time_needed_delivery : quotaDetails.realistic_time_needed
    );
    let real_hrs = parseInt(Math.floor(timestamp / 3600));
    let real_mins = parseInt(Math.floor((timestamp % 3600) / 60));
    let pick_hrs = parseInt(moment(timeValue.Pickup_After).format('HH'));
    if (type) {
      pick_hrs = '';
      isEmpty(timeValue.Delivery_Before)
        ? (pick_hrs = parseInt(moment().format('HH')))
        : (pick_hrs = parseInt(moment(timeValue.Delivery_Before).format('HH')));
    }
    let hours = [];
    if (real_mins > 55) {
      real_hrs += 1;
      real_mins -= 55;
    } else {
      {
        real_hrs += 1;
        real_mins -= real_mins;
      }
    }
    // pick_hrs += real_hrs;
    hourslist.map(h => {
      if (!isEmpty(workingHours)) {
        if (h < pick_hrs) {
          hours.push(h);
        }
        if (h > parseInt(workingHours.stop_time)) {
          hours.push(h);
        }
      }
    });
    return hours;
  };
  Pickup_AfterDisableMinutes = f => {
    let { workingHours, quotaDetails } = this.props;
    let { PAreal, type } = this.state;
    let minutes = [],
      i,
      timestamp;
    if (type) {
      timestamp = Number(quotaDetails.realistic_time_needed_pickup);
    } else {
      timestamp = Number(quotaDetails.realistic_time_needed);
    }
    let real_mins = parseInt(Math.floor((timestamp % 3600) / 60));
    let real_hrs = parseInt(Math.floor(timestamp / 3600));
    let min,
      m = real_mins;
    if (m < 55) {
      min = m % 10 == 0 || m % 10 == 5 ? m + 1 : m;
      real_hrs += 1;
    } else {
      real_mins -= 55;
      real_hrs += 1;
      min = 0;
    }
    let minute = Math.ceil(min / 5) * 5;

    for (i = 0; i < moment().minute(); i++) {
      if (f == moment().hour()) minutes.push(i);
    }
    for (i = 0; i < minuteslist.length; i++) {
      if (f == parseInt(workingHours.start_time.split(':')[0])) {
        if (minuteslist[i] <= workingHours.start_time.split(':')[1]) minutes.push(minuteslist[i]);
      }
      if (f == parseInt(workingHours.stop_time) - real_hrs) {
        if (minuteslist[i] <= minute) {
          minutes.push(minuteslist[i]);
        }
      }
      if (f == parseInt(workingHours.stop_time)) {
        if (i > 0) minutes.push(minuteslist[i]);
      }
    }

    return minutes;
  };
  Pickup_DeadlineDisableMinutes = f => {
    const { workingHours, quotaDetails, timeValue } = this.props;
    const { type } = this.state;
    let minutes = [],
      i,
      timestamp,
      pick_hrs,
      pick_mins,
      deli_hrs,
      deli_mins;
    timestamp = Number(quotaDetails.realistic_time_needed_pickup);
    let real_hrs = parseInt(Math.floor(timestamp / 3600));
    let real_mins = parseInt(Math.floor((timestamp % 3600) / 60));
    pick_hrs = parseInt(moment(timeValue.Pickup_After).format('HH'));
    pick_mins = parseInt(moment(timeValue.Pickup_After).format('mm'));
    deli_hrs = parseInt(moment(timeValue.Delivery_Before).format('HH'));
    deli_mins = parseInt(moment(timeValue.Delivery_Before).format('mm'));
    let min,
      m = pick_mins;
    if (m < 55) {
      min = m % 10 == 0 || m % 10 == 5 ? m + 1 : m;
    } else {
      pick_mins -= 55;
      pick_hrs += 1;
      min = 0;
    }
    let minute = Math.ceil(min / 5) * 5;
    if (real_mins > 55) {
      real_mins -= 55;
      real_hrs += 1;
    }
    let re_m = pick_mins + real_mins + parseInt('05');
    let re_h = pick_hrs + real_hrs;
    if (re_m > 55) {
      re_m -= 55;
      re_h += 1;
    }
    for (i = 0; i < moment().minute(); i++) {
      if (f == moment().hour()) minutes.push(i);
    }
    for (i = 0; i < minuteslist.length; i++) {
      if (f == re_h) {
        if (minuteslist[i] <= re_m) {
          minutes.push(minuteslist[i]);
        }
      }
      if (f == pick_hrs) {
        if (minuteslist[i] <= minute) minutes.push(minuteslist[i]);
      }
      if (f == deli_hrs) {
        if (minuteslist[i] >= deli_mins) minutes.push(minuteslist[i]);
      }
      if (f == parseInt(workingHours.stop_time)) {
        if (i > 0) minutes.push(minuteslist[i]);
      }
    }
    return minutes;
  };
  Delivery_BeforeDisableMinutes = f => {
    const { workingHours, quotaDetails, timeValue, jobDetails } = this.props;
    const { type } = this.state;
    let minutes = [],
      i;
    let pick_hrs, pick_mins;
    pick_hrs = parseInt(moment(timeValue.Pickup_Deadline).format('HH'));
    pick_mins = parseInt(moment(timeValue.Pickup_Deadline).format('mm'));
    let min,
      m = pick_mins;
    if (m < 55) {
      min = m % 10 == 0 || m % 10 == 5 ? m + 1 : m;
      // pick_hrs += 1;
    } else {
      pick_mins -= 55;
      pick_hrs += 1;
      min = 0;
    }
    let minute = Math.ceil(min / 5) * 5;

    for (i = 0; i < moment().minute(); i++) {
      if (f == moment().hour()) minutes.push(i);
    }
    for (i = 0; i < minuteslist.length; i++) {
      if (f == pick_hrs) {
        if (minuteslist[i] <= minute) minutes.push(minuteslist[i]);
      }
      if (f == parseInt(workingHours.stop_time)) {
        if (i > 0) minutes.push(minuteslist[i]);
      }
    }
    return minutes;
  };
  Delivery_DeadlineDisabledMinutes = f => {
    const { workingHours, quotaDetails, timeValue } = this.props;
    const { type } = this.state;
    let minutes = [],
      i;
    let pick_hrs, pick_mins;
    const timestamp = Number(
      type ? quotaDetails.realistic_time_needed_delivery : quotaDetails.realistic_time_needed
    );
    let real_hrs = parseInt(Math.floor(timestamp / 3600));
    let real_mins = parseInt(Math.floor((timestamp % 3600) / 60));
    if (type) {
      pick_hrs = parseInt(moment(timeValue.Delivery_Before).format('HH'));
    } else {
      pick_hrs = parseInt(moment(timeValue.Pickup_After).format('HH'));
    }
    if (type) {
      pick_mins = parseInt(moment(timeValue.Delivery_Before).format('mm'));
    } else {
      pick_mins = parseInt(moment(timeValue.Pickup_After).format('mm'));
    }
    let hrs, mins;
    hrs = real_hrs;
    mins = real_mins;

    let min,
      m = pick_mins;
    if (m < 55) {
      min = m % 10 == 0 || m % 10 == 5 ? m + 1 : m;
      // pick_hrs += 1;
    } else {
      pick_mins -= 55;
      pick_hrs += 1;
      min = 0;
    }
    if (real_mins > 55) {
      real_hrs += real_hrs;
    }
    let minute = Math.ceil(min / 5) * 5;

    for (i = 0; i < moment().minute(); i++) {
      if (f == moment().hour()) minutes.push(i);
    }
    for (i = 0; i < minuteslist.length; i++) {
      if (f == pick_hrs) {
        if (minuteslist[i] <= minute) minutes.push(minuteslist[i]);
      }
      if (f == real_hrs) {
        if (minuteslist[i] <= real_mins) minutes.push(minuteslist[i]);
      }
      if (f == parseInt(workingHours.stop_time)) {
        if (i > 0) minutes.push(minuteslist[i]);
      }
    }
    return minutes;
  };
  KeyDown = e => {
    let {
      earliest_pickup_time_changed,
      earliest_delivery_time_changed,
      pickup_deadline_changed,
      delivery_deadline_changed
    } = this.state;
    if (e.keyCode == 27) {
      if (earliest_pickup_time_changed) {
        this.handlePickAfterTimeCloseChange();
      }
      if (earliest_delivery_time_changed) {
        this.handleDelivBeforTimeCloseChange();
      }
      if (pickup_deadline_changed) {
        this.handlePickdeadTimeCloseChange();
      }
      if (delivery_deadline_changed) {
        this.handleDelivDeadTimeCloseChange();
      }
      this.setState({
        pickUpTimeOpen: false,
        pickdeadTimeOpen: false,
        delivBeforeTimeOpen: false,
        dropdeadTimeOpen: false,
        earliest_pickup_time_changed: false,
        earliest_delivery_time_changed: false,
        pickup_deadline_changed: false,
        delivery_deadline_changed: false,
        Date_Open: false
      });
    }
  };
  render() {
    const { placeholder, quotaDetails, className, timeValue, timeName, value } = this.props;
    const {
      pickUpTimeOpen,
      delivBeforeTimeOpen,
      pickdeadTimeOpen,
      dropdeadTimeOpen,
      type,
      Date_Open
    } = this.state;
    return (
      <FormWrapper className={className} onKeyDown={e => this.KeyDown(e)}>
        <DatePicker
          format={DateFormat}
          value={value !== '' ? moment(value) : moment(new Date(), DateFormat)}
          placeholder={placeholder}
          className="custom-datepicker"
          dropdownClassName="custom-datepicker-dropdown"
          onChange={this.handleChange}
          getCalendarContainer={node => node}
          open={Date_Open}
          allowClear={false}
          disabledDate={d => this.handleDisabledDate(d)}
          onOpenChange={this.handleDateOpen}
          disabled={this.props.timeName === 'endTime' && true}
        />
        {/* <OutsideClickHandler onOutsideClick={}> */}
        <TimePicker
          format={TimeFormat}
          name={timeName}
          value={!isEmpty(timeValue.Pickup_After) ? moment(timeValue.Pickup_After) : moment()}
          suffixIcon={<i className="las la-angle-down"></i>}
          allowClear={false}
          placeholder="HR MIN"
          open={pickUpTimeOpen}
          getPopupContainer={node => node}
          className="custom-timepicker"
          popupClassName="custom-time-dropdown"
          onChange={this.handlePickAfterTimeChange}
          onOpenChange={this.handlePickAfterTimeOpenChange}
          disabledHours={this.Pickup_AfterDisabledHours}
          disabledMinutes={this.Pickup_AfterDisableMinutes}
          minuteStep={5}
          addon={() => (
            <Button size="small" type="primary" onClick={this.handlePickAfterTimeCloseChange}>
              Ok
            </Button>
          )}
        />
        {/* </OutsideClickHandler> */}
        <TimePicker
          format={TimeFormat}
          name={timeName}
          value={!isEmpty(timeValue.Pickup_Deadline) ? moment(timeValue.Pickup_Deadline) : moment()}
          suffixIcon={<i className="las la-angle-down"></i>}
          allowClear={false}
          placeholder="HR MIN"
          open={pickdeadTimeOpen}
          getPopupContainer={node => node}
          className={`custom-timepicker ${!type && 'hide'}`}
          popupClassName="custom-time-dropdown"
          onChange={this.handlePickdeadTimeChange}
          onOpenChange={this.handlePickdeadTimeOpenChange}
          disabled={timeValue.Pickup_After == '' && true}
          disabledHours={this.Pickup_DeadlineDisabledHours}
          disabledMinutes={this.Pickup_DeadlineDisableMinutes}
          minuteStep={5}
          addon={() => (
            <Button size="small" type="primary" onClick={this.handlePickdeadTimeCloseChange}>
              Ok
            </Button>
          )}
        />
        <TimePicker
          format={TimeFormat}
          name={timeName}
          value={!isEmpty(timeValue.Delivery_Before) ? moment(timeValue.Delivery_Before) : moment()}
          suffixIcon={<i className="las la-angle-down"></i>}
          allowClear={false}
          placeholder="HR MIN"
          open={delivBeforeTimeOpen}
          getPopupContainer={node => node}
          className={`custom-timepicker ${!type && 'hide'}`}
          popupClassName="custom-time-dropdown"
          onChange={this.handleDelivBeforTimeChange}
          onOpenChange={this.handleDelivBeforTimeOpenChange}
          disabledMinutes={this.Delivery_BeforeDisableMinutes}
          disabledHours={this.Delivery_BeforeDisabledHours}
          minuteStep={5}
          addon={() => (
            <Button size="small" type="primary" onClick={this.handleDelivBeforTimeCloseChange}>
              Ok
            </Button>
          )}
        />
        <TimePicker
          format={TimeFormat}
          name={timeName}
          value={
            !isEmpty(timeValue.Delivery_Deadline) ? moment(timeValue.Delivery_Deadline) : moment()
          }
          suffixIcon={<i className="las la-angle-down"></i>}
          allowClear={false}
          placeholder="HR MIN"
          open={dropdeadTimeOpen}
          getPopupContainer={node => node}
          className="custom-timepicker"
          disabled={timeValue.Pickup_After == '' && true}
          popupClassName="custom-time-dropdown"
          onChange={this.handleDelivDeadTimeChange}
          onOpenChange={this.handleDelivDeadTimeOpenChange}
          disabledHours={this.Delivery_DeadlineDisabledHours}
          disabledMinutes={this.Delivery_DeadlineDisabledMinutes}
          minuteStep={5}
          addon={() => (
            <Button size="small" type="primary" onClick={this.handleDelivDeadTimeCloseChange}>
              Ok
            </Button>
          )}
        />
        {type ? (
          <FormButton
            className="new-btn new-btn-simple purple-box purple-btn btn-simple"
            onClick={this.changeType}
          >
            SIMPLE
          </FormButton>
        ) : (
          <FormButton
            className="new-btn new-btn-simple purple-box purple-btn btn-advance"
            onClick={this.changeType}
          >
            ADVANCED
          </FormButton>
        )}
      </FormWrapper>
    );
  }
}
CustomDateTimePicker.defaultProps = {};
const mapStateToProps = state => ({
  quotaDetails: state.booking.quotaDetails,
  workingHours: state.booking.workingHours,
  jobDetails: state.booking.sameDayJobDetails
});

const mapStateToDispatch = dispatch => ({
  getQuota: payload => dispatch(getQuota(payload)),
  getJob: payload => dispatch(getJob(payload)),
  updateJob: (payload, type) => dispatch(updateJob(payload, type))
});

export default connect(mapStateToProps, mapStateToDispatch)(CustomDateTimePicker);
