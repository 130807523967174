import React, { Component } from 'react';

import { Switch } from 'antd';
import { FormWrapper } from './style';

class Swticher extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: false
    };
  }
  handleChange = e => {
    this.setState({
      selected: e
    });
    this.props.switchData(e);
  };
  render() {
    const { selected } = this.state;
    const { left, right } = this.props;
    return (
      <FormWrapper>
        <span className={`text ${!selected ? 'font-medium' : ''}`}>{left}</span>
        <Switch className="switch" onChange={this.handleChange} />
        <span className={`text ${selected ? 'font-medium' : ''}`}>{right}</span>
      </FormWrapper>
    );
  }
}
Swticher.defaultProps = {
  status: 'left',
  left: 'left',
  right: 'right'
};

export default Swticher;
