import React from 'react';

export const NoData = ({ title }) => {
  return (
    <div className="no-data-div d-flex">
      <div className="align-center">
        <p>{title}</p>
      </div>
    </div>
  );
};
