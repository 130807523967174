import styled from 'styled-components';
import { size } from 'App/device';
import { borderRadius } from 'App/mixins';
import { FormWrapper } from 'components/FormInput/style';

const FormNumericWrapper = styled(FormWrapper)`
  .ant-input-number {
    width: 100%;
    height: 42px;
    outline: none;
    &:hover {
      border-color: transparent;
      border-right-width: 1px !important;
    }
    &:focus {
      border: none;
      box-shadow: none;
    }
  }
  .ant-input-number-focused {
    border-color: transparent;
    box-shadow: none;
  }
  .ant-input-number-input {
    border: 1px solid ${props => props.theme.blackColor};
  }
  .ant-input-number-handler-wrap {
    display: none;
  }
`;

export { FormNumericWrapper };
