import styled from 'styled-components';
const Wrapper = styled.div`
  display: flex;
  background: transparent;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  transition: all 0.4s ease;
  .main-loader {
    position: relative;
    left: 16px;
    top: 16px;
    .anticon-spin {
      fill: #8f48ff;
    }
  }
`;

export { Wrapper };
