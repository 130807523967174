import styled from 'styled-components';
import { size } from 'App/device';
import downArrow from 'assets/images/dropdown-arrow.svg';

const FormWrapper = styled.div`
  .weight-dropdown {
    background: #fff;
    box-shadow: 10px 10px 25px rgba(0, 0, 0, 0.1);
    position: relative;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    .label-parcel {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      line-height: 58px;
      text-align: center;
      width: 110px;
      font-weight: 500;
      font-size: 13px;
      letter-spacing: 0.05em;
      color: #49465d;
      font-style: normal;
      @media ${size['desktop-mm-max']} {
        line-height: 44px;
      }
    }
    //Remove
    .selected-dropdown {
      background: none;
      padding: 0;
      font-size: 15px;
      font-weight: 300;
      color: #979797;
      min-width: 70px;
      @media ${size['desktop-mm-max']} {
        font-size: 16px;
      }
      @media ${size['tablet-max']} {
        font-size: 14px;
      }
      .dropdown {
        padding-left: 110px;
        position: relative;
        .dropdown-toggle {
          line-height: 58px;
          border-radius: 5px;
          -webkit-border-radius: 5px;
          -moz-border-radius: 5px;
          display: block;
          text-align: left;
          padding: 0 20px 0 52px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          padding-left: 0;
          @media ${size['desktop-mm-max']} {
            line-height: 44px;
          }
          :after {
            border-width: 0;
            background: url(${downArrow}) no-repeat center;
            background-size: 8px auto;
            width: 10px;
            position: absolute;
            top: 0;
            bottom: 0;
            right: 14px;
            display: inline-block;
            margin-left: 0.255em;
            vertical-align: 0.255em;
            content: '';
            //border-top: 0.3em solid;
            border-right: 0.3em solid transparent;
            border-bottom: 0;
            border-left: 0.3em solid transparent;
          }
        }
      }
    }
  }
`;

export { FormWrapper };
