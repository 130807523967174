import styled from 'styled-components';
import { Form } from 'antd';
import { size } from 'App/device';

const FormWrapper = styled.div`
  margin: 0;
  position: relative;
  .error-wrapper {
    box-shadow: 0px 0px 5px #d6181a !important;
    border-radius: 0px !important;
    border: 1px solid #d6181a !important;
  }
  @media ${size['tablet-md-max']} {
    .parcel-icon {
      margin-top: 32px !important;
      margin-left: 0 !important;
    }
    .size-icon {
      // margin-top: 35px !important;
    }
  }
`;

const FormItem = styled(Form.Item)`
  &.ant-row.ant-form-item {
    margin-bottom: 0;
    // @media ${size['tablet-md-max']} {
    //   margin-bottom: 2px;
    // }
  }
`;
const FormGroup = styled.div`
  position: relative;
  padding: 0;
  margin: 15px 0 0;
  &.PLR {
    padding-left: 30px !important;
    padding-right: 30px !important;
    // margin-top: 30px !important;
    margin: 20px 0 0 !important;
    @media ${size['desktop-sm-max']} {
      margin: 10px 0 0 !important;
    }
  }
  &.PLR-profile {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  &.assign-parcels-card {
    margin: 0 !important;
  }
  &.assign-parcels-label {
    margin: 0;
  }
  .sameday-footer {
    margin-top: 20px;
    .ant-row {
      width: 100%;
    }
  }
  &.top-margin {
    // margin-top: 30px !important;
    margin: 20px 0 0 !important;
    @media ${size['desktop-sm-max']} {
      margin: 10px 0 0 !important;
    }
  }
  &.sequence-wrapper {
    margin-bottom: 15px;
  }
  .form-group.errorwrapper {
    margin-bottom: 0px;
  }
  &.top {
    margin-top: 10px;
  }
  &.signup {
    margin-bottom: 10px;
  }
  @media ${size['desktop-sm-max']} {
    // margin-bottom: 10px;
  }
  input[type='checkbox'] {
    cursor: pointer;
  }
  .plus-btns-group {
    margin-top: 12px;
    float: right;
  }
`;
export { FormItem, FormWrapper, FormGroup };
