export const transition = (time = 0.4) => `
    transition: all ${time}s ease;
    -webkit-transition: all ${time}s ease;
    -moz-transition: all ${time}s ease;
`;

export const borderRadius = (size = '30px') => `
    border-radius: ${size};
    -webkit-border-radius: ${size};
    -moz-border-radius: ${size};
`;

export const customScroller = (size = '5px', color = '#8f48ff') => `
&::-webkit-scrollbar {
    width: ${size};
    height: ${size};
  }
  &::-webkit-scrollbar-track {
    box-shadow: none;
    ${borderRadius('10px')};
  }
  &::-webkit-scrollbar-thumb {
    background: ${color};
    ${borderRadius('10px')};
  }
  &::-webkit-scrollbar-thumb:hover {
    background: ${color};
  }
`;
