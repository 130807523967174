import React, { Component } from 'react';
import isEmpty from 'lodash.isempty';
import { FormWrapper } from './style';

class FormReadOnlyInput extends Component {
  render() {
    const {
      handleChange,
      disabled,
      className,
      classNameWrapper,
      value,
      isError,
      ...props
    } = this.props;
    return (
      <FormWrapper className={`${classNameWrapper} ${isError ? 'error-wrapper' : ''}`}>
        <input
          className={`form-control ${className}`}
          disabled={disabled ? true : false}
          onChange={handleChange}
          value={!isEmpty(value) ? value : '-'}
          {...props}
          readOnly={true}
        />
      </FormWrapper>
    );
  }
}
FormReadOnlyInput.defaultProps = {
  isError: false,
  placeholder: '',
  tabIndex: '1',
  maxLength: 128,
  className: '',
  classNameWrapper: '',
  readOnly: true
};
export default FormReadOnlyInput;
