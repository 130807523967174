import styled from 'styled-components';
import { borderRadius } from 'App/mixins';
import { size } from 'App/device';

const TermsModalWrapper = styled.div`
  .terms-modal {
    .ant-checkbox-wrapper {
      box-sizing: border-box;
      margin: 0;
      padding: 0;
      color: rgba(0, 0, 0, 0.65);
      font-size: 14px;
      font-variant: tabular-nums;
      line-height: 1.5;
      list-style: none;
      display: inline-block;
      line-height: unset;
      cursor: pointer;
      margin-bottom: 20px;
    }
    .ant-checkbox-input {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1;
      width: 100%;
      height: 100%;
      cursor: pointer;
      opacity: 0;
    }
    .ant-checkbox-inner {
      top: 0;
      // left: 0;
      // height: 16px;
      // width: 16px;
      background: transparent;
      border-radius: 5px;
      border: 1.42px solid #d9d9d9;
    }
    .form-error {
      top: -15px;
    }
    .new-btn-style {
      float: right;
      background: #00ff9f;
      color: black;
      font-family: ${props => props.theme.primaryFont}, sans-serif;
      font-weight: 700;
      border: 1px solid black;
      border-radius: 0;
      -webkit-border-radius: 0;
      -moz-border-radius: 0;
      &:hover {
        background: #fff;
        color: #00ff9f;
        border: 1px solid #00ff9f;
      }
    }
    .input-wrapper {
      div {
        margin-top: 0;
      }
    }

    .dislay-content .form-group {
      margin-bottom: 15px;
    }
  }
  .ant-modal-header {
    text-transform: uppercase;
    background: ${props => props.theme.secondaryColor};
    .ant-modal-title {
      font-family: ${props => props.theme.primaryFont}, sans-serif;
      font-weight: 500;
      color: #fff;
    }
  }
`;
export { TermsModalWrapper };
