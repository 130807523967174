const ChangeConstant = {
  title: 'Change password',
  subTitle:
    'Forgot your password? Don’t worry, give us your email or mobile number, and we’ll send you a verification code',
  buttonText: 'Confirm',
  gophrCourier: 'GophrCourier',
  footerYear: '2020',
  newPassword: 'New password',
  codeInput: 'Code input',
  problemText: 'Problem? Contact us at gophrsupport@mail.com',
  enterConfirmPassword: 'Re-Enter your password',
  confirm_password: 'Confirm password'
};

export { ChangeConstant };
