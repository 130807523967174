import { Checkbox, Col, Collapse, Popconfirm, Row } from 'antd';
import { AddressConstant } from 'App/AppConstant';
import BookingHeader from 'components/BookingHeader';
import { HeaderInfo, NoData } from 'components/CommonComponent';
import { CommonTabWrapper, MaxContainer, MiddleSection } from 'components/CommonStyle/style';
import AddFavModal from 'components/Favourites/AddFavModal';
import { FormButton as Button, FormGroup, FormReadOnlyInput, Label, Popover } from 'components/Form';
import { FormWrapper } from 'components/InputIcon/style';
import Search from 'components/Search';
import isEmpty from 'lodash.isempty';
import { onError } from 'modules/errorHandler';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { deleteFavourites, favouriteToAddJob } from 'redux/favourite/actions';
import { FavouritesWrappper } from './favourites.style';

const { Panel } = Collapse;
export class Favourites extends Component {
  constructor(props) {
    super(props);
    this.state = {
      colKey: '0',
      favourites: [],
      favModalVisible: false,
      favSelect: [],
      editData: undefined,
    };
    this.container = React.createRef();
  }
  componentDidMount() {
    let { favourites } = this.props;
    this.setState({
      favourites,
    });
  }
  componentDidCatch(error) {
    onError(error, 'Favourites', 'On Component Render');
  }
  componentDidUpdate(prevProps) {
    let { favourites, deleteStatus } = this.props;
    if (deleteStatus !== prevProps.deleteStatus && deleteStatus) {
      this.setState({ favSelect: [] });
    }
    if (favourites !== prevProps.favourites) {
      this.setState({
        favourites,
      });
    }
  }
  tabHeader = () => {
    return (
      <ul className="ui-header">
        <li>
          <div className="t-col-1 td-info header d-flex">
            <div className="left-align">&nbsp;</div>
            {'ADDRESS'}
          </div>
          <div className="t-col-2 price-col favourites-city header">{'CITY'}</div>
          <div className="t-col-3 address-td favourites-postcode header">{'POSTCODE'}</div>
          <div className="t-col-4 status-col favourites-company header">{'COMPANY'}</div>
          <div className="t-col-5 truck-tex header">{'NAME'}</div>
          <div className="t-col-6 t-header-btns d-flex favourites-btns header">
            <div className="ml-auto"></div>
          </div>
        </li>
      </ul>
    );
  };
  handleDelete = () => {
    let { favSelect } = this.state;
    if (!isEmpty(favSelect)) {
      this.props.deleteFavourites({ ids: [...favSelect] });
    }
  };
  favouriteList = () => {
    const { getfilteredData } = this.props;

    let ui = [];
    if (!isEmpty(getfilteredData)) {
      getfilteredData.map((fav, inx) => {
        ui.push(this.panelUI(fav, inx));
      });
      return (
        <Checkbox.Group style={{ width: '100%' }} onChange={this.onChange}>
          <Collapse accordion>{ui}</Collapse>
        </Checkbox.Group>
      );
    } else {
      return <NoData title="No Favourite" />;
    }
  };
  onChange = (e) => {
    this.setState({
      favSelect: [...e],
    });
  };
  addFavModalHandler = (id = false) => {
    let { favourites } = this.state;
    let editData = undefined;
    if (id && !isEmpty(favourites)) {
      editData = favourites.filter((favourite) => {
        return favourite.id === id;
      });
    }
    this.setState({
      favModalVisible: true,
      editData,
    });
  };
  handleClose = () => {
    this.setState({
      favModalVisible: false,
      editData: undefined,
    });
  };

  render() {
    const { favModalVisible, editData, favourites } = this.state;
    return (
      <FavouritesWrappper>
        <CommonTabWrapper>
          <BookingHeader active="Favourites" />
          <MiddleSection className="jobs-board-padding padding-tb50">
            <hr className="horizontal-ruler" />
            <MaxContainer ref={this.container} className="max">
              <HeaderInfo title="Favorites" />
              <Row type="flex" className="box-shadow">
                <Col xs={24} sm={24} md={24} lg={24}>
                  <div className="Page-title">
                    <p className="title-text">Manage your Favourites</p>
                  </div>
                  <div className="Job-type-header">
                    <div className={`tab `}></div>
                    <div className={`tab`}></div>
                    <div className={`tab `}></div>
                    <div className="add-btn-grp">
                      <Button onClick={() => this.addFavModalHandler(false)} className="new-btn add-favourite">
                        ADD A NEW ADDRESS
                      </Button>
                      <AddFavModal
                        visible={favModalVisible}
                        editData={!isEmpty(editData) && editData[0] ? editData[0] : undefined}
                        closeHandler={this.handleClose}
                      />
                      <Search jobData={favourites} type="favourites" tabKey={1} showInput={false} />
                    </div>
                    {!isEmpty(this.state.favSelect) ? (
                      <Popconfirm
                        getPopupContainer={() => this.container.current}
                        title="Are you sure?"
                        placement="bottom"
                        onConfirm={(e) => {
                          e.stopPropagation();
                          this.handleDelete();
                        }}
                        onCancel={(e) => {
                          e.stopPropagation();
                        }}
                        okText="YES"
                        cancelText="NO"
                      >
                        <button className="delete-btn-enabled" type="button"></button>
                      </Popconfirm>
                    ) : (
                      <button className="delete-btn-disabled" type="button"></button>
                    )}
                  </div>
                  <div className="table-list draftsDiv">
                    {this.tabHeader()}
                    {this.favouriteList()}
                  </div>
                </Col>
              </Row>
            </MaxContainer>
          </MiddleSection>
        </CommonTabWrapper>
      </FavouritesWrappper>
    );
  }
  colapseChange = (e) => {
    const { colKey } = this.state;
    this.setState({
      colKey: parseInt(colKey) === +e ? '0' : e.toString(),
    });
  };
  favToJob = (id, key) => {
    let { favourites } = this.state;
    let payload = {};
    if (id && !isEmpty(favourites)) {
      payload = JSON.parse(JSON.stringify(favourites)).find((favourite) => {
        return favourite.id === id;
      });
      if (!isEmpty(payload)) {
        delete payload.id;
        this.props.favouriteToAddJob(payload, key);
      }
    }
  };

  dropDownData = (id) => {
    return (
      <ul
        className="menuItems"
        onClick={(event) => {
          event.preventDefault();
          event.stopPropagation();
        }}
      >
        <li onClick={() => this.addFavModalHandler(id)} style={{ cursor: 'pointer' }}>
          {AddressConstant.edit}
        </li>
        <Popconfirm
          getPopupContainer={() => this.container.current}
          title="Are you sure delete this favourite?"
          placement="bottom"
          onConfirm={(e) => {
            e.stopPropagation();
            this.props.deleteFavourites({ ids: [id] });
          }}
          onCancel={(e) => {
            e.stopPropagation();
          }}
          okText="YES"
          cancelText="NO"
        >
          <li style={{ cursor: 'pointer' }}>{AddressConstant.delete}</li>
        </Popconfirm>
        <li onClick={() => this.favToJob(id, 'pickup')}>Use as pickup address</li>
        <li onClick={() => this.favToJob(id, 'delivery')}>Use as delivery address</li>
      </ul>
    );
  };

  handleFavCheckClick = (event) => {
    event.stopPropagation();
  };

  panelUI = (data, index) => {
    return (
      <Panel
        showArrow={false}
        key={index + 1}
        header={
          <div className="custom-collapse-header">
            <div className="t-col-1 td-info">
              <div className="couriers-name-image d-flex">
                <i className="courier-image fav-select">
                  <Checkbox value={data.id} onClick={this.handleFavCheckClick}></Checkbox>
                </i>
                <div className="couriers-info">
                  <h6>{data.address1}</h6>
                </div>
              </div>
            </div>
            <div className="t-col-2 price-col favourites-city">{data.city}</div>
            <div className="t-col-3 address-td favourites-postcode">{data.postcode}</div>
            <div className="t-col-4 status-col favourites-company">{data.company_name ? data.company_name : ''}</div>
            <div className="t-col-5 truck-text">{data.person_name}</div>
            <div className="t-col-6 t-header-btns d-flex ">
              <div className="ml-auto d-flex">
                <button className="toggle-icon" type="button" onClick={() => this.colapseChange(index + 1)}></button>

                <div className="dropdown setting-btn-main">
                  <Popover className="progress-popup" content={this.dropDownData(data.id)}>
                    <button
                      type="button"
                      className="dots-btn dropdown-toggle"
                      onClick={(event) => {
                        event.preventDefault();
                        event.stopPropagation();
                      }}
                    />
                  </Popover>
                </div>
              </div>
            </div>
          </div>
        }
      >
        <FormWrapper>
          <Row className="fav-content" type="flex">
            <Col lg={24} md={24} sm={24} xs={24} className="left-align-collapse">
              <Row gutter={30} className="progress-main">
                <Col xs={24} sm={24} md={4} lg={4}>
                  <FormGroup>
                    <Label title={AddressConstant.streetNumber}></Label>
                    <FormReadOnlyInput type="text" className="form-control" placeholder=" " value={data.address1} />
                  </FormGroup>
                </Col>
                <Col xs={24} sm={24} md={4} lg={4}>
                  <FormGroup>
                    <Label title={AddressConstant.buildingFloor}></Label>
                    <FormReadOnlyInput type="text" className="form-control" placeholder=" " value={data.address2} />
                  </FormGroup>
                </Col>
                <Col xs={24} sm={24} md={4} lg={4}>
                  <FormGroup>
                    <Label title={AddressConstant.city}></Label>
                    <FormReadOnlyInput type="text" className="form-control" placeholder=" " value={data.city} />
                  </FormGroup>
                </Col>
                <Col xs={24} sm={24} md={4} lg={4}>
                  <FormGroup>
                    <Label title={AddressConstant.postcode}></Label>
                    <FormReadOnlyInput type="text" className="form-control" placeholder=" " value={data.postcode || ''} />
                  </FormGroup>
                </Col>
                <Col xs={24} sm={24} md={4} lg={4}>
                  <FormGroup>
                    <Label title={AddressConstant.country}></Label>
                    <FormReadOnlyInput type="text" className="form-control" placeholder=" " value={data.country_code} />
                  </FormGroup>
                </Col>
                <Col xs={24} sm={24} md={4} lg={4}>
                  <FormGroup>
                    <Label title={'Tips How To Find'}></Label>
                    <FormReadOnlyInput
                      type="text"
                      className="form-control"
                      placeholder=" "
                      value={!isEmpty(data.tips_how_to_find) ? data.tips_how_to_find : '//'}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row gutter={30} className="progress-main">
                <Col xs={24} sm={24} md={4} lg={4}>
                  <FormGroup>
                    <Label title={'Responsible'}></Label>
                    <FormReadOnlyInput type="text" className="form-control" placeholder=" " value={data.person_name} />
                  </FormGroup>
                </Col>
                <Col xs={24} sm={24} md={4} lg={4}>
                  <FormGroup>
                    <Label title={AddressConstant.email}></Label>
                    <FormReadOnlyInput type="text" className="form-control" placeholder=" " value={data.email || ''} />
                  </FormGroup>
                </Col>

                <Col xs={24} sm={24} md={4} lg={4}>
                  <FormGroup>
                    <Label title={AddressConstant.phoneNumber}></Label>
                    <FormReadOnlyInput type="text" className="form-control" placeholder=" " value={data.phone_number} />
                  </FormGroup>
                </Col>
                <Col xs={24} sm={24} md={4} lg={4}>
                  <FormGroup>
                    <Label title={AddressConstant.companyName}></Label>
                    <FormReadOnlyInput type="text" className="form-control" placeholder=" " value={data.company_name || ''} />
                  </FormGroup>
                </Col>
                <Col xs={24} sm={24} md={4} lg={4}>
                  <FormGroup>
                    <Label title={AddressConstant.mobileNumber}></Label>
                    <FormReadOnlyInput type="text" className="form-control" placeholder=" " value={data.mobile_number} />
                  </FormGroup>
                </Col>
              </Row>
            </Col>
          </Row>
        </FormWrapper>
      </Panel>
    );
  };
}
const mapStateToProps = (state) => ({
  favourites: state.favourite.favourites,
  deleteStatus: state.favourite.deleteStatus,
  getfilteredData: state.booking.filteredData,
});
const mapDispatchToProps = (dispatch) => ({
  deleteFavourites: (payload) => dispatch(deleteFavourites(payload)),
  favouriteToAddJob: (payload, key) => dispatch(favouriteToAddJob(payload, key)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Favourites);
