import { notification } from 'antd';
import { loader } from 'redux/app/actions';
import { axiosAuthPost } from 'modules/Axios';
import { apiConstant } from 'modules/config';

import * as actions from './constant';

export const getTeams = payload => async dispatch => {
  try {
    dispatch(loader(true));
    dispatch({ type: actions.GETTEAM_INITIATED });
    let response = await axiosAuthPost(apiConstant.GET_TEAMS, payload);
    if (!response.success) {
      dispatch({
        type: actions.GETTEAM_ERROR,
        error: response.error.message
      });
    } else {
      dispatch({
        type: actions.GETTEAM_SUCCESS,
        payload: response.data
      });
    }
    dispatch(loader(false));
  } catch (error) {
    dispatch(loader(false));
    dispatch({
      type: actions.GETTEAM_ERROR,
      error
    });
  }
};
export const addTeam = payload => async dispatch => {
  let status = false;
  try {
    dispatch(loader(true));
    dispatch({ type: actions.ADDTEAM_INITIATED });
    let response = await axiosAuthPost(apiConstant.ADD_TEAM, payload);
    if (!response.success) {
      dispatch({
        type: actions.ADDTEAM_ERROR,
        error: response.error.message
      });
    } else {
      status = true;
      dispatch({
        type: actions.ADDTEAM_SUCCESS,
        payload: response.data
      });
      dispatch(getTeams());
    }
  } catch (error) {
    console.log(error);
    dispatch({
      type: actions.ADDTEAM_ERROR,
      error
    });
  } finally {
    dispatch(loader(false));
    return status;
  }
};
export const deleteTeam = payload => async dispatch => {
  try {
    dispatch(loader(true));
    dispatch({ type: actions.DELETETEAM_INITIATED });
    let removeTeamParams = { team_id: payload };
    let response = await axiosAuthPost(apiConstant.DELETE_TEAM, removeTeamParams);
    if (response.success) {
      dispatch({
        type: actions.DELETETEAM_SUCCESS,
        payload: response.success
      });
      dispatch(getTeams());
    }
    dispatch(loader(false));
  } catch (error) {
    dispatch(loader(false));
    dispatch({
      type: actions.DELETETEAM_ERROR,
      error
    });
  }
};
export const openEdit = payload => async dispatch => {
  dispatch({
    type: actions.OPEN_EDIT,
    payload
  });
};
export const updateTeam = payload => async dispatch => {
  try {
    let status = false;
    dispatch(loader(true));
    await dispatch({ type: actions.UPDATETEAM_INITIATED });
    let renameTeamParams = {
      team_id: payload.id,
      team_name: payload.name
    };
    let response = await axiosAuthPost(apiConstant.UPDATE_TEAM, renameTeamParams);
    if (!response.success) {
      await dispatch({
        type: actions.UPDATETEAM_ERROR,
        error: response.error.message
      });
    } else {
      await dispatch({
        type: actions.UPDATETEAM_SUCCESS,
        payload: response.data
      });
      status = true;
      dispatch(getTeams());
    }
    dispatch(loader(false));
    return status;
  } catch (error) {
    dispatch(loader(false));
    await dispatch({
      type: actions.UPDATETEAM_ERROR,
      error
    });
  }
};

export const inviteMember = payload => async dispatch => {
  let status = false;
  try {
    dispatch(loader(true));
    dispatch({ type: actions.INVITEMEMBER_INITIATED });
    let response = await axiosAuthPost(apiConstant.INVITE_MEMBER, payload);
    if (!response.success) {
      dispatch({
        type: actions.INVITEMEMBER_ERROR,
        error: response.error.message
      });
    } else {
      status = true;
      notification['success']({
        message: 'Team member added'
      });
      dispatch({
        type: actions.INVITEMEMBER_SUCCESS,
        payload: response.data
      });
      dispatch(getTeams());
    }
  } catch (error) {
    console.log(error);
    dispatch({
      type: actions.INVITEMEMBER_ERROR,
      error
    });
  } finally {
    dispatch(loader(false));
    return status;
  }
};
export const deleteMember = payload => async dispatch => {
  try {
    dispatch(loader(true));
    let response = await axiosAuthPost(apiConstant.DELETE_MEMBER, payload);
    if (response.success) {
      notification['success']({
        message: 'Team member deleted'
      });
      dispatch(getTeams());
    }
  } catch (error) {
    console.log(error);
  } finally {
    dispatch(loader(false));
  }
};
export const reInviteMember = payload => async dispatch => {
  try {
    dispatch(loader(true));
    let response = await axiosAuthPost(apiConstant.REINVITE_MEMBER, payload);
    if (response.success) {
      notification['success']({
        message: 'Invite Link Sent'
      });
    }
  } catch (error) {
    console.log(error);
  } finally {
    dispatch(loader(false));
  }
};
