export const CLEARJOB = 'CLEARJOB';

export const BOOKING_INITIATED = 'BOOKING_INITIATED';
export const BOOKING_SUCCESS = 'BOOKING_SUCCESS';
export const BOOKING_ERROR = 'BOOKING_ERROR';

export const GETJOB_INITIATED = 'GETJOB_INITIATED';
export const GETJOB_SUCCESS = 'GETJOB_SUCCESS';
export const GETJOB_ERROR = 'GETJOB_ERROR';

export const BACK_GETJOB_INITIATED = 'BACK_GETJOB_INITIATED';
export const BACK_GETJOB_SUCCESS = 'BACK_GETJOB_SUCCESS';
export const BACK_GETJOB_ERROR = 'BACK_GETJOB_ERROR';

export const ADDJOB_INITIATED = 'ADDJOB_INITIATED';
export const ADDJOB_SUCCESS = 'ADDJOB_SUCCESS';
export const ADDJOB_ERROR = 'ADDJOB_ERROR';

export const UPDATEJOB_INITIATED = 'UPDATEJOB_INITIATED';
export const UPDATEJOB_SUCCESS = 'UPDATEJOB_SUCCESS';
export const UPDATEJOB_ERROR = 'UPDATEJOB_ERROR';

export const CANCELJOB_INITIATED = 'CANCELJOB_INITIATED';
export const CANCELJOB_SUCCESS = 'CANCELJOB_SUCCESS';
export const CANCELJOB_ERROR = 'CANCELJOB_ERROR';

export const GETQUOTA_INITIATED = 'GETQUOTA_INITIATED';
export const GETQUOTA_SUCCESS = 'GETQUOTA_SUCCESS';
export const GETQUOTA_ERROR = 'GETQUOTA_ERROR';

export const GETNEXTDAYQUOTE_INITIATED = 'GETNEXTDAYQUOTE_INITIATED';
export const GETNEXTDAYQUOTE_SUCCESS = 'GETNEXTDAYQUOTE_SUCCESS';
export const GETNEXTDAYQUOTE_ERROR = 'GETNEXTDAYQUOTE_ERROR';

export const COURIERSLIST_INITIATED = 'COURIERSLIST_INITIATED';
export const COURIERSLIST_SUCCESS = 'COURIERSLIST_SUCCESS';
export const COURIERSLIST_ERROR = 'COURIERSLIST_ERROR';

export const ADDPARCEL_INITIATED = 'ADDPARCEL_INITIATED';
export const ADDPARCEL_SUCCESS = 'ADDPARCEL_SUCCESS';
export const ADDPARCEL_ERROR = 'ADDPARCEL_ERROR';

export const GETPARCEL_INITIATED = 'GETPARCEL_INITIATED';
export const GETPARCEL_SUCCESS = 'GETPARCEL_SUCCESS';
export const GETPARCEL_ERROR = 'GETPARCEL_ERROR';

export const PARCEL_INITIATED = 'PARCEL_INITIATED';
export const PARCEL_ERROR = 'PARCEL_ERROR';
export const PARCELADD_SUCCESS = 'PARCELADD_SUCCESS';

export const UPDATEPARCEL_INITIATED = 'UPDATEPARCEL_INITIATED';
export const UPDATEPARCEL_ERROR = 'UPDATEPARCEL_ERROR';
export const UPDATEPARCEL_SUCCESS = 'UPDATEPARCEL_SUCCESS';

export const DELETEPARCEL_INITIATED = 'DELETEPARCEL_INITIATED';
export const DELETEPARCEL_ERROR = 'DELETEPARCEL_ERROR';
export const DELETEPARCEL_SUCCESS = 'DELETEPARCEL_SUCCESS';

export const GETPARCELFOREDIT_INITIATED = 'GETPARCELFOREDIT_INITIATED';
export const GETPARCELFOREDIT_SUCCESS = 'GETPARCELFOREDIT_SUCCESS';
export const UPDATEPARCELFOREDIT_ERROR = 'UPDATEPARCELFOREDIT_ERROR';

export const ITEM_INITIATED = 'ITEM_INITIATED';
export const ITEM_ERROR = 'ITEM_ERROR';
export const ITEMADD_SUCCESS = 'ITEMADD_SUCCESS';

export const UPDATEITEM_INITIATED = 'ITEM_INITIATED';
export const UPDATEITEM_ERROR = 'ITEM_ERROR';
export const UPDATEITEMADD_SUCCESS = 'ITEMADD_SUCCESS';

export const DELETEITEM_INITIATED = 'DELETEITEM_INITIATED';
export const DELETEITEM_ERROR = 'DELETEITEM_ERROR';
export const DELETEITEM_SUCCESS = 'DELETEITEM_SUCCESS';

export const GET_NEXTDAYESTIMATE_INITIATED = 'GET_NEXTDAYESTIMATE_INITIATED';
export const GET_NEXTDAYESTIMATE_ERROR = 'GET_NEXTDAYESTIMATE_ERROR';
export const GET_NEXTDAYESTIMATE_SUCCESS = 'GET_NEXTDAYESTIMATE_SUCCESS';

export const SETNEXTDAYSERVICE_INITIATED = 'SETNEXTDAYSERVICE_INITIATED';
export const SETNEXTDAYSERVICE_SUCCESS = 'SETNEXTDAYSERVICE_SUCCESS';
export const SETNEXTDAYSERVICE_ERROR = 'SETNEXTDAYSERVICE_ERROR';

export const GETPARCELSTORES_INITIATED = 'GETPARCELSTORES_INITIATED';
export const GETPARCELSTORES_SUCCESS = 'GETPARCELSTORES_SUCCESS';
export const GETPARCELSTORES_ERROR = 'GETPARCELSTORES_ERROR';

export const CLEARPARCELSTORES = 'CLEARPARCELSTORES';

export const GETCURRENTJOB_INITIATED = 'GETCURRENTJOB_INITIATED';
export const GETCURRENTJOB_ERROR = 'GETCURRENTJOB_ERROR';
export const GETCURRENTJOB_SUCCESS = 'GETCURRENTJOB_SUCCESS';

export const CONVERTMULTIJOB_INITIATED = 'CONVERTMULTIJOB_INITIATED';
export const CONVERTMULTIJOB_ERROR = 'CONVERTMULTIJOB_ERROR';
export const CONVERTMULTIJOB_SUCCESS = 'CONVERTMULTIJOB_SUCCESS';

export const SET_MULTI_CURRENT_ADD = 'SET_MULTI_CURRENT_ADD';
export const CLEAR_MULTI_CURRENT_ADD = 'CLEAR_MULTI_CURRENT_ADD';

export const SET_FILTERED_DATA = 'SET_FILTERED_DATA';
export const REMOVEDRAFT = 'REMOVEDRAFT';
export const ADD_PARCEL_NEWADDRESS = 'ADD_PARCEL_NEWADDRESS';
export const ADD_SELECTED_JOB_ID_PARCEL = 'ADD_SELECTED_JOB_ID_PARCEL';

export const EDIT_ADDRESS_DATA = 'EDIT_ADDRESS_DATA';
export const EDIT_PARCEL_DATA = 'EDIT_PARCEL_DATA';
export const SELECTEDJOBID_PARCEL_ADD = 'SELECTEDJOBID_PARCEL_ADD';

export const CONVERT_SAME_NEXT_DAY_INITIATED = 'CONVERT_SAME_NEXT_DAY_INITIATED';
export const CONVERT_SAME_NEXT_DAY_ERROR = 'CONVERT_SAME_NEXT_DAY_ERROR';
export const CONVERT_SAME_NEXT_DAY_SUCCESS = 'CONVERT_SAME_NEXT_DAY_SUCCESS';

export const PRIVATE_GETCOURIERS_AROUND_ME_INITIATED = 'PRIVATE_GETCOURIERS_AROUND_ME_INITIATED';
export const PRIVATE_GETCOURIERS_AROUND_ME_ERROR = 'PRIVATE_GETCOURIERS_AROUND_ME_ERROR';
export const PRIVATE_GETCOURIERS_AROUND_ME_SUCCESS = 'PRIVATE_GETCOURIERS_AROUND_ME_SUCCESS';

export const PUBLIC_GETCOURIERS_AROUND_ME_INITIATED = 'PUBLIC_GETCOURIERS_AROUND_ME_INITIATED';
export const PUBLIC_GETCOURIERS_AROUND_ME_ERROR = 'PUBLIC_GETCOURIERS_AROUND_ME_ERROR';
export const PUBLIC_GETCOURIERS_AROUND_ME_SUCCESS = 'PUBLIC_GETCOURIERS_AROUND_ME_SUCCESS';

export const OPTIMISE_SEQUENCE_INITIATED = 'OPTIMISE_SEQUENCE_INITIATED';
export const OPTIMISE_SEQUENCE_ERROR = 'OPTIMISE_SEQUENCE_ERROR';
export const OPTIMISE_SEQUENCE_SUCCESS = 'OPTIMISE_SEQUENCE_SUCCESS';

export const CHANGE_SEQUENCE_INITIATED = 'CHANGE_SEQUENCE_INITIATED';
export const CHANGE_SEQUENCE_ERROR = 'CHANGE_SEQUENCE_ERROR';
export const CHANGE_SEQUENCE_SUCCESS = 'CHANGE_SEQUENCE_SUCCESS';

export const GET_WORKING_HOURS_INITIATED = 'GET_WORKING_HOURS_INITIATED';
export const GET_WORKING_HOURS_ERROR = 'GET_WORKING_HOURS_ERROR';
export const GET_WORKING_HOURS_SUCCESS = 'GET_WORKING_HOURS_SUCCESS';

export const VALIDATEJOB_INITIATED = 'VALIDATEJOB_INITIATED';
export const VALIDATEJOB_ERROR = 'VALIDATEJOB_ERROR';
export const VALIDATEJOB_SUCCESS = 'VALIDATEJOB_SUCCESS';

export const UPDATE_COURIER = 'UPDATE_COURIER';

export const GETSAMEDAYJOB_INITIATED = 'GETSAMEDAYJOB_INITIATED';
export const GETSAMEDAYJOB_SUCCESS = 'GETSAMEDAYJOB_SUCCESS';
export const GETSAMEDAYJOB_ERROR = 'GETSAMEDAYJOB_ERROR';

export const CHANGEJOBTYPE = 'CHANGEJOBTYPE';

export const WAITANDRETURN = 'WAITANDRETURN';
export const PICKUPPAYLOAD = 'PICKUPPAYLOAD';
export const JOB_COURIER_MANIFEST = 'JOB_COURIER_MANIFEST';
export const JOB_COURIER_LOCATION = 'JOB_COURIER_LOCATION';
export const RATE_COURIER_FEEDBACK = 'RATE_COURIER_FEEDBACK';
export const SHOW_TIMER_FROM_PUBNUB = 'SHOW_TIMER_FROM_PUBNUB';
export const SHOW_FEEDBACK_FROM_PUBNUB = 'SHOW_FEEDBACK_FROM_PUBNUB';
export const SHOW_LOADER_FROM_PUBNUB = 'SHOW_LOADER_FROM_PUBNUB';

export const GET_SAME_DAY_LABELS_INITIATED = 'GET_SAME_DAY_LABELS_INITIATED';
export const GET_SAME_DAY_LABELS_SUCCESS = 'GET_SAME_DAY_LABELS_SUCCESS';
export const GET_SAME_DAY_LABELS_ERROR = 'GET_SAME_DAY_LABELS_ERROR';

export const GET_LABEL_ZIP_FILE_INITIATED = 'GET_LABEL_ZIP_FILE_INITIATED';
export const GET_LABEL_ZIP_FILE_SUCCESS = 'GET_LABEL_ZIP_FILE_SUCCESS';
export const GET_LABEL_ZIP_FILE_ERROR = 'GET_LABEL_ZIP_FILE_ERROR';