import * as actions from './constant';
const initialState = {
  error: false,
  isProfileUpdate: false,
  userData: false,
  loading: false,
  message: false,
  userSecondaryData: false,
  isSecondaryProfileUpdate: false
};

const profileUpdate = (state = initialState, action) => {
  switch (action.type) {
    case actions.PROFILE_INITIATED:
      return {
        ...state,
        isProfileUpdate: false,
        message: false,
        error: false,
        loading: true
      };
    case actions.PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        isProfileUpdate: true,
        message: 'Profile Updated'
      };
    case actions.PROFILE_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        isProfileUpdate: false,
        message: action.error
      };
    case actions.USERDATA_INITIATED:
      return {
        ...state,
        userData: false,
        message: false,
        error: false,
        loading: true
      };
    case actions.USERDATA_SUCCESS:
      return {
        ...state,
        loading: false,
        userData: action.payload,
        error: false,
        message: false
      };
    case actions.USERDATA_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        message: action.error
      };
    case actions.SECONDARY_USERDATA_INITIATED:
      return {
        ...state,
        userSecondaryData: false,
        message: false,
        error: false,
        loading: true
      };
    case actions.SECONDARY_USERDATA_SUCCESS:
      return {
        ...state,
        loading: false,
        userSecondaryData: action.payload,
        error: false,
        message: false
      };
    case actions.SECONDARY_USERDATA_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        message: action.error
      };
    case actions.SECONDARY_PROFILE_INITIATED:
      return {
        ...state,
        isSecondaryProfileUpdate: false,
        message: false,
        error: false,
        loading: true
      };
    case actions.SECONDARY_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        isSecondaryProfileUpdate: true,
        message: 'Secondary Profile Updated'
      };
    case actions.SECONDARY_PROFILE_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        isSecondaryProfileUpdate: false,
        message: action.error
      };
    case actions.PROFILE_CLEAR:
      return {
        ...state,
        ...initialState
      };
    case actions.PROFILE_PICTURE_UPLOAD_INITIATED:
      return {
        ...state,
        uploadingProfilePicture: true,
        profilePictureUploaded: false,
        profilePictureError: false
      };
    case actions.PROFILE_PICTURE_UPLOAD_SUCCESS:
      return {
        ...state,
        uploadingProfilePicture: false,
        profilePictureUploaded: true,
        profilePictureError: false
      };
    case actions.PROFILE_PICTURE_UPLOAD_ERROR:
      return {
        ...state,
        uploadingProfilePicture: false,
        profilePictureUploaded: false,
        profilePictureError: action.error
      };
    default:
      return state;
  }
};
export default profileUpdate;