import React, { Component } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Button } from './style';
const ButtonConstant = {
  primary: { background: '#00ff9f', boxShadow: 'rgba(0,0,0,0.1)' },
  secondary: { background: '#110c23', boxShadow: 'rgba(17,12,35,0.35)' }
};
class FormButton extends Component {
  render() {
    const {
      children,
      htmlType,
      color,
      onClick,
      className,
      id,
      disabled,
      refProps,
      ...props
    } = this.props;
    return (
      <Button
        ref={refProps}
        bgcolor={ButtonConstant[color]}
        type={htmlType}
        onClick={onClick}
        className={className}
        disabled={disabled}
        {...props}
      >
        {children}
      </Button>
    );
  }
}
FormButton.defaultProps = {
  text: 'Button',
  color: 'primary',
  htmlType: 'button',
  className: '',
  disabled: false,
  refProps: null,
  handleClick: () => {}
};
export default FormButton;
