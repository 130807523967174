export const GETPAYMENTMETHOD_INITIATED = 'GETPAYMENTMETHOD_INITIATED';
export const GETPAYMENTMETHOD_SUCCESS = 'GETPAYMENTMETHOD_SUCCESS';
export const GETPAYMENTMETHOD_ERROR = 'GETPAYMENTMETHOD_ERROR';

export const ADDPAYMENT_INITIATED = 'ADDPAYMENT_INITIATED';
export const ADDPAYMENT_SUCCESS = 'ADDPAYMENT_SUCCESS';
export const ADDPAYMENT_ERROR = 'ADDPAYMENT_ERROR';

export const BOOKCOURIER_INITIATED = 'BOOKCOURIER_INITIATED';
export const BOOKCOURIER_SUCCESS = 'BOOKCOURIER_SUCCESS';
export const BOOKCOURIER_ERROR = 'BOOKCOURIER_ERROR';

export const GETCREDITCARDS_INITIATED = 'GETCREDITCARDS_INITIATED';
export const GETCREDITCARDS_SUCCESS = 'GETCREDITCARDS_SUCCESS';
export const GETCREDITCARDS_ERROR = 'GETCREDITCARDS_ERROR';

export const DELETECREDITCARD_INITIATED = 'DELETECREDITCARD_INITIATED';
export const DELETECREDITCARD_SUCCESS = 'DELETECREDITCARD_SUCCESS';
export const DELETECREDITCARD_ERROR = 'DELETECREDITCARD_ERROR';

export const GETPAYMENTS_INITIATED = 'GETPAYMENTS_INITIATED';
export const GETPAYMENTS_SUCCESS = 'GETPAYMENTS_SUCCESS';
export const GETPAYMENTS_ERROR = 'GETPAYMENTS_ERROR';

export const GETINVOICES_INITIATED = 'GETINVOICES_INITIATED';
export const GETINVOICES_SUCCESS = 'GETINVOICES_SUCCESS';
export const GETINVOICES_ERROR = 'GETINVOICES_ERROR';
