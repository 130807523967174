export const LOGIN_INITIATED = 'LOGIN_INITIATED';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const SET_AUTH = 'SET_AUTH';
export const LOGOUT = 'LOGOUT';
export const LOGOUT_ERROR = 'LOGOUT_ERROR';
export const LOGOUT_INITIATED = 'LOGOUT_INITIATED';
export const SOCIAL_LOGIN = 'SOCIAL_LOGIN';
export const SOCIAL_LOGIN_ERROR = 'SOCIAL_LOGIN_ERROR';
export const SOCIAL_LOGIN_NEW = 'SOCIAL_LOGIN_NEW';
