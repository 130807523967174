const hourslist = [
  0,
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  17,
  18,
  19,
  20,
  21,
  22,
  23
];

const minuteslist = [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55];

export { hourslist, minuteslist };
