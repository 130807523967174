import React, { Component } from 'react';
import { Button } from 'antd';
import { DownloadTemplateWrapper } from './style';

export class DownloadTemplate extends Component {
  render() {
    return (
      <DownloadTemplateWrapper {...this.props}>
        <div className="ant-upload ant-upload-select ant-upload-select-picture-card">
          <div className="ant-upload">Download Template</div>
        </div>
      </DownloadTemplateWrapper>
    );
  }
}
