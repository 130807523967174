import React, { Component } from 'react';
import { connect } from 'react-redux';
import isEmpty from 'lodash.isempty';
import { AutoComplete } from 'antd';
import { onError } from 'modules/errorHandler';
import { GoogleComponentTypes, HandleAddressTypes } from 'App/AppConstant';
import { FormWrapper } from './style';
import { setGeoLocation } from 'redux/shift/actions';
const { Option } = AutoComplete;
// SECTION - Need the custom Google AutoComplete
class GoogleAutoComplete extends Component {
  constructor(props) {
    super(props);
    this.state = {
      resultSuggestion: [],
      currentAddress: {},
      searchValue: '',
      geolocation: {}
    };
    this.autoRef = React.createRef();
    this.autocomplete = null;
  }

  async componentDidMount() {
    try {
      const { googleRef } = this.props;
      if (!isEmpty(googleRef)) {
        this.createAutoComplete(googleRef);
      }
    } catch (error) {
      onError(error, 'GoogleAutoComplete', 'Autocomplete', 'Mount Error');
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.googleRef !== prevProps.googleRef) {
      this.createAutoComplete(this.props.googleRef);
    }
    if (this.state.currentAddress !== prevState.currentAddress) {
      this.props.locationChange(this.state.currentAddress);
    }
    if (this.props.clearAutoComplete !== prevProps.clearAutoComplete) {
      if (this.props.clearAutoComplete) {
        this.setState({
          searchValue: ''
        });
      }
      this.props.resetAutoComplete(false);
    }
  }

  createAutoComplete = async google => {
    this.autocomplete = new google.maps.places.AutocompleteService({
      fields: ['name', 'geometry.location', 'place_id', 'formatted_address', 'types']
    });
  };

  handleSelect = async (placeId, options) => {
    const { googleRef, favourites, userData } = this.props;
    let currentAddress = {},
      geolocation = {};
    if (options.props['data-profile']) {
      HandleAddressTypes.map(field => {
        currentAddress[field] = userData[field];
      });

      this.setState({
        currentAddress,
        searchValue: userData.address1
      });
    } else if (options.props['data-fav']) {
      // Favourites Select
      let payload = {};
      let favData = favourites.find(data => data.id === placeId);
      if (!isEmpty(favData)) {
        for (const key in favData) {
          if (favData[key] && key !== 'id') payload[key] = favData[key];
        }
        payload.isFav = true;
        geolocation.lat = payload.location_lat;
        geolocation.lng = payload.location_lng;
        this.setState({
          currentAddress: payload,
          searchValue: favData.address1,
          geolocation
        });
      }
    } else {
      // Google Autocomplete Select
      if (!placeId) {
        this.setState({
          searchValue: '',
          resultSuggestion: []
        });
      } else {
        new googleRef.maps.places.PlacesService(document.createElement('div')).getDetails(
          { placeId },
          (place, status) => {
            if (status === googleRef.maps.places.PlacesServiceStatus.OK) {
              currentAddress.formatted_address = place.formatted_address;
              if (!isEmpty(place.address_components)) {
                if (place.address_components.length > 4) {
                  // Types Check
                  place.address_components.map(component => {
                    GoogleComponentTypes.map(element => {
                      if (component.types.includes(element.type)) {
                        if (element.type === 'country')
                          currentAddress[element.label] = component.short_name;
                        else currentAddress[element.label] = component.long_name;
                      }
                    });
                  });

                  // If there are no route make the name as address1
                  if (!currentAddress.route) {
                    currentAddress.address1 = place.name;
                  } else {
                    if (!currentAddress.street_number)
                      currentAddress.address1 = currentAddress.route;
                    else
                      currentAddress.address1 = `${currentAddress.street_number} ${currentAddress.route}`;
                  }
                }
              }
              // Establishment Check
              if (place.types.includes('establishment')) currentAddress.company_name = place.name;
              else currentAddress.company_name = '';

              // Geolocation Check
              geolocation.lat = place.geometry.location.lat();
              geolocation.lng = place.geometry.location.lng();
              currentAddress.location_lat = geolocation.lat;
              currentAddress.location_lng = geolocation.lng;
            }

            this.setState({
              currentAddress,
              searchValue: place.formatted_address,
              geolocation
            });
          }
        );
      }
    }
    // this.props.changeOpen();
  };

  onSearch = async text => {
    this.setState({
      searchValue: text
    });
    if (!isEmpty(text)) {
      let autoCompletionRequest = {
        input: text,
        componentRestrictions: {
          country: ['UK', 'GB']
        },
        bounds: this.props.googleRef.maps.LatLngBounds()
      };
      this.autocomplete.getPlacePredictions(autoCompletionRequest, (prediction, status) => {
        if (status === this.props.googleRef.maps.places.PlacesServiceStatus.OK) {
          this.setState({
            resultSuggestion: prediction
          });
        } else {
          this.setState({
            resultSuggestion: []
          });
        }
      });
    } else {
      this.setState({
        resultSuggestion: []
      });
    }
  };

  favFilter = () => {
    let { searchValue } = this.state;
    return x => x.address1.toLowerCase().includes(searchValue.toLowerCase()) || !searchValue;
  };

  render() {
    const {
      favourites,
      placeholder,
      userData,
      isProfileRequired,
      isFavRequired,
      onFocus,
      onChange,
      onBlur,
      page,
      open
    } = this.props;
    let { resultSuggestion, searchValue } = this.state;
    let favsFiltered = !isEmpty(favourites) ? favourites.filter(this.favFilter()) : [];

    let profileFiltered =
      !isEmpty(userData) && userData.address1
        ? userData.address1.toLowerCase().includes(searchValue.toLowerCase()) || !searchValue
        : {};
    profileFiltered = !isEmpty(userData) && isEmpty(userData.address1) && false;
    return (
      <FormWrapper>
        {!isEmpty(searchValue) ? (
          <i className="map-icon purple" />
        ) : (
          <i className="map-icon gray" />
        )}
        <AutoComplete
          placeholder={placeholder}
          onSelect={this.handleSelect}
          value={searchValue}
          onSearch={this.onSearch}
          dropdownClassName="custom-search-dropdown"
          onFocus={onFocus}
          onChange={onChange}
          onBlur={onBlur}
        >
          {isProfileRequired && profileFiltered && (
            <Option value="profile" data-profile={true} className="dark-active">
              {userData.person_name + ', ' + userData.address1}{' '}
              <i className="user-icon las la-user"></i>
            </Option>
          )}
          {isFavRequired &&
            !isEmpty(favsFiltered) &&
            favsFiltered.map(location => (
              <Option key={location.id} value={location.id} data-fav={true} className="dark-active">
                {location.person_name + ', ' + location.address1} <i className="star-icon "></i>
              </Option>
            ))}
          {!isEmpty(resultSuggestion)
            ? resultSuggestion.map(location => {
                return (
                  <Option key={location.place_id} value={location.place_id}>
                    {location.description}
                  </Option>
                );
              })
            : null}
        </AutoComplete>
      </FormWrapper>
    );
  }
}

const mapStateToProps = state => ({
  userData: state.profileUpdate.userData,
  googleRef: state.app.googleRef,
  favourites: state.favourite.favourites
});

GoogleAutoComplete.defaultProps = {
  placeholder: '',
  isFavRequired: true,
  isProfileRequired: true,
  open: false,
  page: ''
};

export default connect(mapStateToProps, {})(GoogleAutoComplete);
