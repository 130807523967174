import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTheme } from 'styled-components';
import { FormWrapper } from './style';
import { FormGroup } from 'components/Form';
class ReviewDropdown extends Component {
  render() {
    const { iconText, data, className } = this.props;
    return (
      <FormWrapper>
        <FormGroup className={className}>
          <div className="weight-dropdown">
            <i className="label-parcel">{iconText}</i>
            <div className="selected-dropdown">
              <div className="dropdown">
                <a className="dropdown-toggle text-color-dark" role="button">
                  {data}
                </a>
              </div>
            </div>
          </div>
        </FormGroup>
      </FormWrapper>
    );
  }
}
ReviewDropdown.defaultProps = {};

const mapStateToProps = state => ({
  appTheme: state.app.theme
});

export default connect(mapStateToProps, {})(withTheme(ReviewDropdown));
