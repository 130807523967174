import styled from 'styled-components';
import { size } from 'App/device';
const FormWrapper = styled.div`
  .text {
    font-size: 15px;
    line-height: 18px;
    color: ${props => props.theme.primaryColor};
    display: inline-block;
    vertical-align: middle;
    padding: 5px 0;
    margin: 2px 12px;
    @media ${size['tablet-max']} {
      font-size: 14px;
      margin: 2px 5px;
    }
    &.font-medium {
      font-weight: 500;
    }
  }
  .switch {
    width: 50px;
    border: 0;
    background: #fff !important;
    height: 22px;
    box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.15);
    border-radius: 30px;
    -webkit-border-radius: 30px;
    &.ant-switch-checked:after {
      background: #e27c17;
    }
    :after {
      width: 16px;
      height: 16px;
      border-radius: 50%;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      border: 0;
      top: 2px;
      left: 3px;
      background: #e27c17;
    }
    &.ant-switch-checked:after {
      left: 96%;
    }
  }
`;

export { FormWrapper };
