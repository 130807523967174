import styled from 'styled-components';
import { size } from 'App/device';
const FormWrapper = styled.div`
  & label {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    text-transform: uppercase;
    line-height: 14px;
    color: #816e9b;
  }
  .fl-right {
    float: right;
  }
  .after {
    /* margin-left: 166px; */
    margin-left: 150px;
    @media ${size['desktop-sm-max']} {
      margin-left: 150px;
    }
  }
  .deadline {
    /* margin-left: 34px; */
    margin-left: 93px;
  }
  .drop {
    margin-left: 160px;
    /* @media ${size['desktop-sm-max']} {
      margin-left: 90px;
    } */
  }
  .jobType {
    text-align: center;
    margin-bottom: 0;
    padding-top: 5px;
    margin-right: 30px;
    &.orange {
        color: #FFA100;
    }
    &.red {
        color: #E8286B;
    }
    &.purple {
        color: #8f48ff;
    }
    &.green {
        color: #00C68D;
    }
  }
  label.focus {
    opacity: 1 !important;
    color: ${props => props.theme.secondaryColor};
  }
`;

export { FormWrapper };
