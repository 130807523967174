import { Row } from 'antd';
import { size } from 'App/device';
import { borderRadius, customScroller, transition } from 'App/mixins';
import arrowRightLargeIcon from 'assets/images/arrow-right-large-icon.svg';
import bookIcon from 'assets/images/book-left-icon.svg';
import ParceldeleteIcon from 'assets/images/booking/delete-icon.svg';
import closeIcon from 'assets/images/close-white-icon.svg';
import cupIcon from 'assets/images/cup-icon.svg';
import deleteIcon from 'assets/images/delete-icon-black.svg';
import deleteIconDisabled from 'assets/images/delete-icon-disabled.svg';
import deliveryOrangeIcon from 'assets/images/delivery-small-icon-orange.svg';
import dollerIcon from 'assets/images/doller-icon-white.svg';
import dpdLogo from 'assets/images/dpd-logo.jpeg';
import draggableIcon from 'assets/images/draggable-icon.svg';
import { default as dropdownArrow, default as dropdownArrowIcon } from 'assets/images/dropdown-arrow.svg';
import editPen from 'assets/images/edit-pen.svg';
import emailIcon from 'assets/images/email-icon.svg';
import filterDarkIcon from 'assets/images/filter-dark-icon.svg';
import infoIcon1 from 'assets/images/info-icon1.svg';
import infoWhiteIcon from 'assets/images/info-white-icon.svg';
import itemIcon from 'assets/images/item-icon.svg';
import locationSend from 'assets/images/location-send.svg';
import gophrLogo from 'assets/images/logo-shape-white.svg';
import mapDarkIcon from 'assets/images/map-dark-icon.svg';
import pickupSmallIcon from 'assets/images/pickup-small-icon.svg';
import printerRequiredIcon from 'assets/images/printer-required-icon.png';
import refrenceIcon from 'assets/images/refrence-icon.png';
import refreshIcon from 'assets/images/refresh-icon.svg';
import searchDarkIcon from 'assets/images/search-dark-icon.svg';
import { default as settingsIcon, default as settingsIconGray } from 'assets/images/settings-icon-gray.svg';
import shareIcon from 'assets/images/share-icon.svg';
import sizeIcon from 'assets/images/size-icon.svg';
import threeDots from 'assets/images/three-dots.svg';
import tracking from 'assets/images/tracking.svg';
import upArrow from 'assets/images/up-arrow.svg';
import warningIcon from 'assets/images/warning-icon.svg';
import whiteArrow from 'assets/images/white-arrow.svg';
import { FormWrapper as SelectFormWrapper } from 'components/Select/style';
import styled from 'styled-components';

const BookingWrapper = styled.div`
  .middle-section {
    margin-top: 95px;
    position: relative;
    z-index: 1;
    @media ${size['laptop-max']} {
      margin-top: 65px;
    }
    .max-container {
      max-width: 1790px;
      width: 100%;
      margin: 0 auto;
      padding: 0 15px;
      @media ${size['desktop-md-max']} {
        max-width: 100%;
        padding: 0 4%;
      }
      @media ${size['laptop-max']} {
        max-width: 100%;
        padding: 0 20px;
      }
      @media ${size['tablet-max']} {
        padding: 0 15px;
      }
    }
    .page-info {
      margin-bottom: 30px;
      h2 {
        font-size: 40px;
        line-height: 47px;
        margin: 0 0 5px 0;
        @media ${size['desktop-mm-max']} {
          font-size: 36px;
          line-height: 36px;
        }
        @media ${size['desktop-sm-max']} {
          font-size: 36px;
        }
        @media ${size['laptop-max']} {
          font-size: 30px;
        }
        @media ${size['laptop-sm-max']} {
          font-size: 28px;
        }
        @media ${size['tablet-max']} {
          font-size: 26px;
          line-height: normal;
        }
      }
      p {
        font-size: 18px;
        line-height: 22px;
        margin: 0;
        color: #707070;
        letter-spacing: 0.025em;
        @media ${size['desktop-mm-max']} {
          font-size: 16px;
          line-height: 20px;
        }
        @media ${size['tablet-max']} {
          font-size: 14px;
          line-height: 18px;
        }
      }
    }
    .padding-40 {
      padding-left: 40px;
    }
    .page-info.padding-40 {
      @media ${size['tablet-max']} {
        padding-left: 0;
      }
    }
  }
`;

const AccordianStyle = styled.div`
  padding: 0;
  @media ${size['desktop-mm-max']} {
    margin: 0 -10px;
  }
  @media ${size['tablet-max']} {
    margin: 0 -20px;
  }
  .parcel-local-id {
    color: ${(props) => props.theme.secondaryColor};
    margin-right: 10px;
  }
  .ant-collapse {
    border: 0;
    width: 100%;
    background-color: transparent;
  }
  .ant-collapse-content {
    background-color: transparent;
    border-top: 0;
  }
  .ant-collapse > .ant-collapse-item {
    border-bottom: 0;
  }
  .ant-collapse-content > .ant-collapse-content-box {
    padding: 0px;
  }
  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    padding: 0;
  }
  .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow svg {
    display: none;
  }
  .ant-collapse-item.ant-collapse-item-active {
    .toggle-icon-main {
      transform: rotate(180deg);
    }
  }
  .ant-collapse-header {
    .collapse-header,
    .marketplace-modal {
      padding: 10px 40px 10px 80px;
      list-style: none;
      position: relative;
      font-size: 16px;
      line-height: 20px;
      color: #49465d;
      font-weight: 400;
      transition: all 0.4s ease;
      -webkit-transition: all 0.4s ease;
      -moz-transition: all 0.4s ease;
      cursor: pointer;
      -webkit-flex: flex;
      display: flex;
      align-items: center;
      margin: 0px;
      @media ${size['desktop-mm-max']} {
        font-size: 15px;
        // padding: 15px 30px 15px 60px;
      }
      @media ${size['tablet-max']} {
        font-size: 13px;
        // padding: 10px 20px 10px 40px;
      }
      @media ${size['mobile-md-max']} {
        // padding-left: 20px !important;
      }
      :hover {
        background: #fafafa;
        font-weight: 700 !important;
        .toggle-icon-main,
        .draggable-icon,
        .setting-btn {
          opacity: 1 !important;
        }
      }
      .collapse-header {
        padding: 10px 40px 10px 80px;
        list-style: none;
        position: relative;
        font-size: 16px;
        line-height: 20px;
        color: #49465d;
        font-weight: 400;
        transition: all 0.4s ease;
        -webkit-transition: all 0.4s ease;
        -moz-transition: all 0.4s ease;
        cursor: pointer;
        -webkit-flex: flex;
        display: flex;
        align-items: center;
        margin: 0px;
        @media ${size['desktop-mm-max']} {
          font-size: 15px;
          padding: 15px 30px 15px 60px;
        }
        @media ${size['tablet-max']} {
          font-size: 13px;
          padding: 10px 20px 10px 40px;
        }
        @media ${size['mobile-md-max']} {
          padding-left: 70px !important;
        }
        :hover {
          background: #fafafa;
          font-weight: 700 !important;
          .toggle-icon-main,
          .draggable-icon,
          .setting-btn {
            opacity: 1 !important;
          }
        }
      }
      :before {
        display: none;
        position: absolute;
        left: 50px;
        top: 26px;
        width: 4px;
        height: 4px;
        background: #c4c4c4;
        border-radius: 50%;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        content: '';
        @media ${size['desktop-mm-max']} {
          top: 28px;
          // left: 40px;
        }
        @media ${size['tablet-max']} {
          top: 22px;
          // left: 25px;
        }
      }
      span {
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding-right: 10px;
        @media ${size['tablet-max']} {
          width: 70%;
        }
        @media ${size['mobile-md-max']} {
          width: 55%;
        }
      }
      .address-bar {
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding-right: 10px;
        @media ${size['desktop-md-max']} {
          max-width: 68%;
        }
        @media ${size['mobile-md-max']} {
          max-width: 65%;
        }
        @media ${size['mobile-sm-max']} {
          max-width: 42%;
        }
      }
      .toggle-icon-main {
        height: 30px;
        width: 30px;
        background: #dedede url(${dropdownArrow}) no-repeat center;
        background-size: 8px auto;
        border-radius: 50%;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        opacity: 1;
        transition: all 0.4s ease;
        -webkit-transition: all 0.4s ease;
        -moz-transition: all 0.4s ease;
        display: inline-block;
        vertical-align: middle;
        margin-right: 25px;
        @media ${size['desktop-mm-max']} {
          margin-right: 20px;
        }
        @media ${size['tablet-max']} {
          margin-right: 5px;
        }
      }
      .setting-btn {
        background: url(${settingsIconGray}) no-repeat center;
        width: 22px;
        height: 22px;
        margin-left: 25px;
        background-size: 100% auto;
        border: 0;
        opacity: 0;
        transition: all 0.4s ease;
        -webkit-transition: all 0.4s ease;
        -moz-transition: all 0.4s ease;
        display: inline-block;
        vertical-align: middle;
        @media ${size['desktop-mm-max']} {
          width: 20px;
          height: 20px;
        }
        @media ${size['tablet-max']} {
          width: 15px;
          height: 15px;
        }
      }
      .draggable-icon {
        background: url(${draggableIcon}) no-repeat center;
        width: 23px;
        height: 10px;
        background-size: 100% auto;
        border: 0;
        margin-left: 25px;
        opacity: 0;
        transition: all 0.4s ease;
        -webkit-transition: all 0.4s ease;
        -moz-transition: all 0.4s ease;
        display: inline-block;
        vertical-align: middle;
        @media ${size['desktop-mm-max']} {
          margin-left: 20px;
          width: 20px;
        }
        @media ${size['tablet-max']} {
          margin-left: 10px;
          width: 15px;
        }
      }
    }
    .pickup-dropoff-main {
      padding-right: 10px;
      padding-left: 45px;
      font-size: 18px;
      line-height: 21px;
      color: #49465d;
      width: 100%;
      align-items: center;
      @media ${size['desktop-mm-max']} {
        font-size: 16px;
      }
      @media ${size['tablet-max']} {
        font-size: 14px;
      }
      &:before {
        position: absolute;
        left: 16px;
        top: 30px;
        width: 4px;
        height: 4px;
        background: #c4c4c4;
        border-radius: 50%;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        content: '';
      }
      .price-text {
        font-weight: 400;
        line-height: 36px;
        color: #424242;
      }
      .pickup-dropoff {
        width: 60%;
        span {
          display: inline-block;
          vertical-align: middle;
          width: unset;
          white-space: unset;
        }
        i {
          display: inline-block;
          vertical-align: middle;
          margin: 0 15px;
          background: url(${arrowRightLargeIcon}) no-repeat center;
          background-size: 100% auto;
          width: 24px;
          height: 10px;
          @media ${size['tablet-max']} {
            margin: 0 5px;
            width: 15px;
          }
        }
      }
      .toggle-icon-main {
        height: 30px;
        width: 30px;
        margin: 0 auto;
        background: #dedede url(${dropdownArrow}) no-repeat center;
        background-size: 8px auto;
        border-radius: 50%;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        opacity: 0;
        transition: all 0.4s ease;
        -webkit-transition: all 0.4s ease;
        -moz-transition: all 0.4s ease;
        @media ${size['desktop-mm-max']} {
          margin-right: 20px;
        }
      }
      .price-text {
        margin-left: auto;
        font-size: 20px;
        color: #424242;
        @media ${size['desktop-mm-max']} {
          font-size: 18px;
        }
        @media ${size['tablet-max']} {
          font-size: 14px;
        }
        .job-boards {
          margin-left: none;
        }
      }
    }
  }
  .ant-collapse-content {
    .under-list-accordion {
      padding: 0;
      margin-top: 10px;
      display: flex;
      .parcel-store-data-wrapper {
        width: 100%;
        padding: 0 20px 20px 20px;
        label {
          font-size: 14px;
        }
        p {
          font-size: 15px;
          color: #323232;
          line-height: 20px;
        }
      }
      .location-dots {
        border: 0;
        background: none;
        padding: 0;
        font-size: 32px;
        color: #49465d;
        @media ${size['tablet-max']} {
          font-size: 26px;
        }
        i {
          line-height: 20px;
        }
      }
      ul.parcel-items.modal {
        li {
          padding: 0 40px 0 45px;
          min-height: 60px;
          width: 350px;
          float: right;
          list-style: none;
          position: relative;
          font-size: 18px;
          line-height: 20px;
          color: #323232;
          font-weight: 500;
          transition: all 0.4s ease;
          -webkit-transition: all 0.4s ease;
          -moz-transition: all 0.4s ease;
          cursor: pointer;
          -webkit-flex: flex;
          display: flex;
          align-items: center;
          @media ${size['desktop-mm-max']} {
            font-size: 15px;
          }
          @media ${size['tablet-max']} {
            font-size: 13px;
            padding: 0 20px 0 50px;
            min-height: 50px;
          }
          @media ${size['mobile-md-max']} {
            // padding-left: 20px !important;
          }
          :hover {
            background: #fff;
            font-weight: 400 !important;
          }
          span {
            font-weight: 500;
            font-size: 15px;
            line-height: 18px;
            color: #49465d;
            max-width: 100%;
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            padding-right: 10px;
            @media ${size['tablet-max']} {
              font-size: 13px;
            }
          }
        }
      }
      ul.parcel-items {
        margin: 0 !important;
        width: 100%;
        padding: 0;
        li {
          padding: 0 40px 0 95px;
          min-height: 60px;
          list-style: none;
          position: relative;
          font-size: 18px;
          line-height: 20px;
          color: #49465d;
          font-weight: 400;
          transition: all 0.4s ease;
          -webkit-transition: all 0.4s ease;
          -moz-transition: all 0.4s ease;
          cursor: pointer;
          -webkit-flex: flex;
          display: flex;
          align-items: center;
          @media ${size['tablet-max']} {
            padding: 0 20px 0 50px;
            min-height: 50px;
          }
          :hover {
            background: #fafafa;
            font-weight: 700 !important;
          }
          span {
            font-weight: 500;
            font-size: 15px;
            line-height: 18px;
            color: #49465d;
            max-width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            padding-right: 10px;
            @media ${size['tablet-max']} {
              font-size: 13px;
              width: 70%;
            }
            @media ${size['mobile-md-max']} {
              width: 55%;
            }
          }
        }
      }
      .parcel-list {
        margin: auto !important;
        .download-label {
          color: black !important;
          text-decoration: none !important;
        }
        .print-label:hover,
        .download-label:hover {
          color: #00ff9f !important;
        }
      }
    }
  }
  .parcel-details {
    font-size: 18px;
    line-height: 154%;
    color: #696969;
    margin: 0;
    font-weight: 400;
    padding: 0 40px 0 95px;
  }
  &#parcel-item-preview {
    .ant-collapse {
      .ant-collapse-header {
        .collapse-header {
          padding: 10px 40px 10px 100px;
          ${transition(0.4)};
          @media ${size['desktop-mm-max']} {
            padding: 10px 40px 10px 120px;
          }
          @media ${size['tablet-max']} {
            padding: 10px 40px 10px 70px;
            font-size: 14px;
          }
          &.parcel-list-active {
            /* background: #8f48ff21; */
          }
        }
      }
    }
  }
`;
const CouriersWrapper = styled.div`
  .find-courier-list {
    margin: 0 -35px;
    @media ${size['desktop-mm-max']} {
      margin: 0 -25px;
    }
    @media ${size['tablet-md-max']} {
      margin: 0 -15px;
    }
    .courier-image {
      width: 56px;
      min-width: 56px;
      max-width: 56px;
      // height: 56px;
      background: #c4c4c4;
      border-radius: 50%;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      display: inline-block;
      vertical-align: middle;
      margin-right: 20px;
      overflow: hidden;
      // background: url(${dpdLogo}) no-repeat;
      background-size: 100px 87px;
      background-position: -24px 3px;
      @media ${size['desktop-mm-max']} {
        margin-right: 15px;
        width: 46px;
        min-width: 46px;
        // height: 46px;
        background-size: 88px 75px;
        background-position: -22px 4px;
      }
      @media ${size['desktop-md-max']} {
        margin-right: 15px;
      }
    }
    .ant-collapse-item {
      margin: 0 0 15px 0;
    }
  }
  ul {
    max-height: 100%;
    height: 100%;
    padding: 0;
    margin: 0 0 0 0;
    @media ${size['mobile-sm-max']} {
      padding: 0;
    }
    @media ${size['mobile-md-max']} {
      padding: 0;
    }
    li.list-head {
      width: auto;
      padding: 20px 20px;
      margin: 0;
      padding-bottom: 15px;
      -moz-transition: all 0.4s ease;
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 600;
      font-size: 9.24882px;
      line-height: 11px;
      letter-spacing: 0.05em;
      color: #49465d;
    }
    li {
      list-style: none;

      -webkit-flex: flex;
      display: flex;
      flex-wrap: wrap;
      cursor: pointer;
      border-radius: 5px;
      -webkit-border-radius: 5px;
      -moz-border-radius: 5px;
      transition: all 0.4s ease;
      -webkit-transition: all 0.4s ease;
      -moz-transition: all 0.4s ease;
      @media ${size['tablet-max']} {
        width: 768px;
      }
      @media ${size['desktop-md-max']} {
        margin: 0 -10px;
        padding: 15px 0;
      }
      strong {
        font-weight: 500;
        font-size: 18px;
        line-height: 21px;
        color: #49465d;
        margin: 0;
      }
    }
    .couriers-name-image.detail {
      padding-left: 0 !important;
    }
    .couriers-name-image {
      width: 50%;
      min-width: 50%;
      max-width: 50%;
      align-items: center;
      @media ${size['desktop-md-max']} {
        padding-right: 10px;
        padding-left: 10px;
      }
      @media ${size['tablet-max']} {
        width: 40%;
        min-width: 40%;
        max-width: 40%;
      }
    }
    .couriers-rating-main,
    .couriers-price-main {
      width: 16%;
      min-width: 16%;
      max-width: 16%;
    }
    .custom {
      padding-left: 0 !important;
    }
    .couriers-name-image,
    .couriers-price-main,
    .couriers-rating-main,
    .info-and-toggle-main {
      padding-left: 15px;
      padding-right: 15px;
      font-family: ${(props) => props.theme.secondaryFont};
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      @media ${size['desktop-md-max']} {
        padding-right: 10px;
        padding-left: 10px;
      }
      @media ${size['mobile-sm-max']} {
        padding-right: 10px;
        padding-left: 45px;
      }
      @media ${size['mobile-md-max']} {
        padding-right: 10px;
        padding-left: 45px;
      }
      P {
        margin-bottom: 0;
      }
      &.custom {
        font-family: ${(props) => props.theme.primaryFont};
        font-size: 12px;
        font-weight: 600;
      }
    }
    .info-and-toggle-main {
      width: 18%;
      min-width: 18%;
      max-width: 18%;
      align-items: flex-start;
      .toggle-icon {
        background: url(${dropdownArrow}) no-repeat center;
        width: 8px;
        height: 8px;
        background-size: 100% auto;
        transition: all 0.4s ease;
        -webkit-transition: all 0.4s ease;
        -moz-transition: all 0.4s ease;
      }
      .info-tooltip {
        width: 70%;
        i {
          font-size: 20px;
          color: #a4a4a4;
        }
        .toggle-icon {
          margin-left: auto;
          margin-top: 6px;
        }
      }
    }
    .couriers-info {
      h6 {
        font-family: ${(props) => props.theme.secondaryFont};
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 17px;
        color: #323232;
        mix-blend-mode: normal;
        margin: 0;
        @media ${size['laptop-md-max']} {
          font-size: 14px;
        }
        @media ${size['laptop-max']} {
          font-size: 18px;
        }
        @media ${size['laptop-sm-max']} {
          font-size: 16px;
        }
        @media ${size['tablet-max']} {
          font-size: 15px;
        }
      }
      p {
        font-family: ${(props) => props.theme.secondaryFont};
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 15px;
        color: #323232;
        mix-blend-mode: normal;
        margin: 0;
        @media ${size['laptop-md-max']} {
          font-size: 14px;
          line-height: 17px;
        }
        @media ${size['desktop-mm-max']} {
          font-size: 16px;
          line-height: 19px;
        }
      }
    }
    .ant-collapse {
      width: 100%;
      border: 0;
      border-radius: 0;
      background-color: transparent;
      .ant-collapse-item {
        padding: 0 20px;
        &.selected-service {
          background: #8f48ff;
          ${borderRadius('4px')};
          .courier-image {
            ${borderRadius('04px')};
          }
          .couriers-info {
            h6,
            p {
              color: #fff;
            }
          }
          .couriers-price-main {
            color: #fff;
          }
        }
        &.ant-collapse-item-active {
          background: #fff;
          .couriers-info {
            h6 {
              color: #49465d;
            }
            p {
              color: #606060;
            }
          }
          .couriers-price-main {
            color: #49465d;
          }
        }
      }
    }
    .ant-collapse-item:last-child > .ant-collapse-content {
      border-radius: 0;
    }
    .ant-collapse > .ant-collapse-item {
      border-bottom: 0;
    }
    .ant-collapse-item.ant-collapse-item-active {
      padding: 0 20px;
      box-shadow: 10px 10px 25px rgba(0, 0, 0, 0.1);
    }
    .ant-collapse > .ant-collapse-item > .ant-collapse-header {
      padding: 14px 0;
      @media ${size['desktop-sm-max']} {
        padding: 10px 0;
      }
    }
    .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
      top: 32%;
      right: 3%;
      left: unset;
      @media ${size['mobile-sm-max']} {
        right: -5%;
      }
      @media ${size['mobile-sm-max']} {
        right: -5%;
      }
    }
    .ant-collapse-item-active {
      .ant-collapse-content-box {
        padding: 0 !important;
      }
      .ant-collapse-arrow {
        transform: rotate(180deg) !important;
      }
    }
    .ant-collapse-content {
      background-color: transparent;
      border-top: 0;
    }
    .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
      background: url(${dropdownArrow}) no-repeat center;
      width: 8px;
      height: 8px;
      background-size: 100% auto;
      transition: all 0.4s ease;
      -webkit-transition: all 0.4s ease;
      -moz-transition: all 0.4s ease;
      svg {
        display: none;
      }
    }
    .ant-collapse-content > .ant-collapse-content-box {
      padding: 20px 15px;
      padding-bottom: 20px !important;
      width: 100%;
      min-width: 100%;
      max-width: 100%;
      -webkit-align-items: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      .accor-div {
        .accor-left {
          flex-wrap: wrap;
          width: 100%;
          min-width: 100%;
          max-width: 100%;
          margin: -10px 0;
        }
        .icon-with-text {
          padding-right: 20px;
          margin: 20px 0;
          width: 33.33%;
          height: 30px;
          overflow: hidden;
          @media ${size['tablet-max']} {
            width: 100%;
          }
        }
        div + div {
          margin: 20px 0;

          @media ${size['desktop-md-max']} {
            margin: 10px 0;
          }
        }
        .icon-with-text-btn {
          width: 33.33%;
          margin: 10px 0;
          @media ${size['desktop-md-max']} {
            margin: 0;
          }
        }
        .btn {
          // min-width: 220px;
          // height: 62px;
          // @media ${size['desktop-md-max']} {
          //   min-width: 185px;
          //   font-size: 13px;
          //   height: 54px;
          //   line-height: 54px;
          // }
          @media ${size['desktop-sm-max']} {
            min-width: 175px;
            font-size: 12px;
            // height: 50px;
            // line-height: 50px;
            padding: 0 10px;
            width: auto;
          }
        }
        .icon-with-text {
          position: relative;
          padding-left: 35px;
          -webkit-flex: flex;
          display: flex;
          align-items: center;
          min-height: 30px;
          strong {
            font-size: 12px !important;
            color: #787878 !important;
            white-space: nowrap;
            @media ${size['desktop-md-max']} {
              font-size: 10px !important;
            }
            @media ${size['desktop-mm-max']} {
              font-size: 16px;
              line-height: 19px;
            }
            @media ${size['laptop-md-max']} {
              font-size: 14px;
              line-height: 17px;
            }
          }
          i.arrow-icon {
            background: #ec00ff url(${whiteArrow}) no-repeat center;
            background-size: 8px auto;
          }
          i.doller-icon {
            background: #ec00ff url(${dollerIcon}) no-repeat center;
            background-size: 8px auto;
          }
          i.tracking-icon {
            background: #ec00ff url(${tracking}) no-repeat center;
            background-size: 14px auto;
          }
          i.collection-icon {
            background: #ec00ff url(${emailIcon}) no-repeat center;
            background-size: 11px auto;
          }
          i.printer-icon {
            background: #ec00ff url(${printerRequiredIcon}) no-repeat center;
            background-size: 14px auto;
          }
          i {
            width: 30px;
            height: 30px;
            display: inline-block;
            background: #ec00ff;
            border-radius: 5px;
            -webkit-border-radius: 5px;
            -moz-border-radius: 5px;
            box-shadow: 0px 4px 20px rgba(236, 0, 255, 0.15);
            margin-right: 10px;
            position: absolute;
            left: 0;
            top: 0;
            @media ${size['desktop-md-max']} {
              margin-right: 8px;
              width: 25px;
              height: 25px;
            }
          }
        }
      }
    }
  }
`;
const MiddleSection = styled.div`
  margin-top: 0px;
  position: relative;
  @media ${size['laptop-max']} {
    margin-top: 65px;
  }
`;
const MaxContainer = styled.div`
  //max-width: 1500px;
  width: 100%;
  padding: 0 15px;
  margin: 0 auto;

  &.increase-width{
    max-width: 1800px;
  }

  &.max {
    // max-width: 1400px;
    .gmIBJv .ant-tabs .ant-tabs-nav .ant-tabs-tab {
      font-size: 12px;
      min-width: 0;
    }
    .ant-tabs-tab-active.ant-tabs-tab {
      font-size: 12px;
      bottom: -4px;
      height: 90%;
      border-radius: 5px;
    }
    .ant-tabs-nav .ant-tabs-tab {
      bottom: -4px;
      height: 90%;
    }
  }

  .ant-popover {
    .ant-btn {
      text-transform: uppercase;
      width: 130px;
      letter-spacing: 1px;
        height: 34px;
        font-size: 12px;
        font-weight: 600;
        background: #fff !important;
        color: black !important;
        border-radius: 4.26875px !important;
        border: 0.711458px solid rgba(223,223,223,0.75) !important;
      &:hover{
        border: 1px solid #E8286B !important;
      }
      &.ant-btn-primary {
        border: 1px solid ${(props) => props.theme.primaryButtonColor} !important;
        background-color: ${(props) => props.theme.primaryButtonColor} !important;
        color: #fff !important;
        &:hover{
          background-color: #fff !important;
          color: ${(props) => props.theme.primaryButtonColor} !important;
          border: 1px solid ${(props) => props.theme.primaryButtonColor} !important;
        }
        }
      }
    }
  }
  .cancle-section {
    .cancle-radio {
      .ant-radio-group .ant-radio-wrapper .ant-radio {
        top: 5px !important;
      }
    }
    .filter-input {
      position: absolute;
      margin-top: -65px;
      left: 40%;
      width: 50%;
    }
    .filter-select {
      position: absolute;
      top: 45%;
      left: 40%;
      width: 50%;
    }
    .unallocated-txt {
      font-size: 20px;
      margin-top: 5px;
      margin-bottom: 0;
    }
  }
  @media ${size['desktop-md-max']} {
    //max-width: 100%;
    // padding: 0 2%;
  }
  @media ${size['laptop-max']} {
    //max-width: 100%;
    padding: 0 20px;
  }
  @media ${size['tablet-max']} {
    padding: 0 20px;
  }
  .search-wrapper + .ant-collapse {
    padding-top: 60px !important;
    background-color: white;
  }
  .nav-btn-wrapper {
    border: 1px solid #DFDFDF;
    box-sizing: border-box;
    border-radius: 10px 10px 0px 0px;
  }
`;
const PageInfo = styled.div`
  margin-bottom: 14px;
  h2 {
    font-size: 36px;
    line-height: 47px;
    margin: 0;
    @media ${size['desktop-mm-max']} {
      font-size: 36px;
      line-height: 36px;
    }
    @media ${size['desktop-sm-max']} {
      font-size: 36px;
    }
    @media ${size['laptop-max']} {
      font-size: 30px;
    }
    @media ${size['laptop-sm-max']} {
      font-size: 28px;
    }
    @media ${size['tablet-max']} {
      font-size: 26px;
      line-height: normal;
    }
  }
  p {
    font-size: 16px;
    line-height: 22px;
    margin: 0;
    color: #707070;
    letter-spacing: 0.025em;
    @media ${size['desktop-mm-max']} {
      font-size: 16px;
      line-height: 20px;
    }
    @media ${size['tablet-max']} {
      font-size: 14px;
      line-height: 18px;
    }
  }
  &.light-text {
    h2 {
      font-weight: 400;
    }
    p {
      text-transform: uppercase;
      -webkit-letter-spacing: 0;
      -moz-letter-spacing: 0;
      -ms-letter-spacing: 0;
      letter-spacing: 0;
    }
  }
`;

const CardHeader = styled.div`
  .ant-tooltip {
    max-width: 300px;
    z-index: 99999;
  }
  .job-priority-wrapper {
    width: fit-content;
  }
  .ant-tooltip-inner {
    background: #8f48ff;
    padding: 15px;
    .custom-tooltip-inner font {
      text-transform: none;
      font-weight: 300;
    }
    strong {
      text-transform: none;
      font-weight: 500;
    }
  }
  .ant-tooltip-arrow::before {
    background-color: #8f48ff;
    width: 9px;
    height: 9px;
  }
  .showarrow {
    width: 13px;
    height: 13px;
    // margin-left: auto;
    background: url(${dropdownArrowIcon}) no-repeat center;
    cursor: pointer;
    -webkit-transition: all 0.4s ease;
    transform: rotate(180deg) !important;
    &.no {
      transform: rotate(0deg) !important;
      -webkit-transition: all 0.4s ease;
    }
  }
  .info-icon1 {
    width: 13px;
    height: 13px;
    display: inline-block;
    background: url(${infoIcon1}) no-repeat center;
    background-size: 100% auto;
    vertical-align: middle;
  }
  i {
    cursor: pointer;
  }
  .info-white-icon {
    width: 13px;
    height: 13px;
    display: inline-block;
    background: url(${infoWhiteIcon}) no-repeat center;
    background-size: 100% auto;
    vertical-align: middle;
    margin-left: auto;
  }
  .edit-btn {
    width: 20px;
    height: 20px;
    cursor: pointer;
    path {
      fill: #000;
    }
    margin-left: 20px;
  }
  .info-white-icon,
  .info-icon,
  .info-icon1 {
    background-size: 100% auto;
    margin: -1px 0 0 10px;
  }
  .card-header .panel-next-day-text {
    font-size: 10px;
    line-height: 12px;
    -webkit-letter-spacing: 0.105em;
    -moz-letter-spacing: 0.105em;
    -ms-letter-spacing: 0.105em;
    letter-spacing: 0.105em;
    font-weight: 400;
  }
  .showarrow {
    width: 13px;
    // margin-left: auto;
    background: url(${dropdownArrowIcon}) no-repeat center;
    cursor: pointer;
    -webkit-transition: all 0.4s ease;
    transform: rotate(180deg) !important;
    &.no {
      transform: rotate(0deg) !important;
      -webkit-transition: all 0.4s ease;
    }
  }
`;
const DropdownWrapper = styled.div`
  .ant-dropdown {
    ul {
      transform: none !important;
      right: 0;
      left: auto !important;
      top: 30px !important;
      width: 285px !important;
      background: #fff;
      box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
      border-radius: 7px;
      -webkit-border-radius: 7px;
      -moz-border-radius: 7px;
      border: 0;
      padding: 10px 0;
      margin: 0;
      list-style-type: none;
      padding-left: 0;
      &.location-item-menus {
        width: 100% !important;
        .ant-menu-item {
          align-items: baseline !important;
        }
      }
      li.ant-menu-item:hover {
        background: #fafafa;
        font-weight: 700 !important;
        color: rgba(0, 0, 0, 0.65);
        i.ant-menu-submenu-arrow::after,
        i.ant-menu-submenu-arrow::before {
          background: rgba(0, 0, 0, 0.65);
        }
      }
      li {
        padding: 12px 35px !important;
        font-weight: normal;
        font-size: 18px;
        line-height: 20px;
        :hover {
          background: #fafafa;
          font-weight: 700 !important;
          .parcel-toggle-btn {
            background: #e8286b;
          }
          i.ant-menu-submenu-arrow {
            transform: rotate(180deg);
            color: rgba(0, 0, 0, 0.65);
          }
        }
        &.ant-menu-submenu:first-child {
          padding: 0 !important;
        }
        .ant-menu-submenu-title {
          font-weight: normal;
          font-size: 18px;
          padding: 0 35px !important;
          :hover {
            color: rgba(0, 0, 0, 0.65);
            i.ant-menu-submenu-arrow::after,
            i.ant-menu-submenu-arrow::before {
              background: rgba(0, 0, 0, 0.65);
            }
          }
        }
      }
    }
  }
`;
const LocationPreviewWrapper = styled.div`
  width: 100%;
  .location-preview {
    .pickup-dropoff-main {
      font-size: 18px;
      line-height: 21px;
      color: #49465d;
      width: 100%;
      align-items: center;
      @media ${size['desktop-mm-max']} {
        font-size: 16px;
      }
      @media ${size['tablet-max']} {
        font-size: 14px;
        i {
          margin: 0 5px;
          width: 15px;
        }
      }
      .pickup-dropoff {
        width: 60%;
      }
    }

    ul.net-gross-price {
      &:hover {
        background: transparent !important;
      }
      li {
        &:hover {
          background: transparent !important;
        }
      }
    }
    ul.total-price li {
      padding-top: 0;
      padding-bottom: 0;
    }
    ul.total-price li p {
      font-weight: normal;
      font-size: 16px;
      line-height: 18px;
      color: #a9a9a9;
      margin: 0;
      letter-spacing: 0.025em;
      @media ${size['desktop-mm-max']} {
        font-size: 16px;
        line-height: 18px;
      }
      @media ${size['tablet-max']} {
        font-size: 14px;
      }
    }
    ul.total-price li .pickup-dropoff-main .price-text {
      margin-left: auto;
      font-weight: 500;
      font-size: 22px;
      line-height: 22px;
      color: #424242;
      @media ${size['tablet-max']} {
        font-size: 20px;
      }
    }

    .beforenone:before {
      display: none;
    }
    .order-receipt-list {
      margin: 0 -35px;
      padding: 0;
      li {
        margin: 0px;
        list-style: none;
        position: relative;
        font-size: 18px;
        line-height: 20px;
        color: #49465d;
        font-weight: 400;
        padding: 20px 40px 20px 80px;
        transition: all 0.4s ease;
        -webkit-transition: all 0.4s ease;
        -moz-transition: all 0.4s ease;
        cursor: pointer;
        -webkit-flex: flex;
        display: flex;
        align-items: center;
        flex-direction: column;
        min-height: 70px;
        justify-content: center;
        @media ${size['desktop-mm-max']} {
          min-height: 60px;
          font-weight: 500;
        }
        @media ${size['tablet-max']} {
          min-height: 46px;
        }
        :hover .toggle-icon-main {
          opacity: 1 !important;
        }
        :before {
          position: absolute;
          left: 50px;
          top: 33px;
          width: 4px;
          height: 4px;
          background: #c4c4c4;
          border-radius: 50%;
          -webkit-border-radius: 50%;
          -moz-border-radius: 50%;
          content: '';
          @media ${size['desktop-mm-max']} {
            top: 29px;
            left: 40px;
          }
          @media ${size['tablet-max']} {
            top: 22px;
            left: 25px;
          }
          display: none;
        }
      }
      .ant-collapse {
        border: 0;
        width: 100%;
        background-color: transparent;
      }
      .ant-collapse-content {
        background-color: transparent;
        border-top: 0;
      }
      .ant-collapse > .ant-collapse-item {
        border-bottom: 0;
      }
      .ant-collapse-content > .ant-collapse-content-box {
        padding: 0px;
      }
      .ant-collapse > .ant-collapse-item > .ant-collapse-header {
        padding: 0;
      }
      .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow svg {
        display: none;
      }
      .ant-collapse-item.ant-collapse-item-active {
        .toggle-icon-main {
          transform: rotate(180deg);
        }
      }
      .accor-content {
        width: 100%;
        position: relative;
        padding-right: 80px !important;
        padding-left: 45px !important;
        margin-top: 20px;
        .delivery-time {
          position: absolute;
          right: 0;
          top: 0;
          font-weight: 500;
          font-size: 18px;
          line-height: 154%;
          text-align: right;
          color: #696969;
          @media ${size['desktop-mm-max']} {
            font-size: 16px;
          }
          @media ${size['tablet-max']} {
            font-size: 14px;
          }
        }
        p {
          font-size: 18px;
          line-height: 154%;
          color: #696969;
          margin: 0;
          font-weight: 400;
          @media ${size['desktop-mm-max']} {
            font-size: 16px;
          }
          @media ${size['tablet-max']} {
            font-size: 14px;
          }
          strong {
            font-weight: 500;
          }
        }
      }
      .pickup-dropoff-main {
        font-size: 18px;
        line-height: 21px;
        color: #49465d;
        width: 100%;
        align-items: center;
        @media ${size['desktop-mm-max']} {
          font-size: 16px;
        }
        @media ${size['tablet-max']} {
          font-size: 14px;
        }
        .price-text {
          font-weight: 500;
          font-size: 30px;
          line-height: 36px;
          color: #424242;
          @media ${size['desktop-mm-max']} {
            font-size: 16px;
          }
        }
        .pickup-dropoff {
          width: 60%;
          span {
            display: inline-block;
            vertical-align: middle;
          }
          i {
            display: inline-block;
            vertical-align: middle;
            margin: 0 15px;
            background: url(${arrowRightLargeIcon}) no-repeat center;
            background-size: 100% auto;
            width: 24px;
            height: 10px;
          }
        }
        .toggle-icon-main {
          height: 30px;
          width: 30px;
          margin: 0 auto;
          background: #dedede url(${dropdownArrow}) no-repeat center;
          background-size: 8px auto;
          border-radius: 50%;
          -webkit-border-radius: 50%;
          -moz-border-radius: 50%;
          opacity: 0;
          transition: all 0.4s ease;
          -webkit-transition: all 0.4s ease;
          -moz-transition: all 0.4s ease;
          @media ${size['desktop-mm-max']} {
            margin-right: 20px;
          }
          @media ${size['mobile-md-max']} {
            margin-left: 0;
          }
        }
        .price-text {
          margin-left: auto;
          font-size: 20px;
          color: #424242;
          @media ${size['desktop-mm-max']} {
            font-size: 18px;
          }
          @media ${size['tablet-max']} {
            font-size: 14px;
          }
        }
      }
    }
    ul.location-listing-wrapper.pickup-location-listing.starting-address {
      // margin: 0 -35px;
      padding: 0;
    }
    ul.total-price.net-gross-price {
      margin: 0 -35px;
      padding: 0;
    }
    ul.location-listing-wrapper {
      // margin: 0 -35px;
      // padding: 0;
      // @media ${size['desktop-mm-max']} {
      //   margin: 0 -25px;
      // }
      // @media ${size['tablet-max']} {
      //   margin: 0 -20px;
      // }
      li {
        margin: 0px;
        display: flex;
        align-items: center;
        cursor: pointer;
        list-style: none;
        transition: none;
        position: relative;
        font-size: 12.81px !important;
        line-height: 16px;
        color: #49465d;
        font-family: ${(props) => props.theme.seciondaryFont};
        font-weight: 400;
        padding: 15px 40px 15px 80px;
        transition: all 0.4s ease;
        -webkit-transition: all 0.4s ease;
        -moz-transition: all 0.4s ease;
        @media ${size['desktop-mm-max']} {
          font-size: 15px;
          min-height: 60px;
          padding: 15px 30px 15px 60px;
        }
        @media ${size['tablet-max']} {
          min-height: 46px;
          font-size: 13px;
          padding: 10px 20px 10px 40px;
        }
        :hover {
          background: #fafafa;
          font-weight: 700 !important;
          .setting-btn,
          .draggable-icon {
            opacity: 1;
          }
        }
        span {
          max-width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          padding-right: 10px;
        }
        .setting-btn {
          background: url(${settingsIcon}) no-repeat center;
          width: 22px;
          height: 22px;
          background-size: 100% auto;
          border: 0;
          opacity: 0;
        }
        .draggable-icon {
          background: url(${draggableIcon}) no-repeat center;
          width: 23px;
          height: 10px;
          background-size: 100% auto;
          border: 0;
          margin-left: 25px;
          opacity: 0;

          display: inline-block;
          vertical-align: middle;
        }
      }
      &.pickup-location-listing {
        li {
          // :after {
          //   content: '';
          //   display: inline-block;
          //   position: absolute;
          //   background: url(${pickupSmallIcon}) no-repeat center;
          //   width: 20px;
          //   height: 22px;
          //   left: 45px;
          //   @media ${size['desktop-mm-max']} {
          //     left: 28px;
          //   }
          //   @media ${size['tablet-max']} {
          //     left: 10px;
          //   }
          // }
        }
      }
    }
  }
  .plr-50 {
    padding-left: 60px;
    padding-right: 50px;
    @media ${size['desktop-mm-max']} {
      padding-left: 88px;
      padding-right: 50px;
    }
    @media ${size['desktop-sm-max']} {
      padding-right: 53px;
      padding-left: 88px;
    }
    @media ${size['laptop-max']} {
      padding-left: 65px;
      padding-right: 28px;
    }
    @media ${size['tablet-md-max']} {
      padding-left: 40px;
      padding-right: 10px;
    }
    .warning-text {
      font-size: 15px;
      font-weight: 500;
      line-height: 18px;
      color: #565656;
      padding-left: 30px;
      margin-top: 55px;
      display: block;
      @media ${size['desktop-mm-max']} {
        padding-left: 0px;
        margin-top: 30px;
      }
    }
  }
  .map-view {
    height: 465px;
  }
`;

const CardWrapper = styled.div`
  .card-custom {
    .ant-card-head {
      background: #fafafa;
      border: 0;
      padding: 18px 35px;
      padding-bottom: 18px;
      position: relative;
      align-items: center;
      ${borderRadius('9px 9px 0px 0px')};
      @media ${size['desktop-mm-max']} {
        padding: 10px 25px;
      }
      @media ${size['tablet-max']} {
        padding: 10px 20px;
      }
      .ant-card-head-title {
        text-transform: uppercase;
        padding: 0 0;
        overflow: visible;
      }
    }
    .card-header {
      background: #fafafa;
      border: 0;
      position: relative;
      align-items: center;
      ${borderRadius('9px 9px 0px 0px')};

      h5 {
        margin: 0;
        color: ${(props) => props.theme.secondaryColor};
        font-family: ${(props) => props.theme.primaryFont}, sans-serif;
        font-size: 20px;
        line-height: 26px;
        font-weight: 500;
        @media ${size['desktop-mm-max']} {
          font-size: 20px;
          line-height: 24px;
        }
        @media ${size['tablet-max']} {
          font-size: 18px;
          line-height: 20px;
        }
      }
      &.d-flex {
        @media ${size['mobile-md-max']} {
          display: block !important;
        }
      }
    }
    .three-fields-group {
      flex-wrap: wrap;
      margin: 25px 0 0;
      position: relative;
      padding: 0;
      @media ${size['desktop-mm-max']} {
        margin: 20px 0 0;
      }
      @media ${size['tablet-max']} {
        padding-bottom: 15px;
      }
    }
    .parcelDiv {
      margin: 30px 0 0;
      padding-bottom: 10px;
    }
    .ant-card-head {
      z-index: 2;
    }
    .ant-card-body {
      // height: 520px;
      // max-height: 520px;
      // min-height: 520px;
      // overflow: auto;
      box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
      border-radius: 0 0 10px 10px;
      padding: 35px 35px 45px 35px;
      margin-top: -25px;
      // @media ${size['desktop-sm-max']} {
      //   height: 520px;
      //   max-height: 520px;
      //   min-height: 520px;
      // }
      &::-webkit-scrollbar {
        width: 5px;
        height: 5px;
      }
      &::-webkit-scrollbar-track {
        box-shadow: none;
        border-radius: 10px;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
      }
      &::-webkit-scrollbar-thumb {
        background: #8f48ff;
        border-radius: 10px;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
      }
      &::-webkit-scrollbar-thumb:hover {
        background: #8f48ff;
      }
      @media ${size['desktop-mm-max']} {
        padding: 25px 25px 30px 25px;
      }
      @media ${size['tablet-max']} {
        padding: 20px 20px 20px 20px;
        margin-top: -15px;
      }
      .form-group {
        margin: 25px 0;
        @media ${size['desktop-mm-max']} {
          margin: 20px 0;
        }
      }
      .detailDiv {
        margin-bottom: 25px;
      }
      label.label {
        margin-bottom: 7px;
        text-transform: uppercase;
        @media ${size['desktop-mm-max']} {
          font-size: 12px;
          line-height: 14px;
        }
      }
    }
  }

  .service-review-section {
    margin-bottom: 0px;
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    .service-col {
      display: flex;
      flex-direction: column;
      .service-name {
        font-size: 15px;
        font-weight: 600;
        margin-bottom: 0;
      }
      .collection-type {
        font-size: 15px;
      }
      .carrier-image {
        width: 52px;
        // height: 52px;
      }
      img {
        width: 100%;
        padding-top: 6px;
        // height: 100%;
      }
    }
    .date-col {
      display: flex;
      flex-direction: column;
      font-size: 15px;
      .date-name {
        font-weight: 600;
      }
    }
    .price-col {
      display: flex;
      flex-direction: column;
      font-size: 16px;
      .price-name {
        font-weight: 600;
      }
      .price-value {
        font-weight: 700;
      }
    }
  }

  // position: static;
  // top: 49px;
  // left: 15px;
  // right: 15px;
  // min-height: 565px;
  // z-index: 99999;
  .card-custom {
    .ant-card-head {
      background-color: #8f48ff;
      .custom {
        padding: 0;
      }
      .card-header {
        background-color: #8f48ff;
        h5 {
          color: #fff;
        }
        .panel-next-day-text {
          color: #fff;
        }
      }
    }
    .ant-card-body {
      // min-height: 550px;
      height: unset;
      // @media ${size['desktop-sm-max']} {
      //   min-height: 410px;
      //   height: 450px;
      //   }
      .location-preview {
        .order-receipt-list {
          margin: 0;
          .accor-content {
            width: 100%;
            position: relative;
            padding-right: 0px !important;
            padding-left: 45px !important;
            margin-top: 20px;
            .address-wrapper {
              width: 60%;
            }
            .delivery-time {
              position: absolute;
              right: 10px;
              top: 0;
              font-weight: 500;
              font-size: 18px;
              line-height: 154%;
              text-align: right;
              color: #696969;
              @media ${size['desktop-mm-max']} {
                font-size: 16px;
              }
              @media ${size['tablet-max']} {
                font-size: 14px;
              }
            }
            p {
              font-size: 18px;
              line-height: 154%;
              color: #696969;
              margin: 0;
              font-weight: 400;
              @media ${size['desktop-mm-max']} {
                font-size: 16px;
              }
              @media ${size['tablet-max']} {
                font-size: 14px;
              }
              strong {
                font-weight: 500;
              }
            }
          }
        }
        ul.total-price li {
          padding-top: 0;
          padding-bottom: 0;
          min-height: 35px;
        }
        ul.total-price li p {
          font-weight: normal;
          font-size: 16px;
          line-height: 18px;
          color: #424242;
          margin: 0;
          font-family: ${(props) => props.theme.secondaryFont}, sans-serif;
          letter-spacing: 0.025em;
          @media ${size['desktop-mm-max']} {
            font-size: 16px;
            line-height: 18px;
          }
          @media ${size['tablet-max']} {
            font-size: 14px;
          }
        }
        ul.total-price li .pickup-dropoff-main .price-text {
          margin-left: auto;
          font-weight: 500;
          font-size: 18px;
          line-height: 18px;
          color: #424242;
          @media ${size['tablet-max']} {
            font-size: 26px;
          }
          @media ${size['mobile-md-max']} {
            font-size: 20px;
          }
        }
        ul.total-price:hover {
          background: #fafafa;
        }
        ul {
          // margin: 0 -35px;
          // padding: 0;
          // @media ${size['desktop-mm-max']} {
          //   margin: 0 -25px;
          // }
          // @media ${size['tablet-max']} {
          //   margin: 0 -20px;
          // }
          li {
            margin: 0px;
            list-style: none;
            position: relative;
            font-size: 18px;
            line-height: 20px;
            color: #49465d;
            font-weight: 400;
            padding: 10px 40px 10px 80px;
            transition: all 0.4s ease;
            -webkit-transition: all 0.4s ease;
            -moz-transition: all 0.4s ease;
            -webkit-flex: flex;
            display: flex;
            align-items: center;
            flex-direction: column;
            // min-height: 70px;
            justify-content: center;
            @media ${size['desktop-mm-max']} {
              min-height: 60px;
              font-weight: 500;
              padding-right: 30px;
            }
            @media ${size['desktop-mm-min']} {
              // min-height: 50px;
            }
            @media ${size['tablet-max']} {
              min-height: 46px;
              font-size: 13px;
              padding: 10px 20px 10px 40px;
            }
            :hover {
              background: #fafafa;
              font-weight: 700 !important;
              .toggle-icon-main,
              .delete-icon {
                opacity: 1 !important;
              }
            }
            :before {
              display: none;
              position: absolute;
              left: 50px;
              top: 50%;
              width: 4px;
              height: 4px;
              background: #c4c4c4;
              ${borderRadius('50%')};
              content: '';
              @media ${size['desktop-mm-max']} {
                top: 29px;
                left: 40px;
              }
              @media ${size['tablet-max']} {
                top: 22px;
                left: 25px;
              }
            }
            .ant-collapse {
              border: 0;
              width: 100%;
              background-color: transparent;
            }
            .ant-collapse-content {
              background-color: transparent;
              border-top: 0;
            }
            .ant-collapse > .ant-collapse-item {
              border-bottom: 0;
            }
            .ant-collapse-content > .ant-collapse-content-box {
              padding: 0px;
            }
            .ant-collapse > .ant-collapse-item > .ant-collapse-header {
              padding: 0;
            }
            .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow svg {
              display: none;
            }
            .ant-collapse-item.ant-collapse-item-active {
              .toggle-icon-main {
                transform: rotate(180deg);
              }
            }
            .pickup-dropoff-main {
              font-size: 18px;
              line-height: 21px;
              padding-right: 0px;
              color: #49465d;
              width: 100%;
              align-items: center;
              @media ${size['desktop-mm-max']} {
                font-size: 16px;
              }
              @media ${size['tablet-max']} {
                font-size: 14px;
              }
              .price-text {
                font-weight: 400;
                line-height: 36px;
                color: #424242;
              }
              .pickup-dropoff {
                width: 60%;
                span {
                  display: inline-block;
                  vertical-align: middle;
                }
                i {
                  display: inline-block;
                  vertical-align: middle;
                  margin: 0 15px;
                  background: url(${arrowRightLargeIcon}) no-repeat center;
                  background-size: 100% auto;
                  width: 24px;
                  height: 10px;
                  @media ${size['tablet-max']} {
                    margin: 0 5px;
                    width: 15px;
                  }
                }
              }
              .toggle-icon-main {
                height: 30px;
                width: 30px;
                margin: 0 auto;
                background: #dedede url(${dropdownArrow}) no-repeat center;
                background-size: 8px auto;
                ${borderRadius('50%')};
                opacity: 0;
                transition: all 0.4s ease;
                -webkit-transition: all 0.4s ease;
                -moz-transition: all 0.4s ease;
                @media ${size['desktop-mm-max']} {
                  margin-right: 20px;
                }
              }
              .price-text {
                margin-left: auto;
                font-size: 20px;
                color: #424242;
                @media ${size['desktop-mm-max']} {
                  font-size: 18px;
                }
                @media ${size['tablet-max']} {
                  font-size: 14px;
                }
              }
            }
          }
        }
      }
    }
  }
  .location-header-selected-service {
    @media ${size['desktop-sm-max']} {
      margin-top: 10px;
    }
  }

  .parcel-address {
    margin: 20px 0px;
    padding-left: 32px;
    li {
      // :after {
      //   content: '';
      //   display: inline-block;
      //   position: absolute;
      //   background: url(${deliveryOrangeIcon}) no-repeat center;
      //   width: 20px;
      //   height: 22px;
      //   left: 0;
      // }
      &:hover {
        .price-tag {
          opacity: 0;
          display: none;
        }
      }
    }
  }
  .pickup-dropoff-main {
    justify-content: space-between;
    .price {
      padding: 0 5px 0 0;
    }
    &.price-bold {
      p {
        font-weight: 600 !important;
        color: #424242 !important;
      }
      .price-text {
        font-weight: 600 !important;
      }
    }
  }

  .location-listing-wrapper {
    li {
      flex-direction: row !important;
      justify-content: initial !important;
    }
  }
  .empty-state-wrapper {
    .icon-container {
      height: 160px;
      width: 160px;
      border-radius: 100%;
      padding: 28px 32px 36px 32px;
      margin-inline: auto;
      margin-block: 64px;
      background-color: ${(props) => props.theme.greyShadeColor2};
    }
    .text-wrapper {
      text-align: center;
      padding-inline: 85px;
      color: ${(props) => props.theme.fontColor};
      .title {
        font-weight: 700;
        font-size: 18px;
      }
      .sub-title {
        font-weight: 400;
        font-size: 14px;
      }
    }
  }
`;

const CommonTabWrapper = styled.div`
  .jobs-board-padding {
    @media ${size['desktop-sm-max']} {
      /* padding-top: 84px; */
      padding-bottom: 40px;
    }
    .table-list input[readonly] {
      border: 0.1rem solid #cecece;
    }
    .table-list.draftsDiv {
      // .t-col-2 {
      //   text-align: center;
      // }
      padding: 12px 30px 12px;
      @media ${size['mobile-sm-max']} {
        padding: 0;
      }
      .t-col-2.price-col {
        // padding-right: 50px;
        width: 12.6%;
        text-align: left !important;
        @media ${size['desktop-mm-max']} {
          padding-right: 20px;
          text-align: right;
        }
        .strong-price {
          float: left;
        }
        &.price-col-nextday {
          width: 9.6%;
        }
      }
    }
    #parcel-item-preview input[readonly] {
      border: 0.1rem solid #cecece;
    }
    .Page-title {
      text-transform: uppercase;
      padding: 12px 30px 12px;
      background: #8f48ff;
      border-radius: 5px 5px 0px 0px;
      :before {
        border: 1px solid #dfdfdf;
      }
      .title-text {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        margin-bottom: 0;
        line-height: 17px;
        color: #ffffff;
        font-size: 16px;
      }
      &.public-tracker {
        height: 80px;
        margin: auto 0;
        display: block;
        .gophr-logo {
          background-size: 60% auto;
          background-image: url(${gophrLogo});
          background-repeat: no-repeat;
          background-position: center;
          padding: 0px;
          position: relative;
          cursor: pointer;
          height: 32px;
          width: 40px;
          top: 10px;
          display: inline-block;
        }
        span {
          display: inline-block;
        }
        .light-text {
          font-weight: 400;
        }
        .live-tracking {
          margin: auto 0 auto auto;
        }
      }
    }

    .Job-type-header {
      display: flex;
      // box-shadow: 0px 71.1458px 56.9167px rgba(0, 0, 0, 0.07),
      //   0px 29.723px 23.7784px rgba(0, 0, 0, 0.0406743);
      border-radius: 7.82604px;
      padding: 8px 30px;
      background: #ffffff;
      div {
        & + div {
          margin-left: 10px;
        }
      }
      .ant-row {
        div {
          & + div {
            margin-left: 0;
          }
        }
      }
      .teams-select-wrapper {
        width: 75%;
        .ant-row-flex {
          width: 65%;
          margin-left: auto !important;
          .label {
            font-weight: 500;
          }
          .select-custom {
            .ant-select-selection-selected-value {
              color: #110c23 !important;
              font-family: 'OpenSans';
            }
          }
        }
        div {
          & + div {
            margin-left: 0;
          }
        }
      }
      .t-col-6 {
        width: 100% !important;
        margin-left: auto;
        @media ${size['desktop-sm-max']} {
          width: 17%;
        }
        @media ${size['tablet-md-max']} {
          width: 23%;
          .btn.purple-btn {
            display: none;
          }
        }
        &.favourites-btns {
          width: 3%;
        }
        input {
          display: none;
          width: 0%;
        }
        .t-header-btns {
          position: absolute;
          right: 0;
          .ml-auto {
            margin-left: 0;
          }
        }
      }
      .tab {
        font-family: ${(props) => props.theme.primaryFont}, sans-serif;
        // min-width: 100px;
        font-weight: 600;
        font-size: 15px;
        letter-spacing: 0.05em;
        padding: 12px 0;
        border: 0;
        background: #fff;
        line-height: 18px;
        margin: 0 25px 0 0;
        margin-bottom: 1px;
        text-align: center;
        border-radius: 5px;
        cursor: pointer;
        &:hover {
          color: #8f48ff;
        }
      }
      .active {
        position: relative;
        &:after {
          content: '';
          background: #8f48ff;
          height: 4px;
          bottom: 0;
          ${borderRadius('30px')};
          ${transition(0.4)};
          z-index: 2;
          position: absolute;
          right: 0;
          left: 0;
          width: 100%;
        }
      }
      &.blur-body {
        filter: blur(2px);
      }
    }
    .collapse-subtabs {
      display: flex;
      border-radius: 7.82604px;
      div {
        & + div {
          margin-left: 10px;
        }
      }
      .ant-row {
        div {
          & + div {
            margin-left: 0;
          }
        }
      }
      .teams-select-wrapper {
        width: 75%;
        div {
          & + div {
            margin-left: 0;
          }
        }
      }
      .t-col-6 {
        width: 100% !important;
        margin-left: auto;
        @media ${size['desktop-sm-max']} {
          width: 18%;
        }
        @media ${size['tablet-md-max']} {
          width: 23%;
          .btn.purple-btn {
            display: none;
          }
        }
        &.favourites-btns {
          width: 3%;
        }
        input {
          display: none;
          width: 0%;
        }
        .t-header-btns {
          position: absolute;
          right: 0;
          .ml-auto {
            margin-left: 0;
          }
        }
      }
      .tab {
        font-family: ${(props) => props.theme.primaryFont}, sans-serif;
        // min-width: 100px;
        font-weight: 600;
        font-size: 15px;
        letter-spacing: 0.05em;
        padding-top: 12px;
        // padding: 12px 20px;
        border: 0;
        background: #fff;
        line-height: 18px;
        margin: 0 25px 0 0;
        margin-bottom: 1px;
        text-align: center;
        border-radius: 5px;
        cursor: pointer;
        &:hover {
          color: #8f48ff;
        }
      }
      .tab.active.first {
        &:after {
          bottom: 6px;
          @media ${size['tablet-max']} {
            bottom: -6px;
          }
        }
      }
      .active {
        position: relative;
        &:after {
          content: '';
          background: #8f48ff;
          height: 4px;
          bottom: -6px;
          ${borderRadius('30px')};
          ${transition(0.4)};
          z-index: 2;
          position: absolute;
          right: 0;
          left: 0;
          width: 100%;
        }
      }

      .Parcles-header-tab-wrapper {
        display: flex;
        p {
          padding-top: 12px;
        }
        .Pickup-address {
          font-family: ${(props) => props.theme.secondaryFont};
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          color: #110c23;
        }
        .Pickup-text,
        .Dropoff-text {
          font-family: 'Montserrat';
          font-style: normal;
          font-weight: 600;
          font-size: 11.38px;
          letter-spacing: 0.06em;
          color: #110c23;
        }
        .right-side-wrapper {
          display: flex;
          // position: absolute;
          // right: 30px;
        }
        &.history-parcel-header-tab {
          width: 50%;
          .right-side-wrapper {
            position: relative;
            margin-left: auto;
          }
        }
        div#select-job-board {
          width: 358px;
          height: 39px;
          left: 870px;
          top: 423px;
          background: #ffffff;
          box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12);
          border-radius: 5px;
          .select-custom.teams.select-job-board.ant-select.ant-select-enabled.ant-select-no-arrow {
            border: none;
          }
          .ant-select-selection-selected-value {
            // line-height: 3.5 !important;
          }
          .ant-select-selection:after {
            top: 20px !important;
          }
          .ant-select-selection-selected-value {
            font-size: 12px;
            line-height: 16px;
            color: #110c23;
            font-family: ${(props) => props.theme.secondaryFont};
          }
        }
      }
    }
    .table-list {
      /* Table Header Helper Style*/
      padding: 12px 30px 12px;
      &.blur-body {
        filter: blur(2px);
      }
      .left-header-align {
        margin-right: 15px;
        width: 58px;
        min-width: 58px;
        @media ${size['desktop-sm-max']} {
          width: 44px;
          min-width: 44px;
          margin-right: 10px;
        }
      }
      .left-align {
        width: 58px;
        margin-right: 5px;
        @media ${size['desktop-sm-max']} {
          width: 44px;
        }
      }
      .header {
        font-family: ${(props) => props.theme.primaryFont} !important;
        font-size: 12px !important;
        font-weight: 600 !important;
      }
      .t-col-1 {
        width: 24%;
        padding-right: 15px;
        color: #323232;
        font-weight: 400;
        font-size: 15px;
        &.carrier-width {
          width: 35%;
        }
        @media ${size['laptop-max']} {
          padding-right: 10px;
        }
        @media ${size['tablet-md-max']} {
          width: 70%;
        }
        &.date {
          width: 14%;
          @media ${size['tablet-md-max']} {
            width: 35%;
          }
        }
        &.carrier-jobs {
          width: 27%;
          &.col-nextday {
            width: 23%;
          }
        }
        &.inc-width {
          width: 31%;
          @media ${size['laptop-max']} {
            width: 33%;
          }
        }
        &.show-map-inc-width {
          width: 45%;
        }
      }
      .t-col-2 {
        width: 12.6%;
        text-align: left;
        color: #323232;
        font-weight: 400;
        font-size: 15px;
        @media ${size['desktop-mm-max']} {
          padding-right: 20px;
        }
        .strong-price {
          float: left;
          font-weight: 400 !important;
          // font-size: 14px !important;
          line-height: 19px !important;
        }
        &.favourites-city {
          width: 17.4% !important;
        }
        &.time {
          width: 20% !important;
        }
        &.show-map-inc-width {
          width: 18.6% !important;
        }
        &.price-col-nextday {
          width: 9.6% !important;
        }
      }
      .t-col-3 {
        width: 19.4%;
        padding-right: 15px;
        color: #323232;
        font-weight: 400;
        font-size: 15px;
        @media ${size['laptop-max']} {
          padding-right: 10px;
        }

        &.favourites-postcode {
          width: 17.4%;
        }
        &.vehicle {
          width: 15%;
        }
        &.inc-width {
          width: 23.4%;
        }
        &.carrier-address {
          width: 16.4%;
        }
        &.show-map-inc-width {
          width: 35.4%;
        }
      }
      .t-col-4 {
        width: auto;
        color: #323232;
        // margin-left: auto;
        font-weight: 400;
        font-size: 15px;
        // padding-right: 15px;
        // @media ${size['laptop-max']} {
        //   padding-right: 10px;
        // }
        &.riders {
          width: 11%;
        }
        &.favourites-company {
          width: 15.4%;
        }
        &.details-col {
          position: absolute;
          right: 10px;
          display: table !important;
        }
      }
      .t-col-5 {
        width: 10%;
        padding-right: 15px;
        color: #323232;
        font-weight: 400;
        font-size: 15px;
        @media ${size['laptop-max']} {
          padding-right: 10px;
        }
        @media ${size['tablet-md-max']} {
          width: 5%;
        }
        &.favourites-name {
          width: 20%;
        }
        &.address {
          width: 17%;
        }
        &.carrier-vehicle {
          width: 13%;
        }
        &.shift-start-address {
          width: 20%;
        }
        &.inc-width {
          width: 15% !important;
        }
      }
      .t-col-6 {
        width: 16%;
        color: #323232;
        font-weight: 400;
        font-size: 15px;
        @media ${size['desktop-sm-max']} {
          width: 18%;
        }
        @media ${size['tablet-md-max']} {
          width: 23%;
          .btn.purple-btn {
            display: none;
          }
        }
        &.favourites-btns {
          width: 3%;
        }
        input {
          display: none;
          width: 0%;
        }
        .t-header-btns {
          position: absolute;
          right: 0;
          .ml-auto {
            margin-left: 0;
          }
        }
        &.same-day-empty {
          width: auto;
        }
      }
      .t-col-7 {
        width: 11%;
        color: #323232;
        font-weight: 400;
        font-size: 15px;
      }

      .row-select-container {
        margin-top: 15px;
      }
      .t-ref-col {
        width: 5%;
      }
      .t-col-2,
      .t-col-3,
      .t-col-4,
      .t-col-5,
      .t-ref-col {
        @media ${size['tablet-md-max']} {
          display: none;
          width: 0%;
        }
      }
      .mx-auto {
        margin: auto !important;
      }
      .truck-text {
        font-weight: 'Montserrat';
        font-family: ${(props) => props.theme.secondaryFont};
        line-height: 18px;
        color: #323232;
        font-weight: 400;
        mix-blend-mode: normal;
        position: relative;
        white-space: nowrap;
        text-overflow: ellipsis;
        @media ${size['desktop-sm-max']} {
          // font-size: 16px;
          line-height: 16px;
          padding-left: 0px;
        }
        @media ${size['laptop-max']} {
          font-size: 12px;
        }
        &.status-purple {
          color: #816e9b;
        }
        &.shift-history {
          width: 15%;
        }
      }
      ul.ui-header {
        font-family: ${(props) => props.theme.secondaryFont}, sans-serif;
        list-style: none;
        padding: 15px 0;
        margin: 0;
        &.costs {
          display: block;
          border-bottom: none !important;
          padding: 0;
          li {
            padding: 0;
          }
        }
        &.show {
          display: block;
        }
        border-bottom: 1px solid #d9d9d9;
        .pick-drop {
          font-family: ${(props) => props.theme.secondaryFont};
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 19px;
          color: #323232;
          width: 18%;
        }
        .description {
          font-family: ${(props) => props.theme.secondaryFont};
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 19px;
          color: #323232;
          width: 14%;
          @media ${size['tablet-max']} {
            width: 100%;
          }
        }
        .from {
          width: 4%;
          font-family: 'Montserrat';
          font-style: normal;
          font-weight: 600;
          font-size: 11.38px;
          line-height: 14px;
          color: #323232;
          @media ${size['tablet-max']} {
            width: 100%;
          }
        }
        .location {
          width: 12%;
          font-family: ${(props) => props.theme.secondaryFont};
          display: flex;
          i.weight-icon {
            background-size: 90% auto;
          }
          .weight-icon {
            z-index: 1;
            width: 25px;
            height: 33px;
            display: block;
            margin-right: 10px;
            background: url(${itemIcon}) no-repeat center;
          }
          @media ${size['tablet-max']} {
            width: 100%;
          }
        }
        .address {
          font-family: ${(props) => props.theme.secondaryFont};
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          width: 24%;
          line-height: 19px;
          color: #323232;
          @media ${size['tablet-max']} {
            width: 100%;
          }
        }
        .fragile {
          display: flex;
          width: 13%;
          i.fragile-icon {
            background-size: 90% auto;
          }
          .fragile-icon {
            z-index: 1;
            width: 25px;
            height: 33px;
            display: block;
            margin-right: 10px;
            background: url(${warningIcon}) no-repeat center;
          }
          @media ${size['tablet-max']} {
            width: 100%;
          }
        }
        .size {
          width: 22%;
          display: flex;
          i.size-icon {
            background-size: 74% auto;
          }
          .size-icon {
            z-index: 1;
            width: 25px;
            height: 33px;
            display: block;
            margin-right: 10px;
            background: url(${sizeIcon}) no-repeat center;
          }
          @media ${size['tablet-max']} {
            width: 100%;
          }
        }
        .dropOff {
          font-family: 'Montserrat';
          font-style: normal;
          font-weight: 600;
          font-size: 11.38px;
          line-height: 14px;
          color: #323232;
          width: 8%;
          display: flex;
          .food {
            width: 11%;
            @media ${size['tablet-max']} {
              width: 100%;
            }
          }
          i.cup-icon {
            background-size: 90% auto;
            @media ${size['tablet-max']} {
              width: 100%;
            }
          }
          .cup-icon {
            z-index: 1;
            width: 25px;
            height: 33px;
            display: block;
            margin-right: 10px;
            background: url(${cupIcon}) no-repeat center;
          }
        }
        .parcels {
          font-family: 'Montserrat';
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          line-height: 14px;
          color: #323232;
          display: flex;
          &.refrence {
            width: 18%;
            @media ${size['tablet-max']} {
              width: 100%;
            }
          }
          i.hash-icon {
            background-size: 67% auto;
          }
          .hash-icon {
            z-index: 1;
            width: 25px;
            height: 33px;
            display: block;
            margin-right: 10px;
            background: url(${refrenceIcon}) no-repeat center;
          }
        }
        .parcel-count {
          font-family: ${(props) => props.theme.secondaryFont};
          font-weight: 600;
          font-size: 14px;
          line-height: 19px;
          color: #323232;
          text-align: center;
          width: 7%;
          &.insurance {
            width: 10%;
            display: flex;
            @media ${size['tablet-max']} {
              width: 100%;
            }
          }
          i.insurance-icon {
            background-size: 67% auto;
          }
          .insurance-icon {
            z-index: 1;
            width: 25px;
            height: 33px;
            display: block;
            margin-right: 10px;
            background: url(${bookIcon}) no-repeat center;
          }
        }
        .price {
          color: #323232;
          font-weight: 600;
          font-size: 14px;
          text-align: end;
          width: 9%;
        }
      }
      li {
        // background: #8f48ff;
        list-style: none;
        margin: 0;
        -webkit-flex: flex;
        display: flex;
        align-items: center;
        // box-shadow: -4px -4px 10px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        min-height: 55px;
        // padding: 0 45px;
        // color: #fff;
        font-weight: 500;
        font-size: 15px;
        line-height: 18px;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        flex-wrap: wrap;
        @media ${size['desktop-md-max']} {
          // min-height: 70px;
        }
        @media ${size['desktop-sm-max']} {
          // min-height: 70px;
          font-size: 14px;
          line-height: 16px;
        }
        @media ${size['laptop-max']} {
          min-height: 60px;
          font-size: 12px;
          padding: 10px 20px;
        }
        @media ${size['tablet-md-max']} {
          padding: 0;
          .t-col-1 {
            display: none;
            width: 0%;
          }
          .t-col-6 {
            width: 100%;
            .ml-auto {
              margin-left: 0px !important;
              width: 100%;
              > div {
                padding-right: 90px;
                input {
                  display: block;
                  width: 100%;
                  background: #fff;
                  box-shadow: 6px 6px 16px rgba(143, 72, 255, 0.1);
                  border-radius: 4px;
                  -webkit-border-radius: 4px;
                  -moz-border-radius: 4px;
                  height: 36px;
                  padding: 0 15px 0 40px;
                }
              }
              .search-btn {
                background: url(${searchDarkIcon}) no-repeat center;
                position: absolute;
                top: 0.8%;
                left: 15px;
                transform: translate(0, -50%);
                background-size: 100% auto;
                width: 15px;
                height: 15px;
              }
              .filter-btn {
                background: url(${filterDarkIcon}) no-repeat center;
                background-size: 100% auto;
                width: 16px !important;
                height: 17px !important;
                top: 0.5%;
                left: 95%;
                position: absolute;
              }
            }
          }

          @media ${size['tablet-max']} {
            white-space: nowrap;
          }
        }
      }
      .ant-collapse {
        padding: 0;
        // margin: 20px 0 0;
        border: 0;
        @media ${size['desktop-sm-max']} {
          /* margin-top: 10px; */
        }
        background-color: transparent;
        .ant-collapse-header {
          margin: 15px 45px;
        }
        .ant-collapse-item {
          padding-bottom: 0;
          padding-top: 10px;
          @media ${size['laptop-max']} {
            padding-top: 0;
          }
          :hover {
            background: #fdfdfd;
            background: #f7f7f7;
            @media ${size['tablet-md-max']} {
              box-shadow: none;
            }
          }
          @media ${size['desktop-md-max']} {
            /* min-height: 80px; */
          }
          @media ${size['desktop-sm-max']} {
            font-size: 16px;
            /* min-height: 60px; */
            line-height: 16px;
          }
          @media ${size['laptop-max']} {
            min-height: 100%;
            font-size: 12px;
          }
          @media ${size['tablet-md-max']} {
            padding: 0;
          }
          &:hover {
            .t-header-btns {
              .btn.purple-btn,
              .setting-btn-main,
              .toggle-icon,
              .delete-icon {
                opacity: 1 !important;
              }
            }
          }
          &.ant-collapse-item-active {
            background: #fdfdfd;
            background: #f7f7f7;
            @media ${size['tablet-md-max']} {
              box-shadow: none;
              padding: 0;
            }
            .toggle-icon {
              background: #8f48ff url(${upArrow}) no-repeat center;
              background-size: 8px auto;
            }
            .t-header-btns {
              .btn.purple-btn,
              .setting-btn-main,
              .toggle-icon,
              .delete-icon {
                opacity: 1 !important;
              }
            }
          }
          .ant-collapse-header {
            margin: 0;
            -webkit-flex: flex;
            display: flex;
            // padding-bottom: 15px !important;
            align-items: center;
            border-radius: 10px;
            -webkit-border-radius: 10px;
            -moz-border-radius: 10px;
            min-height: 100px;
            color: #49465d;
            font-weight: 400;
            font-size: 15px;
            line-height: 18px;
            flex-wrap: wrap;
            transition: all 0.4s ease;
            -webkit-transition: all 0.4s ease;
            @media ${size['desktop-sm-max']} {
              padding-bottom: 12px;
            }
            .custom-collapse-header {
              display: contents;
              &.no-blur {
                div,
                p,
                span,
                h6,
                strong {
                  font-weight: 900 !important;
                  filter: none !important;
                }
              }
            }
            .couriers-name-image {
              align-items: center;
              .courier-image {
                &.fav-select {
                  margin-right: 5px;
                }
                &.vehicles {
                  background: #be24ff;
                  height: 60px;
                  border-radius: 50%;
                  img {
                    width: 65%;
                    object-fit: initial;
                    margin: auto;
                  }
                }
                margin-right: 15px;
                width: 58px;
                min-width: 58px;
                // height: 58px;
                /* @media ${size['desktop-sm-max']} {
                  width: 44px;
                  min-width: 44px;
                  // height: 44px;
                  margin-right: 10px;
                } */
                img {
                  width: 100%;
                  height: 100%;
                  object-position: center;
                  object-fit: cover;
                  display: block;
                  max-width: 100%;
                  border: none;
                  outline: none;
                }
              }
              .couriers-info {
                h6 {
                  font-weight: 400;
                  font-size: 15px;
                  font-family: 'OpenSans', sans-serif;
                  line-height: 20px;
                  color: #323232;
                  margin: 0;
                  text-overflow: ellipsis;
                  /* @media ${size['desktop-sm-max']} {
                    font-weight: 600;
                    font-size: 15px;
                    line-height: 20px;
                  }
                  @media ${size['laptop-max']} {
                    font-size: 14px;
                  }
                  @media ${size['tablet-md-max']} {
                    font-size: 16px;
                  } */
                }
                p {
                  font-weight: normal;
                  font-size: 15px;
                  color: #323232;
                  line-height: 18px;
                  margin: 0;
                  width: 100%;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  font-family: ${(props) => props.theme.secondaryColor}, sans-serif;
                  letter-spacing: 0.025em;
                  @media ${size['desktop-sm-max']} {
                    font-size: 14px;
                    line-height: 16px;
                  }
                  @media ${size['laptop-max']} {
                    font-size: 12px;
                  }
                }
              }
              .custom {
                h6 {
                  font-weight: ${(props) => props.theme.primaryFont};
                  font-weight: 400;
                  font-size: 15px;
                  line-height: 17px;
                  color: #323232;
                }
                p {
                  width: 140px !important;
                  font-weight: 500;
                  font-size: 15px;
                  white-space: normal !important;
                  color: #323232;
                }
              }
            }
            .price-col strong {
              font-weight: 600;
              color: #323232;
              // font-size: 14px;
              line-height: 19px;
              font-family: ${(props) => props.theme.secondaryFont};
              .vat-label {
                font-size: 10px;
              }
              @media ${size['laptop-max']} {
                font-size: 12px;
                .vat-label {
                  font-size: 8px;
                }
              }
            }
            .address-info {
              position: relative;
              font-family: ${(props) => props.theme.secondaryFont};
              font-weight: 400;
              // font-size: 14px;
              color: #323232;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              line-height: 22px;
              @media ${size['desktop-sm-max']} {
                // font-size: 16px;
                // padding-left: 30px;
              }
              @media ${size['laptop-max']} {
                font-size: 12px;
              }
              .map-dark-icon {
                position: absolute;
                left: 0;
                top: 0;
              }
              .address-drop.show {
                display: inline-block;
                margin-left: 0.255em;
                vertical-align: 0.255em;
                content: '';
                border-top: 0.3em solid;
                border-right: 0.3em solid transparent;
                border-bottom: 0;
                border-left: 0.3em solid transparent;
                border-width: 0;
                background: url(${dropdownArrowIcon}) no-repeat center;
                background-size: 8px auto;
                width: 10px;
                position: absolute;
                top: 0;
                bottom: 0;
                right: 0;
              }
            }
            .history {
              @media ${size['desktop-sm-max']} {
                padding-left: 0px;
              }
            }
            .status-col {
              .ant-popover-inner-content {
                padding: 0;
              }
              .progrssDiv {
                padding: 25px 20px;
                min-width: 300px;
                border: 0;
                background: #fff;
                box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15);
                @media ${size['desktop-sm-max']} {
                  padding: 20px 15px;
                  min-width: 260px;
                }
                strong {
                  font-weight: 500;
                  font-size: 18px;
                  line-height: 21px;
                  color: #49465d;
                  display: block;
                  margin-bottom: 5px;
                  @media ${size['desktop-sm-max']} {
                    font-size: 16px;
                    line-height: 18px;
                  }
                }
                a {
                  text-align: left;
                  font-weight: 500;
                  font-size: 15px;
                  line-height: 18px;
                  color: #49465d;
                  background: none !important;
                  padding: 5px 0;
                  box-shadow: none !important;
                  border-radius: 5px;
                  -webkit-border-radius: 5px;
                  width: 100%;
                  margin-bottom: 5px;
                  @media ${size['desktop-sm-max']} {
                    font-size: 13px;
                    line-height: 16px;
                  }
                  small {
                    font-size: 80%;
                    font-weight: 400;
                  }
                }
              }
              .selected-dropdown {
                min-width: 1px;
                // font-size: 14px;
                color: #323232;
                font-weight: 600;
                font-family: ${(props) => props.theme.secondaryFont};
                display: inline-block;
                background: none;
                padding: 0;
                @media ${size['desktop-sm-max']} {
                  font-size: 15px;
                }
                @media ${size['laptop-max']} {
                  font-size: 12px;
                }
                .dropdown-toggle {
                  position: relative;
                  line-height: normal;
                  color: #49465d;
                  padding-right: 16px;
                  text-decoration: none !important;
                  text-align: center;
                  &:after {
                    display: inline-block;
                    margin-left: 0.255em;
                    vertical-align: 0.255em;
                    content: '';
                    border-top: 0.3em solid;
                    border-right: 0.3em solid transparent;
                    border-bottom: 0;
                    border-left: 0.3em solid transparent;
                    border-width: 0;
                    background: url(${dropdownArrowIcon}) no-repeat center;
                    background-size: 8px auto;
                    width: 10px;
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    right: 0;
                  }
                  &.progress {
                    color: #323232;
                    mix-blend-mode: normal;
                    font-weight: 400;
                    font-size: 15px;
                    &:after {
                      display: none;
                    }
                  }
                }
              }
            }
            .t-header-btns {
              //opacity: 0;
              transition: all 0.4s ease;
              -webkit-transition: all 0.4s ease;
              -moz-transition: all 0.4s ease;
              .d-flex {
                align-items: center;
              }
              .btn.purple-btn,
              .setting-btn-main,
              .toggle-icon,
              .delete-icon {
                opacity: 0;
                @media ${size['tablet-md-max']} {
                  opacity: 1 !important;
                }
              }
              .opacity-full {
                opacity: 1 !important;
              }
              .setting-btn-main {
                width: auto;
                height: auto;
                margin-left: 30px;
                @media ${size['desktop-sm-max']} {
                  margin-left: 15px;
                }
                @media ${size['laptop-max']} {
                  margin-left: 10px;
                }
              }
              .ant-popover-inner-content {
                padding: 0;
              }
              ul.menuItems {
                box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.1) !important;
                transform: none !important;
                right: 0;
                left: auto !important;
                top: 30px !important;
                width: 222px !important;
                background: #fff;
                border-radius: 7px;
                -webkit-border-radius: 7px;
                -moz-border-radius: 7px;
                border: 0;
                padding: 10px 0;
                margin: 0;
                font-size: 1rem;
                color: #212529;
                text-align: left;
                list-style: none;
                float: left;
                li {
                  cursor: pointer;
                  padding: 12px 35px !important;
                  font-weight: normal;
                  font-size: 16px;
                  line-height: 20px;
                  color: #828097;
                  @media ${size['desktop-md-max']} {
                    padding: 12px 30px !important;
                  }
                  @media ${size['laptop-md-max']} {
                    padding: 10px 25px !important;
                  }
                }
              }
            }
          }
          .ant-collapse-content.ant-collapse-content-active {
            display: flex !important;
          }
          .parcelDiv {
            .ant-collapse {
              width: auto;
            }
            .ant-collapse-item {
              padding: 0;
              .ant-collapse-header {
                .collapse-header {
                  width: 100%;
                }
              }
            }
          }
          .receiptDiv {
            .ant-collapse {
              margin: 0;
            }
            .ant-collapse-item {
              // padding: 0;
              .price-text {
                font-weight: 400;
              }
            }
            .ant-collapse-item:hover {
              box-shadow: none;
            }
            ul.order-receipt-list {
              .order-receipt-list {
                margin: 0;
              }
              :ul {
                box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
              }
            }
          }
          .ant-collapse-header {
            margin: 0;
            padding: 0;
            min-height: auto;
            /* margin: 15px 0; */
            padding-bottom: 5px;
            @media ${size['laptop-max']} {
              padding: 7px 0;
            }
          }
          .ant-collapse-content {
            border: 0;
            width: 100%;
            /* margin-top: 18px; */
            overflow: visible;
            .ant-collapse-content-box {
              width: 100%;
              // margin-bottom: 10px;
              padding: 0;
              .tabs-inline-custom {
                padding: 0;
                overflow: visible;
                margin-top: 0;
                .ant-tabs-bar {
                  background: transparent;
                  padding: 0;
                }
                .ant-tabs-nav-wrap {
                  margin-bottom: 0;
                  min-height: 72px;
                  border-radius: 5px;
                }
                .ant-tabs-nav {
                  justify-content: flex-start;
                  .ant-tabs-tab {
                    color: #868686;
                    background: transparent;
                    box-shadow: none;
                    padding: 10px 5px 17px 5px;
                    min-width: auto;
                    font-size: 13px;
                    line-height: 15px;
                    letter-spacing: 0.05em;
                    &.ant-tabs-tab-active {
                      color: #49465d;
                      position: relative;
                      font-weight: 600;
                      &:after {
                        content: '';
                        background: #8f48ff;
                        height: 7px;
                        border-radius: 30px;
                        -webkit-border-radius: 30px;
                        -moz-border-radius: 30px;
                        transition: all 0.4s ease;
                        -webkit-transition: all 0.4s ease;
                        -moz-transition: all 0.4s ease;
                        bottom: 1px;
                        z-index: 2;
                        position: absolute;
                        right: 0;
                        left: 0;
                        width: 100%;
                        @media ${size['tablet-md-max']} {
                          height: 4px;
                        }
                      }
                    }
                  }
                }
                .ant-tabs-content {
                  .ant-row.tabpanrow {
                    width: 100%;
                    // margin-top: 15px;
                    .light-color-field {
                      label {
                        color: #adadad;
                        @media ${size['desktop-sm-max']} {
                          margin-bottom: 8px;
                        }
                      }
                      .field-value {
                        font-weight: normal;
                        font-size: 20px;
                        line-height: 58px;
                        color: #adadad;
                        height: 58px;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        @media ${size['laptop-max']} {
                          font-size: 14px;
                        }
                      }
                      .d-hide {
                        display: none;
                        @media ${size['tablet-md-max']} {
                          display: block;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .teams-select-wrapper .select-custom {
    .ant-select-selection:after {
      top: 50%;
    }
    .ant-select-selection-selected-value {
      @media ${size['mobile-md-max']} {
        width: 50px;
        padding: 5px 0 !important;
      }
    }
  }
  .tabs-custom {
    .ant-tabs-nav {
      display: flex;
      justify-content: end;
      @media ${size['tablet-md-max']} {
        justify-content: flex-start;
      }
      .nextday-tab,
      .shift-tab {
        height: 87%;
        z-index: 1;
        bottom: -7px;
        @media ${size['desktop-md-max']} {
          bottom: 2px;
        }
      }
    }
    .tabs-tabluar-custom {
      margin-top: -50px;
      @media ${size['desktop-md-max']} {
        margin-top: -59px;
      }
      @media ${size['tablet-md-max']} {
        margin-top: -15px;
      }
    }
  }
  /* Custom Tab Tabular Style */
  .tabs-tabluar-custom {
    overflow: visible;
    margin-top: -67px;
    @media ${size['tablet-md-max']} {
      margin-top: -20px;
      .ant-tabs-bar {
        background: transparent;
        padding: 0;
      }
      .ant-tabs-nav-wrap {
        margin-bottom: 0;
        min-height: 72px;
        border-radius: 5px;
      }
      .ant-tabs-nav {
        justify-content: flex-start;
        .ant-tabs-tab {
          color: #868686;
          background: transparent;
          box-shadow: none;
          padding: 10px 5px 17px 5px;
          min-width: auto;
          font-size: 13px;
          line-height: 15px;
          letter-spacing: 0.05em;
          &.ant-tabs-tab-active {
            color: #49465d;
            position: relative;
            font-weight: 600;
            &:after {
              content: '';
              background: #8f48ff;
              height: 7px;
              border-radius: 30px;
              -webkit-border-radius: 30px;
              -moz-border-radius: 30px;
              transition: all 0.4s ease;
              -webkit-transition: all 0.4s ease;
              -moz-transition: all 0.4s ease;
              bottom: 1px;
              z-index: 2;
              position: absolute;
              right: 0;
              left: 0;
              width: 100%;
              @media ${size['tablet-md-max']} {
                height: 4px;
              }
            }
          }
        }
      }
      .ant-tabs-content {
        .ant-row.tabpanrow {
          width: 100%;
          // margin-top: 15px;
          .right-map-block {
            @media ${size['tablet-md-max']} {
              position: relative;
              left: 35%;
              width: auto;
            }
          }
          .light-color-field {
            @media ${size['tablet-md-max']} {
              background: #f9f9f9;
              margin: 0 0 20px 0;
              padding: 20px 10px 5px 25px;
              width: 100%;
              position: relative;
              .ant-col:nth-child(1) label:before {
                position: absolute;
                content: '';
                width: 6px;
                height: 6px;
                left: -15px;
                top: 4px;
                background: #8f48ff;
                border-radius: 50%;
                -webkit-border-radius: 50%;
                -moz-border-radius: 50%;
              }
            }
            label {
              color: #adadad;
              @media ${size['desktop-sm-max']} {
                margin-bottom: 8px;
              }
              @media ${size['tablet-md-max']} {
                font-weight: 400 !important;
                font-size: 12px;
                letter-spacing: 0.05em;
                color: #49465d !important;
              }
            }
            .field-value {
              font-weight: normal;
              font-size: 20px;
              line-height: 58px;
              color: #adadad;
              height: 58px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              @media ${size['laptop-max']} {
                font-size: 14px;
              }
              @media ${size['tablet-md-max']} {
                line-height: 16px !important;
                font-size: 14px;
                font-weight: 500 !important;
                color: #49465d !important;
                height: auto !important;
                margin-bottom: 15px;
              }
            }
          }
        }
      }
      .ui-header {
        li {
          background: #fff !important;
          box-shadow: none !important;
        }
      }
    }
    .ant-tabs-nav {
      display: flex;
      justify-content: flex-start;
      @media ${size['tablet-md-max']} {
        justify-content: flex-start;
      }
      .nextday-tab,
      .shift-tab {
        height: 87%;
        z-index: 1;
        bottom: -7px;
      }
    }
    .ant-tabs-content {
      .ant-tabs-tabpane {
        background: #fff;
        border-radius: 5px;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        position: relative;
        padding: 0;
        box-shadow: none;
        @media ${size['tablet-md-max']} {
          //width: 900px;
          // padding: 0 20px;
          .ant-tabs-tabpane {
            width: 100%;
            padding: 0;
          }
        }
      }
    }
  }
  .table-list {
    &.reduce-width {
      width: calc(100% - 600px);
      overflow: auto;
      height: calc(100vh - 222px);
      ${customScroller()};
    }
  }
  .draftsDiv.table-list {
    padding: 12px 30px 12px;
    @media ${size['mobile-sm-max']} {
      padding: 0;
    }
    &.blur-body {
      filter: blur(2px);
    }
    .ui-header {
      @media ${size['tablet-md-max']} {
        display: none;
      }
    }
    .t-col-3 {
      @media ${size['laptop-max']} {
        width: 18.4%;
      }
    }
    .t-col-4 {
      width: 13%;
    }
    .t-col-5 {
      width: 7%;
    }
    .t-col-6 {
      // width: 23%;
      @media ${size['laptop-max']} {
        // width: 24%;
      }
    }
    .btn.purple-btn {
      min-width: 175px;
      &.btn-size {
        min-width: auto;
        width: 103px;
        height: 40px;
        line-height: 40px;
        background: #fff;
        border-radius: 5px;
        box-shadow: none;
        border: none;
        color: #000000;
        @media ${size['desktop-sm-max']} {
          min-width: 132px !important;
          height: 35px !important;
          line-height: 35px !important;
        }
      }
      @media ${size['desktop-mm-min']} {
        min-width: 175px;
        font-size: 13px;
      }
      @media ${size['desktop-md-max']} {
        // min-width: 150px;
      }
    }
    .search-input {
      margin-top: 15px;
      @media ${size['desktop-mm-min']} {
        height: 42px;
      }
    }
    .close-btn {
      background-image: url(${closeIcon});
      background-size: 15px auto;
      background-repeat: no-repeat;
      background-position: center;
      height: 45px;
      width: 50px;
      position: absolute;
      right: 0;
      margin-top: 15px;
      @media ${size['desktop-mm-min']} {
        height: 42px;
      }
    }
  }
  .scheduled-list.table-list {
    padding: 12px 30px 12px;
    .t-col-5 {
      width: auto;
    }
    .t-col-6 {
      width: 17%;
      .btn.purple-btn {
        min-width: 140px;
      }
      .btn {
        padding: 0px 10px;
      }
    }
  }
  .active-list-section.table-list {
    .ui-header {
      @media ${size['tablet-md-max']} {
        display: none;
      }
    }
    .t-col-5 {
      width: auto;
    }
    .t-col-6 {
      // width: 20%;
      .btn.purple-btn {
        min-width: 140px;
      }
      .btn {
        padding: 0px 10px;
      }
    }
  }
  .completed-col-main.table-list {
    .t-col-4 {
      width: 12%;
    }
    .t-col-5 {
      width: 8%;
    }
    .t-col-6 {
      width: 17%;
    }
    .btn.purple-btn {
      min-width: 120px;
      @media ${size['desktop-sm-max']} {
        margin-right: 15px;
        font-size: 12px;
        height: 48px;
        line-height: 48px;
        min-width: 142px;
        letter-spacing: 0.025em;
      }
      @media ${size['laptop-max']} {
        margin-right: 10px;
      }
    }
  }
  .t-header-btns {
    button + button {
      margin-left: 20px !important;
      @media ${size['laptop-max']} {
        margin-left: 10px !important;
      }
    }
  }
  .ant-row-flex {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .progress-main {
    position: relative;
    .progress {
      display: flex;
      height: 1rem;
      overflow: hidden;
      font-size: 0.75rem;
      background-color: #e9ecef;
      border-radius: 0.25rem;
    }
    .vertical-progress {
      height: 7px;
      position: absolute;
      left: -80px;
      top: 70px;
      width: 140px;
      transform: rotate(90deg);
      border-radius: 10px;
      -webkit-border-radius: 10px;
      @media ${size['laptop-max']} {
        opacity: 0;
      }
      .progress-bar {
        display: flex;
        flex-direction: column;
        justify-content: center;
        color: #fff;
        text-align: center;
        white-space: nowrap;
        background-color: #007bff;
        transition: width 0.6s ease;
      }
      .progress-bar-purple {
        width: 40%;
        background: #8f48ff;
        border-radius: 10px;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
      }
    }
  }
  ul.total-price {
    border-radius: 40px;
    -webkit-border-radius: 40px;
    -moz-border-radius: 40px;
    min-height: 60px;
    padding: 15px 51px 15px 88px;
    @media ${size['desktop-sm-max']} {
      border-radius: 40px;
      -webkit-border-radius: 40px;
      -moz-border-radius: 40px;
      padding: 15px 54px 15px 88px;
    }
    @media ${size['laptop-max']} {
      padding: 15px 30px 15px 62px;
    }
    @media ${size['tablet-md-max']} {
      padding: 15px 10px 15px 40px;
    }
  }
  .total-price-wrapper {
    border-radius: 40px;
    -webkit-border-radius: 40px;
    -moz-border-radius: 40px;
    padding: 0;
    float: right;
    margin: 0;
    .pickup-dropoff {
      font-family: ${(props) => props.theme.secondaryFont};
      font-weight: 600;
      font-size: 14px;
      color: #323232;
    }
    strong {
      margin-left: 20px;
      font-family: ${(props) => props.theme.secondaryFont};
      font-style: normal;
      font-weight: 600;
      font-size: 25px;
      color: #323232;
    }
  }
  input[readonly] {
    height: 40px;
    line-height: 40px;
    color: #323232;
    font-weight: 600;
    @media ${size['laptop-md-min']} {
      height: 35px;
      line-height: 35px;
    }
  }
  textarea[readonly] {
    height: 175px;
    line-height: normal;
    padding-top: 10px;
    box-shadow: none;
    color: #49465d;
    font-weight: 600;
    background: #f7f7f7 !important;
    @media ${size['desktop-sm-max']} {
      height: 155px;
      line-height: normal;
    }
    &.flags {
      width: 100%;
      resize: none;
      overflow: auto;
      height: 36px;
      padding: 2px;
      padding-left: 30px;
      @media ${size['desktop-sm-max']} {
        padding-left: 20px;
      }
    }
  }
  .dropdown-menu {
    ul {
      padding: 0;
      margin: 0;
      :last-child {
        padding-bottom: 30px;
        @media ${size['desktop-sm-max']} {
          padding-bottom: 20px;
        }
        @media ${size['laptop-max']} {
          padding-bottom: 10px;
        }
      }
      li {
        margin: 0px;
        font-weight: normal;
        font-size: 18px;
        line-height: 20px;
        color: #444444;
        position: relative;
        list-style: none;
        padding: 10px 30px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
        @media ${size['desktop-sm-max']} {
          font-size: 16px;
          line-height: 18px;
          padding: 5px 20px;
        }
        @media ${size['laptop-max']} {
          font-size: 13px;
        }
        :before {
          position: absolute;
          left: 0;
          top: 50%;
          width: 4px;
          height: 4px;
          background: #c4c4c4;
          border-radius: 50%;
          -webkit-border-radius: 50%;
          -moz-border-radius: 50%;
          content: '';
        }
      }
    }
  }
  .right-map-section {
    position: relative;
    .share-btn-icon {
      position: absolute;
      right: 0;
      top: -20px;
      width: 30px;
      height: 30px;
      background: #a9a9a9 url(${shareIcon}) no-repeat center;
      background-size: 11px auto;
      border-radius: 50%;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      @media ${size['tablet-max']} {
        top: 0;
      }
      @media ${size['tablet-md-max']} {
        z-index: 1;
        top: 10px;
      }
    }
    .map-image-box {
      width: 326px;
      height: 326px;
      border-radius: 50%;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      overflow: hidden;
      margin-top: 20px;
      position: relative;
      @media ${size['laptop-max']} {
        width: 290px;
        height: 290px;
      }
      @media ${size['tablet-md-max']} {
        width: 124px;
        height: 124px;
        margin: 0;
      }
      @media ${size['tablet-max']} {
        width: 100px;
        height: 100px;
        // width: 350px;
        // height: 350px;
        // margin: 0 auto;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        display: block;
        border: none;
        outline: none;
        vertical-align: top;
      }
    }
  }
  .search-btn-wrapper-history {
    border: 1.5px solid #d1d1d1;
    border-radius: 4.26868px;
    width: 175px;
    margin-right: 10px;
    display: flex;
    form {
      display: flex;
    }
    input {
      height: 34px;
      display: block !important;
      width: 130px !important;
      border: none;
      font-size: 15px;
      margin-left: 10px;
      font-weight: 600;
      font-family: OpenSans, sans-serif;
      color: #323232;
    }
  }
  .search-btn-wrapper {
    border: 1.5px solid #d1d1d1;
    border-radius: 4.26868px;
    width: 175px;
    display: flex;
    form {
      display: flex;
    }
    input {
      height: 34px;
      display: block !important;
      width: 130px !important;
      margin-left: 10px;
      border: none;
      font-size: 15px;
      font-weight: 600;
      font-family: OpenSans, sans-serif;
      color: #323232;
    }
  }
  .location-btn {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    background-size: 100% auto;
    background: url(${locationSend}) no-repeat center;
    width: 50px;
    height: 50px;
    transition: all 0.4s ease;
    -webkit-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    opacity: 1;
    visibility: visible;
    @media ${size['tablet-md-max']} {
      width: 35px;
      background-size: 28px auto;
      height: 25px;
    }
  }
  .map-dark-icon {
    width: 18px;
    height: 22px;
    background: url(${mapDarkIcon}) no-repeat center;
    background-size: 100% auto;
    border: 0;
    padding: 0;
    display: inline-block;
    vertical-align: middle;
  }
  .toggle-icon {
    height: 30px;
    width: 30px;
    background: #dedede url(${dropdownArrowIcon}) no-repeat center;
    background-size: 8px auto;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    transition: all 0.4s ease;
    -webkit-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    display: inline-block;
    vertical-align: middle;
    border: 0;
    margin-left: 0 !important;
  }
  .refresh-btn {
    width: 22px;
    height: 16px;
    background: url(${refreshIcon}) no-repeat center;
    background-size: 100% auto;
    border: 0;
    padding: 0;
  }
  .setting-btn-main {
    display: inline-block;
    vertical-align: middle;
    position: relative;
  }
  .dots-btn {
    height: 27px;
    width: 5px;
    background: url(${threeDots}) no-repeat center;
    background-size: 5px auto;
    display: inline-block;
    vertical-align: middle;
    border: 0;
  }
  .search-btn-job-boards {
    width: 18px;
    height: 35px;
    background: url(${searchDarkIcon}) no-repeat center;
    background-size: 100% auto;
    border: 0;
    padding: 0;
    float: right;
    margin-right: 10px;
  }
  .search-btn {
    width: 18px;
    height: 35px;
    background: url(${searchDarkIcon}) no-repeat center;
    background-size: 100% auto;
    border: 0;
    padding: 0;
    margin-left: 30px;
  }
  .opacity0 {
    opacity: 0;
  }
  .delete-icon {
    width: 18px;
    height: 35px;
    background: url(${ParceldeleteIcon}) no-repeat center;
    background-size: 100% auto;
    border: 0;
    padding: 0;
    opacity: 0;
    margin-left: 20px;
  }
  .delete-btn-disabled {
    width: 18px;
    height: 22px;
    position: absolute;
    top: 58.5px;
    background: url(${deleteIconDisabled}) no-repeat center;
    background-size: 100% auto;
    border: 0;
    padding: 0;
    right: 15px;
  }
  .delete-btn {
    width: 18px;
    height: 22px;
    position: absolute;
    top: 58.5px;
    background: url(${deleteIcon}) no-repeat center;
    background-size: 100% auto;
    border: 0;
    padding: 0;
    right: 15px;
  }
  .delete-btn-enabled {
    width: 18px;
    height: 22px;
    position: absolute;
    top: 58.5px;
    background: url(${ParceldeleteIcon}) no-repeat center;
    background-size: 100% auto;
    border: 0;
    padding: 0;
    right: 15px;
  }
  .filter-btn {
    width: 20px;
    height: 35px;
    background: url(${filterDarkIcon}) no-repeat center;
    background-size: 100% auto;
    border: 0;
    padding: 0;
  }
  strong {
    font-weight: 600;
  }
  #parcel-item-preview,
  .order-receipt-list {
    padding: 0;
    margin: 0 !important;
    .pickup-dropoff-main:before {
      top: unset;
    }
    .ant-collapse-item {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }
  }
  .opacityfull {
    opacity: 1 !important;
  }
  .btn.purple-btn {
    min-width: 175px;
    &.btn-size {
      min-width: auto;
      width: auto;
      height: 40px;
      opacity: 0 !important;
      line-height: 40px;
      background: #8f48ff;
      border-radius: 5px;
      border: none;
      &:hover {
        color: #fff;
      }
      @media ${size['desktop-sm-max']} {
        min-width: 132px !important;
        height: 35px !important;
        line-height: 35px !important;
      }
    }
    @media ${size['desktop-mm-min']} {
      min-width: 175px;
      font-size: 13px;
    }
    @media ${size['desktop-md-max']} {
      // min-width: 150px;
    }
  }
  .btn.details-btn {
    min-width: 175px;
    &.btn-size {
      min-width: auto;
      width: 157px;
      height: 40px;
      line-height: 40px;
      background: #fff;
      border-radius: 5px;
      box-shadow: none;
      color: ${(props) => props.theme.fontColor};
      border: 1px solid #d9d9d9;
      @media ${size['desktop-sm-max']} {
        min-width: 132px !important;
        height: 35px !important;
        line-height: 35px !important;
      }
    }
    @media ${size['desktop-mm-min']} {
      min-width: 175px;
      font-size: 13px;
    }
    @media ${size['desktop-md-max']} {
      // min-width: 150px;
    }
  }
  .btn.print-label-btn{
    height: 40px !important;
    width: 135px !important ;
  }
  .m-title {
    display: none;
    padding: 15px 0;
    @media ${size['tablet-max']} {
      display: block;
      width: 100%;
    }
    @media (min-width: 576px) {
      > div.d-flex {
        flex: 0 0 100%;
        max-width: 100%;
      }
    }
    h6 {
      align-items: center;
      font-size: 15px;
      font-weight: 500;
      margin: 0;
      margin-top: 5px;
      letter-spacing: 0.05em;
    }
    .toggle-icon {
      align-items: center;
      &.hide {
        background: #dedede url(${dropdownArrow}) no-repeat center !important;
        background-size: 8px auto !important;
      }
    }
  }
  .setting-btn {
    margin-top: 5px;
    background: url(${settingsIcon}) no-repeat center;
    width: 22px;
    height: 22px;
    background-size: 100% auto;
    border: 0;
    transition: all 0.4s ease;
    -webkit-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    display: inline-block;
    vertical-align: middle;
    position: relative;
    margin-right: 15px;
  }
  .orderDiv,
  .pickDiv,
  .dropDiv,
  .pickLoc {
    @media ${size['tablet-max']} {
      display: none !important;
    }
  }
  .opacityZero {
    opacity: 0;
  }
  .select-job-board {
    height: 40px !important;
    width: 150px;
    border: 1.5px solid #d1d1d1;
    box-sizing: border-box;
    border-radius: 5px;
    .ant-select-selection__rendered {
      margin-right: 15px !important;
    }
  }
  .btn.purple-btn {
    @media ${size['desktop-mm-max']} {
      font-size: 14px;
    }
  }
  .ant-list-items {
    padding: 12px 24px !important;
    margin-bottom: 80px;
  }
  .ant-list-items li {
    text-transform: none !important;
  }
  .print-labels:visited{
    background: "red";
  }
  .ant-list-split .ant-list-item:last-child{
    border-bottom: 1px solid #e8e8e8 !important;
  }
`;

const UploadWrapper = styled.div`
  display: flex;
  span.ant-upload-picture-card-wrapper {
    width: 100%;
  }
  .ant-upload-select-picture-card {
    width: 100%;
  }
  .ant-upload-list-picture-card-container {
    width: 100%;
    .ant-upload-list-item {
      width: 100%;
    }
  }
`;

const TeamSelectWrapper = styled(SelectFormWrapper)`
  .dropdown-section {
    & > div > div {
      overflow: unset !important;
    }
  }

  /* .select-custom {
    .ant-select-selection {
      height: 32px;
    }
  } */
  .ant-select-dropdown-menu-item-group-title {
    font-family: ${(props) => props.theme.primaryFont}, sans-serif;
    font-weight: 500;
    font-size: 20px;
    color: ${(props) => props.theme.primaryColor};
  }
  .dropdown-custom ul li.ant-select-dropdown-menu-item:hover:not(.ant-select-dropdown-menu-item-disabled) {
    background-color: ${(props) => props.theme.orangeShadeColor1};
  }
  .dropdown-custom ul li.ant-select-dropdown-menu-item-selected {
    background-color: ${(props) => props.theme.orangeShadeColor1};
  }
  .dropdown-custom {
    ul {
      margin: 0 -5px 0 -18px;
      padding: 0 0 0 5px;
      max-height: none;
      overflow-x: hidden;
      li {
        padding: 15px 0 15px 25px;
        &.ant-select-dropdown-menu-item-selected {
          background-color: ${(props) => props.theme.orangeShadeColor1};
          color: #fff;
          border-radius: 5px !important;
          font-family: ${(props) => props.theme.secondaryFont}, sans-serif;
          font-weight: 600;
        }
        &.ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled) {
          background-color: ${(props) => props.theme.orangeShadeColor1};
          border-radius: 5px !important;
          font-family: ${(props) => props.theme.secondaryFont}, sans-serif;
          font-weight: 600;
        }
      }
    }
    &.teams {
      ul {
        li {
          padding: 15px 25px;
        }
      }
    }
  }
`;
const HeadPanel = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  @media ${size['tablet-md-max']} {
    display: inline-block;
  }
  .teams-select-wrapper {
    width: 40%;
    margin-bottom: 20px;
    @media ${size['desktop-md-max']} {
      width: 45%;
    }
    @media ${size['tablet-md-max']} {
      width: 100%;
      // margin-bottom: 30px;
      margin-bottom: 0;
    }
  }
`;
const JobBoardsTeamSelectRow = styled(Row)`
  align-items: center;
  p.label {
    margin: 0;
    font-family: ${(props) => props.theme.primaryFont}, sans-serif;
    font-weight: 400;
  }
  .dropdown-custom ul li.ant-select-dropdown-menu-item:hover:not(.ant-select-dropdown-menu-item-disabled) {
    background-color: #ffa100;
  }
  .dropdown-custom ul li.ant-select-dropdown-menu-item-selected {
    background-color: #ffa100;
  }
  .dropdown-custom ul {
    margin: 0;
    padding: 0 0 0 0;
    &.ant-select-dropdown-menu-root {
      margin: 0 -5px 0 -5px;
    }
    li {
      &.users-options {
      }
      &.ant-select-dropdown-menu-item-selected {
        background-color: #ffa100;
      }
      &.ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled) {
        background-color: #ffa100;
      }
    }
    .ant-select-dropdown-menu-item-group {
      padding: 0px;
      .ant-select-dropdown-menu-item-group-title {
        padding-left: 25px;
      }
    }
  }
`;

const OrderPreviewCardWrapper = styled.div`
  .route-optimization-wrapper {
    background: #8f48ff;
    border-radius: 5px;
    padding: 10px 30px;
    margin: 0 auto 10px auto;
    width: 93%;
    span {
      &.title-header {
        font-weight: 600;
      }
      font-family: ${(props) => props.theme.primaryFont};
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      letter-spacing: 0.05em;
      color: #ffffff;
      .link-text {
        cursor: pointer;
        text-decoration: underline;
      }
    }
    .close-btn {
      background-size: 60% auto;
      background-image: url(${closeIcon});
      background-repeat: no-repeat;
      background-position: center;
      padding: 0px;
      position: absolute;
      cursor: pointer;
      height: 20px;
      width: 15px;
      top: 5px;
      right: 35px;
    }
  }
  .card-custom {
    .ant-card-head {
      background: #fafafa;
      border: 0;
      padding: 15px 35px;
      padding-bottom: 18px;
      position: relative;
      align-items: center;
      ${borderRadius('9px 9px 0px 0px')};

      @media ${size['desktop-mm-max']} {
        padding: 10px 25px;
      }
      @media ${size['tablet-max']} {
        padding: 10px 20px;
      }
      .ant-card-head-title {
        text-transform: uppercase;
        padding: 0 0;
        overflow: visible;
      }
    }
    .card-header {
      background: #fafafa;
      border: 0;
      position: relative;
      align-items: center;
      ${borderRadius('9px 9px 0px 0px')};

      h5 {
        margin: 0;
        color: ${(props) => props.theme.secondaryColor};
        font-family: ${(props) => props.theme.primaryFont}, sans-serif;
        font-size: 20px;
        line-height: 26px;
        font-weight: 500;
        @media ${size['desktop-mm-max']} {
          font-size: 20px;
          line-height: 24px;
        }
        @media ${size['tablet-max']} {
          font-size: 18px;
          line-height: 20px;
        }
      }
      &.d-flex {
        @media ${size['mobile-md-max']} {
          display: block !important;
        }
      }
    }
    .three-fields-group {
      flex-wrap: wrap;
      margin: 25px 0 0;
      position: relative;
      padding: 0;
      @media ${size['desktop-mm-max']} {
        margin: 20px 0 0;
      }
      @media ${size['tablet-max']} {
        padding-bottom: 15px;
      }
    }
    .parcelDiv {
      margin: 30px 0 0;
      padding-bottom: 10px;
    }
    .ant-card-head {
      z-index: 2;
    }
    .ant-card-body {
      // height: 520px;
      // max-height: 520px;
      // min-height: 520px;
      // overflow: auto;
      box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
      border-radius: 0 0 10px 10px;
      padding: 35px 35px 45px 35px;
      margin-top: -25px;
      .booking-current-service-type {
        ul {
          padding: 0;
          margin: 0;
          li {
            list-style: none;
            display: flex;
            font-weight: normal;
            font-size: 15px;
            line-height: 20px;
            color: #323232;
            span {
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
              padding-right: 20px;
            }
            .text-link {
              background: #fff;
              border-radius: 4.26868px;
              margin-left: auto;
              border: 1px solid #d1d1d1;
              font-weight: 600;
              font-size: 12px;
              color: #323232;
              text-transform: uppercase;
              height: 46px;
              line-height: 46px;
              display: inline-block;
              text-align: center;
              width: auto;
              padding: 0 20px;
              min-width: max-content;
              .new-edit-icon {
                display: inline-block;
                vertical-align: middle;
                background: url(${editPen}) no-repeat center;
                background-size: 100% auto;
                width: 19px;
                height: 19px;
                margin: -2px 5px 0 0;
                transition: all 0.4s ease;
              }
              &:hover {
                border: 1px solid #00c68d;
              }
            }
            .dots-btn {
              display: inline-block;
              vertical-align: middle;
              background: url(${threeDots}) no-repeat center;
              background-size: 4px auto;
              height: 27px;
              width: 5px;
              margin: -2px 10px 0 10px;
              transition: all 0.4s ease;
            }
          }
        }
      }
      // @media ${size['desktop-sm-max']} {
      //   height: 520px;
      //   max-height: 520px;
      //   min-height: 520px;
      // }
      &::-webkit-scrollbar {
        width: 5px;
        height: 5px;
      }
      &::-webkit-scrollbar-track {
        box-shadow: none;
        border-radius: 10px;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
      }
      &::-webkit-scrollbar-thumb {
        background: #8f48ff;
        border-radius: 10px;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
      }
      &::-webkit-scrollbar-thumb:hover {
        background: #8f48ff;
      }
      @media ${size['desktop-mm-max']} {
        padding: 25px 25px 30px 25px;
      }
      @media ${size['tablet-max']} {
        padding: 20px 20px 20px 20px;
        margin-top: -15px;
      }
      .form-group {
        margin: 25px 0;
        @media ${size['desktop-mm-max']} {
          margin: 20px 0;
        }
      }
      .detailDiv {
        margin-bottom: 25px;
      }
      label.label {
        margin-bottom: 7px;
        @media ${size['desktop-mm-max']} {
          font-size: 12px;
          line-height: 14px;
        }
      }
    }
  }

  .service-review-section {
    margin-bottom: 0px;
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    .service-col {
      display: flex;
      flex-direction: column;
      .service-name {
        font-size: 15px;
        font-weight: 600;
        margin-bottom: 0;
      }
      .collection-type {
        font-size: 15px;
      }
      .carrier-image {
        width: 52px;
        // height: 52px;
      }
      img {
        width: 100%;
        padding-top: 6px;
        // height: 100%;
      }
    }
    .date-col {
      display: flex;
      flex-direction: column;
      font-size: 15px;
      .date-name {
        font-weight: 600;
      }
    }
    .price-col {
      display: flex;
      flex-direction: column;
      font-size: 16px;
      .price-name {
        font-weight: 600;
      }
      .price-value {
        font-weight: 700;
      }
    }
  }

  // position: static;
  // top: 49px;
  // left: 15px;
  // right: 15px;
  // min-height: 565px;
  // z-index: 99999;
  .card-custom {
    .ant-card-head {
      background-color: #8f48ff;
      .custom {
        padding: 0;
      }
      .card-header {
        background-color: #8f48ff;
        h5 {
          color: #fff;
        }
        .panel-next-day-text {
          color: #fff;
        }
      }
    }
    .ant-card-body {
      // min-height: 550px;
      // height: unset;
      // @media ${size['desktop-sm-max']} {
      //   min-height: 410px;
      //   height: 450px;
      //   }
      .location-preview {
        .order-receipt-list {
          margin: 0;
          .accor-content {
            width: 100%;
            position: relative;
            padding-right: 0px !important;
            padding-left: 45px !important;
            margin-top: 20px;
            .address-wrapper {
              width: 60%;
            }
            .delivery-time {
              position: absolute;
              right: 10px;
              top: 0;
              font-weight: 500;
              font-size: 18px;
              line-height: 154%;
              text-align: right;
              color: #696969;
              @media ${size['desktop-mm-max']} {
                font-size: 16px;
              }
              @media ${size['tablet-max']} {
                font-size: 14px;
              }
            }
            p {
              font-size: 18px;
              line-height: 154%;
              color: #696969;
              margin: 0;
              font-weight: 400;
              @media ${size['desktop-mm-max']} {
                font-size: 16px;
              }
              @media ${size['tablet-max']} {
                font-size: 14px;
              }
              strong {
                font-weight: 500;
              }
            }
          }
        }
        ul.total-price li {
          padding-top: 0;
          padding-bottom: 0;
          min-height: 35px;
        }
        ul.total-price li p {
          font-weight: normal;
          font-size: 16px;
          line-height: 18px;
          color: #424242;
          margin: 0;
          font-family: ${(props) => props.theme.secondaryFont}, sans-serif;
          letter-spacing: 0.025em;
          @media ${size['desktop-mm-max']} {
            font-size: 16px;
            line-height: 18px;
          }
          @media ${size['tablet-max']} {
            font-size: 14px;
          }
        }
        ul.total-price li .pickup-dropoff-main .price-text {
          margin-left: auto;
          font-weight: 500;
          font-size: 18px;
          line-height: 18px;
          color: #424242;
          @media ${size['tablet-max']} {
            font-size: 26px;
          }
          @media ${size['mobile-md-max']} {
            font-size: 20px;
          }
        }
        ul.total-price:hover {
          background: #fafafa;
          font-weight: 700 !important;
        }
        ul {
          // margin: 0 -35px;
          // padding: 0;
          // @media ${size['desktop-mm-max']} {
          //   margin: 0 -25px;
          // }
          // @media ${size['tablet-max']} {
          //   margin: 0 -20px;
          // }
          li {
            margin: 0px;
            list-style: none;
            position: relative;
            font-size: 18px;
            line-height: 20px;
            color: #49465d;
            font-weight: 400;
            padding: 10px 40px 10px 80px;
            transition: all 0.4s ease;
            -webkit-transition: all 0.4s ease;
            -moz-transition: all 0.4s ease;
            -webkit-flex: flex;
            display: flex;
            align-items: center;
            flex-direction: column;
            // min-height: 70px;
            justify-content: center;
            @media ${size['desktop-mm-max']} {
              min-height: 60px;
              font-weight: 500;
              padding-right: 30px;
            }
            @media ${size['desktop-mm-min']} {
              // min-height: 50px;
            }
            @media ${size['tablet-max']} {
              min-height: 46px;
              font-size: 13px;
              padding: 10px 20px 10px 40px;
            }
            :hover {
              background: #fafafa;
              font-weight: 700 !important;
              .toggle-icon-main {
                opacity: 1 !important;
              }
            }
            :before {
              display: none;
              position: absolute;
              left: 50px;
              top: 50%;
              width: 4px;
              height: 4px;
              background: #c4c4c4;
              ${borderRadius('50%')};
              content: '';
              @media ${size['desktop-mm-max']} {
                top: 29px;
                left: 40px;
              }
              @media ${size['tablet-max']} {
                top: 22px;
                left: 25px;
              }
            }
            .ant-collapse {
              border: 0;
              width: 100%;
              background-color: transparent;
            }
            .ant-collapse-content {
              background-color: transparent;
              border-top: 0;
            }
            .ant-collapse > .ant-collapse-item {
              border-bottom: 0;
            }
            .ant-collapse-content > .ant-collapse-content-box {
              padding: 0px;
            }
            .ant-collapse > .ant-collapse-item > .ant-collapse-header {
              padding: 0;
            }
            .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow svg {
              display: none;
            }
            .ant-collapse-item.ant-collapse-item-active {
              .toggle-icon-main {
                transform: rotate(180deg);
              }
            }
            .pickup-dropoff-main {
              font-size: 18px;
              line-height: 21px;
              padding-right: 0px;
              color: #49465d;
              width: 100%;
              align-items: center;
              @media ${size['desktop-mm-max']} {
                font-size: 16px;
              }
              @media ${size['tablet-max']} {
                font-size: 14px;
              }
              .price-text {
                font-weight: 400;
                line-height: 36px;
                color: #424242;
              }
              .pickup-dropoff {
                width: 60%;
                span {
                  display: inline-block;
                  vertical-align: middle;
                }
                i {
                  display: inline-block;
                  vertical-align: middle;
                  margin: 0 15px;
                  background: url(${arrowRightLargeIcon}) no-repeat center;
                  background-size: 100% auto;
                  width: 24px;
                  height: 10px;
                  @media ${size['tablet-max']} {
                    margin: 0 5px;
                    width: 15px;
                  }
                }
              }
              .toggle-icon-main {
                height: 30px;
                width: 30px;
                margin: 0 auto;
                background: #dedede url(${dropdownArrow}) no-repeat center;
                background-size: 8px auto;
                ${borderRadius('50%')};
                opacity: 0;
                transition: all 0.4s ease;
                -webkit-transition: all 0.4s ease;
                -moz-transition: all 0.4s ease;
                @media ${size['desktop-mm-max']} {
                  margin-right: 20px;
                }
              }
              .price-text {
                margin-left: auto;
                font-size: 20px;
                color: #424242;
                @media ${size['desktop-mm-max']} {
                  font-size: 18px;
                }
                @media ${size['tablet-max']} {
                  font-size: 14px;
                }
              }
            }
          }
        }
      }
    }
  }
  .location-header-selected-service {
    @media ${size['desktop-sm-max']} {
      margin-top: 10px;
    }
  }
  .pickup-location-listing {
    // margin: 0 -35px;
    padding: 0;
    .pickup {
      margin: 0px;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-align-items: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      cursor: pointer;
      list-style: none;
      position: relative;
      font-size: 15px !important;
      font-family: ${(props) => props.theme.secondaryFont};
      line-height: 16px;
      color: #323232;
      font-weight: normal;
      padding: 6px 0 6px 0;
      padding-top: 0;
      padding-bottom: 0;
      span {
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        line-height: 20px;
      }
    }
  }
  .orangeIcon {
    margin-right: 14px;
    width: 20px;
  }
  .parcel-address {
    margin: 20px 0px;
    // padding-lefT: 32px;
    li {
      // :after {
      //   content: '';
      //   display: inline-block;
      //   position: absolute;
      //   background: url(${deliveryOrangeIcon}) no-repeat center;
      //   width: 20px;
      //   height: 22px;
      //   left: 0;
      // }
      &:hover {
        .price-tag {
          opacity: 0;
          display: none;
        }
      }
    }
  }
  .pickup-dropoff-main {
    justify-content: space-between;
    .price {
      padding: 0 5px 0 0;
    }
    &.price-bold {
      p {
        font-weight: 600 !important;
        color: #424242 !important;
      }
      .price-text {
        font-weight: 600 !important;
      }
    }
  }

  .location-listing-wrapper {
    li {
      flex-direction: row !important;
      justify-content: initial !important;
    }
  }

  .empty-state-wrapper {
    .icon-container {
      height: 160px;
      width: 160px;
      border-radius: 100%;
      padding: 28px 32px 36px 32px;
      margin-inline: auto;
      margin-block: 64px;
      background-color: ${(props) => props.theme.greyShadeColor2};
    }
    .text-wrapper {
      text-align: center;
      padding-inline: 85px;
      color: ${(props) => props.theme.fontColor};
      .title {
        font-weight: 700;
        font-size: 18px;
      }
      .sub-title {
        font-weight: 400;
        font-size: 14px;
      }
    }
  }
`;

const OrderLocationPreviewWrapper = styled.div`
  width: 100%;
  .location-preview {
    .map-switch {
      margin-top: -10px;
      margin-bottom: 10px;
      span {
        color: #323232;
      }
      button.ant-switch {
        margin-left: 10px;
      }
      button.ant-switch.ant-switch-checked {
        background-color: #8f48ff;
      }
    }
    .pickup-dropoff-main {
      font-size: 18px;
      line-height: 21px;
      color: #49465d;
      width: 100%;
      align-items: center;
      @media ${size['desktop-mm-max']} {
        font-size: 16px;
      }
      @media ${size['tablet-max']} {
        font-size: 14px;
        i {
          margin: 0 5px;
          width: 15px;
        }
      }
      .pickup-dropoff {
        width: 60%;
      }
    }
    ul.total-price li {
      padding-top: 0;
      padding-bottom: 0;
    }
    ul.total-price li p {
      font-weight: normal;
      font-size: 16px;
      line-height: 18px;
      color: #a9a9a9;
      margin: 0;
      letter-spacing: 0.025em;
      @media ${size['desktop-mm-max']} {
        font-size: 16px;
        line-height: 18px;
      }
      @media ${size['tablet-max']} {
        font-size: 14px;
      }
    }
    ul.total-price li .pickup-dropoff-main .price-text {
      margin-left: auto;
      font-weight: 500;
      font-size: 22px;
      line-height: 22px;
      color: #424242;
      @media ${size['tablet-max']} {
        font-size: 20px;
      }
    }

    .beforenone:before {
      display: none;
    }
    .order-receipt-list {
      margin: 0 -35px;
      padding: 0;
      li {
        margin: 0px;
        list-style: none;
        position: relative;
        font-size: 18px;
        line-height: 20px;
        color: #49465d;
        font-weight: 400;
        padding: 20px 40px 20px 80px;
        transition: all 0.4s ease;
        -webkit-transition: all 0.4s ease;
        -moz-transition: all 0.4s ease;
        cursor: pointer;
        -webkit-flex: flex;
        display: flex;
        align-items: center;
        flex-direction: column;
        min-height: 70px;
        justify-content: center;
        @media ${size['desktop-mm-max']} {
          min-height: 60px;
          font-weight: 500;
        }
        @media ${size['tablet-max']} {
          min-height: 46px;
        }
        :hover .toggle-icon-main .delete-icon {
          opacity: 1 !important;
        }
        :before {
          position: absolute;
          left: 50px;
          top: 33px;
          width: 4px;
          height: 4px;
          background: #c4c4c4;
          border-radius: 50%;
          -webkit-border-radius: 50%;
          -moz-border-radius: 50%;
          content: '';
          @media ${size['desktop-mm-max']} {
            top: 29px;
            left: 40px;
          }
          @media ${size['tablet-max']} {
            top: 22px;
            left: 25px;
          }
          display: none;
        }
      }
      .ant-collapse {
        border: 0;
        width: 100%;
        background-color: transparent;
      }
      .ant-collapse-content {
        background-color: transparent;
        border-top: 0;
      }
      .ant-collapse > .ant-collapse-item {
        border-bottom: 0;
      }
      .ant-collapse-content > .ant-collapse-content-box {
        padding: 0px;
      }
      .ant-collapse > .ant-collapse-item > .ant-collapse-header {
        padding: 0;
      }
      .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow svg {
        display: none;
      }
      .ant-collapse-item.ant-collapse-item-active {
        .toggle-icon-main {
          transform: rotate(180deg);
        }
      }
      .accor-content {
        width: 100%;
        position: relative;
        padding-right: 80px !important;
        padding-left: 45px !important;
        margin-top: 20px;
        .delivery-time {
          position: absolute;
          right: 0;
          top: 0;
          font-weight: 500;
          font-size: 18px;
          line-height: 154%;
          text-align: right;
          color: #696969;
          @media ${size['desktop-mm-max']} {
            font-size: 16px;
          }
          @media ${size['tablet-max']} {
            font-size: 14px;
          }
        }
        p {
          font-size: 18px;
          line-height: 154%;
          color: #696969;
          margin: 0;
          font-weight: 400;
          @media ${size['desktop-mm-max']} {
            font-size: 16px;
          }
          @media ${size['tablet-max']} {
            font-size: 14px;
          }
          strong {
            font-weight: 500;
          }
        }
      }
      .pickup-dropoff-main {
        font-size: 18px;
        line-height: 21px;
        color: #49465d;
        width: 100%;
        align-items: center;
        @media ${size['desktop-mm-max']} {
          font-size: 16px;
        }
        @media ${size['tablet-max']} {
          font-size: 14px;
        }
        .price-text {
          font-weight: 500;
          font-size: 30px;
          line-height: 36px;
          color: #424242;
          @media ${size['desktop-mm-max']} {
            font-size: 16px;
          }
        }
        .pickup-dropoff {
          width: 60%;
          span {
            display: inline-block;
            vertical-align: middle;
          }
          i {
            display: inline-block;
            vertical-align: middle;
            margin: 0 15px;
            background: url(${arrowRightLargeIcon}) no-repeat center;
            background-size: 100% auto;
            width: 24px;
            height: 10px;
          }
        }
        .toggle-icon-main {
          height: 30px;
          width: 30px;
          margin: 0 auto;
          background: #dedede url(${dropdownArrow}) no-repeat center;
          background-size: 8px auto;
          border-radius: 50%;
          -webkit-border-radius: 50%;
          -moz-border-radius: 50%;
          opacity: 0;
          transition: all 0.4s ease;
          -webkit-transition: all 0.4s ease;
          -moz-transition: all 0.4s ease;
          @media ${size['desktop-mm-max']} {
            margin-right: 20px;
          }
          @media ${size['mobile-md-max']} {
            margin-left: 0;
          }
        }
        .price-text {
          margin-left: auto;
          font-size: 20px;
          color: #424242;
          @media ${size['desktop-mm-max']} {
            font-size: 18px;
          }
          @media ${size['tablet-max']} {
            font-size: 14px;
          }
        }
      }
    }
    ul.location-listing-wrapper.pickup-location-listing.starting-address {
      // margin: 0 -35px;
      padding: 0;
    }
    ul.total-price.net-gross-price {
      margin: 0 -35px;
      padding: 0;
    }
    ul.location-listing-wrapper {
      // margin: 0 -35px;
      // padding: 0;
      // @media ${size['desktop-mm-max']} {
      //   margin: 0 -25px;
      // }
      // @media ${size['tablet-max']} {
      //   margin: 0 -20px;
      // }
      li {
        margin: 0px;
        display: flex;
        align-items: center;
        transition: none;
        cursor: pointer;
        list-style: none;
        position: relative;
        font-size: 18px;
        line-height: 20px;
        color: #49465d;
        font-weight: 400;
        padding: 15px 40px 15px 80px;
        transition: all 0.4s ease;
        -webkit-transition: all 0.4s ease;
        -moz-transition: all 0.4s ease;
        @media ${size['desktop-mm-max']} {
          font-size: 15px;
          min-height: 60px;
          padding: 15px 30px 15px 60px;
        }
        @media ${size['tablet-max']} {
          min-height: 46px;
          font-size: 13px;
          padding: 10px 20px 10px 40px;
        }
        :hover {
          background: #fafafa;
          font-weight: 700 !important;
          .setting-btn,
          .draggable-icon {
            opacity: 1;
          }
        }
        span {
          max-width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          padding-right: 10px;
        }
        .setting-btn {
          background: url(${settingsIcon}) no-repeat center;
          width: 22px;
          height: 22px;
          background-size: 100% auto;
          border: 0;
          opacity: 0;
        }
        .draggable-icon {
          background: url(${draggableIcon}) no-repeat center;
          width: 23px;
          height: 10px;
          background-size: 100% auto;
          border: 0;
          margin-left: 25px;
          opacity: 0;

          display: inline-block;
          vertical-align: middle;
        }
      }
      &.pickup-location-listing {
        li {
          // :after {
          //   content: '';
          //   display: inline-block;
          //   position: absolute;
          //   background: url(${pickupSmallIcon}) no-repeat center;
          //   width: 20px;
          //   height: 22px;
          //   left: 45px;
          //   @media ${size['desktop-mm-max']} {
          //     left: 28px;
          //   }
          //   @media ${size['tablet-max']} {
          //     left: 10px;
          //   }
          // }
        }
      }
    }
  }
  .plr-50 {
    padding-left: 60px;
    padding-right: 50px;
    @media ${size['desktop-mm-max']} {
      padding-left: 88px;
      padding-right: 50px;
    }
    @media ${size['desktop-sm-max']} {
      padding-right: 53px;
      padding-left: 88px;
    }
    @media ${size['laptop-max']} {
      padding-left: 65px;
      padding-right: 28px;
    }
    @media ${size['tablet-md-max']} {
      padding-left: 40px;
      padding-right: 10px;
    }
    .warning-text {
      font-size: 15px;
      font-weight: 500;
      line-height: 18px;
      color: #565656;
      padding-left: 30px;
      margin-top: 55px;
      display: block;
      @media ${size['desktop-mm-max']} {
        padding-left: 0px;
        margin-top: 30px;
      }
    }
  }
  .map-view {
    height: 465px;
  }
  
`;

const SpaceWrapper = styled.div`
  margin: ${(props) => props.margin || 0};
  padding: ${(props) => props.padding || 0};
`;
export {
  SpaceWrapper,
  OrderPreviewCardWrapper,
  OrderLocationPreviewWrapper,
  UploadWrapper,
  BookingWrapper,
  AccordianStyle,
  CouriersWrapper,
  CardHeader,
  PageInfo,
  MiddleSection,
  MaxContainer,
  LocationPreviewWrapper,
  DropdownWrapper,
  CardWrapper,
  CommonTabWrapper,
  TeamSelectWrapper,
  HeadPanel,
  JobBoardsTeamSelectRow,
};

