import React, { Component } from 'react';
import { Formik, Field } from 'formik';
import { Col, Collapse, Input as input, Row, Modal, Form } from 'antd';
import { connect } from 'react-redux';
import { setFilteredData } from 'redux/booking/actions';
import { SearchWrapper } from './style';
import { FormGroup, SelectOption, FormButton as Button, FieldInput } from 'components/Form';
import isEmpty from 'lodash.isempty';

//FIXME - Need Optimization
class Search extends Component {
  state = {
    filter: '',
    searchString: '',
    visible: false
  };
  async componentDidUpdate() {
    const { filter } = this.state;
    const { jobData, type, tabKey } = this.props;
    let lowercasedFilter = '',
      filteredData = '';
    if (!isEmpty(filter)) {
      lowercasedFilter = filter.toLowerCase();
    }
    if (!isEmpty(jobData)) {
      filteredData = jobData.filter(item => {
        if (item != null) {
          if (type == 'jobboard' || type == 'history') {
            return Object.keys(item).some(key => {
              if (
                key == 'pickup_person_name' ||
                key == 'pickup_address2' ||
                key == 'pickup_address1' ||
                key == 'pickup_postcode' ||
                key == 'pickup_city' ||
                key == 'pickup_country_code' ||
                key == 'delivery_person_name' ||
                key == 'delivery_address2' ||
                key == 'delivery_address1' ||
                key == 'delivery_postcode' ||
                key == 'delivery_city' ||
                key == 'delivery_country_code' ||
                key == 'job_title' ||
                key == 'carrier_name'
              ) {
                return (
                  typeof item[key] === 'string' &&
                  item[key].toLowerCase().includes(lowercasedFilter)
                );
              }
            });
          } else if (type == 'payment') {
            return Object.keys(item).some(key => {
              if (this.paymentsFilterKey(key)) {
                return (
                  typeof item[key] === 'string' &&
                  item[key].toLowerCase().includes(lowercasedFilter)
                );
              }
            });
          } else if (type == 'favourites') {
            return Object.keys(item).some(key => {
              if (
                key == 'address1' ||
                key == 'city' ||
                key == 'postcode' ||
                key == 'person_name' ||
                key == 'mobile_number' ||
                key == 'phone_number'
              ) {
                return (
                  typeof item[key] === 'string' &&
                  item[key].toLowerCase().includes(lowercasedFilter)
                );
              }
            });
          }
        }
      });
    }
    await this.props.setFilteredData(filteredData);
  }
  paymentsFilterKey = key => {
    const { tabKey } = this.props;
    if (tabKey == 1 || tabKey == 3) {
      return key == 'type_name' || key == 'payment_status';
    } else if (tabKey == 2) {
      return key == 'last_4_digits' || key == 'card_type';
    } else {
      return key == 'outstanding_amount';
    }
  };
  inputChange = e => {
    this.setState({ searchString: e.target.value });
  };
  Search = () => {
    this.setState({ filter: this.state.searchString });
  };
  handleChange = () => {
    this.setState({ visible: true });
  };
  clearSearch = () => {
    this.setState({
      filter: '',
      visible: false
    });
  };
  handleCancel = e => {
    this.setState({
      visible: false
    });
  };
  render() {
    const { jobData, type, tabKey, screenWidth } = this.props;
    const { visible } = this.state;
    return (
      <SearchWrapper className={`${visible ? 'search-wrapper' : ''}, team-left-box1`}>
        <div className={`search-btn-grp`}>
          <form
            onSubmit={e => {
              e.preventDefault();
              this.Search();
            }}
          >
            <input type="text" onChange={this.inputChange} />
            <button className="search-btn" type="submit"></button>
          </form>
        </div>
        {/* {showInput && type !== 'favourites' && (
          <div className="search-bar">
            <input type="text" className="form-control search-input" onChange={this.inputChange} />
            <button
              type="button"
              className="close-btn"
              id="mapdropdown"
              onClick={this.clearSearch}
            />
          </div>
        )} */}
      </SearchWrapper>
    );
  }
}

const mapStateToProps = state => ({
  screenWidth: state.app.screenWidth
});
const mapDispatchToProps = dispatch => ({
  setFilteredData: payload => dispatch(setFilteredData(payload))
});
export default connect(mapStateToProps, mapDispatchToProps)(Search);
