import { size } from 'App/device';
import { borderRadius } from 'App/mixins';
import styled from 'styled-components';

// FIXME - Need Clean up
const FormWrapper = styled.div`
  padding: 0;
  margin: 0;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
  input[type='password']:-webkit-autofill,
  input[type='password']:-webkit-autofill:hover,
  input[type='password']:-webkit-autofill:focus {
    background-color: initial !important;
    -webkit-text-fill-color: #979797 !important;
    transition: background-color 5000s ease-in-out 0s !important;
  }
  input[type='text']:-webkit-autofill,
  input[type='text']:-webkit-autofill:hover,
  input[type='text']:-webkit-autofill:focus {
    background-color: initial !important;
    -webkit-text-fill-color: #49465d !important;
    transition: background-color 5000s ease-in-out 0s !important;
  }
  input.form-control {
    background: #fff;
    font-size: 15px;
    font-weight: 600;
    /* padding: 0px 14px; */
    ${borderRadius('0px')};
    color: ${props => props.theme.primaryColor};
    font-family: ${props => props.theme.secondaryFont}, sans-serif;
    /* border: 0.1rem solid #cecece; */
    display: block;
    width: 100%;
    height: 50px;
    line-height: 1.5;
    background-clip: padding-box;
    letter-spacing: 0;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    &::placeholder {
      color: ${props => props.theme.primaryColor};
    }
    &::-ms-input-placeholder {
      color: ${props => props.theme.primaryColor};
    }
    &::-ms-input-placeholder {
      color: ${props => props.theme.primaryColor};
    }
    &:first-child {
      input {
        border-right: 1px solid #e7e7e7;
      }
    }
    @media ${size['desktop-sm-max']} {
      &:first-child {
        //box-shadow: none;
        border-radius: 0;
      }
    }
    @media ${size['desktop-sm-max']} {
      padding: 10px 14px;
      height: 38px;
      font-size: 14px;
    }
    @media ${size['tablet-max']} {
      font-size: 14px;
      height: 36px;
    }
  }
  &.error-wrapper {
    input.form-control {
      // box-shadow: 0px 0px 5px #d6181a !important;
      border-bottom: 1px solid #d6181a !important;
    }
    .password-icon:checked {
      /* border-top: 1px solid #d6181a;
      border-bottom: 1px solid #d6181a; */
    }
  }
  &.code-type .password-icon {
    width: 12px;
    @media ${size['desktop-sm-max']} {
      height: 38px;
    }
  }
  &.code-type input.form-control {
    font-size: 15px;
    font-weight: 600;
    letter-spacing: initial;
    &.password-normal-input {
      font-size: 16px;
      font-weight: 400;
      letter-spacing: initial;
    }
  }
  &.code-type .password-icon:checked + input.form-control {
    color: #49465d;
  }
`;

const CheckIcon = styled.input`
  &.password-icon {
    width: 18px;
    height: 50px;
    background: url(${props => props.iconBackground}) no-repeat center;
    background-size: 100% auto !important;
    position: absolute;
    right: 22px;
    z-index: 4;
    padding: 0 !important;
    box-shadow: none !important;
    border: 0;
    top: 18px;
    @media ${size['desktop-sm-max']} {
      height: 38px;
    }
  }
  &.password-icon:focus {
    outline: none !important;
  }
  &.password-icon:checked {
    background: transparent url(${props => props.iconBackgroundActive}) no-repeat center;
    background-size: 100% auto !important;
  }
`;

export { FormWrapper, CheckIcon };
