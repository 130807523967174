import { Col, Form, Modal, Row, Switch, Tooltip } from 'antd';
import {
  AddressConstant,
  AddressPayload,
  AppValidation,
  FormValidation,
  handleNumberBlur,
  handlew3wBlur,
  PlacesOptions,
  UserPayload,
} from 'App/AppConstant';
import { FormButton as Button, FormGroup, FormNewInput as Input, FormTextarea } from 'components/Form';
import { FormWrapper } from 'components/FormNewInput/style';
import MapMarker from 'components/MapMarker';
import { Formik } from 'formik';
import isEmpty from 'lodash.isempty';
import React, { Component } from 'react';
import PlacesAutocomplete from 'react-places-autocomplete';
import { connect } from 'react-redux';
import { addFavourite, addNewAddressFavourites, getFavourites } from 'redux/favourite/actions';
import * as Yup from 'yup';
import { AddFavModalWrapper } from './style';

const addressSchema = Yup.object().shape({
  address1: Yup.string().required(FormValidation.streetNumberRequired),
  postcode: Yup.string().required(FormValidation.passcodeRequired),
  city: Yup.string().required(FormValidation.cityRequired),
  country_code: Yup.string().required(FormValidation.countryRequired),
  person_name: Yup.string().required(FormValidation.personNameRequired),
  email: Yup.string().email(FormValidation.emailInvalid),
  phone_number: Yup.string()
    .test({
      message: FormValidation.phoneNumberMinimum,
      test: (value) => value == null || (value.charAt(0) === '1' ? value.length >= 9 : value.length === 10),
    })
    .test({
      message: FormValidation.numberUkValidate,
      test: (value) => {
        if (value) {
          value = '0' + value;
          if (AppValidation.ukNumber.test(value)) {
            return true;
          }
          return false;
        }
        return true;
      },
    }),
  mobile_number: Yup.string()
    .required(FormValidation.mobileNumberRequired)
    .test({
      message: FormValidation.mobileNumberMinimum,
      test: (value) => value == null || (value.charAt(0) === '1' ? value.length >= 9 : value.length === 10),
    })
    .test({
      message: FormValidation.numberUkValidate,
      test: (value) => {
        value = '0' + value;
        if (AppValidation.ukNumber.test(value)) {
          return true;
        }
        return false;
      },
    }),
});
const personalSchema = Yup.object().shape({
  person_name: Yup.string().required(FormValidation.personNameRequired),
  email: Yup.string().email(FormValidation.emailInvalid),
  phone_number: Yup.string()
    .test({
      message: FormValidation.phoneNumberMinimum,
      test: (value) => value == null || (value.charAt(0) === '1' ? value.length >= 9 : value.length === 10),
    })
    .test({
      message: FormValidation.numberUkValidate,
      test: (value) => {
        if (value) {
          value = '0' + value;
          if (AppValidation.ukNumber.test(value)) {
            return true;
          }
          return false;
        }
        return true;
      },
    }),
  mobile_number: Yup.string()
    .required(FormValidation.mobileNumberRequired)
    .test({
      message: FormValidation.mobileNumberMinimum,
      test: (value) => value == null || (value.charAt(0) === '1' ? value.length >= 9 : value.length === 10),
    })
    .test({
      message: FormValidation.numberUkValidate,
      test: (value) => {
        value = '0' + value;
        if (AppValidation.ukNumber.test(value)) {
          return true;
        }
        return false;
      },
    }),
});

export class AddFavModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // pageKey: 'prev',
      isMapOpen: false,
      formAddressData: {
        ...AddressPayload,
        ...UserPayload,
      },
      formPersonalData: {
        ...UserPayload,
      },
      geoLocation: {},
      displayAddressFields: false,
      editData: this.props.editData || undefined,
      focusedName: '',
    };
    this.modalRef = React.createRef();
  }
  componentDidUpdate(prevProps, prevState) {
    const { isMapOpen } = this.state;
    let { editData, addFavouriteStatus, geoCode } = this.props;
    if (addFavouriteStatus !== prevProps.addFavouriteStatus && addFavouriteStatus) {
      this.setState({
        editData: undefined,
        pageKey: 'prev',
        formAddressData: {
          ...AddressPayload,
          ...UserPayload,
        },
        formPersonalData: {
          ...UserPayload,
        },
        geoLocation: {},
      });
    }
    if (isMapOpen !== prevState.isMapOpen) {
      if (isMapOpen) {
        this.props.addNewAddressFavourites(this.state.formAddressData);
      }
    }
    if (geoCode !== prevProps.geoCode && !isEmpty(geoCode)) {
      this.setState({
        geoLocation: {
          lat: geoCode.lat,
          lng: geoCode.lng,
        },
      });
    } else if (editData !== prevProps.editData && typeof editData !== 'undefined') {
      this.setState({ editData }, () => {
        this.setState({
          // geoLocation: {
          //   lat: editData.location_lat,
          //   lng: editData.location_lng
          // },
          formAddressData: {
            address1: editData.address1 || '',
            address2: editData.address2 || '',
            city: editData.city || '',
            country_code: editData.country_code || 'GB',
            postcode: editData.postcode || '',
            tips_how_to_find: editData.tips_how_to_find || '',
            w3w: (!isEmpty(editData.w3w) && editData.w3w.trim()) || '',
            company_name: editData.company_name || '',
            email: editData.email || '',
            mobile_number: editData.mobile_number || '',
            person_name: editData.person_name || '',
            phone_number: editData.phone_number || '',
          },
          formPersonalData: {
            company_name: editData.company_name || '',
            email: editData.email || '',
            mobile_number: editData.mobile_number || '',
            person_name: editData.person_name || '',
            phone_number: editData.phone_number || '',
          },
        });
      });
    }
  }
  multiForm = () => {
    const { pageKey } = this.state;
    switch (pageKey) {
      case 'prev':
        return this.addressSection();
      case 'next':
        return this.personalSection();
      default:
        return this.addressSection();
    }
  };
  render() {
    const { visible } = this.props;
    const { pageKey, editData, isMapOpen, formAddressData } = this.state;
    return (
      <AddFavModalWrapper ref={this.modalRef} className={`${pageKey === 'next' ? 'map-display-wrap' : ''}`}>
        <Modal
          visible={visible}
          closable={false}
          onCancel={(e) => this.handleCancel(true)}
          className={`custom-primary-modal ${isMapOpen ? 'show-map' : ''}`}
          getContainer={() => this.modalRef.current}
          title={`${!isEmpty(editData) ? 'Edit' : 'Add'} Favorite`}
          footer={null}
          centered
        >
          <div className="main-content">
            <Row>
              <div className="map-switch">
                <span>SHOW MAP</span>
                <Switch
                  className="ant-map-switch"
                  disabled={!isEmpty(formAddressData.address1) && !isEmpty(formAddressData.city) ? false : true}
                  onClick={this.switchClick}
                  checked={isMapOpen}
                />
              </div>
              <Col md={24}>{this.multiForm()}</Col>
            </Row>
          </div>
        </Modal>
      </AddFavModalWrapper>
    );
  }
  onLocationChange = (location, placeId, setFieldValue) => {
    let { googleRef } = this.props;
    let { formAddressData } = this.state;
    let convertData = {};
    if (!isEmpty(placeId)) {
      new googleRef.maps.places.PlacesService(document.createElement('div')).getDetails({ placeId: placeId }, (place, status) => {
        setFieldValue('googleAddress', place.formatted_address);
        if (!isEmpty(place.address_components)) {
          place.address_components.map((component) => {
            if (component.types.includes('route')) {
              convertData.route = component.long_name;
            }
            if (component.types.includes('street_number')) {
              convertData.street_number = component.long_name;
              formAddressData.street_number = convertData.street_number;
            }
            if (component.types.includes('postal_code')) {
              setFieldValue('postcode', component.long_name);
              formAddressData.postcode = component.long_name;
            }
            if (component.types.includes('postal_town')) {
              setFieldValue('city', component.long_name);
              formAddressData.city = component.long_name;
            }
          });
          if (isEmpty(convertData.route)) {
            convertData.address1 = place.name;
            formAddressData.address1 = convertData.address1;
          } else {
            if (isEmpty(convertData.street_number)) {
              convertData.address1 = convertData.route;
              formAddressData.address1 = convertData.address1;
            } else {
              convertData.address1 = `${convertData.street_number} ${convertData.route}`;
              formAddressData.address1 = convertData.address1;
            }
          }
          setFieldValue('address1', convertData.address1);
        }
        if (place.types.includes('establishment')) {
          formAddressData['company_name'] = place.name;
        } else {
          formAddressData['company_name'] = '';
        }
        let lat = place.geometry.location?.lat();
        let lng = place.geometry.location?.lng();
        if (lat && lng) {
          this.setState({
            geoLocation: {
              lat,
              lng,
            },
          });
        }
        this.setState({
          formAddressData,
        });
      });
    }
  };
  handleCancel = (status) => {
    this.setState({ isMapOpen: false });
    if (status) {
      this.setState({
        formAddressData: {
          ...AddressPayload,
          ...UserPayload,
        },
        formPersonalData: {
          ...UserPayload,
        },
        geoLocation: {},
        displayAddressFields: false,
        editData: undefined,
        pageKey: 'prev',
      });
      this.props.closeHandler();
    } else {
      this.setState({
        pageKey: 'prev',
      });
    }
  };
  handleAddressSubmit = async (values, options) => {
    this.setState({
      // pageKey: 'next',
      formAddressData: {
        ...values,
      },
    });
    // this.props.addNewAddressFavourites(this.state.formAddressData);
    let { formAddressData, editData } = this.state;
    Object.keys(values).map((key) => {
      if (!values[key] && typeof editData !== 'undefined' && editData[key]) {
        values[key] = ' ';
      }
    });
    formAddressData.id = typeof editData !== 'undefined' ? editData.id : undefined;
    this.setState({
      // pageKey: 'next',
      formPersonalData: {
        ...values,
      },
    });
    let status = await this.props.addFavourite({ ...formAddressData, ...values, id: formAddressData.id || '' });
    if (status) {
      this.props.getFavourites();
      this.handleCancel(true);
    }
  };
  handleFocus = (e) => {
    this.setState({ focusedName: e.target.name });
  };
  removeFocus = () => {
    this.setState({ focusedName: '' });
  };

  switchClick = (value, event) => {
    let { isMapOpen } = this.state;
    event.preventDefault();
    event.stopPropagation();
    this.setState({ isMapOpen: !isMapOpen });
  };
  addressSection = () => {
    let { displayAddressFields, formAddressData, focusedName, isMapOpen } = this.state;
    return (
      <Row type="flex" gutter={30}>
        <Col md={24} lg={isMapOpen ? 12 : 24}>
          <Formik
            initialValues={{
              id: '',
              type: '',
              ...formAddressData,
            }}
            validationSchema={addressSchema}
            enableReinitialize
            onSubmit={this.handleAddressSubmit}
          >
            {({ values, errors, touched, setFieldValue, handleChange, onBlur, handleSubmit, resetForm }) => (
              <>
                <Form onSubmit={handleSubmit} noValidate>
                  <FormGroup className={`${'form-group'} ${focusedName == 'googleAddress' ? 'focus' : values.googleAddress ? 'show' : ''}`}>
                    {/*<h5 className="field-title">LOCATION</h5>*/}
                    <div className="field-with-icon three-dots with-map-icon">
                      <i className="map-icon" />
                      <PlacesAutocomplete
                        searchOptions={PlacesOptions}
                        open={true}
                        value={values.googleAddress || ''}
                        onChange={(location) => {
                          setFieldValue('googleAddress', location);
                        }}
                        onSelect={(location, place_id) => {
                          this.onLocationChange(location, place_id, setFieldValue);
                        }}
                      >
                        {({ getInputProps, suggestions, getSuggestionItemProps }) => (
                          <div>
                            <FormWrapper className={`${'form-group'} ${values.googleAddress ? 'show' : ''}`} id="header">
                              <label className="form-control-placeholder title placeinputlbl ">Search for address</label>
                              <input
                                autoComplete="off"
                                {...getInputProps({
                                  placeholder: '',
                                  name: 'googleAddress',
                                  className: 'placeInput',
                                  autoComplete: 'nope',
                                })}
                                className="form-control"
                              />
                            </FormWrapper>
                            <div className={`autocomplete-dropdown-container ${!isEmpty(suggestions) ? 'active' : ''}`}>
                              {suggestions.map((suggestion) => {
                                const className = suggestion.active ? 'suggestion-item--active' : 'suggestion-item';
                                return (
                                  <div
                                    {...getSuggestionItemProps(suggestion, {
                                      className,
                                    })}
                                  >
                                    <span>{suggestion.description}</span>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        )}
                      </PlacesAutocomplete>
                    </div>
                  </FormGroup>
                  <div className={`${displayAddressFields ? 'dislay-content' : 'no-display'}`}>
                    {/*<h5 className="field-title">{'ADDRESS DETAILS'}</h5>*/}
                    <Row>
                      <Row>
                        <div className="d-flex MLR">
                          <Col xs={12} sm={12} md={12} lg={12}>
                            <FormGroup className="form-group PLR">
                              <Input
                                type="text"
                                name="address1"
                                value={values.address1 || ''}
                                className="form-control booking"
                                // className="form-control"
                                placeholder={'Required'}
                                handleChange={handleChange}
                                label={AddressConstant.streetNumber}
                                isError={errors.address1 && touched.address1 ? true : false}
                              />
                              {errors.address1 && touched.address1 ? <div className="form-error">{errors.address1}</div> : null}
                            </FormGroup>
                          </Col>
                          <Col xs={12} sm={12} md={12} lg={12}>
                            <FormGroup className="form-group PLR">
                              <Input
                                type="text"
                                className="form-control"
                                placeholder={''}
                                handleChange={handleChange}
                                label={AddressConstant.buildingFloor}
                                onBlur={onBlur}
                                name="address2"
                                value={values.address2 || ''}
                              />
                            </FormGroup>
                          </Col>
                        </div>
                      </Row>
                      <Row>
                        <div className="d-flex MLR">
                          <Col xs={6} sm={6} md={8} lg={8}>
                            <FormGroup className="form-group PLR">
                              <Input
                                type="text"
                                placeholder={''}
                                value={values.city || ''}
                                name={'city'}
                                onBlur={onBlur}
                                placeholder={'Required'}
                                handleChange={handleChange}
                                label={AddressConstant.city}
                                isError={errors.city && touched.city ? true : false}
                              />
                              {errors.city && touched.city ? <div className="form-error">{errors.city}</div> : null}
                            </FormGroup>
                          </Col>
                          <Col xs={8} sm={8} md={8} lg={8}>
                            <FormGroup className="form-group PLR">
                              <Input
                                type="text"
                                className="form-control"
                                name="postcode"
                                value={values.postcode || ''}
                                onBlur={onBlur}
                                handleChange={handleChange}
                                placeholder={'Required'}
                                label={AddressConstant.postcode}
                                isError={errors.postcode && touched.postcode ? true : false}
                              />
                              {errors.postcode && touched.postcode ? <div className="form-error">{errors.postcode}</div> : null}
                            </FormGroup>
                          </Col>
                          <Col xs={8} sm={8} md={8} lg={8}>
                            <FormGroup className="form-group PLR">
                              <Input
                                type="text"
                                className="form-control country"
                                name="country_code"
                                label={AddressConstant.country}
                                value={values.country_code || 'GB'}
                                disabled={true}
                                handleChange={handleChange}
                                placeholder={''}
                              />
                              {errors.country_code && touched.country_code ? <div className="form-error">{errors.country_code}</div> : null}
                            </FormGroup>
                          </Col>
                        </div>
                      </Row>
                      <Row>
                        <FormGroup className={`${'form-group text-area'}`}>
                          <FormTextarea
                            placeholder=""
                            value={values.tips_how_to_find}
                            handleChange={handleChange}
                            label="Tips"
                            name="tips_how_to_find"
                          />
                        </FormGroup>
                      </Row>
                      <Row>
                        <FormGroup className="form-group">
                          <i className="arrow-icon" onClick={() => window.open(`https://what3words.com/${values.w3w}`, '_blank')} />
                          <Tooltip
                            placement="top"
                            getPopupContainer={() => document.getElementById('header')}
                            title={
                              <div className="custom-tooltip-inner">
                                <strong>You will be redirected to w3w web.</strong>
                              </div>
                            }
                          >
                            <i className="w3w-info" />
                          </Tooltip>
                          <Input
                            type="text"
                            className="form-control"
                            name="w3w"
                            value={values.w3w || ''}
                            handleChange={(e) => {
                              handleChange(e);
                              handlew3wBlur(setFieldValue, e);
                            }}
                            label={'What3words'}
                            placeholder={''}
                          />
                        </FormGroup>
                      </Row>
                      <Row>
                        <div className="d-flex MLR">
                          <Col xs={12} sm={12} md={12} lg={12}>
                            <FormGroup className="form-group PLR">
                              <Input
                                type="text"
                                name="person_name"
                                value={values.person_name || ''}
                                onBlur={onBlur}
                                className="form-control"
                                placeholder={'Required'}
                                label={AddressConstant.personName}
                                handleChange={handleChange}
                                isError={errors.person_name && touched.person_name ? true : false}
                              />
                              {errors.person_name && touched.person_name ? <div className="form-error">{errors.person_name}</div> : null}
                            </FormGroup>
                          </Col>
                          <Col xs={12} sm={12} md={12} lg={12}>
                            <FormGroup className="form-group PLR">
                              <Input
                                type="text"
                                className="form-control"
                                onBlur={onBlur}
                                placeholder={''}
                                label={AddressConstant.companyName}
                                handleChange={handleChange}
                                name="company_name"
                                value={values.company_name || ''}
                              />
                            </FormGroup>
                          </Col>
                        </div>
                      </Row>
                      <Row>
                        <div className="d-flex MLR">
                          <Col xs={12} sm={12} md={12} lg={12}>
                            <FormGroup className="form-group PLR">
                              <Input
                                type="text"
                                placeholder={'Required'}
                                label={AddressConstant.mobileNumber}
                                value={values.mobile_number || ''}
                                onBlur={(event) => handleNumberBlur(setFieldValue, event)}
                                name={'mobile_number'}
                                handleChange={handleChange}
                                isError={errors.mobile_number && touched.mobile_number ? true : false}
                              />
                              {errors.mobile_number && touched.mobile_number ? (
                                <div className="form-error">{errors.mobile_number}</div>
                              ) : null}
                            </FormGroup>
                          </Col>
                          <Col xs={12} sm={12} md={12} lg={12}>
                            <FormGroup className="form-group PLR">
                              <Input
                                type="text"
                                className="form-control"
                                name="phone_number"
                                value={values.phone_number || ''}
                                onBlur={(event) => handleNumberBlur(setFieldValue, event)}
                                handleChange={handleChange}
                                placeholder={''}
                                label={AddressConstant.phoneNumber}
                                isError={errors.phone_number && touched.phone_number ? true : false}
                              />
                              {errors.phone_number && touched.phone_number ? <div className="form-error">{errors.phone_number}</div> : null}
                            </FormGroup>
                          </Col>
                        </div>
                      </Row>
                    </Row>
                    <Row>
                      <FormGroup className="form-group">
                        <Input
                          type="email"
                          className="form-control"
                          name="email"
                          onBlur={onBlur}
                          value={values.email || ''}
                          handleChange={handleChange}
                          placeholder={''}
                          label={'EMAIL'}
                        />
                        {errors.email && touched.email ? <div className="form-error">{errors.email}</div> : null}
                      </FormGroup>
                    </Row>
                    <Row type="flex" style={{ justifyContent: 'flex-end' }}>
                      <Button
                        color="secondary"
                        className="new-btn reverse-btn red-hover"
                        onClick={() => {
                          resetForm();
                          this.handleCancel(true);
                        }}
                      >
                        CANCEL
                      </Button>
                      <Button color="secondary" htmlType="submit" className={`new-btn left`}>
                        SAVE
                      </Button>
                    </Row>
                  </div>
                </Form>
              </>
            )}
          </Formik>
        </Col>
        {isMapOpen && (
          <Col md={24} lg={12}>
            <div className="image-wrapper">
              <MapMarker
                className="map-view"
                scaleControl={true}
                disableDefaultUI={true}
                streetViewControl={false}
                reviewMap={true}
                isBound={false}
                markerPoints={[
                  {
                    latitude: this.state.geoLocation.lat || 53.0,
                    longitude: this.state.geoLocation.lng || 51.0,
                  },
                ]}
              />
            </div>
          </Col>
        )}
      </Row>
    );
  };
  personalSection = () => {
    let { formPersonalData, formAddressData } = this.state;
    return (
      <Row type="flex" gutter={30}>
        <Col md={24} lg={12}>
          <FormGroup className="form-group">
            <h5 className="field-title">LOCATION AND ADDRESS</h5>
            <p className="address-placeholder">{`${formAddressData.address1}, ${formAddressData.city}, ${formAddressData.country_code}, ${formAddressData.postcode}`}</p>
          </FormGroup>
          <Formik
            initialValues={{
              ...formPersonalData,
            }}
            validationSchema={personalSchema}
            enableReinitialize
            onSubmit={async (values, options) => {
              let { formAddressData, editData } = this.state;
              formAddressData.id = typeof editData !== 'undefined' ? editData.id : undefined;
              this.setState({
                pageKey: 'next',
                formPersonalData: {
                  ...values,
                },
              });
              let status = await this.props.addFavourite({ ...formAddressData, ...values });
              if (status) {
                this.props.getFavourites();
                this.handleCancel(true);
              }
            }}
          >
            {({ values, errors, touched, handleChange, onBlur, handleSubmit, setFieldValue }) => (
              <Form onSubmit={handleSubmit} noValidate>
                {/*<h5 className="field-title">{'INFORMATION'}</h5>*/}
                <Row>
                  <Row>
                    <div className="d-flex MLR">
                      <Col xs={12} sm={12} md={12} lg={12}>
                        <FormGroup className="form-group PLR">
                          <Input
                            type="text"
                            name="person_name"
                            value={values.person_name || ''}
                            className="form-control"
                            placeholder={''}
                            label={AddressConstant.personName}
                            handleChange={handleChange}
                            isError={errors.person_name && touched.person_name ? true : false}
                          />
                          {errors.person_name && touched.person_name ? <div className="form-error">{errors.person_name}</div> : null}
                        </FormGroup>
                      </Col>
                      <Col xs={12} sm={12} md={12} lg={12}>
                        <FormGroup className="form-group PLR">
                          <Input
                            type="text"
                            className="form-control"
                            placeholder={''}
                            label={AddressConstant.companyName}
                            handleChange={handleChange}
                            onBlur={onBlur}
                            name="company_name"
                            value={values.company_name || ''}
                          />
                        </FormGroup>
                      </Col>
                    </div>
                  </Row>
                  <Row>
                    <div className="d-flex MLR">
                      <Col xs={12} sm={12} md={12} lg={12}>
                        <FormGroup className="form-group PLR">
                          <Input
                            type="text"
                            placeholder={''}
                            label={AddressConstant.mobileNumber}
                            value={values.mobile_number || ''}
                            onBlur={(event) => handleNumberBlur(setFieldValue, event)}
                            name={'mobile_number'}
                            handleChange={handleChange}
                            isError={errors.mobile_number && touched.mobile_number ? true : false}
                          />
                          {errors.mobile_number && touched.mobile_number ? <div className="form-error">{errors.mobile_number}</div> : null}
                        </FormGroup>
                      </Col>
                      <Col xs={12} sm={12} md={12} lg={12}>
                        <FormGroup className="form-group PLR">
                          <Input
                            type="text"
                            className="form-control"
                            name="phone_number"
                            value={values.phone_number || ''}
                            onBlur={(event) => handleNumberBlur(setFieldValue, event)}
                            handleChange={handleChange}
                            placeholder={''}
                            label={AddressConstant.phoneNumber}
                            isError={errors.phone_number && touched.phone_number ? true : false}
                          />
                          {errors.phone_number && touched.phone_number ? <div className="form-error">{errors.phone_number}</div> : null}
                        </FormGroup>
                      </Col>
                    </div>
                  </Row>
                  <Row>
                    <FormGroup className="form-group">
                      <Input
                        type="email"
                        className="form-control"
                        name="email"
                        value={values.email || ''}
                        handleChange={handleChange}
                        placeholder={''}
                        label={'EMAIL'}
                      />
                      {errors.email && touched.email ? <div className="form-error">{errors.email}</div> : null}
                    </FormGroup>
                  </Row>
                </Row>
                <Row type="flex" className="button-wrapper">
                  <Button color="secondary" className="new-btn reverse-btn red-hover" onClick={() => this.handleCancel(false)}>
                    BACK
                  </Button>
                  <Button color="secondary" htmlType="submit" className={`new-btn left`}>
                    SAVE
                  </Button>
                </Row>
              </Form>
            )}
          </Formik>
        </Col>
        {/* <Col md={24} lg={12}>
          <div className="image-wrapper">
            <MapMarker
              className="map-view"
              scaleControl={true}
              disableDefaultUI={true}
              streetViewControl={false}
              reviewMap={true}
              isBound={false}
              markerPoints={[
                {
                  latitude: geoLocation.lat || 53.0,
                  longitude: geoLocation.lng || 51.0
                }
              ]}
            />
          </div>
        </Col> */}
      </Row>
    );
  };
}
const mapStateToProps = (state) => ({
  googleRef: state.app.googleRef,
  addFavouriteStatus: state.favourite.addFavourite,
  geoCode: state.favourite.geoCode,
});
const mapDispatchToProps = (dispatch) => ({
  addFavourite: (payload) => dispatch(addFavourite(payload)),
  addNewAddressFavourites: (payload) => dispatch(addNewAddressFavourites(payload)),
  getFavourites: (payload) => dispatch(getFavourites(payload)),
});
export default connect(mapStateToProps, mapDispatchToProps)(AddFavModal);
