import { axiosPost } from 'modules/Axios';
import * as actions from './constant';

export const changePassword = payload => async dispatch => {
  try {
    dispatch({ type: actions.CHANGEPASSWORD_INITIATED });
    let response = await axiosPost('registration/password-reset-save', payload);
    if (!response.success) {
      dispatch({
        type: actions.CHANGEPASSWORD_ERROR,
        error: response.error.message
      });
    } else {
      dispatch({
        type: actions.CHANGEPASSWORD_SUCCESS,
        payload: response
      });
    }
  } catch (error) {
    console.log(error);
    dispatch({
      type: actions.CHANGEPASSWORD_ERROR,
      error
    });
  }
};
