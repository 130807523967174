import { size } from 'App/device';
import styled from 'styled-components';
import { transition } from 'App/mixins';

const FavouritesWrappper = styled.div`
  input {
    font-size: 16px;
    color: #49465d;
    font-weight: 600;
  }
  .table-list {
    &.draftsDiv {
      padding: 12px 30px 12px;
      .ui-header {
        letter-spacing: 2px;
        li {
          min-height: 55px !important;
        }
      }
    }
  }
  .ant-collapse {
    /* margin: 10px 0 0 !important; */
  }
  .couriers-info {
    color: #323232 !important;
  }
  .fav-content {
    width: 100%;
    .left-align-collapse {
      padding-left: 63px;
      @media ${size['desktop-sm-max']} {
        padding-left: 49px;
      }
    }
  }
  .ant-checkbox-wrapper {
    height: 100%;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .ant-checkbox-inner {
    border-radius: 5px;
    width: 15px;
    height: 15px;
  }
  .ant-checkbox-checked {
    .ant-checkbox-inner {
      background-color: transparent;
      border: 1.42px solid #d9d9d9;
      border-radius: 5px;
      width: 15px;
      height: 15px;
      &:after {
        // display: inline-block;
        // border: 2px solid #8f48ff;
        // background-color: #8f48ff;
        // border-top: 0;
        // height: 7px;
        // width: 7px;
        left: 2px;
        top: 45%;
        // border-radius: 50px;
      }
    }
  }
  .ant-checkbox-wrapper:hover .ant-checkbox-inner {
    border: 2px solid #8f48ff;
  }
  .ant-checkbox-checked::after {
    content: none;
  }
  .btn-grp {
    top: 50px;
    right: -55%;
    @media ${size['mobile-md-max']} {
      right: 0;
    }
    .new-btn-style.danger {
      @media ${size['mobile-md-max']} {
        margin-bottom: 10px;
      }
    }
    display: inline-block;
    &.top-extra {
      top: 72px;
    }
  }
  .two-col {
    position: relative;
    @media ${size['mobile-md-max']} {
      flex-wrap: wrap;
    }
    & > div {
      width: 50%;
      max-width: 50%;
      min-width: 50%;
      position: relative;
      padding: 0;
      margin-bottom: 0px;
      @media ${size['desktop-sm-max']} {
        width: 50%;
        max-width: 50%;
        min-width: 50%;
      }
      @media ${size['mobile-md-max']} {
        width: 100%;
        max-width: 100%;
        min-width: 100%;
      }
      &:first-child input {
        border-right: 10px solid white;
      }
      input {
        border-radius: 0;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
      }
    }
  }
  .add-btn-grp {
    // margin-top: 15px;
    margin-left: 0 !important;
    position: absolute;
    right: 5%;
  }

  .error-wrapper {
    input.form-control {
      border-radius: 0 !important;
      box-shadow: 0px 0px 5px #e82868;
      border: 1px solid #e82868 !important;
    }
  }
  .search-wrapper + .ant-checkbox-group {
    padding-top: 70px;
  }
  .search-btn-grp .filter-btn {
    margin-left: 12px !important;
  }

  .menuItems {
    li {
      ${transition(0.4)};
    }
    li:hover {
      background-color: #fafafa;
    }
  }
  .no-display {
    .cancel-btn {
      margin-right: 25px;
    }
  }
  .MLR {
    margin-left: -30px;
    margin-right: -30px;
  }
  .Job-type-header {
    .active {
      position: absolute !important;
      &:after {
        background: none !important;
      }
    }
  }
`;
export { FavouritesWrappper };
