import React from 'react';
import { connect } from 'react-redux';
import isEmpty from 'lodash.isempty';
import { PageInfo } from 'components/CommonStyle/style';

const HeaderInfo = ({ userData, title, isBooking, subText, className }) => (
  <PageInfo className={className}>
    <h2>{}</h2>

    {/* {subText === null ? (
      <p>
        {!isEmpty(userData)
          ? !isEmpty(userData.firstname) && !isEmpty(userData.lastname)
            ? `${userData.firstname} ${userData.lastname}, ${
                !isEmpty(userData.address1)
                  ? `${userData.address1}, ${userData.city}, ${userData.country_code}`
                  : '-'
              }`
            : null
          : '-'}
      </p>
    ) : (
      <p>{subText}</p>
    )} */}
  </PageInfo>
);

HeaderInfo.defaultProps = {
  isBooking: false,
  subText: null,
  className: ''
};

const mapStateToProps = state => ({
  userData: state.profileUpdate.userData
});

export default connect(mapStateToProps, {})(HeaderInfo);
