import React, { Component } from 'react';
import { SplashConstant } from './constant';
import { SplashWrapper } from './style';
import logo from 'assets/images/splash-screen-logo.svg';
import { SplashLogo } from './SplashLogo';

class Splash extends Component {
  componentDidMount() {
    sessionStorage.removeItem('isNewUser');
  }
  render() {
    const { splashData } = this.props;
    return (
      <SplashWrapper>
        <SplashLogo />
        <div className="splash-main d-flex">
          <div className="splash-inner">
            <a href="#" className="splash-logo">
              <img src={logo} alt="" />
            </a>
            <h2>
              {SplashConstant.splashTitle}, {splashData.firstName}!
            </h2>
            <p>{SplashConstant.waitCreating}</p>
          </div>
        </div>
      </SplashWrapper>
    );
  }
}
export default Splash;
