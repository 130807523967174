import React from 'react';
import { Spin, Icon } from 'antd';
import { Wrapper } from './style';
export const Indicator = <Icon type="loading" style={{ fontSize: 65 }} spin />;

const PageLoader = () => (
  <Wrapper>
    <Spin size="large" indicator={Indicator} className="main-loader" />
  </Wrapper>
);

export default PageLoader;
