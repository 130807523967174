import { Checkbox, Dropdown } from 'antd';
import { MultiJobsType } from 'App/AppConstant';
import { LocationHeader } from 'components/CommonComponent';
import isEmpty from 'lodash.isempty';
import React, { Component } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import { connect } from 'react-redux';
import { FormWrapper } from './style';

class InuptWIthDropdown extends Component {
  state = { dropVisible: false };
  inputDropdownRef = React.createRef();

  componentDidUpdate(prvProps) {
    let { clearMultiAddressSelection } = this.props;
    if (clearMultiAddressSelection !== prvProps.clearMultiAddressSelection) {
      this.setState({
        radioValue: '0',
      });
      this.props.getSelectedJobs([]);
    }
  }

  handleSelectAllAddress = (event) => {
    this.props.handleSelectAllAddress(event.target.checked);
  };

  handleParcelSelectionToRedux = (event) => {
    this.props.handleParcelSelectionToRedux(event.target.checked);
  };

  list = () => {
    const { title, allSelectedAddressFlag, addParcelSelectionToRedux, parcelsData, multiPickUp, multiDropOff } = this.props;
    return (
      <div>
        <OutsideClickHandler onOutsideClick={this.outerClick}>
          <strong className="d-title">{title}</strong>
          {!multiDropOff && (
            <>
              <LocationHeader
                leftPanel={'LOCATION'}
                rightPanel={'PICK UP'}
                className="fill-wrap"
                lineColor="green"
                rightClassName="green-panel fill-panel"
              />
              {isEmpty(parcelsData) && multiPickUp && (
                <Checkbox
                  checked={allSelectedAddressFlag}
                  className="add-all-address-parcel-wrapper-checkbox"
                  onChange={this.handleSelectAllAddress}
                >
                  <div className={'dark-active list'}>Select All</div>
                </Checkbox>
              )}
              {this.addressListing('pick')}
            </>
          )}
          {!multiPickUp && (
            <>
              <LocationHeader rightPanel={'DROP OFF'} className="fill-wrap" lineColor="red" rightClassName="red-panel fill-panel" />
              {isEmpty(parcelsData) && (
                <Checkbox
                  checked={allSelectedAddressFlag}
                  className="add-all-address-parcel-wrapper-checkbox"
                  onChange={this.handleSelectAllAddress}
                >
                  <div className={'dark-active list'}>Select All</div>
                </Checkbox>
              )}
              {this.addressListing('drop')}
            </>
          )}
          {/* <LocationHeader rightPanel={'AUTOMATION'} />
          <Checkbox
            checked={addParcelSelectionToRedux}
            className="add-all-address-parcel-wrapper-checkbox"
            onChange={this.handleParcelSelectionToRedux}
          >
            <div className={'dark-active list'}>Add Parcel to all new addresses</div>
          </Checkbox> */}
        </OutsideClickHandler>
      </div>
    );
  };

  handleCheckBoxChange = (value) => {
    this.props.getSelectedJobs(value);
  };

  addressListing = (type) => {
    const { jobDetails, checkboxValue, waitandreturnprops } = this.props;
    let ui = [];
    if (jobDetails.subjobs) {
      let subjobList = [...jobDetails.subjobs];
      if (type == 'drop' && jobDetails.leg_type == '0' && jobDetails.multi_job_type == '112' && waitandreturnprops) {
        subjobList.pop();
      }
      subjobList.map((job, i) => {
        let status = '',
          address = '';
        if (type == 'pick') {
          status = jobDetails.multi_job_type == '111' ? 'active' : 'none';
          address = job.pickup_address1 + ', ' + job.pickup_city + ', ' + job.pickup_postcode;
        }
        if (type == 'drop') {
          status = jobDetails.multi_job_type == '112' ? 'active' : 'none';
          address = job.delivery_address1 + ', ' + job.delivery_city + ', ' + job.delivery_postcode;
        }
        if (status === 'active') {
          ui.push(
            <Checkbox value={job.id} key={i + 1}>
              <div className={status == 'active' ? 'dark-active list' : 'list'}>
                <a className="dropdown-item">
                  <span className={status == 'active' ? '' : 'light'} style={{ fontWeight: 600 }}>
                    {' '}
                    {address}
                  </span>
                </a>
              </div>
            </Checkbox>
          );
        } else {
          ui.push(
            <div key={i + 1} className={'not-selectable list'}>
              <div></div>
              <a className="dropdown-item">
                <span className={status == 'active' ? '' : 'light'} style={{ fontWeight: 600 }}>
                  {address}
                </span>
              </a>
            </div>
          );
        }
      });
      if (type == 'pick' && jobDetails.multi_job_type == '111') {
        if (ui.length > 0)
          return (
            <ul>
              <Checkbox.Group value={checkboxValue} onChange={this.handleCheckBoxChange}>
                {ui}
              </Checkbox.Group>
            </ul>
          );
      } else if (type == 'drop' && jobDetails.multi_job_type == '112') {
        if (ui.length > 0)
          return (
            <ul>
              <Checkbox.Group className="d-flex" value={checkboxValue} onChange={this.handleCheckBoxChange}>
                {ui}
              </Checkbox.Group>
            </ul>
          );
      } else
        return (
          <ul>
            <li key={'key' + 1}>{ui[0]}</li>
          </ul>
        );
    }
  };
  outerClick = () => {
    this.props.dropvisiblity(0);
  };

  dropVisiblity = () => {
    let { dropVisible } = this.state;
    let type = dropVisible === 1 ? 0 : 1;
    this.props.dropvisiblity(type);
    this.setState({ dropVisible: dropVisible === true ? false : true });
  };
  render() {
    const {
      jobDetails,
      disabled,
      handleChange,
      isError,
      visible,
      getSelectedJobs,
      dropvisiblity,
      clearMultiAddressSelection,
      checkboxValue,
      idRef,
      selectedAddress,
    } = this.props;
    let address = '';
    if (!isEmpty(checkboxValue)) {
      if (checkboxValue.length === 1) {
        jobDetails.subjobs.map((data) => {
          if (data.id === checkboxValue[0]) {
            if (data.multi_job_type === MultiJobsType.SUBJOB_MULTI_PICKUP) {
              address = data.pickup_address1 + ', ' + data.delivery_city + ', ' + data.delivery_postcode;
            } else {
              address = data.delivery_address1 + ', ' + data.delivery_city + ', ' + data.delivery_postcode;
            }
          }
        });
      }
    }
    return (
      <FormWrapper className={`inputdrop`}>
        {/* <OutsideClickHandler onOutsideClick={this.outerClick}> */}
        <div ref={this.inputDropdownRef}>
          <Dropdown
            visible={visible}
            overlay={this.list()}
            getPopupContainer={() => (idRef ? document.querySelector(`#${idRef}`) : this.inputDropdownRef.current)}
            placement={'bottomCenter'}
          >
            <p className={`selected-address-parcel-wrap ${visible && 'focus'}`} onClick={this.dropVisiblity}>
              {!isEmpty(checkboxValue)
                ? checkboxValue.length === 1
                  ? address
                  : `${checkboxValue.length} Selected Address`
                : 'No Address Selected'}
            </p>
          </Dropdown>
        </div>
        {/* </OutsideClickHandler> */}
      </FormWrapper>
    );
  }
}
InuptWIthDropdown.defaultProps = {
  checkboxValue: [],
  placeholder: null,
  iconType: 'caret-down',
  className: '',
  isError: false,
  getSelectedJobs: [],
  selectedAddress: 'No Address Selected',
  dropvisiblity: 0,
  idRef: '',
};
const mapStateToProps = (state) => ({
  waitandreturnprops: state.booking.waitandreturn,
});
export default connect(mapStateToProps, {})(InuptWIthDropdown);
