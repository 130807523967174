import React, { Component } from 'react';
import { Checkbox } from 'antd';
import { FormWrapper } from './style';

const ButtonConstant = {
  primary: '#606060',
  secondary: '#828097'
};
class FormCheckbox extends Component {
  render() {
    const { text, checked, textRequired, name, handleChange, className, labelText } = this.props;
    return (
      <FormWrapper className={className ? className : ''}>
        {labelText ? labelText : null}
        <Checkbox name={name} onChange={handleChange} checked={checked}>
          {textRequired ? text : null}
        </Checkbox>
      </FormWrapper>
    );
  }
}
FormCheckbox.defaultProps = {
  labelText: '',
  textRequired: true,
  className: '',
  text: 'Check',
  htmlType: null,
  value: false,
  onChange: () => {}
};
export default FormCheckbox;
