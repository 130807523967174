import axios from 'axios';
import qs from 'qs';
import { message } from 'antd';
import store from 'redux/store';
import { configVar, apiConstant } from 'modules/config';
import { onError } from '../errorHandler';
import { getAuthToken } from '../helper';
import { errorHandler, errorEmpty, pendingTCS } from 'redux/app/actions';
import { updateCourier } from 'redux/booking/actions';
import { redirectLogin } from 'redux/app/actions';
import isEmpty from 'lodash.isempty';

export const axiosPost = async (url, data) => {
  try {
    let postData = qs.stringify({
      frontend_version: configVar.FRONTEND_VERSION,
      ...data
    });
    let { data: response } = await axios.post(configVar.BASE_URL + url, postData);
    if (!response.success) {
      store.dispatch(errorHandler(response));
      store.dispatch(errorEmpty());
    }
    return response;
  } catch (error) {
    console.log(error, 'Network Error');
    onError(error, 'API Error', 'Axios api error');
    return Promise.reject(error);
  }
};
export const axiosAuthPost = async (url, data) => {
  try {
    let session_id = getAuthToken();
    if (session_id) {
      let postData = qs.stringify({
        frontend_version: configVar.FRONTEND_VERSION,
        session_id,
        ...data
      });
      let { data: response } = await axios.post(configVar.BASE_URL + url, postData);

      if (!response.success) {
        if (response.error.code === 'ERROR_DATETIME_INCORRECT') {
          store.dispatch(updateCourier(true));
        } else {
          store.dispatch(errorHandler(response, data));
        }
        store.dispatch(errorEmpty());
      }
      if (response.warning) {
        if (response.warning.code === 'WARNING_ACCEPT_TCS') {
          store.dispatch(pendingTCS());
        } else {
          message.warning(response.user_message);
        }
      }
      if (response.info) {
        message.info(`${response.user_message || 'This is a info message'}`);
      }
      return response;
    } else {
      store.dispatch(redirectLogin());
    }
  } catch (error) {
    console.log(error, 'Network Error');
    onError(error, 'API Error', 'Axios api error');
    return Promise.reject(error);
  }
};

export const axiosCsvAuthPost = async (url, data, setBaseUrl = false) => {
  try {
    url = setBaseUrl ? configVar.BASE_URL + url : url;
    let session_id = getAuthToken();
    if (session_id) {
      const formData = new FormData();
      formData.append('frontend_version', configVar.FRONTEND_VERSION);
      formData.append('file', data);
      formData.append('session_id', session_id);
      formData.append('leg_type', data.leg_type);
      if (!isEmpty(data)) {
        if (data.multi_job_type) formData.append('multi_job_type', data.multi_job_type);
        if (data.job_id) formData.append('job_id', data.job_id);
      }
      let response = await axios.post(url, formData);
      if (response.status === 200 && !response.data.success) {
        store.dispatch(errorHandler(response.data));
        store.dispatch(errorEmpty());
      }
      return response;
    } else {
      return Promise.reject('No Session');
    }
  } catch (error) {
    console.log(error, 'Network Error');
    onError(error, 'API Error', 'Axios api error');
    return Promise.reject(error);
  }
};

export const backendSignin = async provider => {
  try {
    let clientId;
    let redirectUrl = window.btoa(window.location.href);
    let params = {
      provider: provider,
      redirect_url: redirectUrl,
      app_type: configVar.APP_TYPE,
      frontend_version: configVar.FRONTEND_VERSION,
      google_analytics_id: clientId || '', //need the clientId (gaTracker) -> will add later during then development
      user_agent: window.navigator.userAgent
    };
    window.location.href =
      configVar.BASE_URL + apiConstant.AUTH_SOCIAL_SIGNIN + qs.stringify(params);
  } catch (error) {
    console.log(error, 'Network Error');
  }
};

export const axiosPostBlob = async (url, data) => {
  try {
    let session_id = getAuthToken();
    if (session_id) {
      let postData = qs.stringify({
        frontend_version: configVar.FRONTEND_VERSION,
        session_id,
        ...data
      });
      let { data: response } = await axios.post(configVar.BASE_URL + url, postData);
      if (response) {
        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `Gophr-Invoice-Jobs-${data.invoice_numbers}.csv`); //or any other extension
        document.body.appendChild(link);
        link.click();
        link.remove();
      }
      return response;
    } else {
      store.dispatch(redirectLogin());
    }
  } catch (error) {
    console.log(error, 'Network Error');
    onError(error, 'API Error', 'Axios blob api error');
    return Promise.reject(error);
  }
};
