const size = {
  'mobile-sm-max': `(max-width: 320px)`,
  'mobile-md-max': `(max-width: 479px)`,
  'tablet-max': `(max-width: 767px)`,
  'tablet-md-max': `(max-width: 991px)`,
  'laptop-sm-max': `(max-width: 1023px)`,
  'laptop-max': `(max-width: 1199px)`,
  'laptop-md-max': `(max-width: 1365px)`,
  'desktop-sm-max': `(max-width: 1399px)`,
  'desktop-mm-max': `(max-width: 1499px)`,
  'desktop-md-max': `(max-width: 1699px)`,
  'desktop-mm-min': `(min-width: 1499px)`,
  'desktop-md-min': `(min-width: 1920px)`
};
export { size };
