import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import app from 'redux/app/reducer';
import login from 'redux/login/reducer';
import resetPassword from 'redux/resetPassword/reducer';
import signup from 'redux/signup/reducer';
import changePassword from 'redux/changePassword/reducer';
import favourite from 'redux/favourite/reducer';
import profileUpdate from 'redux/profileUpdate/reducer';
import booking from 'redux/booking/reducer';
import team from 'redux/team/reducer';
import payment from 'redux/payment/reducer';
import jobHistory from 'redux/jobHistory/reducer';
import shift from 'redux/shift/reducer';

const rootReducer = history =>
  combineReducers({
    app,
    login,
    signup,
    resetPassword,
    payment,
    favourite,
    changePassword,
    profileUpdate,
    booking,
    team,
    jobHistory,
    shift,
    router: connectRouter(history)
  });

export default rootReducer;
