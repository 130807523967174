import React, { Component } from 'react';
import { InputNumber } from 'antd';
import { FormNumericWrapper } from './style';

class FormNumericInput extends Component {
  render() {
    const { onChange, disabled, className, classNameWrapper, isError, ...props } = this.props;
    return (
      <FormNumericWrapper className={`${classNameWrapper} ${isError ? 'error-wrapper' : ''}`}>
        <InputNumber
          className={`form-control ${className}`}
          disabled={disabled ? true : false}
          onChange={value => onChange(value)}
          type={'string'}
          {...props}
        />
      </FormNumericWrapper>
    );
  }
}
FormNumericInput.defaultProps = {
  isError: false,
  placeholder: 'Input',
  tabIndex: '1',
  maxLength: 128,
  className: '',
  classNameWrapper: '',
  readOnly: false
};
export default FormNumericInput;
