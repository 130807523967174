export const SIGNUP_INITIATED = 'SIGNUP_INITIATED';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const SIGNUP_ERROR = 'SIGNUP_ERROR';
export const SEND_EMAIL_SUCCESS_INITIATED = 'SEND_EMAIL_SUCCESS_INITIATED';
export const SEND_EMAIL_SUCCESS = 'SEND_EMAIL_SUCCESS';
export const SEND_EMAIL_ERROR = 'SEND_EMAIL_ERROR';
export const SEND_EMAIL_CLEAR = 'SEND_EMAIL_CLEAR';
export const VERIFY_INITIATED = 'VERIFY_INITIATED';
export const VERIFY_SUCCESS = 'VERIFY_SUCCESS';
export const VERIFY_ERROR = 'VERIFY_ERROR';
