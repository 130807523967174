import styled from 'styled-components';
import { borderRadius } from 'App/mixins';
import { size } from 'App/device';

const AddFavModalWrapper = styled.div`
  .custom-primary-modal {
    width: 666px !important;
  }
  .show-map {
    width: auto !important;
  }
  &.map-display-wrap {
    .ant-modal {
      width: 70% !important;
    }
  }
  .ant-modal-content {
    ${borderRadius('10px')};
  }
  .ant-modal-body {
    padding: 0 0 20px 0;
  }
  .ant-modal-footer {
    border-top: none;
  }
  .btn-grey {
    min-width: 115px;
    height: 55px;
    line-height: 50px;
    padding: 0 20px;
    ${borderRadius('5px')};
    margin-left: 20px;
    color: white;
    -webkit-letter-spacing: 2px;
    -moz-letter-spacing: 2px;
    -ms-letter-spacing: 2px;
    letter-spacing: 2px;
    font-size: 15px;
    text-transform: none;
    font-weight: 500;
    background: #696969;
    box-shadow: 10px 10px 25px rgba(0, 0, 0, 0.1);
    -webkit-transition: all 0.4s ease;
    transition: all 0.4s ease;
  }
  .image-wrapper {
    position: relative;
    height: 100%;
  }
  .main-content {
    padding: 20px 30px 10px 30px;
    h2 {
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 24px;
      letter-spacing: 0.05em;
      color: #686675;
    }
    .map-switch {
      float: right;
      span{
        color: #323232;
        font-weight: 600;
      }
      button.ant-map-switch.ant-switch {
        margin-left: 10px;
      }
      button.ant-map-switch.ant-switch.ant-switch-checked {
        background-color: #8f48ff;
      }
    }
  }
  /* Input and Form Style */
  .field-title {
    font-family: ${props => props.theme.primaryFont}, sans-serif;
    font-size: 15px;
    line-height: 20px;
    -webkit-letter-spacing: 0.05em;
    -moz-letter-spacing: 0.05em;
    -ms-letter-spacing: 0.05em;
    letter-spacing: 0.05em;
    font-weight: 500;
    color: #686675;
    display: block;
    margin: 0 0 10px 0;
  }
  .ant-row-flex {
    margin-top: 10px;
  }
  .form-group {
    // margin-bottom: 8px;
    &.show {
      .label {
        transform: translate3d(0, -100%, 0);
        opacity: 1 !important;
      }
    }
    &.focus {
      .label {
        transform: translate3d(0, -100%, 0);
        opacity: 1 !important;
        color: #8f48ff;
      }
    }
    &.text-area {
      .label {
        position: absolute;
        transition: all 300ms;
        opacity: 0.5;
        margin-bottom: -15px;
        top: 0;
      }
    }
  }
  /* input {
    height: 40px;
    font-size: 16px;
    padding: 5px 20px;
    padding-left: 10px;
  } */
  .countrylbl {
    margin-bottom: 0 !important;
  }
  .purple-hover {
    background: #be24ff;
    box-shadow: 10px 10px 25px rgba(0, 0, 0, 0.1);
    ${borderRadius('5px')};
    &.new-btn-style {
      font-size: 12px;
      background: #00ff9f;
      color: black;
      font-family: ${props => props.theme.primaryFont}, 'sans-serif';
      font-weight: 700;
      border: 1px solid black;
      ${borderRadius('0px')};
      :hover {
        background: #fff;
        color: #00ff9f;
        border: 1px solid #00ff9f;
      }
    }
  }
  input.placeInput {
    ${borderRadius('0px')};
    border: 0.1rem solid #cecece;
  }
  textarea {
    ${borderRadius('0px')};
    // height: 100px;
    font-size: 16px;
    color: #49465d;
    font-weight: 600;
    padding-left: 10px;
    border-bottom: 0.1rem solid #cecece !important;
    border-right: 10px solid white !important;
    border: none;
    &:focus {
      border-bottom: 1px solid #8f48ff !important;
      &::-webkit-input-placeholder {
        color: transparent;
      }
      &::-moz-placeholder {
        /* Firefox 19+ */
        color: transparent;
      }
      &:-ms-input-placeholder {
        color: transparent;
      }
    }
  }
  .map-view {
    & > div > div {
      ${borderRadius('10px 10px 0 0')};
    }
  }
  .address-placeholder {
    font-size: 20px;
    line-height: 24px;
    color: #636363;
  }
  .button-wrapper {
    justify-content: flex-end;
    // button {
    //   margin-right: 25px;
    // }
  }
  @media ${size['laptop-md-max']} {
    .field-title {
      font-size: 13px;
    }
    .address-placeholder {
      font-size: 16px;
      line-height: 18px;
    }
  }
  @media ${size['laptop-max']} {
    .button-wrapper {
      button {
        width: 100%;
        margin: 0;
        &:first-child {
          margin-bottom: 18px;
        }
      }
    }
  }
`;
export { AddFavModalWrapper };
