import React from 'react';
import TermsModal from 'components/TermsModal';
import Pubnub from './Pubnub';
export class AppComponent extends React.Component {
  render() {
    return (
      <React.Fragment>
        {/* Terms and condition Component */}
        <TermsModal />
        {/* Pubnub Component */}
        <Pubnub />
      </React.Fragment>
    );
  }
}

export default AppComponent;
