import { notification } from 'antd';
import isEmpty from 'lodash.isempty';
import PubNub from 'pubnub';
import React from 'react';
import { connect } from 'react-redux';
import {
  cancelJob,
  getCurrentjobs,
  getJob,
  showFeedBackfromPubnub,
  showLoaderfromPubnub,
  showTimerfromPubnub
} from 'redux/booking/actions';
import { history } from '../history';
import { CONFIRMED_BY_CUSTOMER } from './AppConstant';

class Pubnub extends React.Component {
  state = {};
  pubnubMethod = props => {
    if (!isEmpty(localStorage)) {
      const pubnub = new PubNub({
        subscribeKey: process.env.REACT_APP_PUBNUB_SUBSCRIBE_KEY.toString(),
        uuid: PubNub.generateUUID(),
        ssl: true,
        presenceTimeout: 120,
        heartbeatInterval: 20
      });
      pubnub.subscribe({
        channels: [!isEmpty(localStorage) && !isEmpty(localStorage.auth) && JSON.parse(localStorage.auth).notification_channel.toString()],
        withPresence: true
      });
      pubnub.addListener({
        message: async function(msg) {
          if (msg.message.data.code === 'DELIVERED' || msg.message.data.code === 'CANCELED') {
            props.showTimerfromPubnub({});
            props.showFeedBackfromPubnub({ id: msg.message.data.job_id });
          } else if (msg.message.data.code === 'AT_PICKUP' || msg.message.data.code === 'AT_DELIVERY') {
            notification['info']({
              message: `${msg.message.message}`,
              duration: 60
            });
            let tab = '';
            const result = await props.getCurrentjobs();
            if (result.success && !isEmpty(msg.message.data.job_id)) {
              await props.getJob({ id: msg.message.data.job_id });
              props.showTimerfromPubnub({ id: msg.message.data.job_id });
              let data = result.data;
              let filteredjobs = data.filter(ele => ele.leg_type === '0');
              const job = filteredjobs.filter(jobs => jobs.id == msg.message.data.job_id);
              if (!isEmpty(job)) {
                if (job[0].is_scheduled === 1) {
                  tab = '2';
                } else if (job[0].is_scheduled === 0) {
                  tab = '3';
                }
              }
              if (!isEmpty(tab)) {
                history.push(`/job-boards/?type=SameDay&tab=${tab}&id=${msg.message.data.job_id}`);
                notification.destroy();
              } else if (msg.message.data.code === CONFIRMED_BY_CUSTOMER) {
                props.showTimerfromPubnub({});
                props.showLoaderfromPubnub({ id: msg.message.data.job_id });
              } else
                notification['info']({
                  message: `${msg.message.message}`,
                  duration: 60,
                  async onClick() {
                    let tab = '';
                    const result = await props.getCurrentjobs();
                    props.showTimerfromPubnub({});
                    if (result.success && !isEmpty(msg.message.data.job_id)) {
                      let data = result.data;
                      let filteredjobs = data.filter(ele => ele.leg_type === '0');
                      const job = filteredjobs.filter(jobs => jobs.id == msg.message.data.job_id);
                      if (!isEmpty(job)) {
                        if (job[0].is_scheduled === 1) {
                          tab = '2';
                        } else if (job[0].is_scheduled === 0) {
                          tab = '3';
                        }
                      }
                      if (!isEmpty(tab)) {
                        history.push(`/job-boards/?type=SameDay&tab=${`${tab}`}&id=${msg.message.data.job_id}`);
                        notification.destroy();
                      }
                    }
                  }
                });
            }
          }
        }
      });
    }
  };
  render() {
    return <>{this.pubnubMethod(this.props)}</>;
  }
}
const mapStateToProps = state => ({});
const mapDispatchToProps = dispatch => ({
  getCurrentjobs: payload => dispatch(getCurrentjobs(payload)),
  cancelJob: (payload, status) => dispatch(cancelJob(payload, status)),
  getJob: payload => dispatch(getJob(payload)),
  showTimerfromPubnub: payload => dispatch(showTimerfromPubnub(payload)),
  showFeedBackfromPubnub: payload => dispatch(showFeedBackfromPubnub(payload)),
  showLoaderfromPubnub: payload => dispatch(showLoaderfromPubnub(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(Pubnub);
