import React, { Component } from 'react';
import { Row, Col } from 'antd';
import qs from 'qs';
import { SignupWrapper } from 'container/Signup/signup.style';
import AuthHeader from 'components/AuthHeader';
import AuthWrapper from 'components/AuthWrapper';
import logoShapeDarkBG from 'assets/images/logo-shape-dark1.svg';
import { onError } from 'modules/errorHandler';
import Verification from 'components/Signup/Verification';

class Signup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      code: '',
      loading: false
    };
  }
  async componentDidMount() {
    if (this.props.history.location.search) {
      let prefixed = qs.parse(this.props.history.location.search, {
        ignoreQueryPrefix: true
      });
      if (prefixed && prefixed.email) {
        this.setState({
          email: prefixed.email,
          code: prefixed.code || ''
        });
      } else {
        this.props.history.push('/');
      }
    } else {
      this.props.history.push('/');
    }
  }
  componentDidCatch(error) {
    onError(error, 'Signup', 'Component render error');
  }
  render() {
    const { email, code } = this.state;
    return (
      <SignupWrapper>
        <AuthHeader bgImage={logoShapeDarkBG} />
        <AuthWrapper>
          <Row>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
              <Verification emailProps={email} codeProps={code} />
            </Col>
          </Row>
        </AuthWrapper>
      </SignupWrapper>
    );
  }
}

export default Signup;
