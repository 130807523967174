const LoginConstant = {
  login: 'Login',
  signUp: 'Sign Up',
  title: 'Gophr Booking',
  subTitle:
    "We create data-driven, smart technology for B2B last-mile delivery, building the world's first true delivery network across London and expanding to the rest of the UK.",
  forgotPassword: 'Forgot password?',
  buttonText: 'NEXT',
  keepSignedIn: 'Keep me signed in',
  email: 'E-mail',
  password: 'Password',
  enterPassword: 'Enter your password',
  emailplaceholder: 'example@mail.com',
  footerYear: '2020'
};

export { LoginConstant };
