import { push } from 'connected-react-router';
import { axiosPost } from 'modules/Axios';
import { apiConstant } from 'modules/config';
import { loader } from 'redux/app/actions';
import { getFavourites } from 'redux/favourite/actions';
import { getUserData } from 'redux/profileUpdate/actions';

import * as actions from './constant';

// ANCHOR - Register Action
export const signup = payload => async dispatch => {
  try {
    dispatch(loader(true));
    dispatch({ type: actions.SIGNUP_INITIATED });
    let response = await axiosPost(apiConstant.AUTH_REGISTER, payload);
    if (!response.success) {
      dispatch({
        type: actions.SIGNUP_ERROR,
        error: response.error.message
      });
    } else {
      await dispatch({
        type: actions.SIGNUP_SUCCESS,
        payload: response
      });
      sessionStorage.setItem(
        'signupForm',
        JSON.stringify({
          firstName: payload.firstName,
          lastName: payload.lastName
        })
      );
      await dispatch(push(`/verification?email=${encodeURIComponent(payload.email)}`));
    }
  } catch (error) {
    console.log(error, 'action catch');
    dispatch({
      type: actions.SIGNUP_ERROR,
      error
    });
  } finally {
    dispatch(loader(false));
  }
};

// ANCHOR - Send Email Action
export const sendEmail = payload => async dispatch => {
  try {
    dispatch(loader(true));
    dispatch({ type: actions.SEND_EMAIL_SUCCESS_INITIATED });
    let response = await axiosPost(apiConstant.SEND_EMAIL, payload);
    if (response.success) {
      await dispatch({
        type: actions.SEND_EMAIL_SUCCESS,
        payload: 'Email is Sent to your mailbox.'
      });
    }
    dispatch({
      type: actions.SEND_EMAIL_CLEAR
    });
  } catch (error) {
    console.log(error, 'action catch');
  } finally {
    dispatch(loader(false));
  }
};

// ANCHOR - Verify and Login Action
export const verifyEmailAndLogin = payload => async dispatch => {
  try {
    dispatch(loader(true));
    dispatch({ type: actions.VERIFY_INITIATED });
    let response = await axiosPost(apiConstant.VERIFY_AND_LOGIN, payload);
    if (response.success) {
      localStorage.setItem('auth', JSON.stringify(response.data));
      await dispatch({
        type: actions.VERIFY_SUCCESS,
        payload: 'Email verified'
      });
      dispatch(push('/register-profile'));
      await dispatch(getUserData());
      await dispatch(getFavourites());
    }
    dispatch({
      type: actions.SEND_EMAIL_CLEAR
    });
  } catch (error) {
    console.log(error, 'action catch');
    dispatch({
      type: actions.VERIFY_ERROR,
      error
    });
  } finally {
    dispatch(loader(false));
  }
};
