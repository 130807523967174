import React, { Component } from 'react';
import { DatePicker } from 'antd';
import { connect } from 'react-redux';
import { withTheme } from 'styled-components';
import { FormWrapper } from './style';
import calenderWhiteIcon from 'assets/images/calendar-icon-white.svg';
import calenderPurpleIcon from 'assets/images/calendar-icon.svg';

class CustomDatePicker extends Component {
  onChange = (date, dateString) => {
    const { name } = this.props;
    this.props.setFieldValue(name, dateString);
  };
  render() {
    const {
      placeholder,
      name,
      value,
      handleChange,
      type,
      children,
      isActive,
      activeIcon,
      icon
    } = this.props;
    return (
      <FormWrapper className={'light-input-wrapper'}>
        <DatePicker
          onChange={this.onChange}
          placeholder={placeholder}
          // value={value}
          // name={name}
          className="custom-datepicker"
          dropdownClassName="custom-datepicker-dropdown"
          getCalendarContainer={data => {
            return data;
          }}
        />
      </FormWrapper>
    );
  }
}
CustomDatePicker.defaultProps = {
  placeholder: undefined,
  isText: false,
  type: 'text'
};
CustomDatePicker.defaultValue = {};
const mapStateToProps = state => ({
  appTheme: state.app.theme
});
export default connect(mapStateToProps, {})(withTheme(CustomDatePicker));
