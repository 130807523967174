import { message, notification } from 'antd';
import { HandleAddressTypes } from 'App/AppConstant';
import { push } from 'connected-react-router';
import isEmpty from 'lodash.isempty';
import { axiosAuthPost } from 'modules/Axios';
import { apiConstant } from 'modules/config';
import { loader } from 'redux/app/actions';
import store from 'redux/store';
import * as actions from './constant';

export const addFavourite = (payload) => async (dispatch) => {
  let status = false;
  try {
    dispatch(loader(true));
    dispatch({ type: actions.ADDFAVOURITE_INITIATED });
    Object.keys(payload).map((key) => {
      if (!payload[key]) {
        delete payload[key];
      } else {
        if (typeof payload[key] == 'string') payload[key] = payload[key].trim();
      }
    });
    let callApi = payload.id && typeof payload.id !== 'undefined' ? apiConstant.EDIT_FAVOURITE : apiConstant.ADD_FAVOURITE;
    let response = await axiosAuthPost(callApi, payload);
    if (!response.success) {
      dispatch({
        type: actions.ADDFAVOURITE_ERROR,
        error: response.error.message,
      });
    } else {
      status = true;
      notification['success']({
        message: payload.id && typeof payload.id !== 'undefined' ? 'Favourite Updated' : 'Favourite Added',
      });
      dispatch({
        type: actions.ADDFAVOURITE_SUCCESS,
        payload: response.data,
      });
    }
  } catch (error) {
    console.log(error);
    dispatch({
      type: actions.ADDFAVOURITE_ERROR,
      error,
    });
  } finally {
    dispatch(loader(false));
    return status;
  }
};
export const getFavourites = () => async (dispatch) => {
  try {
    dispatch(loader(true));
    dispatch({ type: actions.GETFAVOURITES_INITIATED });
    let response = await axiosAuthPost(apiConstant.GET_FAVOURITES, {});
    if (!response.success) {
      dispatch({
        type: actions.GETFAVOURITES_ERROR,
        error: response.error.message,
      });
    } else {
      dispatch({
        type: actions.GETFAVOURITES_SUCCESS,
        payload: response.data.list,
      });
    }
    dispatch(loader(false));
  } catch (error) {
    dispatch(loader(false));
    dispatch({
      type: actions.GETFAVOURITES_ERROR,
      error,
    });
  }
};
export const deleteFavourites = (payload) => async (dispatch) => {
  try {
    dispatch(loader(true));
    dispatch({ type: actions.DELETEFAVOURITES_INITIATED });
    let response = await axiosAuthPost(apiConstant.DELETE_FAVOURITES, payload);
    if (response.success) {
      dispatch({
        type: actions.DELETEFAVOURITES_SUCCESS,
        payload: response.success,
      });
      dispatch(getFavourites());
    }
    dispatch(loader(false));
  } catch (error) {
    dispatch(loader(false));
    dispatch({
      type: actions.DELETEFAVOURITES_ERROR,
      error,
    });
  }
};

export const favouriteToAddJob = (payload, key) => async (dispatch) => {
  try {
    dispatch(loader(true));
    let newJob = {};
    Object.keys(payload).map((field) => {
      newJob[`${key}_${field}`] = payload[field];
    });
    if (key === 'delivery') {
      let storeRef = store.getState();
      if (
        !isEmpty(storeRef) &&
        !isEmpty(storeRef.profileUpdate) &&
        !isEmpty(storeRef.profileUpdate.userData) &&
        storeRef.profileUpdate.userData.address1
      ) {
        let address = { ...storeRef.profileUpdate.userData };
        HandleAddressTypes.map((key) => {
          newJob[`pickup_${key}`] = address[key];
        });
      } else {
        message.info('Please complete the profile details');
        return;
      }
    }

    let responseAddJob = await axiosAuthPost(apiConstant.ADD_JOB, newJob);
    if (responseAddJob.success) {
      await dispatch(push(`/booking/${responseAddJob.data.id}`));
    }
  } catch (error) {
    console.log(error, 'catch error');
  } finally {
    dispatch(loader(false));
  }
};

export const addNewAddressFavourites = (payload) => async (dispatch) => {
  try {
    dispatch(loader(true));
    dispatch({ type: actions.ADDNEWADDRESSFAVOURITES_INITIATED });
    let response = await axiosAuthPost(apiConstant.GET_GEOCODE, payload);
    if (response.success) {
      dispatch({
        type: actions.ADDNEWADDRESSFAVOURITES_SUCCESS,
        payload: response.data,
      });
    }
    dispatch(loader(false));
  } catch (error) {
    dispatch(loader(false));
    dispatch({
      type: actions.ADDNEWADDRESSFAVOURITES_ERROR,
      error,
    });
  }
};
