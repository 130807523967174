const BookingConstant = {
  routePreview: 'Route Preview',
  profile: 'Amanda Langermann | Telford Street, Toddington, 65, UK',
  imperialSystem: 'Imperial System',
  metricSystem: 'Metric System',
  gophrCourierBooking: 'Booking',
  ItemsUnits: 'Items And Units',
  gophrShiftBooking: 'Gophr Courier Shifts Booking',
  parcel: 'Parcel name',
  parcels: 'Parcel Details',
  addParcel: 'Add parcel',
  editParcel: 'Edit parcel',
  parcelDescription: 'Parcel Description',
  wight: 'WEIGHT',
  weightTotal: 'TOTAL WEIGHT',
  totalWight: 'Total weight',
  size: 'Length, depth & height',
  referenceNumber: 'Reference number',
  value: 'VALUE',
  pound: '£',
  insurance: 'Insurance',
  downloadCSV: 'Download CSV Template',
  savedParcel: 'SAVED PARCEL WILL BE AUTOMATICALLY UPLOADED TO FAVOURITES',
  itemsAdd: 'ADD ITEM',
  itemsDescriptionAmount: 'ITEM DESCRIPTION/AMOUNT',
  itemDescription: 'Item Description',
  itemInsurance: 'Item Insurance',
  savedItem: 'SAVED ITEM WILL BE AUTOMATICALLY UPLOADED TO FAVOURITES',
  ibs: 'Ibs',
  kg: 'kg',
  in: 'in',
  cm: 'cms',
  multiply: '×',
  quantity: 'QUANTITY',
  items: 'Items',
  Vehcile_Time: 'Vehicle and Time',
  shippingRequirements: 'Define service',
  deliveryDay: 'Delivery Service',
  ddmmyyyy: 'DD-MM-YYYY',
  jobType: 'Service type',
  pickUpDate: 'PICK UP DATE',
  Date: 'DATE',
  dropOffDate: 'DROP OFF DATE',
  dateTimevalue: '00:00',
  dateTimePickerValue: '00:00 - 00:00',
  carrierAvailability: 'CARRIER AVAILABILITY',
  Availability: 'AVAILABILITY',
  pickUpAddress: 'ADD PICKUP DETAILS',
  dropoffAddress: 'ADD DROP OFF',

  tryBooking: 'Try Booking Near You',
  seeWhat: 'See what we can offer',
  findBooking: 'Find My Booking',
  provider: 'Provider',
  priceRange: 'Price range',
  companyRating: 'Company Rating',
  pickDrop: 'Direct Pick Up and Drop Off',
  onlineTracking: 'Online Tracking',
  completeBooking: 'Complete your booking',
  CollectionReport: 'Collection / Delivery Report',
  printerRequired: 'Printer Required',
  chooseCourier: 'Choose this courier',
  couriersIn: 'Couriers in',
  UK: 'United Kingdom',
  totalPrice: 'Total price includes taxes + fees. Additional fees will apply after',
  completingBooking: 'completing the booking process.',
  sort: 'Sort',
  orderPreview: 'Order Preview',
  nextDaysingle: 'NEXT DAY | SINGLE PICK UP - DROP OFF',

  location: 'LOCATION',
  pickUp: 'PICK UP',
  dropoff: 'DROP OFF',
  parcelsPreview: 'Parcels Preview',
  availableCouriers: 'Available Couriers',
  nextDay: 'NEXT DAY | PICK UP - DROP OFF',
  yourPick: 'YOUR PICK',
  price: 'PRICE',
  rating: 'RATING',
  othercouries: 'OTHER COURIERS',
  orderReceipt: 'Order Receipt',
  yourReceipt: 'YOUR QUOTE',
  courierGov: 'VAT RATE',
  totalPricetxt: 'TOTAL PRICE',
  pricesChange: 'TOTAL',
  editType: 'edit',
  pickupFieldPrefix: 'delivery_',
  deliveryFieldPrefix: 'delivery_',
};

const jobPriority = [
  {
    id: 0,
    value: 'Standard',
    color: 'green'
  },
  {
    id: 1,
    value: 'High Priority',
    color: 'orange'
  },
  {
    id: 2,
    value: 'Rush',
    color: 'red'
  },
  {
    id: 3,
    value: 'Direct',
    color: 'purple'
  }
];
export { BookingConstant, jobPriority };

