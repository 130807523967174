import React, { Component } from 'react';
import isEmpty from 'lodash.isempty';

import { FormWrapper } from './style';

class FormInput extends Component {
  render() {
    const {
      handleChange,
      disabled,
      className,
      classNameWrapper,
      isError,
      label,
      value,
      isRequired,
      id,
      ref,
      tabIndex,
      ...props
    } = this.props;
    return (
      <FormWrapper
        className={`${classNameWrapper} ${'form-group'} ${isError ? 'errorwrapper' : ''} ${
          value ? 'show' : ''
        }`}
      >
        <input
          className={`form-control ${className}`}
          disabled={disabled ? true : false}
          onChange={handleChange}
          value={value}
          id={id}
          ref={ref}
          tabIndex={tabIndex}
          {...props}
        />
        <label
          className={`form-control-placeholder title ${className}  ${value ? 'show' : ''}`}
        >{`${!isEmpty(label) ? label : ''} ${isRequired ? ' *' : ''}`}</label>
        {/* <label
          className={`form-control-placeholder title ${className}  ${value ? 'show' : ''}`}
        >{`${
          !isEmpty(label)
            ? (value && label) ||
              (!isEmpty(document.activeElement.placeholder) &&
              document.activeElement.placeholder.includes(label)
                ? label
                : '')
            : ''
        } ${
          isRequired
            ? (value && ' *') ||
              (!isEmpty(document.activeElement.placeholder) &&
              document.activeElement.placeholder.includes(label)
                ? ' *'
                : '')
            : ''
        }`}</label> */}
      </FormWrapper>
    );
  }
}
FormInput.defaultProps = {
  isError: false,
  placeholder: '',
  tabIndex: '1',
  maxLength: 128,
  className: '',
  classNameWrapper: '',
  readOnly: false
};
export default FormInput;
