import styled from 'styled-components';
import { size } from 'App/device';
import closeIcon from 'assets/images/close-icon.svg';
const MapWrapper = styled.div`
  button.maps-close-btn {
    z-index: 0;
    background-size: 60% auto;
    background-image: url(${closeIcon});
    background-repeat: no-repeat;
    background-color: rgb(255, 255, 255);
    background-position: center;
    border: 0px;
    margin: 10px;
    padding: 0px;
    position: absolute;
    cursor: pointer;
    user-select: none;
    border-radius: 2px;
    height: 40px;
    width: 40px;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
    overflow: hidden;
    top: 6px;
    right: 42px;
  }
  .maindiv-jobstatus {
    display: flex;
    .tooltip {
      position: relative;
      background-color: #00c68d;
      padding: 10px;
      margin: 10px;
      margin-right: 0;
      padding-right: 0;
      border-radius: 4px 0 0 4px;
      cursor: pointer;
    }
    .tooltiptext {
      background-color: #8f48ff;
      color: #fff;
      text-align: center;
      border-radius: 6px;
      padding: 5px;
      z-index: 1;
      opacity: 0;
      display: none;
      top: 0;
      transition: opacity 0.3s;
      margin: 10px;
      font-family: 'Montserrat';
      font-weight: 600;
      font-size: 12px;
      padding: 10px;
      &.show {
        display: initial;
        opacity: 1;
      }
    }
  }
  .status-div {
    background: rgb(255, 255, 255);
    z-index: 0;
    border: 0px;
    margin: 10px;
    padding: 0px;
    padding-left: 0;
    margin-left: 0;
    cursor: pointer;
    user-select: none;
    overflow: hidden;
    top: 20px;
    right: 5%;
    background: #00c68d;
    padding: 10px;
    border-radius: 0 4px 4px 0;
    font-family: 'Montserrat';
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    color: #ffffff;
  }
  .marker {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 18px;
    height: 18px;
    background-color: #24b596;
    border: 2px solid #fff;
    border-radius: 100%;
    user-select: none;
    transform: translate(-50%, -50%);
    &:hover {
      z-index: 1;
    }
  }
  .pin {
    width: 30px;
    height: 30px;
    border-radius: 50% 50% 50% 0;
    background: #00cae9;
    position: absolute;
    transform: rotate(-45deg);
    left: 50%;
    top: 50%;
    margin: -20px 0 0 -20px;
  }
  .pin:after {
    content: '';
    width: 14px;
    height: 14px;
    margin: 8px 0 0 8px;
    background: #24b596;
    position: absolute;
    border-radius: 50%;
  }

  .bounce {
    animation-name: bounce;
    animation-fill-mode: both;
    animation-duration: 1s;
  }

  .pulse {
    background: #d6d4d4;
    border-radius: 50%;
    height: 14px;
    width: 14px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin: 11px 0px 0px -12px;
    transform: rotateX(55deg);
    z-index: -2;
  }
  .pulse:after {
    content: '';
    border-radius: 50%;
    height: 40px;
    width: 40px;
    position: absolute;
    margin: -13px 0 0 -13px;
    animation: pulsate 1s ease-out;
    animation-iteration-count: infinite;
    opacity: 0;
    box-shadow: 0 0 1px 2px #00cae9;
    animation-delay: 1.1s;
  }
  @keyframes pulsate {
    0% {
      transform: scale(0.1, 0.1);
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      transform: scale(1.2, 1.2);
      opacity: 0;
    }
  }
  @keyframes bounce {
    0% {
      opacity: 0;
      transform: translateY(-2000px) rotate(-45deg);
    }
    60% {
      opacity: 1;
      transform: translateY(30px) rotate(-45deg);
    }
    80% {
      transform: translateY(-10px) rotate(-45deg);
    }
    100% {
      transform: translateY(0) rotate(-45deg);
    }
  }
  h1 {
    font-size: 14px;
    margin-bottom: 0;
  }
`;

export { MapWrapper };
