export const PROFILE_INITIATED = 'PROFILE_INITIATED';
export const PROFILE_SUCCESS = 'PROFILE_SUCCESS';
export const PROFILE_ERROR = 'PROFILE_ERROR';
export const USERDATA_INITIATED = 'USERDATA_INITIATED';
export const USERDATA_SUCCESS = 'USERDATA_SUCCESS';
export const USERDATA_ERROR = 'USERDATA_ERROR';
export const PROFILE_CLEAR = 'PROFILE_CLEAR';

export const SECONDARY_USERDATA_INITIATED = 'SECONDARY_USERDATA_INITIATED';
export const SECONDARY_USERDATA_SUCCESS = 'SECONDARY_USERDATA_SUCCESS';
export const SECONDARY_USERDATA_ERROR = 'SECONDARY_USERDATA_ERROR';

export const SECONDARY_PROFILE_INITIATED = 'SECONDARY__PROFILE_INITIATED';
export const SECONDARY_PROFILE_SUCCESS = 'SECONDARY__PROFILE_SUCCESS';
export const SECONDARY_PROFILE_ERROR = 'SECONDARY__PROFILE_ERROR';

export const PROFILE_PICTURE_UPLOAD_INITIATED = 'PROFILE_PICTURE_UPLOAD_INITIATED';
export const PROFILE_PICTURE_UPLOAD_SUCCESS = 'PROFILE_PICTURE_UPLOAD_SUCCESS';
export const PROFILE_PICTURE_UPLOAD_ERROR = 'PROFILE_PICTURE_UPLOAD_ERROR';
