import axios from 'axios';
import isEmpty from 'lodash.isempty';
import { apiConstant, configVar } from 'modules/config';
import { getAuthToken } from 'modules/helper';
import qs from 'qs';
import uuidv4 from 'uuid/v4';

export const onError = async (error, source, message) => {
  let errorLog = {
    source: source,
    error: error ? error.stack || error.message : '',
    user_agent: window.navigator.userAgent,
    url: window.location.href,
    request_id: uuidv4(),
  };
  if (errorLog.error === '') {
    message += ' (Likely Browser Extension)';
  }
  let parameter = {
    event: 'runtime-exception-nextday',
    description: message,
    frontend_version: configVar.FRONTEND_VERSION,
    text: JSON.stringify(errorLog, null, ' '),
    session_id: !isEmpty(getAuthToken()) ? getAuthToken() : null,
    result: 0,
  };
  await axios.post(configVar.BASE_URL + apiConstant.PUBLIC_LOG_EVENT, qs.stringify(parameter));
};
