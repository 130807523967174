import { jobStatusList, MultiJobsType } from 'App/AppConstant';
import { BookingDetailsConstant, sizeData } from 'components/Booking/constant';
import isEmpty from 'lodash.isempty';
import { configVar } from 'modules/config';
import moment from 'moment';
export const getCookie = name => {
  let match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
  if (match) return match[2];
  return false;
};
export const cookieName = cookie_name => {
  let name;
  switch (configVar.ENVIRONMENT) {
    case 'development':
      name = String('test_' + cookie_name);
      break;
    default:
      name = String(cookie_name);
      break;
  }
  return name;
};
export const getAuthToken = () => {
  if (localStorage.auth) {
    let auth = JSON.parse(localStorage.auth);
    if (auth && auth.session_id) return auth.session_id;
    return false;
  }
  return false;
};

export const checkKeys = (data, key) => {
  if (!isEmpty(data) && !data[key]) {
    return true;
  }
  return false;
};

export const filterJobs = currentJobs => {
  let singleJobs = [],
    multiJobs = [],
    subJobs = [],
    isTeam = false;
  if (!isEmpty(currentJobs) && Array.isArray(currentJobs)) {
    currentJobs.map(currJob => {
      if (currJob.status !== 'NEW' && currJob.team_id) {
        isTeam = true;
      }
      if (
        currJob.multi_job_type === MultiJobsType.MULTI_PICKUP ||
        currJob.multi_job_type === MultiJobsType.MULTI_DROP
      ) {
        multiJobs.push(currJob);
      } else if (
        currJob.multi_job_type === MultiJobsType.SUBJOB_MULTI_PICKUP ||
        currJob.multi_job_type === MultiJobsType.SUBJOB_MULTI_DROP
      ) {
        subJobs.push(currJob);
      } else {
        singleJobs.push(currJob);
      }
    });
    if (!isEmpty(subJobs)) {
      let subJobSelect = {};
      let filterdMultiJobs = multiJobs.map(parentJob => {
        let subFileredJobs = subJobs.filter(ele => ele.multi_job_id === parentJob.id);
        if (!isEmpty(subFileredJobs)) {
          subJobSelect = {
            ...subJobSelect,
            [`${parentJob.id}`]: subFileredJobs[0]
          };
          return {
            ...parentJob,
            sub_jobs: [...subFileredJobs]
          };
        }
      });
      return {
        jobs: [...filterdMultiJobs, ...singleJobs],
        selectSubJob: subJobSelect,
        isTeam
      };
    } else {
      return {
        jobs: [...multiJobs, ...singleJobs],
        selectSubJob: {},
        isTeam
      };
    }
  }
  return {
    jobs: [],
    selectSubJob: {},
    isTeam
  };
};

export const getSameDaySliderEndTime = (startTime, quotaDetails, dateFormat, currentMaxUnix, isUnixResponseTime, isMomentResponseTime) => {
  let maxUnix = currentMaxUnix;
  let newMainValueUnix = moment.unix(startTime).format(dateFormat);
  //newMainValueUnix = moment(newMainValueUnix, dateFormat).add(240, "minutes").format(dateFormat)
  let realisticMins = Math.ceil(quotaDetails.realistic_time_needed / 60);
  let newMainValueUnixCheck = moment(newMainValueUnix, dateFormat).add(realisticMins, "minutes").format(dateFormat)
  let maxUnixDate = new Date(moment.unix(maxUnix).format(dateFormat))
  let newMainValueUnixDate = new Date(newMainValueUnixCheck)
  if (newMainValueUnixDate > maxUnixDate) {
    if (isUnixResponseTime) {
      maxUnix = moment(newMainValueUnixCheck).unix()
    } else {
      maxUnix = newMainValueUnixCheck;
    }
  }
  if (isMomentResponseTime) {
    maxUnix = moment.unix(maxUnix).format(dateFormat);
  }
  return maxUnix;
}
export const validateSameDayCourierEndtime = (mainValueUnix, quotaDetails, workingHours, dateFormat, jobDate) => {
  let sameDayFlag = true;
  let newMainValueUnix = moment.unix(mainValueUnix).format(dateFormat);
  //newMainValueUnix = moment(newMainValueUnix, dateFormat).add(180, "minutes").format(dateFormat)
  let realisticMins = Math.ceil(quotaDetails.realistic_time_needed / 60);
  let newMainValueUnixCheck = moment(newMainValueUnix, dateFormat).add(realisticMins, "minutes").format(dateFormat);
  let currentDate = moment(jobDate).format('YYYY-MM-DD');
  let workingEndTime = workingHours.office_stop_time;
  currentDate = moment(currentDate + " " + workingEndTime).format(dateFormat);

  currentDate = moment(currentDate, dateFormat);
  newMainValueUnixCheck = moment(newMainValueUnixCheck, dateFormat);

  let diff = newMainValueUnixCheck.diff(currentDate, 'minutes')
  if (diff > 0) {
    sameDayFlag = false;
  }
  return sameDayFlag;
}
export const correctDirectJobTime = (date, quotaDetails, workingHours, dateFormat) => {
  let jobDate = moment.unix(date).format(dateFormat);
  let dayEndTime = moment(jobDate.split(" ")[0] + " " + workingHours.office_stop_time).format(dateFormat);

  let realisticMins = Math.ceil(quotaDetails.realistic_time_needed / 60);
  let jobEndTime = moment(jobDate, dateFormat).add(realisticMins, "minutes").format(dateFormat);
  const roundedUp = Math.ceil(moment(jobEndTime).minute() / 5) * 5;
  jobEndTime = moment(jobEndTime).set({ minute: roundedUp }).format(dateFormat);
  if (jobEndTime > dayEndTime) {
    jobEndTime = dayEndTime;
    jobDate = moment(jobEndTime, dateFormat).subtract(realisticMins, "minutes").format(dateFormat);
  }

  return [jobDate, jobEndTime]
}

export const getManifestData = (mData) => {
  let manifetData = [];
  mData.map((data, i) => {
    manifetData.push(data.job_id)
  });
  return manifetData;
}

export const getFinalLocations = (data, typeOfDelivery, startIndex = 1) => {
  let deliveryLocationCnt = startIndex;
  let resultDataArr = [];
  data.map((invoiceData) => {
    if (invoiceData.status !== typeOfDelivery) {
      resultDataArr.push({
        data: { ...invoiceData, lat: invoiceData.delivery_location_lat, lng: invoiceData.delivery_location_lng },
        i: deliveryLocationCnt,
      });
      deliveryLocationCnt++;
    }
  });
  return resultDataArr;
}

export const changeJobStatus = (jobs, newJobStatusList, jobType = 1, jobIndex = "sub_jobs") => {
  // console.log("RRRR", newJobStatusList, typeof newJobStatusList)
  let resultJobs = [];
  let result = {};
  let tempJobs = { ...jobs };
  if (jobs.multi_job_type === MultiJobsType.MULTI_PICKUP && jobType === 1) {
    let arrStatus = JSON.parse(newJobStatusList);
    resultJobs = jobs[jobIndex].map((data) => {
      let temp = data;
      let newStatusIndex = arrStatus.indexOf(+data.id);
      let statusIndex = arrStatus[newStatusIndex + 1];
      if (newStatusIndex !== -1) {
        temp.status = jobStatusList[statusIndex][statusIndex];
      }
      return temp;
    })
    // if (arrStatus.includes(tempJobs.id)) {
    //   let newStatusIndex = arrStatus.indexOf(+tempJobs.id);
    //   let statusIndex = arrStatus[newStatusIndex + 1];
    //   if (newStatusIndex !== -1) {
    //     tempJobs.status = jobStatusList[statusIndex][statusIndex];
    //   }
    // }
    result = { ...tempJobs };
    result[jobIndex] = resultJobs;
  } else if (jobs.multi_job_type === MultiJobsType.MULTI_DROP && jobType === 2) {
    let arrStatus = JSON.parse(newJobStatusList);
    resultJobs = jobs[jobIndex].map((data) => {
      let temp = data;
      let newStatusIndex = arrStatus.indexOf(+data.id);
      let statusIndex = arrStatus[newStatusIndex + 1];
      if (newStatusIndex !== -1) {
        temp.status = jobStatusList[statusIndex][statusIndex];
      }
      return temp;
    })
    // if (arrStatus.includes(tempJobs.id)) {
    //   let newStatusIndex = arrStatus.indexOf(+tempJobs.id);
    //   let statusIndex = arrStatus[newStatusIndex + 1];
    //   if (newStatusIndex !== -1) {
    //     tempJobs.status = jobStatusList[statusIndex][statusIndex];
    //   }
    // }
    result = { ...tempJobs };
    result[jobIndex] = resultJobs;
  }
  return result;
}
export const checkParcelDataUpdate = (values, parcelData) => {

  let currentParcel = parcelData.parcels;
  let updateCurrentJob = false;
  let parcelSize = sizeData[values.size - 1];
  if (values.weight !== +currentParcel.weight) {
    updateCurrentJob = true;
  }
  if (values.size !== BookingDetailsConstant.customParcelSize && (parcelSize.x !== +currentParcel.size_x || parcelSize.y !== +currentParcel.size_y || parcelSize.z !== +currentParcel.size_z)) {
    updateCurrentJob = true;
  }
  if (values.size === BookingDetailsConstant.customParcelSize && (values.customLength !== +currentParcel.size_x || values.customDepth !== +currentParcel.size_y || values.customHeight !== +currentParcel.size_z)) {
    updateCurrentJob = true;
  }

  if (values.insurance === BookingDetailsConstant.customInsuraceAmt && (values.inc_value !== currentParcel.package_value)) {
    updateCurrentJob = true;
  }
  else {
    if (values.insurance !== +currentParcel.package_value) {
      updateCurrentJob = true;
    }
  }
  if (values.parcel.trim() !== currentParcel.description) {
    updateCurrentJob = true;
  }
  if (values.referenceNumber.trim() !== currentParcel.reference_number) {
    updateCurrentJob = true;
  }
  return updateCurrentJob;
}
export const checkAddressDataUpdate = (values, jobDetails, address_type = 'pickup_') => {
  let getNextDayEstimateFlag = false;
  if (jobDetails.subjobs !== undefined && jobDetails.multi_job_type === MultiJobsType.MULTI_DROP && address_type !== 'pickup_') {
    const subJob = jobDetails.subjobs.reduce((acc, job) => {
      if (job.id === values.id) {
        acc.push(job);
      }
      return acc;
    }, []);
    jobDetails = Object.keys(subJob).length ? subJob[0] : jobDetails;
  }
  Object.keys(values).map((field) => {
    let currentField = address_type + field;
    let currentValue = typeof values[field] === "string" ? values[field].trim() : values[field];
    if (jobDetails[currentField] !== undefined && (currentValue !== jobDetails[currentField])) {
      getNextDayEstimateFlag = true;
    }
  });
  return getNextDayEstimateFlag;
}