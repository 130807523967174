import MontserratBold from 'assets/fonts/Montserrat-Bold.ttf';
import MontserratLight from 'assets/fonts/Montserrat-Light.ttf';
import MontserratMedium from 'assets/fonts/Montserrat-Medium.ttf';
import MontserratRegular from 'assets/fonts/Montserrat-Regular.ttf';
import MontserratSemiBold from 'assets/fonts/Montserrat-SemiBold.ttf';
import OpenSansBold from 'assets/fonts/OpenSans-Bold.ttf';
import OpenSansLight from 'assets/fonts/OpenSans-Light.ttf';
import OpenSansRegular from 'assets/fonts/OpenSans-Regular.ttf';
import OpenSansSemiBold from 'assets/fonts/OpenSans-SemiBold.ttf';
import arrowIconPurple from 'assets/images/arrow-purple-right-large-icon.svg';
import { default as arrowIcon, default as arrowRightLargeIcon } from 'assets/images/arrow-right-large-icon.svg';
import ParceldeleteIcon from 'assets/images/booking/delete-icon.svg';
import parcelIcon1 from 'assets/images/booking/parcel-icon1.svg';
import parcelIcon2 from 'assets/images/booking/parcel-icon2.svg';
import closeIcon from 'assets/images/close-icon-light.svg';
import closeIconWhite from 'assets/images/close-white-icon.svg';
import cube from 'assets/images/cube.svg';
import customSizeIcon from 'assets/images/custom-size.svg';
import deliverySmallIcon from 'assets/images/delivery-small-icon.svg';
import dropdownArrow from 'assets/images/dropdown-arrow-grey.svg';
import { default as droparrowIcon, default as dropdownArrowIcon } from 'assets/images/dropdown-arrow.svg';
import editPen from 'assets/images/edit-pen.svg';
import emptyStar from 'assets/images/empty-star.svg';
import infoIconPurple from 'assets/images/info-icon.svg';
import infoIconWhite from 'assets/images/info-white-icon.svg';
import insuranceIcon from 'assets/images/insurance-icon.png';
import mapIcon from 'assets/images/map-preview.svg';
import pickupSmallIcon from 'assets/images/pickup-small-icon.svg';
import addParcelIcon from 'assets/images/plus-circle-outlined-green.svg';
import copyIcon from 'assets/images/redirect-folder-icon.svg';
import refrenceIcon from 'assets/images/refrence-icon-latest.svg';
import starDarkIcon from 'assets/images/star-dark-icon.svg';
import starIcon from 'assets/images/star-icon.svg';
import warningIcon from 'assets/images/warning-icon.svg';
import weightmachineIcon from 'assets/images/weight-machine.svg';
import { createGlobalStyle, keyframes } from 'styled-components';
import { size } from './device';
import { borderRadius, customScroller, transition } from './mixins';

const loader = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const GlobalStyle = createGlobalStyle`
@font-face {
  font-family: "Montserrat";
  src: url(${MontserratLight});
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  src: url(${MontserratRegular});
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  src: url(${MontserratMedium});
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  src: url(${MontserratSemiBold});
  font-weight: 600;
  font-style: bold;
}

@font-face {
  font-family: "Montserrat";
  src: url(${MontserratBold});
  font-weight: 700;
  font-style: bold;
}

@font-face {
  font-family: "OpenSans";
  src: url(${OpenSansLight});
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "OpenSans";
  src: url(${OpenSansRegular});
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "OpenSans";
  src: url(${OpenSansSemiBold});
  font-weight: 600;
  font-style: bold;
}

@font-face {
  font-family: "OpenSans";
  src: url(${OpenSansBold});
  font-weight: 700;
  font-style: bold;
}


* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*, *::after, *::before {
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
}
/* HTML and Body */
html {
  margin: 0px;
  font-size: 14px;
  line-height: 1.4em;
  overflow-x: hidden;
  font-family: ${(props) => props.theme.secondaryFont}, sans-serif;
}
html body {
  background: ${(props) => props.theme.backgroundColor};
  margin: 0px;
  font-size: 14px;
  line-height: 1.4em;
  font-family: ${(props) => props.theme.secondaryFont}, sans-serif;
  ${transition(0.4)};
}

body.body-remove-height {
  height: initial;
}

body.body-hide-overflow {
  overflow: hidden;
}


/* Header Tags */
h1, h2, h3, h4, h5, h6 {
  font-weight: 600;
  line-height: normal;
  font-family:  ${(props) => props.theme.primaryFont}, sans-serif;
  color: ${(props) => props.theme.primaryColor};
}
h1, h2 {
  font-size: 36px;
  font-weight: 600;
  line-height: normal;
  @media ${size['laptop-max']} {
    font-size: 34px;
  }
  @media ${size['laptop-sm-max']} {
    font-size: 30px;
  }
  @media ${size['tablet-max']} {
    font-size: 28px;
  }    
}
h3 {
  font-size: 35px;
}
h4 {
  font-size: 28px;
}
h5 {
  font-size: 20px;
}
h6 {
  font-size: 22px;
  @media ${size['desktop-sm-max']} {
    font-size: 20px;
  }
  @media ${size['laptop-max']} {
    font-size: 18px;
  }
  @media ${size['laptop-sm-max']} {
    font-size: 16px;
  }
  @media ${size['tablet-max']} {
    font-size: 15px;
  }
}

/* Anchor Tag Style */
a {
  text-decoration: none;
  color: ${(props) => props.theme.primaryColor};
  ${transition(0.4)};
  &.termsCondition {
    text-decoration: underline;
    color: ${(props) => props.theme.secondaryColor};
  }
}

/* Button Style */
button:not(:disabled),
  [type='button']:not(:disabled),
  [type='reset']:not(:disabled),
  [type='submit']:not(:disabled) {
    cursor: pointer;
}
button, input {
  overflow: visible;
}
button:focus, button:hover, button:active {
  outline: none;
  box-shadow: none;
}
button {
  font-family: ${(props) => props.theme.primaryFont}, sans-serif;
  font-weight: bold;
}

ul {
  li {
    list-style: none;
  }
}
/* Input Style */
input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
  font-family:  ${(props) => props.theme.secondaryFont}, sans-serif;
}

/* Input AutoFill Style */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input[type='text']:-webkit-autofill,
input[type='text']:-webkit-autofill:hover,
input[type='text']:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  background-color: initial;
  -webkit-text-fill-color: ${(props) => props.theme.primaryColor};
  transition: background-color 5000s ease-in-out 0s;
}

/* Form Error */
.form-error {
  line-height: 20px;
  position: relative;
  top: 5px;
  color: ${(props) => props.theme.dangerColor};
}
.btn {
  border: 0;
  display: inline-block;
  font-size: 12px;
  text-transform: none;
  color: #fff;
  letter-spacing: 0.12em;
  text-transform: uppercase;
  position: relative;
  padding: 0px 14px;
  line-height: 36px;
  height: 36px;
  min-width: 145px; 
  font-family: ${(props) => props.theme.primaryFont}, sans-serif;
  font-weight: 600;
  color: ${(props) => props.theme.blackColor};
  border: 1px solid ${(props) => props.theme.blackColor};
  ${borderRadius('0px')};
  ${transition(0.4)};
  background-color: ${(props) => props.theme.primaryButtonColor};
  box-shadow: none;
  &:hover {
    background-color: #fff;
    color: ${(props) => props.theme.primaryButtonColor};
    border: 1px solid ${(props) => props.theme.primaryButtonColor};
  }
  @media ${size['desktop-sm-max']} {
    min-width: 160px; 
  }
  @media ${size['tablet-md-max']} {
    min-width: 120px; 
    font-size: 13px;  
  }
  @media ${size['mobile-sm-max']} {
    padding-left: 10px;
  }

  &.gray-btn {
    background:#C4C4C4;
    ${borderRadius('5px')};
    font-size: 15px;
    text-transform: none;
    letter-spacing: 0;
    color: #fff;
    &.active, &:focus, &:hover{
      background: #8F48FF;
      color: #fff;
    }
  }
  &.right {
    margin-left: 15px;
  }
 &.purple-btn {
  border: 1px solid ${(props) => props.theme.primaryButtonColor};
  background-color: ${(props) => props.theme.primaryButtonColor};
  color: #fff;
  ${borderRadius('0')};
  text-transform: none;
  letter-spacing: 0;
  
  &.service-btn{
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 13px;
    border-radius: 6px;
  }
  &.print-label{
    font-size: 13px;
    border-radius: 6px;
  }
  &.custom{
    @media ${size['mobile-md-max']} {
    margin-left: 0;
    margin-right: 0;
    margin-top: 10px;
  }
  }
  &.btn-size {
    @media ${size['desktop-sm-max']} {
      min-width: 132px !important;
      height: 35px !important;
      line-height: 35px !important;
    }
    @media ${size['tablet-max']} {
      min-width: 100px !important;
    }
    @media ${size['mobile-md-max']} {
      min-width: 66px !important;
      font-size: 12px;
    }
  }
  :hover {
    background: #fff;
    color: ${(props) => props.theme.primaryButtonColor};
    border: 1px solid ${(props) => props.theme.primaryButtonColor};
    .arrow-btn {
      path {
        stroke: ${(props) => props.theme.primaryButtonColor};
      }
    }
  }
  &[disabled] {
    background: #c4c4c4;
    color: #606060;
    border: 1px solid #c4c4c4;
  }
  @media ${size['desktop-sm-max']} {
    // min-width: 120px;
    // height: 50px;
    // line-height: 50px;
    // font-size: 13px;
    // padding: 0;
  }
 }
  &.cancel-btn {
    color: #fff;
    background-color: ${(props) => props.theme.redShadeColor1};
    ${borderRadius('0')};
    box-shadow: none;
    border: 1px solid ${(props) => props.theme.redShadeColor1};
    @media ${size['desktop-sm-max']} {
      // min-width: 120px;
      // height: 50px;
      // line-height: 50px;
      // font-size: 13px;
      // padding: 0;
    } 
    &:hover {
      background-color: #fff;
      color: ${(props) => props.theme.redShadeColor1};
      border: 1px solid ${(props) => props.theme.redShadeColor1};
      .arrow-btn {
        path {
          stroke: ${(props) => props.theme.redShadeColor1};
        }
      }
    }
  }
}
/* Button Style for Login/Signup Pages */

/* Checkbox Style */

.ant-checkbox-wrapper {
  outline: none;
  .ant-checkbox-checked::after {
    border-color: ${(props) => props.theme.secondaryColor};
  }
}

.ant-checkbox-checked {
  .ant-checkbox-inner {
    top: 0;
    // left: 0;
    // height: 16px;
    // width: 16px;
    left: 0%;
    background-color: transparent;
    border: 1.42px solid #d9d9d9;
    border-radius: 5px;
    &:after {
      border-color: #8f48ff;
      left: 24%;
    }
  }
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: ${(props) => props.theme.secondaryColor};
}

/* Toogle Icon */
.toggle-icon {
  height: 30px;
  width: 30px;
  background: #dedede url(${dropdownArrowIcon}) no-repeat center;
  background-size: 8px auto;
  ${transition(0.4)};
  ${borderRadius('50%')};
  display: inline-block;
  vertical-align: middle;
  border: 0;
  padding: 12px;
}


.parcel-cube-icon {
  background: url(${cube}) no-repeat center;
  width: 16px;
  background-size: 100% auto;
  height: 35px;
  top: -13px;
  left: 20px;
  // margin-left: 25px;
  border: 0;
  opacity: 0;
  display: inline-block;
  vertical-align: middle;
  position: absolute;
  font-family: 'Line Awesome Free';
  font-weight: 900;
  &:before {
    // content: "\f055";
    position: absolute;
    top: 25px;
    left: -27px;
    color: #B4B4B4;
    font-size: 35px;
  }
}
.cube-icon {
  background: url(${cube}) no-repeat center;
  width: 16px;
  height: 20px;
  background-size: 100% auto;
  position: absolute;
  border: 0;
  opacity: 0;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  font-family: 'Line Awesome Free';
  font-weight: 900;
  top: -14px;
  left: -3px;
}
&.pickup-location-listing {
  li {
    // &:after {
    //   content: '';
    //   display: inline-block;
    //   position: absolute;
    //   background: url(${pickupSmallIcon}) no-repeat center;
    //   width: 20px;
    //   height: 22px;
    //   left: 45px;
    //   @media ${size['desktop-mm-max']}{
    //     left: 28px;
    //   }
    //   @media ${size['tablet-max']}{
    //     left: 10px;
    //   }
    // }
    
  }
}

&.delivery-location-listing {
  li {
    // :after {
    //   content: '';
    //   display: inline-block;
    //   position: absolute;
    //   background: url(${deliverySmallIcon}) no-repeat center;
    //   width: 20px;
    //   height: 22px;
    //   left: 45px;
    //   @media ${size['desktop-mm-max']}{
    //     left: 28px;
    //   }
    //   @media ${size['tablet-max']}{
    //     left: 10px;
    //   }
    // }
    &:hover {
      .price-tag {
        opacity: 0;
        display: none;
      }
    }
    span{
      /* width: 275px; */
      /* max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      padding-right: 10px; */
    }
  }
}

.side-grp-btn {
  display: flex;
  align-items: center;
  position: relative;
}
.price-tag {
position: absolute;
right: 0px;
display: inline-block;
${transition(0.4)};
opacity: 1;
font-size: 16px;
font-weight: 600;
}

/*
 W3W Style */
.w3w-info {
  position: relative;
  float: right;
  top: 43px;
  background: url(${infoIconPurple}) no-repeat center;
  cursor: pointer;
  z-index: 1;
  right: 12px;
  width: 13px;
  height: 13px;
  display: inline-block;
  background-size: 100% auto;
  vertical-align: middle;
  margin-left: auto;
  &.label {
    top: 0 !important;
    right: -12px !important;
  }
}

.w3w-arrow-label {
  position: relative;
  float: right;
  top: 42px;
  z-index: 1;
  font-size: 12px;
  right: -2px;
  color:  ${(props) => props.theme.secondaryColor};
  font-family: ${(props) => props.theme.primaryFont};
  :hover {
    text-decoration: underline;
  }
}

.arrow-icon {
  position: relative;
  float: right;
  width: 30px;
  height: 10px;
  top: 46px;
  background: url(${arrowIcon}) no-repeat center;
  background-size: 100% auto;
  vertical-align: middle;
  cursor: pointer;
  background-size: 26px auto;
  display: block;    
  z-index: 1;
  :hover{
  background: url(${arrowIconPurple}) no-repeat center;
  }
}

&.arrow-btn-cube {
  width: 13.44px !important;
  height: 15px !important;
}
&.parcel-cube-svg{
  position: relative;
  margin-right: 10px;
  top: 6px;
  width: 18px;
}
&.add-parcel-svg{
  display: inline-block;
  position: relative;
  margin-right: 10px;
  background-size: 18px auto;
  height: 18px;
  width: 18px;
  top: 6px;
  background: url(${addParcelIcon}) no-repeat center;
}
.arrow-btn {
  width: 15px;
  height: 15px;
  stroke: white;
  position: relative;
  path {
    stroke: white;
  }
  &.arrow-btn-prev {
    top: 5px;
    left: -15px;
  }
  &.arrow-btn-red {
    top: 5px;
    left: -15px;
    height: 15.65px;
  }
  &.arrow-btn-next {
    top: 5px;
    right: -5px;
  }
  &.new-arrow-btn {
    top: 3px;
    left: -5px;
    &.arrow-disabled {
      path {
        stroke: #B9B9B9;
      }
    }
    
  }
}

.fl-right{
  float: right;
}
.booking-head-info-progress-wrap {
  display:flex;
  flex-wrap: wrap;
  // width: 62%;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
.booking-head-info-progress-wrap-shift {
  display:flex;
  flex-wrap: wrap;
  width: 72%;
  // width: 100%;
  justify-content: space-between;
  align-items: center;
  @media ${size['desktop-md-max']} {
    width: 70%;
  }
}

/* Helper Style */
.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0,0,0,0.125);
  ${borderRadius('.25rem')};
}
.d-flex {
  display: flex !important;
}
.pl-0 {
  padding-left: 0 !important;
}
.ml-auto {
  margin-left: auto !important;
} 
.custom-modal{
  margin-left: auto !important;
  @media ${size['tablet-max']} {
    width: 20%;
  }
  @media ${size['mobile-md-max']} {
    width: 22%
  }
  @media ${size['mobile-sm-max']} {
    width: 100% !important;
  }
}
.mr-auto {
  margin-right: auto !important;
}
.mb-0 {
  margin-bottom: 0 !important;
}

.align-center {
  text-align: center;
}

/* Tabs Common Style */ 
.ant-tabs  {
  overflow: visible;
}

//ANCHOR - Transition Group Style
.map-transition-wrapper{
  position: absolute;
  z-index: 1;
  transform: translateX(140%);
  transition: all 0.4s linear;
}
.transition-section-enter {
  opacity: 0;
}
.transition-section-enter-active {
  opacity: 1;
  transition: all 0.4s linear;
}
.transition-section-exit {
  opacity: 0;
}
.transition-section-exit-active {
  opacity: 1;
  transition: all 0.4s linear;
}

.transition-section-single-enter {
  opacity: 0;
}
.transition-section-single-enter-active {
  opacity: 1;
  transition: all 0.4s linear;
}
.transition-section-single-exit {
  opacity: 1;
}
.transition-section-single-exit-active {
  opacity: 0;
  transition: all 0.4s linear;
}

.transition-section-slide-enter {
  opacity: 0;
   transform: translateX(400px);
}
.transition-section-slide-enter-active {
  opacity: 1;
   transform: translateX(0px);
  transition: all 0.4s ease;
}
.transition-section-slide-exit {
  opacity: 1;
   transform: translateX(0px);
}
.transition-section-slide-exit-active {
  opacity: 0;
   transform: translateX(400px);
  transition: all 0.4s ease;
}

.transition-section-slide1-enter {
  opacity: 0;
}
.transition-section-slide1-enter-active {
  opacity: 1;
  transition: all 0.4s ease;
}
.transition-section-slide1-exit {
  opacity: 1;
}
.transition-section-slide1-exit-active {
  opacity: 0;
  transition: all 0.4s ease;
}
.vehicle-title{
  font-weight: 600;
  font-family: 'Montserrat';
}

.down {
  background: url(${dropdownArrow}) no-repeat center;
  transform: rotate(0);
  height: auto;
  width: 20px;
}
.up {
  background: url(${dropdownArrow}) no-repeat center;
  transform: rotate(-180deg);
  height: auto;
  width: 20px;
}
/* Padding Top & Bottom */ 
.padding-tb50 {
  &.public-top{
      padding-top: 25px;
      padding-bottom:0;
    @media ${size['desktop-md-max']} {
      padding-top: 0;
    }
  }
  padding-top: 90px;
  padding-bottom: 20px;
  @media ${size['desktop-md-max']} {
    padding-top: 65px;
  }
  @media ${size['laptop-max']} {
  padding: 10px 0;
  }
  .horizontal-ruler{
    position: absolute;
    width: 99.9%;
    top: 150px;
    left: 0;
    border: 1px solid #DFDFDF;
  @media ${size['laptop-max']} {
    top: 50px;
  }
  }
  .box-shadow {
    box-shadow: 0px 71.1458px 56.9167px rgba(0,0,0,0.07), 0px 29.723px 23.7784px rgba(0,0,0,0.040);
    min-height: 83vh;
  }
  .table-list {
      position: relative;
      .job-board-table-content-block {
        position: absolute;
        width: 100%;
        background: transparent;
        z-index: 1;
        opacity: 0.1;
      }
      &.auto-height{
      height: calc(100vh - 222px);
      overflow-x: hidden;
      overflow-y: auto;
      width: 53%;
      ${customScroller()};
      &.is-mapOpen{
        width: 39%;
      }
      /* @media ${size['desktop-md-max']} {
        width: 56.5%;
      }
      @media ${size['desktop-mm-max']} {
        width: 59%;
      }
      @media (max-width: 1300px) {
        width: 55%;
      } */
    }
    &.history {
      width: 62%;
    }
    .shifts-list{
      .ant-collapse-header{
        cursor: pointer !important;
      }
    }
    }
    .job-tracker-wrapper{
      .image-wrapper {
        height: calc(100vh - 20px);
          margin-top: -25px;
        @media ${size['desktop-md-max']} {
          margin-top: 0;
        }
        &.public {
          height: calc(103vh);
        }
      }
    }
  .invoice-wrapper {
    &.full-width{
      width: 60%;
      right: 0;
      /* @media ${size['desktop-md-max']} {
      right: 27px;
      }
      @media ${size['desktop-mm-max']} {
        width: 600px;
      }
      @media ${size['desktop-sm-max']} {
        width: 530px;
      }
      @media ${size['laptop-md-max']} {
        width: 540px;
      } */
      .data-col{
        overflow: none;
        height: calc(100vh - 200px);
        overflow-y: auto;
        ${customScroller()};
      }
      .map-col {
        height: calc(100vh - 200px);
      }
    }
    &.fixed {
      padding-bottom : 0 !important;
    }
    &.history {
      width: 38.5%;
    }
    position: absolute;
    z-index: 20;
    background: #fff;
    width: 47%;
    right: 0;
    margin-top: 41px;
    height: calc(100vh - 163px);
    padding: 40px;
    padding-top: 40px !important;
    overflow-y: auto;
    font-weight: 600;
    font-weight: 15px;
    color: #323232;
    ${customScroller()};
    box-shadow: 10px 10px 25px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    &.job-tracker{
    height: calc(100vh);
      position: relative;
      width: 100%;
      margin:0;
      padding:0 !important;
        margin-top: -25px;
      @media ${size['desktop-md-max']} {
        margin-top: 0;
      }
      .Page-title {
        display: flex;
      .close-btn {
        width: 16px;
        height: 20px;
        right:2%;
        background-color: transparent;
        background-image: url(${closeIconWhite});
        background-size: 100% auto;
      }
      }
      .tracking-data{
        padding: 40px;
        padding-top: 0;
        @media ${size['desktop-md-max']}{
          padding: 30px;
          padding-top:0;
        }
      }
      p {
        font-family: ${(props) => props.theme.secondaryFont};
        font-weight: normal;
        font-size: 15px;
        /* width: fit-content; */
        line-height: 20px;
        color: #323232;
        span{
        font-weight: 600;
        }
      }
    .parcel-data-div{
      font-weight: 600;
      font-size: 14px;
      text-transform: uppercase;
      color: #816E9B;
    }
    }
    .close-btn {
    background-size: 60% auto;
    background-image: url(${closeIcon});
    background-repeat: no-repeat;
    background-color: rgb(255, 255, 255);
    background-position: center;
    padding: 0px;
    position: absolute;
    cursor: pointer;
    height: 40px;
    width: 40px;
    top: 10px;
    right: 0;
    }
    .invoice-row{
      display: flex;
      padding-bottom: 10px;
      .footer {
        position: sticky !important;
        background: #fff;
        z-index: 1;
        bottom: 0;
        padding: 10px 0;
      }
    }
    .parcel-redirect-col{
      margin: auto;
    }
    .parcel-share-div{
      display: block;
      p {
        display: inline-block;
      }
    }
    .ellipsis-70 {
      white-space: nowrap; 
      width: 70%;
      margin-bottom: 0; 
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .track-link {
      text-decoration: underline;
      cursor: pointer;
      float: right;
    }
    .redirect-parcels
    {
      float: right;
      margin-left: 10px;
      margin-top: 4px;
      margin-bottom: auto; 
      cursor: pointer;
      width: auto;
    }
    p {
      margin-bottom: 5px;
    }
    .address{
      color: #816E9B;
    }
    svg{
      width: auto;
      height: 15px;
    }
   
    .ant-row-flex {
      &.job-board-orange-row {
        position: relative;
        &::after{
          content: '';
            position: absolute;
            left: 0;
            top: 50%;
            right: 0;
            height: 2px;
            background: #FFA100;
            z-index: 0;
        }
      }
      &.job-board-pickup-row {
        position: relative;
        &::after {
            content: '';
            position: absolute;
            left: 0;
            top: 50%;
            right: 0;
            height: 2px;
            background: #00C68D;
            z-index: 0;
          }
      }
      &.job-board-dropoff-row {
        position: relative;
        &::after {
            content: '';
            position: absolute;
            left: 0;
            top: 50%;
            right: 0;
            height: 2px;
            background: #E8286B;
            z-index: 0;
          }

      }
     
      .ant-col {
        .d-flex.fill-wrap{
          margin: 0;
          float: left;
          
        }
        & + .ant-col {
          /* margin: auto; */
          span {
            float: right;
            margin-left: 10px;
            border-bottom: 1px solid #D1D1D1;
          }
        }
      }
    }
    .footer {
        position: sticky !important;
        background: #fff;
        z-index: 1;
        bottom: 0;
        padding: 10px 0;
      }
    .data-div{
      padding: 25px 0;
      .sub-delivery-price {
        float:right;
        margin-top: -20px;
      }
      .address-price {
        float: right;
        margin-top: -4.5%;
      }
      hr {
        margin-top: 20px;
        border-top: 0.711458px solid #D1D1D1;
      }
    }

    .parcel-data-div{
      padding-top: 15px;
      span {
        border-bottom: none !important;
      }
      &.job-board-total-price {
        font-weight: 700;
      }
      .address-price {
        float: right;
        margin-top: -4.5%;
      }
      hr {
        margin-top: 20px;
        border-top: 0.711458px solid #D1D1D1;
      }
    }
    .parcels-div{
      display: flex;
      p {
        display: flex;
        div {
          margin-left: 6px;
        }
      }
    }
    .map-switch {
      display: flex;
      margin-top: -25px;
      float: right;
      span{
        color: #323232;
        font-weight: 600;
      }
      button.ant-switch{
        margin-left: 10px;
        @media ${size['desktop-mm-max']} {
          margin-right: 20px;
        }
      }
      button.ant-switch.ant-switch-checked{
        background-color: #8f48ff;
      }
    }
  }
    .gray-ruler{
      opacity: 0.6;
      border: 1px solid #D1D1D1;
    }
  .shifts-invoice-wrapper {
    width: 100%;
    padding: 40px;
    padding-left : 0;
    font-family: ${(props) => props.theme.secondaryFont};
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 20px;
    color: #323232;
    .ellipsis-70 {
      white-space: nowrap; 
      width: 70%;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    &.top-0{
      padding-top: 0;
    }
    .job-status-inside-parcel{
      color: #00C68D;
    }
    .star-label {
      font-family: 'Montserrat';
      font-weight: 600;
      font-size: 12px;
      line-height: 15px;
      letter-spacing: 0.06em;
      height: 30px;
      position: relative;
      &::after{
        content: '';
        background: #8f48ff;
        height: 4px;
        bottom: 0;
        border-radius: 30px;
        -webkit-border-radius: 30px;
        -moz-border-radius: 30px;
        -webkit-transition: all 0.4s ease;
        transition: all 0.4s ease;
        -webkit-transition: all 0.4s ease;
        -moz-transition: all 0.4s ease;
        z-index: 2;
        position: absolute;
        right: 0;
        left: 0;
        width: 30%;
      }
        &.public{
          &::after{
            width: 44%;
          }
        }
    }
    &.loader{
    padding-right: 0;
    .main-loader{
      display: block;
      margin: auto;
        svg {
          height: auto;
          width: auto;
        }
    }
    .loading-text{
      display: block;
      text-align:center;
      padding-top: 30px;
    }
    }
    .comment-label{
      font-family: 'Montserrat' !important;
      font-weight: 600 !important;
    }
    .rating-wrapper{
      li.ant-rate-star.ant-rate-star-zero{
        background: url(${emptyStar}) no-repeat center;
        background-size: 100% auto;
        width: 18px;
        height: 20px;
      .ant-rate-star-second {
         display: none;
      } 
      }
      li.ant-rate-star.ant-rate-star-full{
        color: #8f48ff;
      }
      i.anticon.anticon-star{
        vertical-align: top;
      }
      li.ant-rate-star.ant-rate-star-half{
        color: #8f48ff;
      }
      span{
        margin-right: 10px;
      }
      svg {
        width: auto;
        height: 20px;
      }
    }
    p{
      margin: 10px 10px 10px 0 !important;
      hr {
      border: 1px solid #DFDFDF;
      }
    }
    p.mr-btm-0 {
      margin-bottom: 0 !important;
    }
    &.full-width{
      width: 100%;
      padding-right: 0;
    }
    .address-count{
      position: absolute;
      left: -30px;
      margin: 0 !important;
      &.delivery{
        color: #E8286B;
      }
      &.pickup{
        color: #00C68D;
      }
    }
    .dots-icon{
      height: 27px;
      margin-left: 6px;
    }
    .right-space {
      margin-right: 20px;
    }
    .cs-pointer{
      cursor: pointer;
        margin: 0 !important;
        padding: 10px 0;
      &:hover {
        background:#F7F7F7;
        .toggle-arrow{
          opacity: 1;
        }
      }
      .toggle-arrow {
        height: 20px;
        opacity: 0;
        float: right;
        width: 30px;
        margin: 0 auto;
        background: url(${dropdownArrowIcon}) no-repeat center;
        background-size: 8px auto;
        transition: all 0.4s ease;
      }
    }
    .fl-rght{
      float:right;
      font-weight:normal !important;
    }
    .shifts-invoice-wrapper{
      padding: 10px 0;
    }
    .job-status {
      color: #00C68D;
    }
    .parcel-data {
      vertical-align: top;
      font-weight: 400 !important;
    }
    textarea.ant-input{
      height: 200px;
      min-height: 200px;
      max-height: 200px;
    }
    .warning-icon {
    bottom: 0;
    left: 15px;
    width: 16px;
    height: 18px;
    background: url(${warningIcon}) no-repeat center;
    background-size: 100% auto;
    display: inline-flex;
    z-index: 1;
    margin-right: 10px;
  }
  .parcel-icon{
    background: url(${customSizeIcon}) no-repeat center;
    background-size: 100% auto;
    width: 18px;
    height: 20px;
    margin-right: 10px;
    display: inline-flex;
  }
  .cube {
    background: url(${parcelIcon1}) no-repeat center;
    background-size: 100% auto;
    width: 18px;
    margin-right: 10px;
    height: 20px;
    display: inline-flex;
  }
  .weight-machine-icon {
    background: url(${weightmachineIcon}) no-repeat center;
    background-size: 100% auto;
    width: 18px;
    margin-right: 10px;
    height: 20px;
    display: inline-flex;
  }
  .insurance-icon {
    background: url(${insuranceIcon}) no-repeat center;
    background-size: 100% auto;
    width: 18px;
    margin-right: 10px;
    height: 20px;
    display: inline-flex;
  }
  .hash-icon {
    background: url(${refrenceIcon}) no-repeat center;
    background-size: 100% auto;
    width: 18px;
    margin-right: 10px;
    height: 20px;
    display: inline-flex;
  }
  .ant-collapse-item.ant-collapse-item-active{
    .toggle-arrow {
        opacity: 1;
        transform: rotate(180deg);
    }
    .copy-clipboard-icon {
        height: 20px;
        float: right;
        width: 30px;
        margin: 0 auto;
        background: url(${copyIcon}) no-repeat center;
        background-size: 20px auto;
        transition: all 0.4s ease;
        &:hover{
          cursor: pointer;
        }
    }
  }
  .ant-collapse-content-box {
    padding: 0 16px;
  }
  }
}

.profile {
  padding-top: 50px;
  padding-bottom: 30px;
  @media ${size['laptop-max']} {
    padding: 10px 0;
  }
  .horizontal-ruler{
    position: absolute;
    width: 99.9%;
    top: 96px;
    left: 0;
    border: 1px solid #DFDFDF;
  }
}
.payment-personal-page {
  padding-top: 90px;
  padding-bottom: 10px;
  @media ${size['laptop-max']} {
    padding: 10px 0;
  }
}
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0; 
}

/* Google Place Input Style */
.placeInput {
  width: 100%;
  margin: 0;
  -webkit-appearance: none;
  background: #fff;
  // border: 0.1rem solid ${(props) => props.theme.blackColor};
  border: 0.1rem solid #cecece;
 ${borderRadius('0px')};
  height: 40px;
  // font-size: 16px;
  font-weight: 300;
  padding: 10px 30px;
  color: ${(props) => props.theme.primaryColor};
  font-family: ${(props) => props.theme.secondaryFont}, sans-serif;
  @media ${size['desktop-sm-max']} {
    font-size: 16px;
    height: 38px;
    padding: 10px 14px;
  }
  @media ${size['laptop-max']} {
    height: 36px;
    font-size: 14px;
  }
}

.autocomplete-dropdown-container {
  overflow: auto;
  max-height: 200px;
  width: 100%;
  position: absolute;
  z-index: 10;
  box-shadow: 10px 10px 25px rgba(0, 0, 0, 0.1);
  display: none;
  &.active {
    display: block;
  }
  .suggestion-item--active {
    padding: 15px 20px;
    font-size: 16px;
    background: #FAFAFA;
    cursor: pointer;
    color: ${(props) => props.theme.primaryColor};
    font-weight: bold;
    @media ${size['desktop-sm-max']} {
      font-size: 14px;
    }
  }
  .suggestion-item {
    padding: 15px 20px;
    color: #818181;
    font-size: 16px;
    background-color: #ffffff;
    cursor: pointer;
    @media ${size['desktop-sm-max']} {
      font-size: 14px;
    }
  }
  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  &::-webkit-scrollbar-track {
    box-shadow: none;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background: #8f48ff;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #8f48ff;
  }
}

.custom-search-dropdown {
  padding: 0;
  position: absolute;
  width: 100%;
  margin: 0;
  max-height: 223px;
  z-index: 5;
  overflow: auto;
  box-shadow: 10px 10px 25px rgba(0, 0, 0, 0.3);
  border-top: 0;
  background-color: #ffffff;
  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  ::-webkit-scrollbar-track {
    box-shadow: none;
    ${borderRadius('10px')};
  }
  ::-webkit-scrollbar-thumb {
    background: #8f48ff;
    ${borderRadius('10px')};
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #8f48ff;
  }
  ul.ant-select-dropdown-menu {
    max-height: initial;
    overflow: hidden;
  }
  .list, .ant-select-dropdown-menu-item {
    font-family: ${(props) => props.theme.secondaryFont}, sans-serif;
    padding: 10px 25px;
    // margin-top: 10px;
    list-style: none;
    font-weight: normal;
    font-size: 16px;
    transition: none !important;
    line-height: 20px;
    color: #818181;
    font-weight: 400;
    -webkit-flex: flex;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    ${transition(0.4)};
    position: relative;
    :hover {
      background: #fafafa;
      font-weight: 700 !important;
    }

     @media ${size['desktop-sm-max']} {
      white-space: normal;
      font-size: 15px;
    }
    a {
      // FIXME - Need to clean up
        color: #818181;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;  
        i.star-icon {
        position: absolute;
        top: 50%;
        bottom: 0;
        right: 50px;
        transform: translate(0, -50%);
        -webkit-transform: translate(0, -50%);
        -moz-transform: translate(0, -50%);
        -ms-transform: translate(0, -50%);
        background-color: transparent;
        background-size: 100% auto;
        margin-left: auto;
        width: 24px;
        height: 24px;
        background-color: transparent;
        @media ${size['desktop-mm-max']} {
          width: 22px;
          height: 22px;
        }
        @media ${size['laptop-max']} {
          width: 20px;
          height: 20px;
        }
        @media ${size['tablet-md-max']} {
          right: 30px;
        }
        @media ${size['mobile-md-max']} {
          right: 20px;
        }
      }
      span {
        font-weight: 500;
      }
      .light {
        color: #818181;
        font-weight: 400;
      }
    }
    .user-icon {
      // margin-left: auto;
      position: absolute;
      font-size: 22px;
      right: 24px;
      @media ${size['mobile-sm-max']} {
        right: 5px;
      }
    }
  }
  li.dark-active {
    a {
      font-family: ${(props) => props.theme.secondaryFont}, sans-serif;
      font-weight: 700;
      color: #444444;
      i.star-icon {
        background: url(${starDarkIcon}) no-repeat center;
        background-size: 100% auto;
        background-color: transparent;
      }
    }
  }
  .ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled) {
    background-color: #FAFAFA;
  }
  .ant-select-dropdown-menu-item.dark-active {
    font-family: ${(props) => props.theme.secondaryFont}, sans-serif;
    font-weight: 500;
    color: #444444;
    i.star-icon {
      position: absolute;
      right: 24px;
      width: 24px;
      height: 24px;
      margin-left: auto;
      background: url(${starDarkIcon}) no-repeat center;
      background-size: 100% auto;
      background-color: transparent;
    @media ${size['mobile-sm-max']} {
      right: 5px
    }
    }
  }
}
.phone-country-code {
  font-family: ${(props) => props.theme.secondaryFont};
    position: absolute;
    z-index: 2;
    top: 29px;
    color: #49465d;
    font-size: 15px;
    font-weight: 600;
    line-height: 22px;
    @media ${size['desktop-sm-max']} {
      top: 26px;
      font-size: 14px;
    }
  }
  
.phone-field {
  background: #fff;
  ${borderRadius('0px')};
 
  .country-code {
    display: block;
    width: 100%;
    background: #fff;
    line-height: 50px;
    position: relative;
    cursor: pointer;
    height: 50px;
    ${borderRadius('0px')};
    border-bottom: 0.1rem solid #cecece !important;
    width: 50px;
    @media ${size['desktop-sm-max']} {
      height: 38px;
      line-height: 38px;
    }
    @media ${size['laptop-max']} {
      height: 38px;
      line-height: 38px;
    }
    .form-dropdown {
      width: 100% !important;
      height: 40px;
      @media ${size['desktop-mm-max']} {
        height: 38px;
        line-height: 38px;
      }
      @media ${size['tablet-md-max']} {
      height: 38px;
      line-height: 38px;
      }
      .anticon {
        display: none;
      }
      a.ant-dropdown-link.ant-dropdown-trigger {
        height: 40px;
        line-height: 50px;
        font-size: 16px;
        // padding: 0 15px;
        font-weight: 300;
        @media ${size['desktop-sm-max']} {
          // height: 42px;
          // line-height: 35px;
          font-size: 16px;
          // padding: 0 30px 0 20px;
        }
        @media ${size['desktop-mm-min']} {
          // line-height: 42px;
        }
      }
    }
  }
  input.phone-input {
    border-left: 0;
  }
}

.custom-primary-modal {
  button.ant-modal-close{
    span.ant-modal-close-x{
      color: #fff;
      height: 45px;
      line-height: 45px;
    }
  }
  .ant-modal-header {
    text-transform: uppercase;
    background: #8f48ff;
    min-height: 41px;
    height: 41px;
    padding: 12px 20px 12px;
    .ant-modal-title{
      text-transform: uppercase;
      font-family: 'Montserrat',sans-serif;
      font-weight: 500;
      color: #fff;
    }
  }
}

.modal-custom-error {
  .ant-modal-body {
    padding:0;
    .anticon.anticon-close-circle {
      display: none;
    }
    .ant-modal-confirm-title {
      text-transform: uppercase;
      font-family: ${(props) => props.theme.primaryFont}, sans-serif;
      font-weight:500;
      padding: 10px 15px;
      background: #E82868;
      color: #fff;
      border-radius: 4px 4px 0 0;
    }
    .ant-modal-confirm-content {
      margin-left: 0;
      padding: 10px 15px;
      font-size: 15px;
      color: ${(props) => props.theme.primaryColor};
    }
    .ant-modal-confirm-btns {
      padding: 10px 15px;
    }
    .ant-btn {
      text-transform: uppercase;
      width: 130px;
      letter-spacing: 1px;
      height: 34px;
      font-weight: 600;
      font-family: ${(props) => props.theme.primaryFont}, sans-serif;
      font-size: 12px;
      color: #fff;
      border: 1px solid ${(props) => props.theme.primaryButtonColor};
      background-color: ${(props) => props.theme.primaryButtonColor};
      ${borderRadius('6')};
      :hover {
        background: #fff;
        color: ${(props) => props.theme.primaryButtonColor};
        border: 1px solid ${(props) => props.theme.primaryButtonColor};
        .arrow-btn {
          path {
            stroke: ${(props) => props.theme.primaryButtonColor};
          }
        }
      }
    }
  }
}

.modal-alert {
  &.jobtype-changes{
    width: 800px !important;
  }
  i.anticon.anticon-question-circle{
    display: none;
  }
  button.ant-btn{
    background-color: ${(props) => props.theme.redShadeColor1} !important;
    border: 1px solid ${(props) => props.theme.redShadeColor1} !important;
    &:hover{
      background-color: #fff !important;
      color: ${(props) => props.theme.redShadeColor1} !important;
      border: 1px solid ${(props) => props.theme.redShadeColor1} !important;
    }
  }
  button.ant-btn.ant-btn-primary {
    border: 1px solid ${(props) => props.theme.primaryButtonColor} !important;
    background-color: ${(props) => props.theme.primaryButtonColor} !important;
    &:hover{
      background-color: #fff !important;
      color: ${(props) => props.theme.primaryButtonColor} !important;
      border: 1px solid ${(props) => props.theme.primaryButtonColor} !important;
    }
  }
  
  .ant-modal-body {
    padding:0;
    .anticon.anticon-close-circle {
      display: none;
    }
    .ant-modal-confirm-title {
      text-transform: uppercase;
      font-family: ${(props) => props.theme.primaryFont}, sans-serif;
      font-weight:500;
      padding: 10px 15px;
      background: #8f48ff;
      color: #fff;
      border-radius: 4px 4px 0 0;
    }
    .ant-modal-confirm-content {
      margin-left: 0 !important;
      padding: 10px 15px;
      font-size: 15px;
      color: ${(props) => props.theme.primaryColor};
    }
    .ant-modal-confirm-btns {
      padding: 10px 15px;
    }
    .ant-btn {
      text-transform: uppercase;
      width: 130px;
      height: 34px;
      letter-spacing: 1px;
      color: #fff;
      font-size: 12px;
      font-weight: 600;
      border: 1px solid ${(props) => props.theme.primaryButtonColor};
      background-color: ${(props) => props.theme.primaryButtonColor};
      ${borderRadius('6')};
      :hover {
        background: #fff;
        color: ${(props) => props.theme.primaryButtonColor};
        border: 1px solid ${(props) => props.theme.primaryButtonColor};
        .arrow-btn {
          path {
            stroke: ${(props) => props.theme.primaryButtonColor};
          }
        }
      }
    }
  }
  &.payments{
    .ant-modal-body {
      padding:0;
      .ant-btn {
      text-transform: uppercase;
      width: 130px;
      letter-spacing: 1px;
        height: 34px;
        font-size: 12px;
        font-weight: 600;
        background: #fff !important;
        color: black !important;
        stroke: black !important;
        border-radius: 4.26875px !important;
        border: 0.711458px solid rgba(223,223,223,0.75) !important;
        &:hover{
          border: 1px solid #E8286B !important;
        }
      }
      .ant-btn.ant-btn-primary
      {
        color: #fff !important;
        background-color: ${(props) => props.theme.primaryButtonColor} !important;
        border: 1px solid ${(props) => props.theme.primaryButtonColor};
        :hover {
          background: #fff !important;
          color: ${(props) => props.theme.primaryButtonColor} !important;
          border: 1px solid ${(props) => props.theme.primaryButtonColor} !important;
          .arrow-btn {
            path {
              stroke: ${(props) => props.theme.primaryButtonColor};
            }
          }
        }
      }
    }
  }
}
.font-weight-low {
  font-weight: 400 !important;
}

/* Spinner style */
.ant-spin.ant-spin-spinning.spinner-custom {
  position: fixed;
  z-index: 500;
  max-height: initial;
  width: 100vw;
  height: 100vh;

  .main-spin-loader-custom-wrapper.ant-spin-dot {
    position: relative;
    top: 50%;
    left: 0%;
  }
}

.loader-custom {
  position: relative;
  width: 65px;
  height: 65px;
  /* top:45%;
  
  left: 15px; */
  display:inline-block;
  padding:0px;
  border-radius:100%;
  border:5px solid;
  border-top-color:${(props) => props.theme.secondaryColor};
  border-bottom-color:${(props) => props.theme.secondaryColor};
  border-left-color:${(props) => props.theme.secondaryColor};
  border-right-color:rgba(255,255,255, 0.3);
  -webkit-animation: ${loader} 1s ease-in-out infinite;
  animation: ${loader} 1s ease-in-out infinite;
}
.spinner-custom svg {
  position: relative;
  top: -56px;
  left: 59px;
  transform: translate(-135%, -28%);
  width: 32px;
  height: 32px;
}


.ant-spin-nested-loading {
  .ant-spin-dot {
    .ant-spin-dot-item {
      background-color: #8f48ff;
    }
  }
}

/* Select drowpdown Style */
.dropdown-custom-booking {
    background: ${(props) => props.theme.backgroundColor};
    color: ${(props) => props.theme.primaryColor};
    &.size-custom-select-wrapper {
      
    }
    ul {
      max-height: initial;
      padding: 5px 10px;
      &::-webkit-scrollbar {
        width: 0.5em;
      }
      &::-webkit-scrollbar-thumb {
        height: 88px;
        width: 8px;
        border-radius: 10px;
        background: #ffa100;
      }
      li {
        margin: 0;
        padding: 10px;
        position: relative;
        text-align: left;
        font-size: 14px;
        color: #fff;
        line-height: 14px;
        cursor: pointer;
        margin: 0;
        border-radius: 5px;
        color: ${(props) => props.theme.selectFontColor};
        @media ${size['desktop-sm-max']} {
          padding: 5px 6px;
        }
        .dropdown-item {
          h5.size-name {
            font-size: 14px;
            margin-bottom: 0;
          }
          h5.custom-size {
            font-size: 14px;
            margin-bottom: 0;
          }
          .size-name-text {
            width: 100%;
            text-align: end;
            font-size: 16px;
          }
        }
        &.ant-select-dropdown-menu-item-selected {
          background-color: rgba(255, 161, 0, 0.68);
          // opacity: 0.5;
          margin-bottom: 3px;
          margin-top: 3px;
          color: #fff;
          font-weight: 500;
          .anticon svg {
            display: inline-block;
          }
        }
        &.ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled) {
          background-color: #ffa100;
          color: #fff;
        }
        &.ant-select-dropdown-menu-item:hover:not(.ant-select-dropdown-menu-item-disabled) {
          background-color: #ffa100;
          color: #fff;
        }
        .anticon svg {
          display: none;
        }
      }
    }
  }

/* Notification style */
.ant-notification {
  .ant-notification-notice {
    background: #8f48ff;
    .ant-notification-notice-with-icon {
      .ant-notification-notice-icon {
        top: 18px;
        font-size: 30px;
        color: #fff;
      }
      .ant-notification-notice-message {
        color: #fff;
        font-weight: 500;
      }
      .ant-notification-notice-description {
        color: #fff;
      }
    }
    .ant-notification-notice-close {
      color: #fff;
    }
  }
}

.ant-modal-root {
  .ant-modal-mask {
    z-index: 10000;
  }
  .ant-modal-wrap {
      z-index: 10001;
    }
}
.no-data-div{
  align-items: center;
  height: 20vh;
  position: relative;
  p{
    font-family: 'OpenSans';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 17px;
    color: #323232;
    mix-blend-mode: normal;
  }
  button{
    top: 23%;
    position: absolute;
    right: 0;
  }
}

/* Small Button Style  */
.btn-small {
  position: relative;
  bottom: 5px;
  height: 50px;
  line-height: 46px;
  padding: 0 15px;
  @media ${size['desktop-mm-max']} {
    bottom: 0px;
    min-width: 130px;
    height: 44px;
    line-height: 44px;
    font-size: 14px;
  }
}
.custom-tooltip {
  margin-left: auto;
  display: inline-block;
  vertical-align: middle;
  position: relative;
}
.info-icon-white {
    width: 13px;
    height: 13px;
    display: inline-block;
    background: url(${infoIconWhite}) no-repeat center;
    background-size: 100% auto;
    vertical-align: middle;
}
.info-icon {
    width: 13px;
    height: 13px;
    display: inline-block;
    background: url(${infoIconPurple}) no-repeat center;
    background-size: 100% auto;
    margin-left: auto;
    &.service-tips{
      margin-left: 10px !important;
    }
}

/* Alert Style */

.custom-alert-sucess {
  font-family: ${(props) => props.theme.primaryFont}, sans-serif;
  background-color: #8f48ff;
  border: 1px solid #8f48ff;
  margin-bottom: 3px;
  @media ${size['mobile-md-max']} {
    margin-top: 0px;
    display: flex;
    width: 60%;
    left: -3px;
    overflow: hidden;
    position: absolute;
    top: 112px;
  }
  .ant-alert-message {
    color: white;
  }
  .ant-alert-icon {
    color: white;
  }
}

#booking-next-day-parcel-form {
  .booking-parcel-form-wrapper {
    .ant-card-body {
      // max-height: calc(100vh - 520px);
      // overflow-y: hidden;
      ${customScroller()};
    }
    .parcel-form-group {
      margin-top: 0;
    }
    .parcel--optional-form-grp {
      margin-top: 30px;
      @media ${size['desktop-mm-max']} {
        margin-top: 15px;
      }
    }
    .bookingparcel .form-group.shift .select-parcel-wrapper .select-custom.teams {
      padding-left: 27px !important;
    }
    .shift .select-custom .ant-select-selection:after {
      top: 26px;
    }
  }
  .booking-pacel-address-select-card-wrapper {
    margin-top: 25px;
    .ant-card-body {
      padding: 20px 30px 20px 30px;
    }
    .location-icon {
      top: 4px;
      width: 24px;
    }
  }
  position: relative;
  .selected-address-parcel-wrap {
    font-weight: 600;
    cursor: pointer;
    background: #fff;
    ${borderRadius('0px')};
    border-bottom: 0.1rem solid #cecece;
    color: ${(props) => props.theme.primaryColor};
    font-family: ${(props) => props.theme.secondaryFont}, sans-serif;
    height: 50px;
    font-size: 15px;
    padding: 10px 36px;
    transition: all 0.15s ease-in-out;
    line-height: 2;
    display: block;
    width: 100%;
    margin: 0;
    padding-right: 26px;
    &:after {
      content: '';
      background: url(${droparrowIcon}) no-repeat center;
      width: 9px;
      height: 8px;
      top: 10px;
      background-size: 100% auto;
      position: absolute;
      border: 0;
      display: inline-block;
      vertical-align: middle;
      float: right;
      position: relative;
      font-family: 'Line Awesome Free';
      font-weight: 900;
      left: 16px;
    }
    @media ${size['mobile-sm-max']} {
      padding-right: 0;
    }
  }
  .selected-address-parcel-wrap::focus {
    border-bottom: 0.1rem solid #8f48ff;
  }
  .focus {
    border-bottom: 0.1rem solid #8f48ff;
  }
  .location-title {
    padding: 0px 40px 0px 40px;
    font-size: 13px;
    letter-spacing: 0.05em;
    font-weight: 500;
    color: #49465d;
    position: relative;
    margin: 27px 0;
    .title {
      font-size: 15px;
      padding-top: 5px;
    }
  }
  .ant-dropdown.ant-dropdown-placement-bottomCenter {
    width: 100%;
    min-width: 1px !important;
  }
  label.add-all-address-parcel-wrapper-checkbox.ant-checkbox-wrapper {
    display: flex !important;
    align-items: center;
  }
  span {
    max-width: 100% !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
  }
  span.ant-checkbox {
    overflow: visible !important;
  }
  .ant-dropdown {
    background: #fff;
    box-shadow: 10px 10px 25px rgba(0, 0, 0, 0.1);
    ${borderRadius('10px')};
    border: 0;
    padding: 20px;
    margin: 0;
    right: 0;
      width: 100%;
    .ant-dropdown-menu {
      -webkit-box-shadow: 0 !important;
      box-shadow: 0 !important;
    }
    .d-title {
      display: block;
      font-weight: 600;
      font-size: 15px;
      line-height: 14px;
      color: ${(props) => props.theme.primaryColor};
    }
    .add-all-address-parcel-wrapper-checkbox {
      width: 100%;
      .ant-checkbox-inner {
        width: 20px;
        height: 20px;
      }
      span:not(.ant-checkbox) {
        font-size: 15px;
        font-weight: 600;
        max-width: 100%;
        text-overflow: ellipsis;
        white-space: nowrap;
        // padding-right: 10px;
        // padding-left: 10px;
        color: ${(props) => props.theme.primaryColor};
        .dark-active{
          padding: 0 10px 0 5px;
        }
      }
    }
    ul {
      padding: 0;
      max-height: 188px;
      overflow: auto;
      ${customScroller()};
      .ant-checkbox-group {
        width: 100%;
        flex-direction: column;
        .ant-checkbox-wrapper {
          display: flex;
          align-items: center;
          justify-content: end;
          .ant-checkbox-input {
            padding: 0 !important;
          }
          .ant-checkbox-inner {
            width: 20px;
            height: 20px;
          }
          &:hover {
            background: #fafafa;
            font-weight: 700 !important;
          }
        }

        .ant-checkbox-wrapper + .ant-checkbox-wrapper {
          margin-left: 0px;
        }
      }
      .list {
        padding: 13px 10px 13px 5px;
        margin: 0;
        list-style: none;
        font-weight: normal;
        font-size: 18px;
        line-height: 20px;
        color: #818181;
        font-weight: 400;
        -webkit-flex: flex;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        transition: all 0.4s ease;
        -webkit-transition: all 0.4s ease;
        -moz-transition: all 0.4s ease;
        position: relative;
        :hover {
          background: #fafafa;
          font-weight: 700 !important;
        }
        @media ${size['tablet-max']} {
          padding: 12px 12px;
        }
        a {
          color: #818181;
          display: flex;
          justify-content: space-between;
          @media ${size['tablet-max']} {
            width: 100%;
            .ant-radio-wrapper .ant-radio-inner {
              top: 0px !important;
              input {
                padding-left: 0px !important;
              }
            }
          }
          &.dropdown-item {
            width: 100%;
            & > span {
              font-size: 15px;
              font-weight: 400;
              max-width: 100%;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              padding-right: 10px;
              font-family: ${(props) => props.theme.secondaryFont};
              color: ${(props) => props.theme.primaryColor};
            }
          }
          i.star-icon {
            position: absolute;
            top: 50%;
            bottom: 0;
            right: 50px;
            transform: translate(0, -50%);
            -webkit-transform: translate(0, -50%);
            -moz-transform: translate(0, -50%);
            -ms-transform: translate(0, -50%);
            background: #fff url(${starIcon}) no-repeat center;
            background-size: 100% auto;
            margin-left: auto;
            width: 24px;
            height: 24px;
          }
          .light {
            color: #555555;
            font-weight: 400;
          }
        }
        &.not-selectable {
          display: inline-flex;
          justify-content: end;
          width: 100%;
          a.dropdown-item {
            width: initial;
          }
        }
      }
      li.dark-active {
        a {
          color: #444444;
          i.star-icon {
            background: url(${starDarkIcon}) no-repeat center;
            background-size: 100% auto;
          }
        }
      }
    }
  }
  .ant-dropdown-menu-item:hover,
  .ant-dropdown-menu-submenu-title:hover {
    background-color: #fafafa;
  }
  .dropinputDiv {
    margin: 15px 0;
    .line {
      left: 9%;
      width: 440px;
      @media ${size['tablet-max']} {
        display: none;
      }
    }
  }
}

.address-accordian {
  border: none !important;
  background-color : #fff !important;
    z-index: 9 !important;
  .ant-collapse-item.ant-collapse-no-arrow{
    border: none !important;
  }
  .ant-collapse.ant-collapse-icon-position-left.address-accordian{
    background: #fff;
    z-index: 9 !important;
  }
            .two-btns {
              padding: 10px 0;
              @media ${size['desktop-mm-max']} {
                padding: 10px 10px;
              }
              .white-btn {
                font-family: Montserrat;
                background: #fff;
                border-radius: 4.26868px;
                border: 1px solid #D1D1D1;
                // box-shadow: 0px 2px 10px rgba(0,0,0,0.15);
                font-weight: 600;
                font-size: 12px;
                color: #323232;
                text-transform: uppercase;
                height: 46px;
                line-height: 46px;
                display: inline-block;
                text-align: center; 
                width: 100%;
                min-width: 1px;
                &:hover{
                    border: 1px solid #00c68d;
                }
                & + .white-btn {
                  margin-left: 15px !important;
                }
                .new-edit-icon {
                  display: inline-block;
                  vertical-align: middle;
                  background: url(${editPen}) no-repeat center;
                  background-size: 100% auto;
                  width: 19px;
                  height: 19px;
                  margin: -2px 5px 0 0;
                  transition: all 0.4s ease;
                }
                .map-preview-icon {
                  display: inline-block;
                  vertical-align: middle;
                  background: url(${mapIcon}) no-repeat center;
                  background-size: 100% auto;
                  width: 22px;
                  height: 20px;
                  transition: all 0.4s ease;
                  margin: -2px 5px 0 0;
                }
              }
              .remove{
                box-shadow: none;
              }
            }
  .p-parcel-list1 {
  padding: 15px 0;
  .p-parcel-list-item1 {
    padding: 15px 0;
    position: relative;
    @media ${size['desktop-mm-max']} {
      padding: 15px 14px;
    }
    @media ${size['tablet-max']} {
    padding: 15px 22px;
  }
  }
  .active {
    background: #f1e7ff;
  }
  .p-col-1 {
    position: relative;
    padding-left: 32px;
    font-weight: normal;
    font-size: 15px;
    line-height: 18px;
    color: #323232;
    width: 50%;
    padding-right: 10px;
    span {
      width: 100%;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      padding-right: 10px;
    }
  }
  .parcel-icon1, .parcel-item-icon {
    background: url(${parcelIcon2}) no-repeat center;
    background-size: 100% auto;
    width: 18px;
    height: 29px;
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(0, -50%);
  }
  .p-col-2 {
    font-weight: normal;
    font-size: 15px;
    line-height: 21px;
    color: #595959;
    width: 30%;
    text-align: center;
    padding: 0 10px;
  }
  .p-col-3 {
    font-size: 15px;
    position: relative;
    width: 35%;
  }
  .parcels{
    width: 40% !important;
  }
  .parcel-delete-icon {
    background: url(${ParceldeleteIcon}) no-repeat center;
    background-size: 100% auto;
    width: 18px;
    height: 20px;
    display: block;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(0, -50%);
    border: 0;
    outline: none;
  }
}
  .ant-collapse-header {
    display: contents !important;
    background: #fff !important;
    border: none !important;
    .price{
          position: absolute;
          right: 0px;
          display: inline-block;
          ${transition(0.4)};
          opacity: 1;
          font-size: 16px;
          font-weight: 600;
        }
    .delivery-location-listing {
        .address-count{
          font-weight: bold;
          font-size: 14px;
          line-height: 20px;
          color: #E8286B;
          margin-right: 10px;
          font-family: 'Montserrat';
          }
        }
        .address-listing-action-icons-wrapper{
          display: flex;
          margin-left: auto;
          strong {
            text-align: right;
            font-size: 15px;
            min-width: 60px;
            width: auto;
          }
        }
        .parcel-toggle-btn{
          width: 36px;
          height: 36px;
          line-height: 36px;
          background: #EFEFEF;
          border-radius: 50%;
          -webkit-border-radius: 50%;
          -moz-border-radius: 50%;
          cursor: pointer;
          text-align: center;
          font-size: 15px;
          margin-right: 15px;
          transition: all 0.4s ease;
        }
    .pickup-location-listing {
        .address-count{
          font-weight: bold;
          font-size: 14px;
          line-height: 20px;
          color: #00c68d;
          margin-right: 10px;
          font-family: 'Montserrat';
          }
        }
        .ant-collapse-content.ant-collapse-content-active{
          border: none !important;
        }
    ul {
      margin-top: 1em !important;
      padding: 0 !important;
      li {
        display: flex !important;
        color: initial !important;
        .deliveryicon{
          margin-right: 14px;
          width: 20px;
          &.op0{
            opacity: 0;
          }
        }
        .order-receipt-list {
                margin: 0 -35px;
                padding: 0;
                li {
                  margin: 0px;
                  list-style: none;
                  position: relative;
                  font-size: 18px;
                  line-height: 20px;
                  color: #49465D;
                  font-weight: 400;
                  padding: 20px 40px 20px 80px !important;
                  transition: all 0.4s ease;
                  -webkit-transition: all 0.4s ease;
                  -moz-transition: all 0.4s ease;
                  cursor: pointer;
                  -webkit-flex: flex;
                  display: flex;
                  align-items: center;
                  flex-direction: column;
                  min-height: 70px;
                  justify-content: center;
                  @media ${size['desktop-mm-max']}{
                      min-height: 60px;
                      font-weight: 500;
                  }
                  @media ${size['tablet-max']}{
                      min-height: 46px;
                  }                  
                  &:hover .toggle-icon-main {
                    opacity: 1 !important;
                  }
                  .ant-collapse{
                    border: 0;
                    width: 100%;
                    background-color: transparent;
                  }
                  .ant-collapse-content {
                    background-color: transparent;
                    border-top: 0;
                  }
                  .ant-collapse > .ant-collapse-item {
                    border-bottom: 0;
                  }
                  .ant-collapse-content > .ant-collapse-content-box {
                    padding: 0px;
                  }
                  .ant-collapse > .ant-collapse-item > .ant-collapse-header {                    
                    padding: 0;
                  }  
                  .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow svg{
                    display:none;
                  }
                  .ant-collapse-item.ant-collapse-item-active{
                    .toggle-icon-main{
                      transform: rotate(180deg);
                    }
                  }
                  .accor-content {
                    width: 100%;
                    position: relative;
                    padding-right: 45px !important;
                    padding-left: 11px !important;
                    margin-top: 20px;
                    .delivery-time {
                      position: absolute;
                      right: 0;
                      top: 0;
                      font-weight: 500;
                      font-size: 18px;
                      line-height: 154%;
                      text-align: right;
                      color: #696969;
                      @media ${size['desktop-mm-max']} {
                        font-size: 16px;
                      }
                      @media ${size['tablet-max']} {
                        font-size: 14px;
                      }
                    }
                    p {
                      font-size: 18px;
                      line-height: 154%;
                      color: #696969;
                      margin: 0;
                      font-weight: 400;
                      @media ${size['desktop-mm-max']} {
                        font-size: 16px;
                      }
                      @media ${size['tablet-max']} {
                        font-size: 14px;
                      }
                      strong {
                        font-weight: 500;
                      }
                    }
                  }                 
                  .pickup-dropoff-main {
                    font-size: 18px;
                    line-height: 21px;
                    color: #49465D;
                    width: 100%;
                    align-items: center;
                    @media ${size['desktop-mm-max']}{
                      font-size: 16px;
                    }
                    @media ${size['tablet-max']}{
                      font-size: 14px;
                    }   
                    .price-text {
                      font-weight: 500;
                      font-size: 30px;
                      line-height: 36px;
                      color: #424242;
                      @media ${size['desktop-mm-max']} {
                        font-size: 16px;
                      }
                    } 
                    .pickup-dropoff {
                      width: 60%;
                      span {
                        display: inline-block;
                        vertical-align: middle;
                        &.vat-span {
                          font-weight: 400;
                        }
                      }
                      i {
                        display: inline-block;
                        vertical-align: middle;
                        margin: 0 15px;
                        background: url(${arrowRightLargeIcon}) no-repeat center;
                        background-size: 100% auto;
                        width: 24px;
                        height: 10px;
                      }
                    }   
                    .toggle-icon-main {
                      height: 30px;
                      width: 30px;
                      margin: 0 auto;
                      background: #DEDEDE url(${dropdownArrowIcon}) no-repeat center;
                      background-size: 8px auto;
                      border-radius: 50%;
                      -webkit-border-radius: 50%;
                      -moz-border-radius: 50%;
                      opacity: 0;
                      transition: all 0.4s ease;
                      -webkit-transition: all 0.4s ease;
                      -moz-transition: all 0.4s ease;
                      @media ${size['desktop-mm-max']} {
                        margin-right: 20px;
                      }
                    }  
                    .price-text {
                      margin-left: auto;
                      font-size: 20px;
                      color: #424242;                                          
                      @media ${size['desktop-mm-max']}{
                        font-size: 18px;
                      }     
                      @media ${size['tablet-max']}{
                        font-size: 14px;
                      }                   
                    }                              
                  }
                }
      }
    }
  } 
}
}
 .time-selector-dropdown{
   min-width: auto !important;
   ul {
     border: none;
     li {
      .ant-menu-item-group-title {
        display: none;
      }
      ul.ant-menu-item-group-list {
          border: 1px solid #9D9D9D;
          border-radius: 4px;
          width: 215px;
          button.ant-btn.time-value-btn{
            border:none;
          }
          .ant-input-number{
            border: none;
            width: 75px;
            box-shadow: none !important;
            .ant-input-number-handler-wrap{
              opacity: 1;
              border: none;
              span {
                border: none;
              }
            }
          }
          form.ant-form{
            display: flex;
            .ant-row.ant-form-item{
              margin-bottom: 0;
            }
            button.ant-btn.time-value-btn{
              border: none;
            }
          }
        }
     }
   }
 }
 .ant-tooltip {
      max-width: 300px;
      z-index: 99999;
    }
  .ant-tooltip-inner {
    background: #8f48ff;
    padding: 15px;
    .custom-tooltip-inner font {
      text-transform: none;
      font-weight: 300;
    }
    strong {
      text-transform: none;
      font-weight: 500;
    }
  }
  .hide {
    .intercom-app{
      display: none;
    }
  }

  .mr-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    margin: 0 !important;
    ul.location-listing-wrapper.mr-0{
    margin-bottom: 0 !important;
    margin-top: 0 !important;
    }
  }
  .mr-btm-0 {
    margin-bottom: 0 !important;
    ul.location-listing-wrapper.mr-0{
    margin-bottom: 0 !important;
    }
  }
  .pd-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    padding: 0 !important;
  }
  

  .rc-slider {
    pointer-events: none;
    .rc-slider-handle {
        pointer-events: auto;
        transform: translateX(0%) !important;
    }
  }
  .dt-tm-row {
    width: 100%;
    .first-col {
      padding: 0 !important;
    }
    .ant-col {
      padding-left: 10px;
      padding-top: 1px;
    }
  }
  .mainWindowSlider {
    margin: 36px 0 16px 14px;
  }
    .rc-slider-track.rc-slider-track-1 {
      background-color: #8f48ff;
    }
    .rc-slider-handle.rc-slider-handle-dragging {
      box-shadow: none;
    }
    .custom-tooltip-wrapper.custom-tooltip-wrapper-placement-top {
      top: -42px;
      padding: 16px 0 2px 0;
      position: absolute;
      visibility: visible;
      box-sizing: border-box;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    }
    .rc-slider-tooltip.rc-slider-tooltip-placement-top {
      top: -42px;
      padding: 16px 0 2px 0;
      .rc-slider-tooltip-arrow {
        bottom: -2px;
      }
    }
    .rc-slider-tooltip-inner {
      background-color: #8f48ff;
    }
    &.purple {
      .rc-slider-track.rc-slider-track-1 {
        background-color: #8f48ff;
      }
    }
    &.red {
      .rc-slider-track.rc-slider-track-1 {
        background-color: #e8286b;
      }
    }
    &.green {
      .rc-slider-track.rc-slider-track-1 {
        background-color: #00c68d;
      }
    }
    &.orange {
      .rc-slider-track.rc-slider-track-1 {
        background-color: #ffa100;
      }
    }

    .custom-tooltip-wrapper-inner {
          padding: 6px 2px;
          min-width: 24px;
          height: 24px;
          font-size: 12px;
          line-height: 1;
          text-align: center;
          text-decoration: none;
          border-radius: 6px;
          color: #fff;
          width: auto;
          left: 50%;
          position: absolute;
          transform: translateX(-50%);
          top: -5px;
          .tooltip {
            padding: 3px 2px;
            box-shadow: 0 0 4px #d9d9d9;
            border-radius: 6px;
            &.orange{
                background-color: #ffa100;
            }
            &.green {
                background-color: #00c68d;
            }
            &.red {
                background-color: #e8286b;
            }
          &.purple {
                background-color: #8f48ff;
                white-space: pre;
          }
          }
        }
        .custom-tooltip-wrapper-arrow {
        bottom: -3px;
        position: absolute;
        width: 0;
        height: 0;
        border-color: transparent;
        border-style: solid;
        left: 50%;
        margin-left: -4px;
        border-width: 4px 4px 0;
        border-top-color: #6c6c6c;
        
      }
`;

export default GlobalStyle;
