import { size } from 'App/device';
import styled from 'styled-components';
const RadioWrapper = styled.div`
  .ant-radio-group {
    &.job-wrapper {
      label + label {
        margin-top: 0 !important;
      }
      /* .tooltip-radio-wrapper {
        &:nth-child(1) {
          .type-info {
            margin-bottom: 30px;
          }
        }
        .type-info {
          margin-left: 30px;
          text-align: justify;
          span {
            font-weight: normal;
            line-height: 15px;
            color: #323232;
            font-size: 12px;
            font-family: 'Montserrat';
          }
        }
      } */
    }
    label + label {
      margin-left: 3px !important;
      span.ant-radio + * {
        padding-left: 0.5px;
      }
      &:last-child {
        margin-left: 0 !important;
        margin-top: 10px;
      }
    }
    &.custom-group {
      label {
        &:last-child {
          margin-left: 0 !important;
          margin-top: 10px;
        }
      }
    }
    .ant-radio-wrapper {
      display: inline-block;
      position: relative;
      text-align: left;
      font-size: 15px;
      color: #323232;
      line-height: 15px;
      cursor: pointer;
      padding-left: 30px;
      margin: 5px 20px 5px 0;
      @media ${size['desktop-mm-max']} {
        font-size: 14px;
        margin: 5px 15px 5px 0;
      }
      @media ${size['tablet-max']} {
        font-size: 13px;
        line-height: 14px;
        padding-left: 20px;
      }
      .ant-radio {
        position: absolute;
        top: -4px;
        left: 0;
        height: 22px;
        width: 22px;
        background: #fff;
        border: 1px solid #c6c6c6;
        border-radius: 50%;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        @media ${size['tablet-max']} {
          top: 0;
          height: 14px;
          width: 14px;
        }
        .ant-radio-input {
          color: #49465d;
          font-weight: 400;
          font-family: ${(props) => props.theme.secondaryFont}, sans-serif;
          font-size: 18px;
          background: #fff;
          border: 0;
          position: absolute;
          opacity: 0;
          left: 0;
          top: 0;
          padding: 0 !important;
          height: 100% !important;
          width: 100%;
          cursor: pointer;
          z-index: 5;
        }
      }
      span.ant-radio + * {
        padding-right: 0;
        padding-left: 0;
      }

      .ant-radio-inner {
        position: absolute;
        top: -1px;
        left: -1px;
        height: 22px;
        width: 22px;
        background: #fff;
        border: 1px solid #c6c6c6;
        border-radius: 50%;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
        @media ${size['tablet-max']} {
          top: 0;
          height: 14px;
          width: 14px;
        }
        :after {
          display: block;
          content: '';
          position: absolute;
          left: 50%;
          top: 50%;
          opacity: 0;
          width: 16px;
          height: 16px;
          background: #8f48ff;
          border-radius: 50%;
          -webkit-border-radius: 50%;
          -moz-border-radius: 50%;
          transform: translate(-50%, -50%);
          -webkit-transform: translate(-50%, -50%);
          -moz-transform: translate(-50%, -50%);
          -ms-transform: translate(-50%, -50%);
          @media ${size['tablet-max']} {
            width: 10px;
            height: 10px;
          }
        }
      }
    }

    .ant-radio-wrapper-checked {
      .ant-radio-inner:after {
        opacity: 1;
      }
      span.ant-radio + * {
        color: #49465d;
      }
    }
  }
  /* Will be removed */
  .not-shown {
    display: none !important;
  }
`;

export { RadioWrapper };
