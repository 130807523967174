import React, { Component } from 'react';
import { Select } from 'antd';
import { FormWrapper } from './style';
import isEmpty from 'lodash.isempty';
const { Option } = Select;

export const SelectOption = Select.Option;
class SelectCustom extends Component {
  handleChange = ele => {
    const { name, isFormik } = this.props;
    if (!isFormik) {
      let postdata = { name: name, ele: ele };
      this.props.onChange(postdata);
    } else {
      this.props.setFieldValue(name, ele);
    }
  };

  render() {
    const {
      id,
      name,
      value,
      data,
      placeholder,
      defaultValue,
      children,
      mode,
      className,
      disabled,
      onSelect,
      mainClassname,
      maxTagCount,
      maxTagTextLength,
      isError,
      bordered,
      onFocus,
      onBlur,
      open,
      type,
      dropDownClass,
      tabIndex
    } = this.props;
    return (
      <FormWrapper
        className={`${mainClassname} ${isError ? 'error-wrapper' : ''} ${'item-select'}`}
      >
        <div id={id ? id : 'form-select'}>
          {type && (type == 'marketplace' || type == 'booking-parcels') ? (
            <Select
              defaultValue={defaultValue}
              bordered={bordered}
              disabled={disabled}
              onSelect={onSelect}
              showArrow={false}
              onChange={this.handleChange}
              placeholder={placeholder}
              name={name}
              tabIndex={tabIndex}
              value={value}
              maxTagCount={maxTagCount}
              maxTagTextLength={maxTagTextLength}
              open={open}
              className={`select-custom teams ${className ? className : ''}`}
              dropdownClassName={`${dropDownClass ? dropDownClass : ''} ${'dropdown-custom'}`}
              dropdownRender={(mountNode, prop) => {
                return (
                  <div className="dropdown-section">
                    <div>{mountNode}</div>
                  </div>
                );
              }}
              mode={mode}
              onFocus={onFocus}
              onBlur={onBlur}
              getPopupContainer={() => document.getElementById(id ? id : 'form-select')}
            >
              {children}
            </Select>
          ) : (
            <Select
              defaultValue={defaultValue}
              bordered={bordered}
              disabled={disabled}
              onSelect={onSelect}
              showArrow={false}
              onChange={this.handleChange}
              placeholder={placeholder}
              name={name}
              tabIndex={tabIndex}
              value={value}
              maxTagCount={maxTagCount}
              maxTagTextLength={maxTagTextLength}
              className={`select-custom teams ${className ? className : ''}`}
              dropdownClassName={`${dropDownClass ? dropDownClass : ''} ${'dropdown-custom'}`}
              dropdownRender={(mountNode, prop) => {
                return (
                  <div className="dropdown-section">
                    <div>{mountNode}</div>
                  </div>
                );
              }}
              mode={mode}
              onFocus={onFocus}
              onBlur={onBlur}
              getPopupContainer={() => document.getElementById(id ? id : 'form-select')}
            >
              {children}
            </Select>
          )}
        </div>
      </FormWrapper>
    );
  }
}
SelectOption.defaultProps = {
  placeholder: null,
  className: '',
  disabled: false
};
SelectCustom.defaultProps = {
  mainClassname: '',
  isFormik: true,
  placeholder: null,
  className: '',
  disabled: false,
  defaultValue: null,
  maxTagCount: null,
  maxTagTextLength: null,
  mode: null
};
export default SelectCustom;
