import courierBike from 'assets/images/courier-tracking-bike.svg';
import courierCar from 'assets/images/courier-tracking-car.svg';
import courierCargoBike from 'assets/images/courier-tracking-cargo-bike.svg';
import courierLargeVan from 'assets/images/courier-tracking-large-van.svg';
import courierMotorBike from 'assets/images/courier-tracking-motor-bike.svg';
import courierSmallVan from 'assets/images/courier-tracking-small-van.svg';
import mapOrangeGreenIcon from 'assets/images/drop-off-pin-orange-green.svg';
import mapOrangeIcon from 'assets/images/drop-off-pin-orange.svg';
import mapPurpleIcon from 'assets/images/drop-off-pin.svg';
import extraAddressIcon from 'assets/images/extra-address-icon.svg';
import extraAddressPurpleIcon from 'assets/images/extra-address-purple-icon.svg';
import mapRedIcon from 'assets/images/pickup-pin.svg';
import { InfoWindow, Map, Marker, Polyline } from 'google-maps-react';
import isEmpty from 'lodash.isempty';
import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { MapWrapper } from './style';
const mapStyles = {
  width: '100%',
  height: '100%',
};
let eventTimeout;
class MapMarker extends Component {
  state = {
    showingInfoWindow: false,
    activeMarker: {},
    selectedPlace: {},
  };
  onMarkerClick = (props, marker, e) => {
    this.props.addressData(props);
    this.setState({
      selectedPlace: props,
      activeMarker: marker,
      showingInfoWindow: true,
    });
  };
  timeDiffCalc(dateFuture, dateNow) {
    let diffInMilliSeconds = Math.abs(dateFuture - dateNow) / 1000;

    // calculate days
    const days = Math.floor(diffInMilliSeconds / 86400);
    diffInMilliSeconds -= days * 86400;

    // calculate hours
    const hours = Math.floor(diffInMilliSeconds / 3600) % 24;
    diffInMilliSeconds -= hours * 3600;

    // calculate minutes
    const minutes = Math.floor(diffInMilliSeconds / 60) % 60;
    diffInMilliSeconds -= minutes * 60;

    let difference = '';
    if (days > 0) {
      difference += days === 1 ? `${days} day, ` : `${days} days, `;
    }

    difference += hours === 0 || hours === 1 ? `${hours} hr, ` : `${hours} hrs, `;

    difference += minutes === 0 || hours === 1 ? `${minutes} min` : `${minutes} mins`;
    return difference;
  }
  showPopUpOfMarker(props, marker, e) {
    let jobDetails = { address: '', eta: '' };
    if (!isEmpty(this.props.currentJobs)) {
      this.props.currentJobs.map((data, i) => {
        if (data.id == props.data.job_id) {
          if (props.data.type == 'pickup') {
            jobDetails.address = data.pickup_address1 + ', ' + data.pickup_city;
          } else jobDetails.address = data.delivery_address1 + ', ' + data.delivery_city;
        }
      });
    }
    const d = new window.Date();
    jobDetails.eta = this.timeDiffCalc(
      new window.Date(!isEmpty(props.data.eta) ? props.data.eta : moment(d).format('YYYY-MM-DD HH:mm:ss')),
      new window.Date(moment(d).format('YYYY-MM-DD HH:mm:ss'))
    );
    if (isEmpty(this.state.selectedPlace)) {
      clearTimeout(eventTimeout);
      eventTimeout = setTimeout(
        function () {
          this.setState({
            selectedPlace: jobDetails,
            activeMarker: marker,
            showingInfoWindow: true,
          });
        }.bind(this),
        100
      );
    }
  }

  hidePopUpOfMarker() {
    this.setState({
      showingInfoWindow: false,
      activeMarker: {},
      selectedPlace: {},
    });
    // If we already left marker, clear the timeout
    clearTimeout(eventTimeout);
  }

  mapRef = React.createRef();
  async componentDidMount() {
    // console.log(this.mapRef.current, 'map ref');
  }
  async componentWillUnmount() {
    this.setState({ showingInfoWindow: false, activeMarker: {}, selectedPlace: {} });
  }
  async componentDidUpdate(prevProps) {
    const { courierLocation, jobStatus } = this.props;
    const { map } = this.state;
    if (this.props.isReset !== prevProps.isReset) {
      this.centerPostion();
    }
    if (jobStatus !== prevProps.jobStatus) {
      if (!isEmpty(courierLocation)) {
        if (map && !isEmpty(jobStatus)) {
          document.querySelector('.status-div').createTextNode = `JOB STATUS: ${jobStatus}`;
        }
      }
    }
  }
  centerPostion = () => {
    const { googleRef, markerPoints } = this.props;
    const center = new googleRef.maps.LatLng(markerPoints[0].latitude, markerPoints[0].longitude);
    this.mapRef.current.map.panTo(center);
  };
  displayMarkers = () => {
    const {
      markerPoints,
      changePin,
      selectedParcelStoreAddress,
      newLocationCoOrdiantes,
      jobDetails,
      showOrange,
      showLabel,
      googleRef,
      courierLocation,
      showJobStatus,
      jobStatus,
    } = this.props;
    const { map } = this.state;
    if (!isEmpty(newLocationCoOrdiantes)) {
      // ANCHOR - BELOW MAP IS USED FOR DISPLAYING THE MARKER WHILE ADDING NEW ADDRESS IN SAMEDAY.
      return [
        newLocationCoOrdiantes.map((store, index) => {
          let icon = mapOrangeIcon;
          return (
            <Marker
              key={index}
              id={index}
              icon={{ url: icon, labelOrigin: new this.props.googleRef.maps.Point(32, 25) }}
              position={{
                lat: store.latitude,
                lng: store.longitude,
              }}
              animation={1}
              onClick={store.address ? this.onMarkerClick : ''}
              name={store.address}
            />
          );
        }),
        markerPoints.map((store, index) => {
          let icon = changePin ? mapOrangeIcon : mapPurpleIcon;
          icon = store.color ? mapRedIcon : icon;
          icon = store.color === 'orange-green' ? mapOrangeGreenIcon : icon;
          let color = changePin ? '#ffa100' : '#E8286B';
          color = store.color ? '#00C68D' : '#E8286B';
          color = store.color === 'orange-green' ? '#00000000' : color;
          return (
            <Marker
              key={index}
              id={index}
              icon={{
                url: icon,
                labelOrigin: showLabel ? new this.props.googleRef.maps.Point(32, 25) : '',
              }}
              label={
                showLabel
                  ? {
                      text: `${!isEmpty(jobDetails) && jobDetails.leg_type == 0 ? index + 1 : ''}`,
                      color: color,
                      fontSize: '25px',
                      fontWeight: 'bold',
                    }
                  : ''
              }
              position={{
                lat: store.latitude,
                lng: store.longitude,
              }}
              animation={changePin && selectedParcelStoreAddress === store.id && 1}
              onClick={store.address ? this.onMarkerClick : ''}
              name={store.address}
            />
          );
        }),
      ];
    } else if (!isEmpty(courierLocation) && !isEmpty(markerPoints)) {
      // ANCHOR - BELOW MAP IS USED FOR TRACKING JOB IN JOBTRACKER AND PUCLIC TRACKER PAGE.
      if (map && !isEmpty(jobStatus)) {
        googleRef.maps.event.trigger(map, 'resize');
        if (showJobStatus) {
          var elementExists = document.getElementsByClassName('maindiv-jobstatus');
          var tooltipExists = document.getElementsByClassName('tooltip');
          while (elementExists.length > 0) {
            elementExists[0].parentNode.removeChild(elementExists[0]);
          }
          while (tooltipExists.length > 0) {
            tooltipExists[0].parentNode.removeChild(tooltipExists[0]);
          }
          const mainDiv = document.createElement('div');
          mainDiv.classList.add('maindiv-jobstatus');
          const statusDiv = document.createElement('div');
          const infoIcon = document.createElement('i');
          statusDiv.classList.add('status-div');
          infoIcon.classList.add('info-icon-white');

          const node = document.createTextNode(`JOB STATUS: ${jobStatus}`);

          const tooltipdiv = document.createElement('div');
          tooltipdiv.classList.add('tooltip');
          const tooltipspan = document.createElement('span');
          tooltipspan.classList.add('tooltiptext');
          const tooltipspantext = document.createTextNode('Blue icons are not your address, its only the path carrier will follow.');
          tooltipspan.appendChild(tooltipspantext);
          tooltipdiv.appendChild(infoIcon);
          infoIcon.onmouseover = () => {
            tooltipspan.className += ' show';
          };
          infoIcon.onmouseout = () => {
            tooltipspan.classList.remove('show');
          };
          statusDiv.appendChild(node);
          mainDiv.appendChild(tooltipspan);
          mainDiv.appendChild(tooltipdiv);
          mainDiv.appendChild(statusDiv);
          map.controls[this.props.googleRef.maps.ControlPosition.TOP_RIGHT].push(mainDiv);
        }
      }
      return [
        courierLocation.map((store, index) => {
          let icon = '';
          const vehcileType = store.data?.vehicle_type;
          if (!isEmpty(vehcileType)) {
            if (vehcileType == '10') {
              icon = courierBike;
            } else if (vehcileType == '15') {
              icon = courierCargoBike;
            } else if (vehcileType == '20') {
              icon = courierMotorBike;
            } else if (vehcileType == '30') {
              icon = courierCar;
            } else if (vehcileType == '40') {
              icon = courierSmallVan;
            } else if (vehcileType == '50') {
              icon = courierLargeVan;
            }
          }
          return (
            <Marker
              key={index}
              id={index}
              icon={{ url: icon }}
              position={{
                lat: store.latitude,
                lng: store.longitude,
              }}
            />
          );
        }),
        markerPoints.map((store, index) => {
          let icon = '',
            anchor,
            color = '#fff';
          let labelText = false,
            showInfo = false;
          if (!isEmpty(store.data)) {
            if (isEmpty(store.data.job_id)) {
              if (index == 0) {
                icon = extraAddressPurpleIcon;
                labelText = true;
                anchor = new this.props.googleRef.maps.Point(18, 20);
              } else {
                icon = extraAddressIcon;
                anchor = new this.props.googleRef.maps.Point(18, 20);
              }
            } else if (store.data.type === 'pickup') {
              icon = mapRedIcon;
              color = '#00C68D';
              labelText = true;
              showInfo = true;
              anchor = new this.props.googleRef.maps.Point(34, 65);
            } else {
              icon = mapPurpleIcon;
              color = '#E8286B';
              labelText = true;
              showInfo = true;
              anchor = new this.props.googleRef.maps.Point(34, 65);
            }
          }
          return (
            <Marker
              key={index}
              id={index}
              name="vkjdvkjfd"
              data={store.data}
              icon={{
                url: icon,
                labelOrigin: color == '#fff' ? new this.props.googleRef.maps.Point(20, 20) : new this.props.googleRef.maps.Point(32, 25),
                origin: new this.props.googleRef.maps.Point(0, 0),
                anchor: anchor,
              }}
              label={labelText ? { text: `${index + 1}`, color: color, fontSize: '20px', fontWeight: 'bold' } : ''}
              position={{
                lat: store.latitude,
                lng: store.longitude,
              }}
              animation={changePin && selectedParcelStoreAddress === store.id && 1}
              onClick={store.address ? this.onMarkerClick : ''}
              name={store.address}
              onMouseover={showInfo ? this.showPopUpOfMarker.bind(this) : ''}
              onMouseout={showInfo ? this.hidePopUpOfMarker.bind(this) : ''}
            />
          );
        }),
      ];
    } else {
      // ANCHOR - BELOW MAP IS USED FOR SIMPLYING DISPLAYING THE MARKERS.
      return markerPoints.map((store, index) => {
        let icon = changePin ? mapOrangeIcon : mapPurpleIcon;
        icon = store.color ? mapRedIcon : icon;
        icon = store.color === 'orange-green' ? mapOrangeGreenIcon : icon;
        let color = changePin ? '#ffa100' : '#E8286B';
        color = store.color ? '#00C68D' : '#E8286B';
        color = store.color === 'orange-green' ? '#00000000' : color;
        if (showOrange) {
          icon = store.color === 'orange' ? mapOrangeIcon : store.color === 'green' ? mapRedIcon : mapPurpleIcon;
        }
        let iconObj = {};
        if (showLabel)
          iconObj = {
            url: icon,
            labelOrigin: showLabel ? new this.props.googleRef.maps.Point(32, 25) : {},
          };
        else iconObj = { url: icon };
        return (
          <Marker
            key={index}
            id={index}
            icon={iconObj}
            position={{
              lat: store.latitude,
              lng: store.longitude,
            }}
            label={
              showLabel
                ? {
                    text: `${showLabel && !isEmpty(jobDetails) && jobDetails.leg_type == 0 ? index + 1 : ''}`,
                    color: color,
                    fontSize: '25px',
                    fontWeight: 'bold',
                  }
                : ''
            }
            animation={(showOrange && store.color === 'orange' && 1) || (changePin && selectedParcelStoreAddress === store.id && 1)}
            onClick={store.address ? this.onMarkerClick : ''}
            name={store.address}
          />
        );
      });
    }
  };

  onReady = (mapProps, map, locationData, jobStatus) => {
    this.setState({ map });
    const { markerPoints, googleRef, isBound, closeMap, showClose, newLocationCoOrdiantes, showJobStatus, boundPoints } = this.props;
    googleRef.maps.event.trigger(map, 'resize');
    if (isBound) {
      let bounds = new googleRef.maps.LatLngBounds();
      if (!isEmpty(boundPoints)) {
        boundPoints.map((points) => {
          bounds.extend({
            lat: parseFloat(points.latitude),
            lng: parseFloat(points.longitude),
          });
        });
      } else {
        if (!isEmpty(markerPoints)) {
          markerPoints.map((points) => {
            bounds.extend({
              lat: parseFloat(points.latitude),
              lng: parseFloat(points.longitude),
            });
          });
        }
        if (!isEmpty(newLocationCoOrdiantes)) {
          let bounds = new googleRef.maps.LatLngBounds();
          newLocationCoOrdiantes.map((points) => {
            bounds.extend({
              lat: parseFloat(points.latitude),
              lng: parseFloat(points.longitude),
            });
          });
        }
      }
      map.fitBounds(bounds);
    }
    const controlButtonDiv = document.createElement('button');
    controlButtonDiv.classList.add('maps-close-btn');
    controlButtonDiv.addEventListener('click', function () {
      closeMap();
    });
    {
      isBound && showClose && map.controls[this.props.googleRef.maps.ControlPosition.TOP_RIGHT].push(controlButtonDiv);
    }
  };
  showPolylineMethod = () => {
    // ANCHOR - USED TO SHOW THE PATH BETWEEN TWO POINTS.
    let { jobDetails, markerPoints, newLocationCoOrdiantes, showPolyLine, courierLocation } = this.props;
    let points = [];
    markerPoints.map((p) => {
      points.push({
        lat: parseFloat(p.latitude),
        lng: parseFloat(p.longitude),
      });
    });
    if (!isEmpty(newLocationCoOrdiantes)) {
      newLocationCoOrdiantes.map((p) => {
        points.push({
          lat: parseFloat(p.latitude),
          lng: parseFloat(p.longitude),
        });
      });
    }
    if ((!isEmpty(jobDetails) && jobDetails.leg_type === '0' && !isEmpty(jobDetails.delivery_address1)) || showPolyLine) {
      return (
        <Polyline path={points} strokeColor={!isEmpty(courierLocation) ? '#44B1DD' : '#333333'} strokeOpacity={0.8} strokeWeight={2} />
      );
    } else {
      return null;
    }
  };
  render() {
    const {
      markerPoints,
      className,
      googleRef,
      streetViewControl,
      disableDefaultUI,
      overviewMapControl,
      fullscreenControl,
      scaleControl,
      isBound,
      reviewMap,
      newLocationCoOrdiantes,
      locationData,
      jobStatus,
      boundPoints,
      showJobStatus,
    } = this.props;
    let { map } = this.state;
    if (showJobStatus) {
      // ANCHOR - USED TO SHOW THE JOB-STATUS IN MAP IN JOBTRACKER AND PUBLIC TRACKER PAGE.
      var elementExists = document.getElementsByClassName('maindiv-jobstatus');
      var tooltipExists = document.getElementsByClassName('tooltip');
      while (elementExists.length > 1) {
        elementExists[0].parentNode.removeChild(elementExists[0]);
      }
      while (tooltipExists.length > 0) {
        tooltipExists[0].parentNode.removeChild(tooltipExists[0]);
      }
    }
    if (!isEmpty(this.state.map) && !isEmpty(markerPoints)) {
      // ANCHOR - USED TO SET THE ZOOM LEVEL OF THE MAP ACCORDING TO THE MARKERPOINTS
      googleRef.maps.event.trigger(map, 'resize');
      if (isBound) {
        let bounds = new googleRef.maps.LatLngBounds();
        if (!isEmpty(boundPoints)) {
        } else {
          if (!isEmpty(markerPoints)) {
            markerPoints.map((points) => {
              bounds.extend({
                lat: parseFloat(points.latitude),
                lng: parseFloat(points.longitude),
              });
            });
            if (!isEmpty(newLocationCoOrdiantes)) {
              newLocationCoOrdiantes.map((points) => {
                bounds.extend({
                  lat: parseFloat(points.latitude),
                  lng: parseFloat(points.longitude),
                });
              });
            }
          }
          map.fitBounds(bounds);
        }
      }
    }
    return (
      <MapWrapper className={className}>
        <Map
          ref={this.mapRef}
          onReady={(mapProps, map) => this.onReady(mapProps, map, locationData, jobStatus)}
          google={this.props.googleRef}
          style={mapStyles}
          initialCenter={{
            lat: markerPoints[0].latitude,
            lng: markerPoints[0].longitude,
          }}
          gestureHandling="greedy"
          zoomControlOptions={{
            position: this.props?.googleRef?.maps?.ControlPosition.RIGHT_TOP,
          }}
          center={
            reviewMap
              ? {
                  lat: markerPoints[0].latitude,
                  lng: markerPoints[0].longitude,
                }
              : undefined
          }
          fullscreenControl={fullscreenControl}
          streetViewControl={streetViewControl}
          disableDefaultUI={disableDefaultUI}
          scaleControl={scaleControl}
          overviewMapControl={overviewMapControl}
          zoom={15}
        >
          {this.displayMarkers()}
          <InfoWindow marker={this.state.activeMarker} visible={this.state.showingInfoWindow}>
            <div>
              <h1>Address: {this.state.selectedPlace.address}</h1>
              <h1>ETA: {this.state.selectedPlace.eta}</h1>
            </div>
          </InfoWindow>
          {this.showPolylineMethod()}
        </Map>
      </MapWrapper>
    );
  }
}
MapMarker.defaultProps = {
  fullscreenControl: true,
  overviewMapControl: true,
  scaleControl: true,
  streetViewControl: false,
  disableDefaultUI: false,
  className: '',
  label: '',
  isReset: false,
  isBound: true,
  changePin: false,
  showClose: true,
  showLabel: false,
  reviewMap: false,
  showPolyLine: false,
  showOrange: false,
  showJobStatus: false,
};
const mapStateToProps = (state) => ({
  googleRef: state.app.googleRef,
  jobDetails: state.booking.jobDetails,
  locationData: state.booking.jobLocationData,
});
export default connect(mapStateToProps, {})(MapMarker);
