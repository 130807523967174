export const ADDFAVOURITE_INITIATED = 'ADDFAVOURITE_INITIATED';
export const ADDFAVOURITE_SUCCESS = 'ADDFAVOURITE_SUCCESS';
export const ADDFAVOURITE_ERROR = 'ADDFAVOURITE_ERROR';

export const DELETEFAVOURITES_INITIATED = 'DELETEFAVOURITES_INITIATED';
export const DELETEFAVOURITES_SUCCESS = 'DELETEFAVOURITES_SUCCESS';
export const DELETEFAVOURITES_ERROR = 'DELETEFAVOURITES_ERROR';

export const GETFAVOURITES_INITIATED = 'GETFAVOURITES_INITIATED';
export const GETFAVOURITES_SUCCESS = 'GETFAVOURITES_SUCCESS';
export const GETFAVOURITES_ERROR = 'GETFAVOURITES_ERROR';

export const ADDNEWADDRESSFAVOURITES_INITIATED = 'ADDNEWADDRESSFAVOURITES_INITIATED';
export const ADDNEWADDRESSFAVOURITES_SUCCESS = 'ADDNEWADDRESSFAVOURITES_SUCCESS';
export const ADDNEWADDRESSFAVOURITES_ERROR = 'ADDNEWADDRESSFAVOURITES_ERROR';
