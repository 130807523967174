import styled from 'styled-components';
import { size } from 'App/device';
const SplashWrapper = styled.div`
  height: auto;
  margin: 0px;
  font-size: 14px;
  line-height: 1.4em;
  overflow-x: hidden;
  color: #000;
  font-family: ${props => props.theme.secondaryFont}, sans-serif;
  .splash-main {
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 100%;
    position: relative;
    z-index: 1;
    height: 100vh;
    .splash-logo {
      margin: 0 auto 30px auto;
      display: table;
      @media ${size['desktop-sm-max']} {
        margin: 0 auto 20px auto;
      }
      @media ${size['laptop-max']} {
        margin: 0 auto 15px auto;
      }
      @media ${size['tablet-md-max']} {
        margin: 0 auto 10px auto;
      }
    }
    p {
      font-weight: normal;
      font-size: 22px;
      line-height: normal;
      text-align: center;
      color: #8a8a8a;
      @media ${size['desktop-sm-max']} {
        font-size: 20px;
      }
      @media ${size['laptop-max']} {
        font-size: 18px;
      }
      @media ${size['tablet-md-max']} {
        font-size: 16px;
      }
    }
  }
  a {
    text-decoration: none;
    color: #110c23;
    transition: all 0.4s ease;
    -webkit-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
  }
  .d-flex {
    display: flex !important;
  }

  h1,
  h2 {
    font-size: 40px;
    @media ${size['desktop-sm-max']} {
      font-size: 36px;
    }
    @media ${size['laptop-max']} {
      font-size: 30px;
    }
    @media ${size['laptop-sm-max']} {
      font-size: 28px;
    }
    @media ${size['tablet-max']} {
      font-size: 24px;
    }
  }
  img {
    max-width: 100%;
    height: auto;
    border: none;
    outline: none;
    display: inline-block;
    vertical-align: top;
  }
`;
const SplashLogoWrapper = styled.div`
  position: fixed;
  left: 0;
  right: auto;
  bottom: 0;
  width: auto;
  height: 800px;
  top: auto;
  @media ${size['desktop-md-max']} {
    height: 670px;
  }
  @media ${size['desktop-sm-max']} {
    height: 570px;
  }
  @media ${size['laptop-max']} {
    height: 450px;
  }
  @media ${size['tablet-max']} {
    height: 300px;
  }
  @media ${size['mobile-md-max']} {
    height: 180px;
  }
  img {
    object-fit: fill;
    width: auto;
    max-width: 100%;
    height: 100%;
    border: none;
    outline: none;
    vertical-align: top;
    object-position: center right;
    @media ${size['tablet-max']} {
      object-position: bottom center;
    }
  }
`;
export { SplashWrapper, SplashLogoWrapper };
