import React, { Component } from 'react';
import { MaxContainer } from 'components/AuthWrapper/style.js';
import styled from 'styled-components';
import { size } from 'App/device';

const Wrapper = styled.div`
  font-weight: 300;
  font-size: 15px;
  line-height: 18px;
  color: #a2a2a2;
  padding: 30px 0;
  position: relative;
  @media ${size['tablet-md-max']} {
    padding: 20px 0;
  }
  @media ${size['tablet-max']} {
    padding: 15px 0;
    text-align: center;
    font-size: 14px;
  }
`;
export class Footer extends Component {
  render() {
    return (
      <Wrapper>
        <MaxContainer>
          © Gophr <span id="year">2020</span>
        </MaxContainer>
      </Wrapper>
    );
  }
}

export default Footer;
