import React from 'react';
import { LocationHeaderWrapper } from './style';

export const LocationHeader = ({
  leftPanel,
  rightPanel,
  rightClassName,
  leftClassName,
  className,
  lineColor
}) => {
  return (
    <LocationHeaderWrapper
      className={`d-flex ${className ? className : ''}`}
      bgLineColor={lineColor ? lineColor : 'red'}
    >
      {leftPanel && (
        <span className={`left-panel ${leftClassName ? leftClassName : ''}`}>{leftPanel}</span>
      )}
      {rightPanel && (
        <span className={`ml-auto right-panel ${rightClassName ? rightClassName : ''} `}>
          {rightPanel}
        </span>
      )}
    </LocationHeaderWrapper>
  );
};

LocationHeader.defaultProps = {
  leftPanel: null,
  rightPanel: null,
  lineColor: null,
  className: '',
  rightClassName: '',
  leftClassName: ''
};
