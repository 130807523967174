import React, { Component } from 'react';
import { Row, Col } from 'antd';
import { SignupWrapper } from 'container/Signup/signup.style';
import AuthHeader from 'components/AuthHeader';
import Address from 'components/Signup/Address';
import AuthWrapper from 'components/AuthWrapper';
import logoShapeDarkBG from 'assets/images/logo-shape-dark1.svg';
import { onError } from 'modules/errorHandler';

class Signup extends Component {
  componentDidCatch(error) {
    onError(error, 'Signup', 'Component render error');
  }
  render() {
    return (
      <SignupWrapper>
        <AuthHeader bgImage={logoShapeDarkBG} />
        <AuthWrapper>
          <div className="max-container">
            <Row>
              <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                <Address />
              </Col>
            </Row>
          </div>
        </AuthWrapper>
      </SignupWrapper>
    );
  }
}

export default Signup;
