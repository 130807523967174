import styled from 'styled-components';
import { size } from 'App/device';
import { borderRadius, transition } from 'App/mixins';
import grayMapIcon from 'assets/images/gray-map-icon.svg';
import purpleMapIcon from 'assets/images/map-icon-purple.svg';
import graySearchIcon from 'assets/images/search-dark-icon.svg';
import purpleSearchIcon from 'assets/images/search-purple-icon.svg';

const FormWrapper = styled.div`
  width: 100%;
  position: relative;
  .ant-select-auto-complete.ant-select .ant-input:focus,
  .ant-select-auto-complete.ant-select .ant-input:hover {
    border: 0.1rem solid #cecece;
    box-shadow: none;
  }
  .search-dropdown {
    width: 100%;
  }

  //ANCHOR - Map Icon Style
  .map-icon {
    position: absolute;
    z-index: 1;
    width: 20px;
    height: 24px;
    display: block;
    top: 50%;
    ${transition(0.4)};
    transform: translate(0, -50%);
    -webkit-transform: translate(0, -50%);
    -moz-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    // right: 15px;
    background-size: 100% auto;
    &.gray {
      background: url(${graySearchIcon}) no-repeat center;
    }
    &.purple {
      background: url(${purpleSearchIcon}) no-repeat center;
    }
  }

  //ANCHOR - Input Style
  .ant-select-auto-complete.ant-select .ant-input.ant-select-search__field {
    padding: 0 10px 0 35px;
    font-family: ${props => props.theme.secondaryFont}, sans-serif !important;
    color: #49465d !important;
    font-size: 15px;
    height: 40px;
    font-weight: 600 !important;
    line-height: 40px;
    border: 0.1rem solid #cecece;
    ${borderRadius(0)};
    .focus-visible {
      border: 0;
      background: red;
    }
    @media ${size['desktop-sm-max']} {
      /* font-size: 12.8061px;
      height: 38px; */
      // padding: 10px 10px;
    }
    @media ${size['tablet-max']} {
      font-size: 14px;
      height: 36px;
    }
  }

  //ANCHOR - Input Placeholder Style
  .ant-select-selection__placeholder {
    padding: 0 10px 0 35px;
    top: 0%;
    color: #49465d;
    font-weight: 600 !important;
    margin-top: 0px;
    margin-left: 0 !important;
    margin-right: 0 !important;
    color: ${props => props.theme.primaryColor};
    height: 42px;
    line-height: 42px;
  }

  //ANCHOR -  Input Render Style
  .ant-select-search__field__mirror {
    width: 100%;
  }
`;

export { FormWrapper };
