import React, { Component } from 'react';
import { SplashLogoWrapper } from './style';
import logo from 'assets/images/splash-screen-logo-shape.svg';

export class SplashLogo extends Component {
  render() {
    return (
      <SplashLogoWrapper>
        <img src={logo} alt="" />
      </SplashLogoWrapper>
    );
  }
}
