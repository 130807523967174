export const OPEN_EDIT = 'OPEN_EDIT';

export const GETTEAM_INITIATED = 'GETTEAM_INITIATED';
export const GETTEAM_SUCCESS = 'GETTEAM_SUCCESS';
export const GETTEAM_ERROR = 'GETTEAM_ERROR';

export const ADDTEAM_INITIATED = 'ADDTEAM_INITIATED';
export const ADDTEAM_ERROR = 'ADDTEAM_ERROR';
export const ADDTEAM_SUCCESS = 'ADDTEAM_SUCCESS';

export const DELETETEAM_INITIATED = 'DELETETEAM_INITIATED';
export const DELETETEAM_SUCCESS = 'DELETETEAM_SUCCESS';
export const DELETETEAM_ERROR = 'DELETETEAM_ERROR';

export const UPDATETEAM_INITIATED = 'UPDATETEAM_INITIATED';
export const UPDATETEAM_ERROR = 'UPDATETEAM_ERROR';
export const UPDATETEAM_SUCCESS = 'UPDATETEAM_SUCCESS';

export const INVITEMEMBER_INITIATED = 'INVITEMEMBER_INITIATED';
export const INVITEMEMBER_ERROR = 'INVITEMEMBER_ERROR';
export const INVITEMEMBER_SUCCESS = 'INVITEMEMBER_SUCCESS';
