import { Col, DatePicker, Row } from 'antd';
import isEmpty from 'lodash.isempty';
import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { endTimeOpen, startDate, startTime, startTimeOpen } from 'redux/shift/actions';
import { FormWrapper } from './style';
import TimeSlider from '../TimeSlider/index';
const TimeFormat = 'HH:mm';
const DateFormat = 'YYYY-MM-DD';
moment.locale('en', {
  week: {
    dow: 1
  }
});
moment.locale('en');
moment().weekday(1);
class CustomDateTimePicker extends Component {
  state = {
    selectedDate: '',
    currentDate: '',
    time: []
  };
  handleChange = (ele, dateString) => {
    const { name, onDateTimeChange, timeName } = this.props;
    onDateTimeChange(name, dateString);
    // this.props.dateTimeTouched();
    if (timeName == 'startTime') {
      this.props.startDate({ startDate: dateString });
    }
  };
  handleDisabledDate = currentDate => {
    if (moment().format('HH') >= 18) {
      return currentDate < moment().subtract('0', 'days');
    }
    return currentDate < moment().subtract('1', 'days');
  };
  roundToNearestHalfHour = (num) => {
    return Math.ceil(num / 1800) * 1800;
  }

  onSliderAfterChange = async () => {
    const { time, onDateTimeChange } = this.props;
    let timeStart = time[0];
    let timeEnd = time[1];
    if ((timeEnd - timeStart) > 48600) {
      timeEnd = timeStart + 48600;
    }
    timeStart = moment.unix(timeStart).format(TimeFormat);
    timeEnd = moment.unix(timeEnd).format(TimeFormat);
    await onDateTimeChange('startTime', [timeStart, timeEnd]);
  };
  onSliderChange = async value => {
    const { onDateTimeChange } = this.props;
    await onDateTimeChange('startTime', [moment.unix(value[0]).format(TimeFormat), moment.unix(value[1]).format(TimeFormat)]);
  };
  render() {
    const { placeholder, className, timeName, value, errorsDateTime, name, time, timemin, timemax } = this.props;
    const initDateVal = value !== '' ? moment(value).format(DateFormat) : '';
    let initDate =
      moment(initDateVal).format('HH') >= 18 && moment(initDateVal).format(DateFormat) == moment(new Date()).format(DateFormat)
        ? moment(initDateVal).add(1, 'days')
        : moment(initDateVal, DateFormat);
    return (
      <FormWrapper className={className}>
        <Row className="dt-tm-row">
          <Col lg={6} className="first-col">
            <DatePicker
              name={name}
              format={DateFormat}
              value={initDate}
              placeholder={placeholder}
              className="custom-datepicker"
              dropdownClassName="custom-datepicker-dropdown"
              onChange={this.handleChange}
              getCalendarContainer={node => node}
              allowClear={false}
              disabledDate={d => this.handleDisabledDate(d)}
              onOpenChange={() => this.handleDateOpen}
            />
            {!isEmpty(errorsDateTime) && errorsDateTime[name] && <div className="form-error">{errorsDateTime[timeName]}</div>}
          </Col>
          <Col lg={18}>
            <TimeSlider
              value={time}
              min={this.roundToNearestHalfHour(timemin)}
              max={this.roundToNearestHalfHour(timemax)}
              onSliderChange={this.onSliderChange}
              onSliderAfterChange={this.onSliderAfterChange}
            />
          </Col>
        </Row>
      </FormWrapper>
    );
  }
}
CustomDateTimePicker.defaultProps = {};
const mapStateToProps = state => ({
  startT: state.shift.startTime.startTime,
  shiftDetails: state.shift.shiftDetails,
  startD: state.shift.startDate.startDate,
  startTimeProps: state.shift.startTimeOpen,
  workingHours: state.booking.workingHours,
  endTimeProps: state.shift.endTimeOpen
});

const mapStateToDispatch = dispatch => ({
  startTime: payload => dispatch(startTime(payload)),
  startDate: payload => dispatch(startDate(payload)),
  startTimeOpen: payload => dispatch(startTimeOpen(payload)),
  endTimeOpen: payload => dispatch(endTimeOpen(payload))
});

export default connect(mapStateToProps, mapStateToDispatch)(CustomDateTimePicker);
