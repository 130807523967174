import * as actions from './constant';
const initialState = {
  error: false,
  loading: false,
  message: false,
  shiftDetails: {},
  shiftQuote: {},
  shiftId: null,
  geoLocation: {},
  areaLocation: {},
  startTime: '',
  startDate: '',
  isClicked: false,
  shiftJobs: [],
  isValidate: false,
  shiftData: {},
  shiftRiders: [],
  courierLocation: [],
  show_notification: true,
  cancelShift: {},
  startTimeOpen: false,
  endTimeOpen: false
};

const shift = (state = initialState, action) => {
  switch (action.type) {
    case actions.ADDSHIFT_INITIATED:
      return {
        ...state,
        message: false,
        error: false,
        loading: true
      };
    case actions.ADDSHIFT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        message: 'New Shift',
        geoLocation: action.geolocation
      };
    case actions.ADDSHIFT_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        message: action.error
      };
    case actions.UPDATESHIFT_INITIATED:
      return {
        ...state,
        message: false,
        error: false,
        loading: true
      };
    case actions.UPDATESHIFT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        message: 'Update Shift',
        show_notification: false,
        shiftDetails: {
          ...state.shiftDetails,
          shift_data: {
            ...state.shiftDetails.shift_data,
            ...action.payload
          }
        }
      };
    case actions.UPDATESHIFT_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        message: action.error
      };
    case actions.GETSHIFT_INITIATED:
      return {
        ...state,
        message: false,
        error: false,
        loading: true,
        shiftDetails: {}
      };
    case actions.GETSHIFT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        shiftDetails: action.payload
      };
    case actions.SET_SHIFT_DATA:
      return {
        ...state,
        loading: false,
        error: false,
        shiftData: action.payload.data.shift_data,
        shiftRiders: action.payload.data.shifts_riders
      };
    case actions.GETSHIFT_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        shiftDetails: {}
      };
    case actions.SET_GEOLOCATION:
      return {
        ...state,
        geoLocation: action.payload
      };
    case actions.SET_AREALOCATION:
      return {
        ...state,
        // show_notification: false,
        areaLocation: action.payload
      };
    case actions.GETSHIFTQUOTE_INITIATED:
      return {
        ...state,
        message: false,
        error: false,
        loading: true,
        shiftQuote: {}
      };
    case actions.GETSHIFTQUOTE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        shiftQuote: action.payload
      };
    case actions.GETSHIFTQUOTE_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        shiftQuote: {}
      };
    case actions.CLEAR_SHIFT:
      return {
        ...state,
        ...initialState
      };
    case actions.SET_SHIFTID:
      return {
        ...state,
        shiftId: action.payload
      };
    case 'startTime':
      return {
        ...state,
        startTime: action.payload
      };
    case 'startDate':
      return {
        ...state,
        startDate: action.payload
      };
    case 'ButtonClicked':
      return {
        ...state,
        isClicked: action.payload
      };
    case 'isNotification':
      return {
        ...state,
        show_notification: true
      };
    case actions.GET_SHIFTJOB_INITIATED:
      return {
        ...state,
        shiftJobs: [],
        message: false,
        error: false,
        loading: true
      };
    case actions.GET_SHIFTJOB_SUCCESS:
      return {
        ...state,
        shiftJobs: action.payload,
        loading: false,
        error: false,
        message: 'get shift-job data'
      };
    case actions.GET_SHIFTJOB_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        message: action.error,
        shiftJobs: []
      };
    case actions.VALIDATESHIFT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        isValidate: true,
        shiftDetails: {}
      };
    case actions.VALIDATESHIFT_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        isValidate: false,
        message: action.error,
        shiftDetails: {}
      };
    case actions.GET_COURIER_LOCATION_INITIATED:
      return {
        ...state,
        courierLocation: [],
        message: false,
        error: false,
        loading: true
      };
    case actions.GET_COURIER_LOCATION_SUCCESS:
      return {
        ...state,
        courierLocation: action.payload,
        loading: false,
        error: false
      };
    case actions.GET_COURIER_LOCATION_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        message: action.error,
        courierLocation: []
      };
    case actions.REMOVESHIFTDRAFT:
      return {
        ...state,
        shiftJobs: [...action.payload]
      };
    case actions.CANCELSHIFTJOB_INITIATED:
      return {
        ...state,
        error: false,
        loading: true
      };
    case actions.CANCELSHIFTJOB_SUCCESS:
      return {
        ...state,
        shiftDetails: {},
        message: false,
        error: false,
        loading: false,
        cancelShift: action.payload
      };
    case actions.CANCELSHIFTJOB_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        message: action.error
      };
    case actions.STARTTIMEOPEN:
      return {
        ...state,
        startTimeOpen: action.payload
      };
    case actions.ENDTIMEOPEN:
      return {
        ...state,
        endTimeOpen: action.payload
      };
    default:
      return state;
  }
};
export default shift;