import { axiosPost } from 'modules/Axios';
import * as actions from './constant';
import { loader } from 'redux/app/actions';

export const resetPassword = payload => async dispatch => {
  try {
    dispatch(loader(true));
    dispatch({ type: actions.RESETPASSWORD_INITIATED });
    let response = await axiosPost('registration/password-reset-request', payload);
    if (!response.success) {
      dispatch({
        type: actions.RESETPASSWORD_ERROR,
        error: response.error.message
      });
    } else {
      dispatch({
        type: actions.RESETPASSWORD_SUCCESS,
        payload: response
      });
    }
  } catch (error) {
    console.log(error);
    dispatch({
      type: actions.RESETPASSWORD_ERROR,
      error
    });
  } finally {
    dispatch(loader(false));
  }
};
