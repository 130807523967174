import { Card, Col, Form, Modal, Row, Tooltip } from 'antd';
import { FormValidation } from 'App/AppConstant';
import { ReactComponent as EmailSvg } from 'assets/images/email-icon.svg';
import defaultImage from 'assets/images/profile-no-image.png';
import BookingHeader from 'components/BookingHeader';
import { ProfilePicture } from 'components/BookingHeader/style';
import { HeaderInfo, NoData } from 'components/CommonComponent';
import { CardHeader, MaxContainer, MiddleSection } from 'components/CommonStyle/style';
import { FieldInput, FormButton as Button, FormGroup, FormInput as Input } from 'components/Form';
import TeamListing from 'components/Teams/TeamListing';
import { Field, Formik } from 'formik';
import isEmpty from 'lodash.isempty';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { addTeam, deleteMember, deleteTeam, getTeams, inviteMember, openEdit, reInviteMember, updateTeam } from 'redux/team/actions';
import * as Yup from 'yup';
import { SearchWrapper, TeamWrapper } from './style';

const initialFormData = {
  team_name: ''
};
export const teamSchema = Yup.object().shape({
  team_name: Yup.string().trim().required(FormValidation.teamNameRequired)
});
const memberSchema = Yup.object().shape({
  email: Yup.string()
    .required(FormValidation.emailRequired)
    .email(FormValidation.emailInvalid)
});
class Booking extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalVisible: false,
      teams: [],
      adminTeams: [],
      memberTeams: [],
      selectedTeam: {},
      teamMates: '-',
      searchTerm: ''
    };
    this.container = React.createRef();
  }
  async componentDidMount() {
    await this.props.getTeams();
  }
  componentDidUpdate(prevProps) {
    const { teams } = this.props;
    if (teams !== prevProps.teams) {
      this.filterTeams();
    }
  }
  filterTeams = () => {
    let { teams } = this.props;
    let adminTeams = [],
      memberTeams = [];
    if (!isEmpty(teams) && !isEmpty(teams.teams)) {
      this.setState({
        teamMates: teams.team_members_count
      });
      teams.teams.map(team => {
        if (team.owner) {
          adminTeams.push(team);
        } else {
          memberTeams.push(team);
        }
      });
    }
    this.setState({
      adminTeams,
      memberTeams,
      selectedTeam: !isEmpty(adminTeams) ? adminTeams[0] : !isEmpty(memberTeams) ? memberTeams[0] : {}
    });
  };

  modalChange = () => {
    this.setState({
      modalVisible: true
    });
  };
  _searchFilter = () => {
    let { searchTerm } = this.state;
    return x =>
      x.name.toLowerCase().includes(searchTerm.toLowerCase()) || x.email.toLowerCase().includes(searchTerm.toLowerCase()) || !searchTerm;
  };
  handleCancel = e => {
    this.setState({
      modalVisible: false
    });
  };

  // ANCHOR - ADD NEW TEAM SUBMIT METHOD FROM WHERE THE NEW TEAM IS ADDED.
  handleSubmit = async (values, options) => {
    let { team_name } = values;
    team_name = team_name.trim()
    let status = await this.props.addTeam({ team_name });
    if (status) {
      options.resetForm();
      this.setState({
        modalVisible: false
      });
    }
  };
  handleMemberSubmit = async (values, options) => {
    let { selectedTeam } = this.state;
    let status = await this.props.inviteMember({ ...values, team_id: selectedTeam.id });
    if (status) {
      options.resetForm();
    }
  };
  handleMemberDelete = async data => {
    await this.props.deleteMember({ team_member_id: data.team_member_id });
  };
  _handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };
  changeSelectedTeam = data => {
    this.setState({
      selectedTeam: {
        ...data
      }
    });
  };

  //  ANCHOR -> ADD NEW TEAM MODAL
  ModalForm = () => {
    return (
      <Formik
        initialValues={{
          ...initialFormData
        }}
        validationSchema={teamSchema}
        onSubmit={this.handleSubmit}
        enableReinitialize
      >
        {({ handleSubmit }) => (
          <>
            <h5 className="field-title">TEAM NAME</h5>
            <Form onSubmit={handleSubmit}>
              <FormGroup className="add-team">
                <Field name="team_name" placeholder="Enter team name" component={FieldInput} className="addTeam" />
                <div className="plus-btns-group">
                  <Button className={`new-btn custom`} htmlType="submit">
                    CREATE NEW TEAM
                  </Button>
                </div>
              </FormGroup>
            </Form>
          </>
        )}
      </Formik>
    );
  };
  render() {
    const { modalVisible, adminTeams, memberTeams, selectedTeam, searchTerm, teamMates } = this.state;
    const { userData } = this.props;
    const { teams } = this.props;
    let pending_arr = [];
    return (
      <TeamWrapper>
        <BookingHeader active="Teams" />
        <MiddleSection className="middle-section padding-tb50">
          <MaxContainer className="max-container max" ref={this.container}>
            <HeaderInfo title="Teams" />
            <div className="team-main-content">
              <Row gutter={30} type="flex">
                <Col xs={14} sm={14} md={14} lg={8} className="left-block">
                  <Card bordered={false}>
                    <div className="team-left-box">
                      <div className="team-p-l-main d-flex">
                        <div className="d-flex">
                          <i className="image-box">
                            <ProfilePicture profilePicutre={!isEmpty(userData) && userData.photo ? userData.photo : defaultImage} />
                            {/* <img src={defaultImage} /> */}
                          </i>
                          <div className="team-count-main d-flex">
                            <div className="team-count">
                              <span>{!isEmpty(teams) && !isEmpty(teams.teams) ? teams.teams.length : 0}</span>
                              <strong>teams</strong>
                            </div>
                            <div className="team-count">
                              <span>{teamMates}</span>
                              <strong>teamMATES</strong>
                            </div>
                          </div>
                        </div>
                      </div>
                      <Modal
                        visible={modalVisible}
                        onCancel={this.handleCancel}
                        footer={null}
                        onOk={this.handleOk}
                        title="Add Team"
                        className="custom-primary-modal"
                        getContainer={() => document.querySelector('.team-left-box')}
                        centered
                      >
                        <div className="main-content">
                          <Row>
                            <Col md={24}>{this.ModalForm()}</Col>
                          </Row>
                        </div>
                      </Modal>
                      {/* ANCHOR Teams Listing */}
                      {!isEmpty(selectedTeam) ? (
                        <TeamListing
                          adminTeams={adminTeams}
                          memberTeams={memberTeams}
                          selectedTeam={selectedTeam}
                          changeSelectedTeam={this.changeSelectedTeam}
                          takeRef={this.container}
                        />
                      ) : (
                        <NoData title="No Team" />
                      )}
                      <div className="plus-btns-group">
                        <Button className={`new-btn add-teams`} htmlType="submit" onClick={this.modalChange}>
                          NEW TEAM
                        </Button>
                      </div>
                    </div>
                  </Card>
                </Col>
                <Col xs={24} sm={24} md={24} lg={16} className="right-block">
                  {/* ANCHOR Team Members Listing */}
                  <Card
                    bordered={false}
                    // title={!isEmpty(selectedTeam) ? selectedTeam.name : undefined}
                    title="Team Members Board"
                    className="team-detail-wrapper-card"
                  >
                    {!isEmpty(selectedTeam) ? (
                      <>
                        <div className="team-right-header">
                          <Row gutter={15} type="flex">
                            <SearchWrapper
                              className={`${!isEmpty(selectedTeam) && selectedTeam.owner ? '' : 'no-email'
                                } ${'team-search-box left ml-auto'}`}
                            >
                              <Input
                                type="text"
                                name="searchTerm"
                                placeholder="Search a Team Member"
                                value={searchTerm}
                                onChange={this._handleChange}
                              />
                              <button className="search-btn left" type="button"></button>
                            </SearchWrapper>
                            {!isEmpty(selectedTeam) && selectedTeam.owner && (
                              <Formik
                                initialValues={{
                                  email: ''
                                }}
                                validationSchema={memberSchema}
                                enableReinitialize
                                onSubmit={this.handleMemberSubmit}
                              >
                                {({ handleSubmit, values, resetForm }) => (
                                  <SearchWrapper className="team-search-box left ml-auto email-wrapper">
                                    <Field name="email" placeholder="Invite a Team Member" component={FieldInput} />
                                    <EmailSvg onClick={() => handleSubmit(values, resetForm)} className="email-btn" />
                                    {/* <button className="mail-btn left" type="button"></button> */}
                                  </SearchWrapper>
                                )}
                              </Formik>
                            )}
                          </Row>
                        </div>
                        <hr />
                        <div className="teams-col-list">
                          <Row>
                            <Col>
                              <strong className="team-number">MEMBERS</strong>
                              <Row type="flex" gutter={[15, 20]}>
                                {!isEmpty(selectedTeam) && !isEmpty(selectedTeam.members)
                                  ? selectedTeam.members.filter(this._searchFilter()).map((data, index) => {
                                    if (data.status === '1') {
                                      return (
                                        <Col xs={24} sm={24} md={6} lg={6} key={index}>
                                          <div className="teams-item-inner">
                                            <i className="image-box">
                                              <img src={data.photo || defaultImage} alt="" />
                                            </i>
                                            <div className="teambox-content">
                                              <h6>{data.status === '0' ? 'Pending' : data.name}</h6>
                                              <p>{data.team_owner ? 'Owner' : 'Member'}</p>
                                            </div>
                                          </div>
                                        </Col>
                                      );
                                    } else if (data.status === '0') {
                                      pending_arr.push(data);
                                    }
                                  })
                                  : null}
                              </Row>
                            </Col>
                            <Col className="pending-user-list">
                              <strong className="team-number">PENDING INVITES</strong>
                              <Row type="flex" gutter={[15, 20]}>
                                {!isEmpty(selectedTeam) && !isEmpty(selectedTeam.members)
                                  ? selectedTeam.members.filter(this._searchFilter()).map((data, index) => {
                                    if (data.status === '0') {
                                      return (
                                        <Col xs={24} sm={24} md={6} lg={6} key={index}>
                                          <CardHeader>
                                            <div className="teams-item-inner" id="email-tooltip">
                                              <i className="image-box">
                                                <img src={data.photo || defaultImage} alt="" />
                                              </i>
                                              <div className="teambox-content">
                                                <Tooltip
                                                  title={data.email}
                                                  getPopupContainer={() => document.getElementById('email-tooltip')}
                                                >
                                                  <h6 className="pending-user-email">{data.email}</h6>
                                                </Tooltip>
                                                <p>Pending</p>
                                                <p>
                                                  {data.status === '0' && (
                                                    <a
                                                      className="reset-link"
                                                      onClick={() => {
                                                        this.props.reInviteMember({
                                                          team_member_id: data.team_member_id
                                                        });
                                                      }}
                                                    >
                                                      Resend Link
                                                    </a>
                                                  )}
                                                </p>
                                              </div>
                                            </div>
                                          </CardHeader>
                                        </Col>
                                      );
                                    }
                                  })
                                  : null}
                              </Row>
                            </Col>
                          </Row>
                        </div>
                      </>
                    ) : (
                      <NoData title={'No Team'} />
                    )}
                  </Card>
                </Col>
              </Row>
            </div>
          </MaxContainer>
        </MiddleSection>
      </TeamWrapper>
    );
  }
}
const mapStateToProps = state => ({
  setEdit: state.team.setEdit,
  userData: state.profileUpdate.userData,
  teams: state.team.teams
});
const mapDispatchToProps = dispatch => ({
  addTeam: payload => dispatch(addTeam(payload)),
  deleteTeam: payload => dispatch(deleteTeam(payload)),
  getTeams: payload => dispatch(getTeams(payload)),
  openEdit: payload => dispatch(openEdit(payload)),
  updateTeam: payload => dispatch(updateTeam(payload)),
  inviteMember: payload => dispatch(inviteMember(payload)),
  deleteMember: payload => dispatch(deleteMember(payload)),
  reInviteMember: payload => dispatch(reInviteMember(payload))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Booking));
