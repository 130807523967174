const SignupConstant = {
  login: 'Login',
  signUp: 'Sign Up',
  title: 'Welcome to Gophr!',
  subTitleSignup: 'Sign up and let’s get started!',
  personalProfile: 'Personal Profile',
  businessProfile: 'Business Profile',
  buttonText: 'NEXT',
  cancelbtn: 'Cancel',
  gophrCourier: 'GophrCourier',
  firstName: 'FIRST NAME',
  firstName_sm: 'firstName',
  lastName: 'LAST NAME',
  lastName_sm: 'Last Name',
  email: 'E-MAIL',
  email_sm: 'email',
  password: 'PASSWORD',
  confirm_password: 'CONFIRM PASSWORD',
  password_sm: 'password',
  SocialLogin: 'OR SIGN UP WITH',
  streetNumber: 'STREET, NUMBER',
  streetNumber_sm: 'streetNumber',
  passcode: 'POSTCODE',
  passcode_sm: 'passcode',
  building: 'BUILDING, FLOOR, FLAT',
  city: 'CITY',
  country: 'COUNTRY',
  city_sm: 'city',
  country_sm: 'country',
  phoneNumber: 'PHONE NUMBER',
  phoneNumber2: 'PHONE NUMBER 02',
  phoneNumber_sm: 'phoneNumber',
  usefullTips: 'USEFUL TIPS',
  startTypeing: 'Start Typing Here',
  oneFinal: 'One Final Step',
  sentCode: 'We’ve sent you a code! Please verify your e-mail to continue.',
  codeInput: 'Code input',
  contactUs: 'Problem? Contact us at yo@gophr.com',
  agreeTerms: 'I agree to Terms and Conditions *',
  agreePolicy: 'I agree with Privacy Policy *',
  resendCode: 'Resend Code',
  createProfile: 'Create Profile',
  countryCode: 'countryCode',
  countryCode2: 'countryCode2',
  agreeTermsName: 'agreeTerms',
  agreePolicyName: 'agreePolicy',
  code: 'code',
  profile: 'PROFILE',
  mobilenum1: 'PHONE NUMBER 01',
  mobilenum2: 'PHONE NUMBER 02',
  viewOnGoogleMaps: 'View on Google Maps',
  usefulTips: 'USEFUL TIPS',
  resetMap: 'RESET MAP'
};
const SignupPlaceHoder = {
  googleSearch: 'Address Lookup',
  streetName: '35 Friar Street 57',
  passcode: 'JF23 1FD',
  buildingName: 'Building, Floor, Flat',
  cityName: 'City name',
  yourCity: 'Your city',
  yourCountry: 'Your country',
  firstName: 'First name',
  lastName: 'Last name',
  email: 'example@mail.com',
  password: 'Enter your password',
  confirm_password: 'Re-Enter your password'
};

const countryPhoneCode = ['+44'];
const profileType = ['Personal', 'Business'];
const confirmView = ['CONFIRM VIEW'];

export { SignupConstant, SignupPlaceHoder, countryPhoneCode, profileType, confirmView };
