export const GETCURRENTHISTORY_INITIATED = 'GETCURRENTHISTORY_INITIATED';
export const GETCURRENTHISTORY_ERROR = 'GETCURRENTHISTORY_ERROR';
export const GETCURRENTHISTORY_SUCCESS = 'GETCURRENTHISTORY_SUCCESS';

export const GETFILTEREDHISTORY_SUCCESS = 'GETFILTEREDHISTORY_SUCCESS';
export const GETSAMEDAYFILTEREDHISTORY_SUCCESS = 'GETSAMEDAYFILTEREDHISTORY_SUCCESS';
export const GETFILTEREDSHIFTHISTORY_SUCCESS = 'GETFILTEREDSHIFTHISTORY_SUCCESS';

export const REBOOKJOB_INITIATED = 'REBOOKJOB_INITIATED';
export const REBOOKJOB_ERROR = 'REBOOKJOB_ERROR';
export const REBOOKJOB_SUCCESS = 'REBOOKJOB_SUCCESS';

export const SET_QUERY_OPTIONS = 'SET_QUERY_OPTIONS';
export const SHIFT_QUERY_OPTIONS = 'SHIFT_QUERY_OPTIONS';

export const GETSHIFTHISTORY_INITIATED = 'GETSHIFTHISTORY_INITIATED';
export const GETSHIFTHISTORY_ERROR = 'GETSHIFTHISTORY_ERROR';
export const GETSHIFTHISTORY_SUCCESS = 'GETSHIFTHISTORY_SUCCESS';

export const GETSAMEDAYHISTORY_INITIATED = 'GETSAMEDAYHISTORY_INITIATED';
export const GETSAMEDAYHISTORY_ERROR = 'GETSAMEDAYHISTORY_ERROR';
export const GETSAMEDAYHISTORY_SUCCESS = 'GETSAMEDAYHISTORY_SUCCESS';

export const GETINVOICEHISTORY_INITIATED = 'GETINVOICEHISTORY_INITIATED';
export const GETINVOICEHISTORY_ERROR = 'GETINVOICEHISTORY_INITIATED';
export const GETINVOICEHISTORY_SUCCESS = 'GETINVOICEHISTORY_SUCCESS' ;
