import { Col, Collapse, Rate, Row, Tooltip } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { hideOverflow, jobTrackerStatusList, MultiJobsType } from 'App/AppConstant';
import { ReactComponent as DropPin } from 'assets/images/delivery-small-icon.svg';
import { ReactComponent as DropDot } from 'assets/images/drop-dot-icon.svg';
import { ReactComponent as PickUpDot } from 'assets/images/pickup-dot-icon.svg';
import { ReactComponent as PickUpPin } from 'assets/images/pickup-small-icon.svg';
import profileImage from 'assets/images/profile-no-image.png';
import { ReactComponent as PublicDot } from 'assets/images/public-dots.svg';
import { MiddleSection } from 'components/AuthWrapper/style';
import { vehcileSelectionData } from 'components/Booking/constant';
import { VehicleSelectionWrapper } from 'components/Booking/style';
import { CommonTabWrapper } from 'components/CommonStyle/style';
import { FormButton as Button, TabPane, Tabs } from 'components/Form';
import { FormWrapper } from 'components/InputIcon/style';
import MapMarker from 'components/MapMarker';
import { BookingConstant } from 'container/Booking/constant';
import { flagsList } from 'container/JobBoards/constant';
import isEmpty from 'lodash.isempty';
import moment from 'moment';
import React, { Component } from 'react';
import ReactLoading from 'react-loading';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  getPublicJob,
  getPublicParcel,
  publicCourierDetails,
  publicCourierLocation,
  publicCourierManifest,
  rateCourierPublic
} from 'redux/app/actions';
import { getParcel, showFeedBackfromPubnub, showLoaderfromPubnub } from 'redux/booking/actions';
const { Panel } = Collapse;

class PublicTracker extends Component {
  state = {
    collapseAddressData: '',
    type: '',
    showAddressData: false,
    showLoader: false,
    showFeedBack: false,
    feedBackComments: '',
    feedBackRatings: 1.0,
    feedbackSubmited: false,
  };
  async componentDidMount() {
    const { params } = this.props.match;
    let payload = {
      id: params.jobId,
      hash: params.hashId,
      location: params.locationType
    };
    await this.props.publicCourierLocation(payload);
    await this.props.getPublicJob(payload);
    await this.props.getPublicParcel(payload);
    await this.props.publicCourierManifest(payload);
    await this.props.publicCourierDetails(payload);
    this.setState({ showJobTracker: true });
    this.mapinterval = setInterval(this.getLocation, 10000);
    this.courierManifestinterval = setInterval(this.getCoutierManifest, 60000);
    // setInterval(() => {
    //   this.props.publicCourierManifest(payload);
    // }, 60000);
    // setInterval(() => {
    //   this.props.publicCourierLocation(payload);
    // }, 10000);
  }
  async componentDidUpdate(prevProps, prevState) {
    const { showLoader, feedbackSubmited } = this.state;
    const { showLoaderProps, showFeedBackProps, locationData } = this.props;
    const id = this.props.match.params.jobId;
    if (showLoaderProps !== prevProps.showLoaderProps) {
      if (!isEmpty(showLoaderProps)) {
        if (showLoaderProps.id == id) {
          this.setState({ showLoader: true, showFeedBack: false });
        } else this.props.showLoaderfromPubnub({});
      }
    }
    if (showFeedBackProps !== prevProps.showFeedBackProps) {
      if (!isEmpty(showFeedBackProps)) {
        if (showFeedBackProps.id == id) {
          this.setState({ showFeedBack: true, showLoader: false });
        } else this.props.showFeedBackfromPubnub({});
      }
    }
    if (locationData !== prevProps.locationData) {
      if (!isEmpty(locationData) && !isEmpty(locationData.job_status)) {
        if (locationData.job_status === 'CONFIRMED_BY_CUSTOMER') {
          this.setState({ showLoader: true });
        }
        if ((locationData.job_status === 'DELIVERED' || locationData.job_status === 'CANCELED') && !feedbackSubmited) {
          this.setState({ showLoader: false, showFeedBack: true });
        } else {
          this.setState({ showLoader: false, showFeedBack: false });
        }
      }
    }
  }
  componentWillUnmount() {
    clearInterval(this.timerInterval);
    clearInterval(this.mapinterval);
    clearInterval(this.courierManifestinterval);
  }
  showAddressInfo = (data, type) => {
    this.setState({ showAddressData: true, collapseAddressData: data, type });
  };
  jobTrackingPickAddress = () => {
    const { jobDetails } = this.props;
    let ui = [];
    if (jobDetails.multi_job_type === MultiJobsType.MULTI_PICKUP) {
      jobDetails.subjobs.map(data => {
        ui.push(
          <>
            <p>
              <PublicDot className="dots-icon right-space" />
            </p>
            <p onClick={e => this.showAddressInfo(data)} className="cs-pointer">
              <PickUpPin className="right-space" />
              <span className="right-space">{moment(data.earliest_pickup_time).format('HH:mm')}</span>
            </p>
            <span className="right-space">
              {(!isEmpty(data.pickup_address1) ? data?.pickup_address1 + ', ' : '') + data?.pickup_city + ', ' + data?.pickup_postcode}
            </span>
          </>
        );
      });
    } else {
      ui.push(
        <>
          <p>
            <PublicDot className="dots-icon right-space" />
          </p>
          <p>
            <PickUpPin className="right-space" />
            <span className="right-space">{moment(jobDetails.earliest_pickup_time).format('HH:mm')}</span>
            <span>
              {(!isEmpty(jobDetails.pickup_address1) ? jobDetails?.pickup_address1 + ', ' : '') +
                jobDetails?.pickup_city +
                ', ' +
                jobDetails?.pickup_postcode}
            </span>
          </p>
        </>
      );
    }
    return <>{ui}</>;
  };
  showAddressInfo = data => {
    this.setState({ showAddressData: true, collapseAddressData: data });
  };
  jobTrackingdeliveryAddress = () => {
    const { jobDetails } = this.props;
    let ui = [];
    if (jobDetails.multi_job_type === MultiJobsType.MULTI_DROP) {
      jobDetails.subjobs.map(data => {
        ui.push(
          <>
            <p>
              <PublicDot className="dots-icon right-space" />
            </p>
            <p onClick={e => this.showAddressInfo(data)} className="cs-pointer">
              <DropPin className="right-space" />
              <span className="right-space">{moment(data.delivery_deadline).format('HH:mm')}</span>
              <span>
                {(!isEmpty(data.delivery_address1) ? data?.delivery_address1 + ', ' : '') +
                  data?.delivery_city +
                  ', ' +
                  data?.delivery_postcode}
              </span>
            </p>
          </>
        );
      });
    } else {
      ui.push(
        <>
          <p>
            <PublicDot className="dots-icon right-space" />
          </p>
          <p>
            <DropPin className="right-space" />
            <span className="right-space">{moment(jobDetails.delivery_deadline).format('HH:mm')}</span>
            <span>
              {(!isEmpty(jobDetails.delivery_address1) ? jobDetails?.delivery_address1 + ', ' : '') +
                jobDetails?.delivery_city +
                ', ' +
                jobDetails?.delivery_postcode}
            </span>
          </p>
        </>
      );
    }
    return <>{ui}</>;
  };
  timeDiffCalc(dateFuture, dateNow) {
    let diffInMilliSeconds = Math.abs(dateFuture - dateNow) / 1000;

    // calculate days
    const days = Math.floor(diffInMilliSeconds / 86400);
    diffInMilliSeconds -= days * 86400;
    // console.log('calculated days', days);

    // calculate hours
    const hours = Math.floor(diffInMilliSeconds / 3600) % 24;
    diffInMilliSeconds -= hours * 3600;
    // console.log('calculated hours', hours);

    // calculate minutes
    const minutes = Math.floor(diffInMilliSeconds / 60) % 60;
    diffInMilliSeconds -= minutes * 60;
    // console.log('minutes', minutes);

    let difference = '';
    if (days > 0) {
      difference += days === 1 ? `${days} day, ` : `${days} days, `;
    }

    difference += hours === 0 || hours === 1 ? `${hours} hr, ` : `${hours} hrs, `;

    difference += minutes === 0 || hours === 1 ? `${minutes} min` : `${minutes} mins`;

    return difference;
  }
  timeFunc(date) {
    let date1 = '',
      date2 = '';
    date1 = new Date(Date.now());
    date2 = new Date(date);
    var res = Math.abs(date1 - date2) / 1000;
    var hours = Math.floor(res / 3600) % 24;
    var minutes = Math.floor(res / 60) % 60;
    var seconds = res % 60;
    return { hours, minutes, seconds: parseInt(seconds) };
  }
  showTimer = timeValue => {
    let minutes = 0,
      seconds = 0,
      hours = 0,
      ui = [];
    const time = this.timeFunc(timeValue);
    minutes = time.minutes;
    seconds = time.seconds;
    hours = time.hours;
    this.timerInterval = setInterval(() => {
      seconds++;
      // if (seconds < 59) {
      //   this.setState(({ seconds }) => ({
      //     seconds: seconds + 1
      //   }));
      // }
      // if (seconds === 59) {
      //   this.setState(({ minutes }) => ({ minutes: minutes + 1, seconds: 0 }));
      // }
      if (minutes === 59 && seconds === 60) {
        minutes = 0;
        seconds = 0;
        hours += 1;
      } else if (seconds === 60) {
        seconds = 0;
        minutes += 1;
      }
      // if (seconds <= 9) {
      //   seconds = '0' + seconds;
      // }
      const hourExist = document.getElementById('hours');
      const minExist = document.getElementById('minutes');
      const secExist = document.getElementById('seconds');
      if (hourExist) {
        if (hours < 10) document.getElementById('hours').innerHTML = '0' + hours;
        else document.getElementById('hours').innerHTML = hours;
      }
      if (minExist) {
        if (minutes < 10) document.getElementById('minutes').innerHTML = '0' + minutes;
        else document.getElementById('minutes').innerHTML = minutes;
      }
      if (secExist) {
        if (seconds < 10) document.getElementById('seconds').innerHTML = '0' + seconds;
        else document.getElementById('seconds').innerHTML = seconds;
      }
    }, 1000);
    // if (minutes == 0 && seconds == 0) {
    //   ui.push(<span style={{ color: '#00C68D' }}>Completed</span>);
    // } else ui.push(<>{minutes + ':' + seconds}</>);
    // return <>{ui}</>;
  };
  handleVehicleSelection = data => {
    return vehcileSelectionData.map((vehicle, i) => {
      if (!isEmpty(data) && data.vehicle_type == vehicle.value)
        return (
          <VehicleSelectionWrapper className="jobTracker" key={Math.random()}>
            <div className="vehicle-group">
              <ul className="vehicles details-side">
                <Tooltip className="d-flex" placement="top" disabled={true}>
                  <li className={`border active`}>
                    <img src={vehicle.file} className="vehicle-img" />
                  </li>
                </Tooltip>
              </ul>
              <div className="vehicle-data-wrapper">
                <div>
                  <span>VEHICLE:</span> <br />
                  <span>WEIGHT:</span> <br />
                  <span>MAX-SIZE:</span>
                </div>
                <div>
                  {vehicle.label} <br />
                  {vehicle.weight} <br />
                  {vehicle.size}
                </div>
              </div>
            </div>
          </VehicleSelectionWrapper>
        );
    });
  };
  jobTrackingParcelDetials = row => {
    let ui = [],
      finalflags = [],
      flags = {},
      selectedFlags = [];
    const { parcels } = this.props;
    flags = {
      is_food: row.is_food,
      is_not_rotatable: row.is_not_rotatable,
      is_fragile: row.is_fragile,
      is_liquid: row.is_liquid,
      is_glass: row.is_glass,
      is_baked: row.is_baked,
      is_flower: row.is_flower,
      is_alcohol: row.is_alcohol,
      is_beef: row.is_beef,
      is_pork: row.is_pork,
      is_contactless: row.is_contactless,
      is_medical: row.is_medical
    };
    Object.keys(flags).map(key => {
      if (flags[key] == 1) {
        selectedFlags.push(key);
      }
    });
    flagsList.map(f => {
      if (selectedFlags.includes(f.value)) {
        finalflags.push(f.label);
      }
    });
    if (!isEmpty(parcels) && !isEmpty(parcels.jobs)) {
      parcels.jobs.map((data, i) => {
        if (row.id == data.job_id) {
          ui.push(
            <>
              <p>
                <i className={`cube`}></i>
                <span className="parcel-data">{!isEmpty(data.parcels.description) ? data.parcels.description : `Parcel ${i + 1}`}</span>
              </p>
              <p>
                <i className={`parcel-icon`}></i>
                <span className="parcel-data">{`${!isEmpty(data.parcels.size_x) ? parseFloat(data.parcels.size_x) : 0} 
                ${BookingConstant.multiply} ${!isEmpty(data.parcels.size_y) ? parseFloat(data.parcels.size_y) : 0}
                ${BookingConstant.multiply} ${!isEmpty(data.parcels.size_z) ? parseFloat(data.parcels.size_z) : 0} cms`}</span>
              </p>
              <p>
                <i className={`warning-icon`}></i>
                <span className="parcel-data">{!isEmpty(finalflags) ? finalflags.map(f => f) : 'N/A'}</span>
              </p>
            </>
          );
        }
      });
    }
    if (!isEmpty(ui)) return <>{ui}</>;
    else return <p>No Parcels</p>;
  };
  changeComment = value => {
    this.setState({ feedBackComments: value });
  };
  ratingChanged = newRating => {
    this.setState({ feedBackRatings: parseFloat(newRating) });
  };
  feedBackSubmit = () => {
    const payload = {
      id: this.props.match.params.jobId,
      rating: this.state.feedBackRatings,
      comment: this.state.feedBackComments,
      hash: this.props.match.params.hashId,
      location: this.props.match.params.locationType
    };
    this.props.rateCourierPublic(payload);
    this.setState({ feedBackComments: '', feedBackRatings: 1.0, feedbackSubmited: true });
  };
  PublicTracking = () => {
    hideOverflow();
    const { locationData, manifestData, jobDetails } = this.props;
    const { collapseAddressData } = this.state;
    let Date = '',
      markerPoints = [],
      courierPoints = [],
      boundPoints = [],
      emptyPoints = true,
      jobType = '',
      ETAData = '',
      SUB_ETA_DATA;
    courierPoints.push({
      latitude: locationData?.current_location_lat,
      longitude: locationData?.current_location_lng,
      data: locationData
    });
    boundPoints.push({
      latitude: locationData?.current_location_lat,
      longitude: locationData?.current_location_lng
    });
    if (!isEmpty(manifestData) && !isEmpty(manifestData.manifest)) {
      manifestData.manifest.map(jobs => {
        markerPoints.push({
          latitude: jobs.lat,
          longitude: jobs.lng,
          data: jobs
        });
        if (!isEmpty(jobs.job_id))
          boundPoints.push({
            latitude: jobs.lat,
            longitude: jobs.lng
          });
      });
    } else {
      if (jobDetails.multi_job_type === MultiJobsType.MULTI_DROP) {
        markerPoints.push({
          latitude: jobDetails.pickup_location_lat,
          longitude: jobDetails.pickup_location_lng,
          color: 'green'
        });
        jobDetails.subjobs.map(data => {
          markerPoints.push({
            latitude: data.delivery_location_lat,
            longitude: data.delivery_location_lng
          });
        });
      } else if (jobDetails.multi_job_type === MultiJobsType.MULTI_PICKUP) {
        {
          jobDetails?.parcel_store
            ? markerPoints.push({
              color: 'orange',
              latitude: jobDetails?.parcel_store?.location_lat,
              longitude: jobDetails?.parcel_store?.location_lng
            })
            : jobDetails.subjobs.map(data => {
              markerPoints.push({
                color: 'green',
                latitude: data.pickup_location_lat,
                longitude: data.pickup_location_lng
              });
            });
          markerPoints.push({
            latitude: jobDetails.delivery_location_lat,
            longitude: jobDetails.delivery_location_lng
          });
        }
      } else {
        markerPoints.push({
          latitude: jobDetails.delivery_location_lat,
          longitude: jobDetails.delivery_location_lng
        });
        markerPoints.push({
          latitude: jobDetails.pickup_location_lat,
          longitude: jobDetails.pickup_location_lng,
          color: 'green'
        });
      }
      boundPoints = markerPoints;
      courierPoints = [];
    }
    // markerPoints.push({
    //   latitude: (locationData?.current_location_lat),
    //   longitude: locationData?.current_location_lng
    // });
    if (!isEmpty(markerPoints)) {
      markerPoints.map(m => {
        if (!isEmpty(m.latitude) && !isEmpty(m.longitude)) {
          emptyPoints = false;
        }
      });
    }
    // if (!isEmpty(courierPoints)) {
    //   if (!isEmpty(courierPoints.latitude) && !isEmpty(courierPoints.longitude)) {
    //     emptyPoints = false;
    //   }
    // }
    if (!isEmpty(locationData)) {
      const d = new window.Date();
      ETAData = this.timeDiffCalc(
        new window.Date(!isEmpty(locationData.eta) ? locationData.eta : moment(d).format('YYYY-MM-DD HH:mm:ss')),
        new window.Date(moment(d).format('YYYY-MM-DD HH:mm:ss'))
      );
    }
    if (!isEmpty(collapseAddressData)) {
      const d = new window.Date();
      SUB_ETA_DATA = this.timeDiffCalc(
        new window.Date(!isEmpty(collapseAddressData.eta) ? collapseAddressData.eta : moment(d).format('YYYY-MM-DD HH:mm:ss')),
        new window.Date(moment(d).format('YYYY-MM-DD HH:mm:ss'))
      );
    }
    if (this.state.showAddressData && !isEmpty(collapseAddressData) && !isEmpty(collapseAddressData.date_checked))
      this.showTimer(collapseAddressData.date_checked);
    return (
      <div className={`job-tracker-wrapper`}>
        <Row className="invoice-row">
          <Col xs={24} sm={24} md={24} lg={10} xl={8} xxl={6} className="data-col">
            <Row type="flex" className={`job-board-pickup-row`}>
              <div className="invoice-wrapper job-tracker">
                <div className="Page-title public-tracker">
                  <p className="title-text">
                    <i className="gophr-logo" />
                    <span className="light-text">GOPHR</span>
                  </p>
                  <p className="title-text live-tracking">
                    <span>LIVE GPS TRACKING</span>
                  </p>
                </div>
                <div className="tracking-data">
                  {this.state.showFeedBack ? (
                    <div className="shifts-invoice-wrapper">
                      <p className="star-label public">How was your job?</p>
                      <Rate
                        value={this.state.feedBackRatings}
                        className="rating-wrapper"
                        allowClear={false}
                        style={{ color: '#8f48ff' }}
                        onChange={this.ratingChanged}
                      />
                      <div className="shifts-invoice-wrapper">
                        <p className="comment-label">Comment</p>
                        <TextArea
                          placeholder=" Your comment here"
                          rows={10}
                          autoSize={false}
                          maxLength={300}
                          value={this.state.feedBackComments}
                          onChange={e => this.changeComment(e.target.value)}
                        />
                        <i className="label-address">
                          {!isEmpty(this.state.feedBackComments) ? `${this.state.feedBackComments.length}/300` : '0/300'}
                        </i>
                      </div>
                      <Row>
                        <Col lg={12}></Col>
                        <Col lg={12}>
                          <Button
                            className="new-btn"
                            onClick={this.feedBackSubmit}
                            disabled={!isEmpty(this.props.match.params.locationType) ? false : true}
                          >
                            SUBMIT
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  ) : this.state.showLoader ? (
                    <div className="shifts-invoice-wrapper loader">
                      <ReactLoading type={'spinningBubbles'} color={'#8f48ff'} className="main-loader" height={'100px'} width={'100px'} />
                      <span className="loading-text">Waiting for courier to be assigned...</span>
                    </div>
                  ) : (
                    <Tabs defaultActiveKey="1" className="tabs-inline-custom">
                      <TabPane tab="TRACKING DETAILS" key="1">
                        <FormWrapper>
                          <div className="shifts-invoice-wrapper full-width">
                            <p>
                              JOB STATUS:{' '}
                              <span className="job-status">
                                {!isEmpty(locationData) && !isEmpty(locationData.job_status)
                                  ? locationData.job_status.replace(/_/g, ' ')
                                  : 'N/A'}
                              </span>
                            </p>
                            <hr className="gray-ruler" />
                            <div className="shifts-invoice-wrapper full-width">
                              <PickUpDot className="right-space" />
                              <span>PICK UP</span>
                              {this.jobTrackingPickAddress()}
                              <div className="shifts-invoice-wrapper">
                                <hr className="gray-ruler" />
                              </div>
                              <DropDot className="right-space" />
                              <span>DELIVERY</span>
                              {this.jobTrackingdeliveryAddress()}
                              <div className="shifts-invoice-wrapper">
                                <div className="shifts-invoice-wrapper">
                                  <div className="shifts-invoice-wrapper">{this.handleVehicleSelection(this.props.jobDetails)}</div>
                                </div>
                              </div>
                              {this.state.showAddressData && (
                                <>
                                  {/* <p>
                                    <span>ADDRESS INFORMATION</span>
                                  </p> */}
                                  <p className="parcel-data-div">Address and parcel INFORMATION</p>
                                  <hr className="gray-ruler" />
                                  <div className="shifts-invoice-wrapper">
                                    <p>
                                      <span>JOB STATUS:</span>{' '}
                                      <span className="job-status-inside-parcel">
                                        {!isEmpty(collapseAddressData) && !isEmpty(collapseAddressData.status)
                                          ? collapseAddressData.status.replace(/_/g, ' ')
                                          : 'N/A'}{' '}
                                        :-{' '}
                                        {!isEmpty(collapseAddressData) && !isEmpty(collapseAddressData.date_checked) ? (
                                          <>
                                            <span id="hours"></span> : <span id="minutes"></span> : <span id="seconds"></span>
                                          </>
                                        ) : (
                                          // ? this.showTimer(collapseAddressData.date_checked)
                                          'N/A'
                                        )}
                                      </span>
                                    </p>
                                    <p>
                                      <span>ETA:</span>
                                      <span className="job-status-inside-parcel">{SUB_ETA_DATA}</span>
                                    </p>
                                    <Row>
                                      <Col lg={8}>
                                        <p>
                                          <span>ADDRESS1 </span>
                                        </p>
                                        <p>
                                          <span>ADDRESS2 </span>
                                        </p>
                                        <p>
                                          <span>Postcode </span>
                                        </p>
                                        <p>
                                          <span>City </span>
                                        </p>
                                        <p>
                                          <span>Country Code </span>
                                        </p>
                                      </Col>
                                      <Col lg={16}>
                                        <p>{collapseAddressData.delivery_address1}</p>
                                        <p>
                                          {!isEmpty(collapseAddressData.delivery_address2) ? collapseAddressData.delivery_address2 : 'N/A'}
                                        </p>
                                        <p>{collapseAddressData.delivery_postcode}</p>
                                        <p>{collapseAddressData.delivery_city}</p>
                                        <p>
                                          {collapseAddressData.delivery_country_code ? collapseAddressData.delivery_country_code : 'N/A'}
                                        </p>
                                      </Col>
                                    </Row>
                                    <div className="shifts-invoice-wrapper">
                                      {this.jobTrackingParcelDetials(collapseAddressData)}
                                      <hr className="gray-ruler" />
                                      {/* <p>
                                      <i className={`cube`}></i>
                                      <span className="parcel-data">
                                        {!isEmpty(data.parcels.description)
                                          ? data.parcels.description
                                          : `Parcel ${i + 1}`}
                                      </span>
                                    </p>
                                    <p>
                                      <i className={`parcel-icon`}></i>
                                      <span className="parcel-data">{`${
                                        !isEmpty(data.parcels.size_x)
                                          ? parseFloat(data.parcels.size_x)
                                          : 0
                                      } 
      x ${!isEmpty(data.parcels.size_y) ? parseFloat(data.parcels.size_y) : 0}
      x ${!isEmpty(data.parcels.size_z) ? parseFloat(data.parcels.size_z) : 0} cms`}</span>
                                    </p>
                                    <p>
                                      <i className={`warning-icon`}></i>
                                      <span className="parcel-data">
                                        {!isEmpty(finalflags) ? finalflags.map(f => f) : 'N/A'}
                                      </span>
                                    </p> */}
                                    </div>
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                        </FormWrapper>
                      </TabPane>
                      <TabPane tab="COURIER DETAILS" key="2">
                        <FormWrapper>
                          <div className="shifts-invoice-wrapper" style={{ paddingBottom: 0 }}>
                            <img className="courier-img" src={locationData?.photo ? locationData.photo : profileImage} alt="" />
                          </div>
                          <div className="shifts-invoice-wrapper" style={{ width: '100%' }}>
                            <div className="shifts-invoice-wrapper">
                              <p>
                                <span>Full name: </span>
                                {locationData?.nick ? locationData.nick : 'N/A'}
                              </p>
                              <p>
                                <span>Phone Number: </span>
                                {locationData?.mobile_number ? locationData.mobile_number : 'N/A'}
                              </p>
                              <p>
                                <span>Vehicle Type: </span>
                                {locationData?.vehicle_type ? locationData.vehicle_type : 'N/A'}
                              </p>
                              <p>
                                <span>Vehicle reg. plate number: </span>
                              </p>
                              <p>
                                <span>Vehicle model: </span>
                              </p>
                              <p></p>
                            </div>
                          </div>
                          <div className="eta-info">
                            <span>ETA {ETAData}</span>
                          </div>
                        </FormWrapper>
                      </TabPane>
                    </Tabs>
                  )}
                </div>
              </div>
            </Row>
          </Col>
          <Col xs={24} sm={24} md={24} lg={14} xl={16} xxl={18} className="map">
            <div className="image-wrapper public">
              {!emptyPoints && (
                <MapMarker
                  showClose={false}
                  courierLocation={courierPoints}
                  markerPoints={markerPoints}
                  showJobStatus={true}
                  boundPoints={boundPoints}
                  showPolyLine={true}
                  jobStatus={!isEmpty(locationData) && !isEmpty(locationData.job_status) ? locationData.job_status.replace(/_/g, ' ') : ''}
                />
              )}
            </div>
          </Col>
        </Row>
      </div>
    );
  };
  getLocation = () => {
    let { showJobTracker } = this.state;
    let { locationData } = this.props;
    const { params } = this.props.match;
    let payload = {
      id: params.jobId,
      hash: params.hashId,
      location: params.locationType
    };
    if (jobTrackerStatusList.includes(locationData.job_status) && showJobTracker) {
      this.props.publicCourierLocation(payload);
    }
  };
  getCoutierManifest = () => {
    let { showJobTracker } = this.state;
    let { locationData } = this.props;
    const { params } = this.props.match;
    let payload = {
      id: params.jobId,
      hash: params.hashId,
      location: params.locationType
    };
    if (jobTrackerStatusList.includes(locationData.job_status) && showJobTracker) {
      this.props.publicCourierManifest(payload);
    }
  };
  render() {
    return (
      <CommonTabWrapper>
        <MiddleSection className="jobs-board-padding padding-tb50 public-top">
          <>{this.PublicTracking()}</>
        </MiddleSection>
      </CommonTabWrapper>
    );
  }
}
const mapStateToProps = state => ({
  manifestData: state.app.publicManifestData,
  locationData: state.app.publicLocationData,
  jobDetails: state.app.getPublicJob,
  parcels: state.app.getPublicParcel,
  showFeedBackProps: state.booking.showFeedBackfromPubnub,
  showLoaderProps: state.booking.showLoaderfromPubnub
});
const mapDispatchToProps = dispatch => ({
  getParcel: payload => dispatch(getParcel(payload)),
  publicCourierLocation: payload => dispatch(publicCourierLocation(payload)),
  publicCourierDetails: payload => dispatch(publicCourierDetails(payload)),
  publicCourierManifest: payload => dispatch(publicCourierManifest(payload)),
  getPublicJob: payload => dispatch(getPublicJob(payload)),
  rateCourierPublic: payload => dispatch(rateCourierPublic(payload)),
  showFeedBackfromPubnub: payload => dispatch(showFeedBackfromPubnub(payload)),
  showLoaderfromPubnub: payload => dispatch(showLoaderfromPubnub(payload)),
  getPublicParcel: payload => dispatch(getPublicParcel(payload))
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PublicTracker));
