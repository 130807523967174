import { size } from 'App/device';
import { borderRadius, transition } from 'App/mixins';
import styled from 'styled-components';

const DownloadTemplateWrapper = styled.div``;
const LocationHeaderWrapper = styled.div`
  font-size: 12px;
  letter-spacing: 0.05em;
  font-weight: 500;
  color: ${(props) => props.theme.fifthColor};
  position: relative;
  margin: 20px 0px;
  @media ${size['desktop-mm-max']} {
    font-size: 12px;
    margin: 08px 0;
  }
  @media ${size['tablet-max']} {
    margin: 07px 0;
  }
  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    right: 0;
    height: 2px;
    background: ${(props) => props.theme.secondaryColor};
    z-index: 0;
  }

  &.fill-wrap {
    &:before {
      background: ${(props) =>
        props.bgLineColor === 'red'
          ? props.theme.redShadeColor1
          : props.bgLineColor === 'orange'
          ? props.theme.orangeShadeColor1
          : props.bgLineColor === 'empty'
          ? 'none'
          : props.theme.primaryButtonColor};
    }
    &.orange {
      &:before {
        background: ${(props) => (props.bgLineColor === 'red' ? props.theme.redShadeColor1 : props.theme.orangeShadeColor1)};
      }
    }
  }
  &.pickup {
    width: 45%;
    margin: 0;
    margin-left: auto !important;
    margin: 8px 0 !important;
    span.left-panel {
      width: 60%;
    }
  }
  &.job-boards {
    width: 100%;
    margin: 0;
    margin: 8px 0 !important;
    margin-left: 74px !important;
    span.left-panel {
      width: 69%;
      padding-right: 0;
    }
    span.left-panel.job-select {
      padding-top: 0;
    }
  }
  span {
    background: #fff;
    position: relative;
    z-index: 1;
    padding: 0;
    font-family: ${(props) => props.theme.primaryFont};
    font-weight: 600;

    &.left-panel {
      padding-right: 15px;
      margin: auto 0;
    }
    &.title-align {
      padding-top: 0 !important;
    }
    &.right-panel {
      padding-left: 15px;
      padding-right: 0;
    }

    &.right-panel {
      &.red-panel {
        color: ${(props) => props.theme.redShadeColor1};
      }

      &.green-panel {
        color: ${(props) => props.theme.primaryButtonColor};
      }

      &.orange-panel {
        color: ${(props) => props.theme.orangeShadeColor1};
      }

      &.fill-panel {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: 25%;
        max-width: 100px;
        padding: 0;
        border-radius: 50px;
        min-width: 140px;
        height: 30px;
        padding: 0 10px;
        @media ${size['mobile-md-max']} {
          font-size: 10px;
          width: 31%;
        }

        &.shift {
          width: 35%;
          /* max-width: none; */
        }
        &.starting {
          @media ${size['mobile-md-max']} {
            width: 50%;
          }
        }
        &.orange-panel {
          background-color: ${(props) => props.theme.orangeShadeColor1};
          color: #fff;
        }
        &.red-panel {
          color: #fff;
          background-color: ${(props) => props.theme.redShadeColor1};
        }

        &.green-panel {
          color: #fff;
          background-color: ${(props) => props.theme.primaryButtonColor};
        }
      }
    }
  }
`;

const ProgressBarUIWrapper = styled.div`
  display: flex;
  overflow: hidden;
  background-color: #fafafa;
  &.vertical-progress {
    width: 170px;
    height: 10px;
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    @media ${size['tablet-max']} {
      width: 100%;
      height: 6px;
    }
  }

  .progress-bar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    background-color: ${(props) => props.theme.fourthColor};
    ${transition(0.6)};
    ${borderRadius('10px')};
  }
`;

const PlaceholderTextWrapper = styled.div`
  h6 {
    font-size: 16px;
    font-weight: 500;
    &.empty-address {
      margin: 0 0 20px 0;
      font-family: ${(props) => props.theme.secondaryFont};
      font-size: 15px;
      color: #323232;
    }
  }
`;

export { DownloadTemplateWrapper, LocationHeaderWrapper, ProgressBarUIWrapper, PlaceholderTextWrapper };
