import { Col, Form, notification, Row } from 'antd';
import { FormValidation } from 'App/AppConstant';
import logoShapeDark from 'assets/images/logo-shape-dark.svg';
import AuthHeader from 'components/AuthHeader';
import AuthWrapper from 'components/AuthWrapper';
import Footer from 'components/Footer';
import { FormButton, FormGroup, FormItem, FormNewInput } from 'components/Form';
import { FormStyling, LoginDiv } from 'components/LoginBox/style';
import { Formik } from 'formik';
import isEmpty from 'lodash.isempty';
import { onError } from 'modules/errorHandler';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { resetPassword } from 'redux/resetPassword/actions';
import * as Yup from 'yup';
import { LoginConstant } from '../Login/constant';
import { ForgotPasswordConstant } from './constant';

const resetSchema = Yup.object().shape({
  email: Yup.string().email(FormValidation.emailInvalid).required(FormValidation.emailRequired),
  code: Yup.string().required(FormValidation.codeRequired),
});
class ResetPassword extends Component {
  state = { codeVisible: false, email: '' };
  componentDidUpdate(prevProps, prevState) {
    if (this.props.isResetPassword !== prevProps.isResetPassword) {
      if (this.props.isResetPassword) {
        this.setState({
          codeVisible: true,
        });
        notification['success']({
          message: `Reset Link Sent`,
        });
      }
    }
  }
  sendMail = async (values, errors, setFieldTouched) => {
    setFieldTouched('email', true, true);
    if (!isEmpty(values) && values.email && isEmpty(errors.email)) {
      this.props.resetPassword({ email: values.email });
      this.setState({ email: values.email });
    }
  };
  handleSubmit = async (values) => {
    this.props.history.push(`/change-password?email=${encodeURIComponent(values.email)}`, {
      code: values.code,
    });
  };
  componentDidCatch(error) {
    onError(error, 'ForgotPassword', 'Component render error');
  }
  render() {
    const { codeVisible } = this.state;
    return (
      <>
        <AuthHeader bgImage={logoShapeDark} />
        <AuthWrapper>
          <LoginDiv>
            <Row>
              <Col xs={24} sm={24} md={24} lg={10} xl={10} xxl={10}>
                <div className="login-box-main">
                  <h2>{!codeVisible ? ForgotPasswordConstant.title : LoginConstant.title}</h2>
                  <p>{!codeVisible ? ForgotPasswordConstant.subTitle : LoginConstant.subTitle}</p>
                  <FormStyling>
                    <Formik initialValues={{ email: '', code: '' }} validationSchema={resetSchema} onSubmit={this.handleSubmit}>
                      {({ values, errors, touched, handleChange, handleSubmit, setFieldTouched }) => (
                        <Form onSubmit={handleSubmit}>
                          <FormItem>
                            <div className="form-styling">
                              {!codeVisible ? (
                                <FormGroup>
                                  <FormNewInput
                                    label={ForgotPasswordConstant.email}
                                    placeholder=""
                                    value={values.email}
                                    handleChange={handleChange}
                                    name="email"
                                    isError={errors.email && touched.email ? true : false}
                                  />
                                  {errors.email && touched.email ? <div className="form-error">{errors.email}</div> : null}
                                </FormGroup>
                              ) : (
                                <FormGroup>
                                  <hr />
                                  <strong>
                                    {`We have sent an email to ${this.state.email} with the instructions to recover your account.`}
                                  </strong>
                                </FormGroup>
                              )}
                              <FormGroup>{/* <p className="problem-text">{ForgotPasswordConstant.problemText}</p> */}</FormGroup>
                              <div className="btn-big-wrap  forgot-pass-btn">
                                {!codeVisible ? (
                                  <FormButton
                                    htmlType="button"
                                    className="new-btn login-btn"
                                    onClick={() => this.sendMail(values, errors, setFieldTouched)}
                                    style={{ width: 'auto' }}
                                  >
                                    {'RECOVER MY ACCOUNT'}
                                  </FormButton>
                                ) : (
                                  <FormButton
                                    htmlType="button"
                                    className="new-btn login-btn"
                                    onClick={() => this.props.history.push('/login')}
                                  >
                                    {'BACK TO LOGIN'}
                                  </FormButton>
                                )}
                              </div>
                            </div>
                          </FormItem>
                        </Form>
                      )}
                    </Formik>
                  </FormStyling>
                </div>
              </Col>
            </Row>
          </LoginDiv>
          {/* </ForgotPasswordDiv> */}
        </AuthWrapper>
        <Footer />
        {/* </ForgotPasswordWrapper> */}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  isResetPassword: state.resetPassword.isResetPassword,
  message: state.resetPassword.message,
});
const mapDispatchToProps = (dispatch) => ({
  resetPassword: (payload) => dispatch(resetPassword(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
