const LoginConstant = {
  loginHeader: 'Log In To Your Profile',
  notProfile: 'Don’t have a profile?',
  logIn: ' Log In',
  signUp: 'Sign Up',
  forgotPassword: 'Forgot password?',
  email: 'E-MAIL',
  emailplaceholder: 'E-Mail Here',
  password: 'PASSWORD',
  passwordplaceholder: 'Password'
};

export { LoginConstant };
