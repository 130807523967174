import styled from 'styled-components';
import profileDropdownArrow from 'assets/images/profile-dropdown-arrow.svg';
import { size } from 'App/device';

const LoginWrapper = styled.div`
  position: absolute;
  right: -50px;
  left: auto;
  top: 100%;
  z-index: 1000;
  float: left;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  border: 0;
  padding: 31px 35px 0 35px;
  margin: 20px 0 0;
  box-shadow: 8px 8px 16px rgba(0, 0, 0, 0.1);
  min-width: 334px;
  background: #fff;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  @media ${size['laptop-max']} {
    padding: 20px 25px 0 25px;
    margin-top: 25px;
  }
  @media ${size['tablet-max']} {
    padding: 0px 15px;
  }
  @media ${size['mobile-md-max']} {
    min-width: 290px;
    width: 100%;
    padding: 15px 15px 0 15px;
  }
  p a {
    display: inline-block;
    font-weight: 500;
    vertical-align: middle;
    font-size: 12px;
    text-align: center;
    line-height: 15px;
    padding: 0;
    color: #49465d;
  }
  .form-styling2 {
    margin-top: 0;
    position: relative;
    .form-group {
      margin-bottom: 10px;
      position: relative;
      padding: 0;
      @media ${size['desktop-sm-max']} {
        margin-bottom: 20px;
      }
    }
    .forgot-password-link {
      font-size: 15px;
      font-weight: bold;
      line-height: 18px;
      letter-spacing: normal;
      font-family: ${props => props.theme.secondaryFont}, sans-serif;
      margin: 17px 0 20px 0;
      @media ${size['desktop-md-max']} {
        margin-top: 50px;
      }
      @media ${size['desktop-sm-max']} {
        margin-top: 30px;
      }
      @media ${size['laptop-max']} {
        margin: 15px 0;
      }
      @media ${size['mobile-md-max']} {
        margin-top: 20px;
      }
      a {
        font-size: 12px;
        line-height: 15px;
        color: #49465d;
        font-weight: 400;
        padding: 0;
      }
    }
    .align-center {
      text-align: center;
    }
    .btn-main {
      max-height: 27px;
      .btn {
        font-size: 12px;
        height: 54px;
        line-height: 54px;
        min-width: 150px;
        margin-bottom: -27px;
        @media ${size['mobile-md-max']} {
          width: 100%;
          font-size: 12px;
          height: 44px;
          line-height: 44px;
          margin-bottom: -22px;
        }
      }
      .btn.gray-btn {
        background: #c4c4c4;
        border-radius: 5px;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
        text-transform: none;
        font-weight: 500;
        letter-spacing: 0;
        color: #fff;
        :hover {
          background: #8f48ff;
          color: #fff;
        }
      }
    }
    label {
      font-size: 9px;
      line-height: 11px;
      color: #49465d;
    }
    input {
      font-size: 12px;
      color: #3a3a3a;
      background: #fff;
      border: 0;
      border-radius: 5px;
      -webkit-border-radius: 5px;
      -moz-border-radius: 5px;
      height: 58px;
      font-weight: 300;
      padding: 10px 14px;
      font-family: ${props => props.theme.secondaryFont}, sans-serif;
      box-shadow: 10px 10px 25px rgba(0, 0, 0, 0.1);
      ::-webkit-input-placeholder {
        color: #3a3a3a;
      }
      :-moz-placeholder {
        color: #3a3a3a;
      }
      ::-moz-placeholder {
        color: #3a3a3a;
      }
      :-ms-input-placeholder {
        color: #3a3a3a;
      }
    }
  }
  h5 {
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    line-height: normal;
    font-family: ${props => props.theme.primaryFont}, sans-serif;
    color: #49465d;
    margin-top: 0;
    margin-bottom: 0.5rem;
    @media ${size['desktop-sm-max']} {
      font-size: 24px;
    }
    @media ${size['laptop-max']} {
      font-size: 22px;
    }
    @media ${size['laptop-sm-max']} {
      font-size: 20px;
    }
    @media ${size['tablet-md-max']} {
      font-size: 20px;
    }
    @media ${size['tablet-max']} {
      font-size: 15px;
    }
  }
  p {
    vertical-align: middle;
    font-size: 12px;
    text-align: center;
    line-height: 15px;
    padding: 0;
    color: #49465d;
    margin: 0 0 20px;
    font-family: ${props => props.theme.secondaryFont}, sans-serif;
    letter-spacing: 0.025em;
  }
  .form-error {
    margin: 10px 0;
  }
`;
export { LoginWrapper };
