import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Row, Col, Modal, Divider, Popconfirm } from 'antd';
import { Formik, Field } from 'formik';
import isEmpty from 'lodash.isempty';
import { FormGroup, FormInput as Input, FieldInput, FormButton as Button } from 'components/Form';
import { teamSchema } from 'container/Teams';
import { SearchWrapper } from 'container/Teams/style.js';
import { ReactComponent as EditSvg } from 'assets/images/edit-pen.svg';
import { ReactComponent as DeleteSvg } from 'assets/images/booking/delete-icon.svg';
import { deleteTeam, openEdit, updateTeam } from 'redux/team/actions';

class AllTeam extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      teamToEdit: {},
      team_name: '',
      selectedTeam: null,
      searchTerm: ''
    };
  }
  modalChange = () => {
    this.setState({
      visible: true
    });
  };
  handleCancel = e => {
    this.setState({
      visible: false
    });
  };
  handleSubmit = async (values, options) => {
    const { teamToEdit } = this.state;
    let status = await this.props.updateTeam({ name: values.team_name, id: teamToEdit.id });
    if (status) {
      this.setState({
        visible: false
      });
    }
  };
  handleChange = e => {
    this.setState({
      team_name: e.target.value
    });
  };
  ModalForm = () => {
    return (
      <Formik
        initialValues={{
          team_name: this.state.team_name
        }}
        validationSchema={teamSchema}
        enableReinitialize
        onSubmit={this.handleSubmit}
      >
        {({ handleSubmit }) => (
          <>
            <h5 className="field-title">TEAM NAME</h5>
            <Form onSubmit={handleSubmit}>
              <FormGroup className="add-team">
                <Field
                  name="team_name"
                  placeholder="Enter Team Name"
                  component={FieldInput}
                  className="addTeam"
                />
                {/* <Divider /> */}
                {/* <input placeholder="Invite a Team Member" className="add-team-input" /> */}
                <div className="plus-btns-group">
                  <Button className={`btn purple-btn`} htmlType="submit">
                    UPDATE TEAM
                  </Button>
                </div>
              </FormGroup>
            </Form>
          </>
        )}
      </Formik>
    );
  };
  handleDelete = data => {
    if (data.owner) {
      this.props.deleteTeam(data.id);
    }
  };
  handleEdit = data => {
    this.setState({
      visible: true,
      teamToEdit: data,
      team_name: data.name
    });
    this.props.openEdit(data);
  };
  _handleClick = data => {
    this.props.changeSelectedTeam(data);
  };
  _searchFilter = () => {
    let { searchTerm } = this.state;
    return x => x.name.toLowerCase().includes(searchTerm.toLowerCase()) || !searchTerm;
  };
  _handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };
  render() {
    const { visible, searchTerm } = this.state;
    const { adminTeams, memberTeams, selectedTeam } = this.props;
    return (
      <>
        {/* <SearchWrapper className="team-search-box right">
          <Input
            type="text"
            name="searchTerm"
            placeholder="Search a Team"
            value={searchTerm}
            onChange={this._handleChange}
          />
          <button className="search-btn right" type="button"></button>
        </SearchWrapper> */}
        <hr style={{ border: '0.711458px solid #CACACA' }} />
        <div className="team-left-menu">
          {/* Admins */}
          <div className="admin-member-label-wrapper">
            <strong className="menu-title">
              ADMIN {!isEmpty(adminTeams) && `(${adminTeams.length})`}
            </strong>
          </div>
          {!isEmpty(adminTeams) && (
            <ul className="team-nav main-page">
              {adminTeams.filter(this._searchFilter()).map((data, index) => (
                <li
                  className="active"
                  key={index}
                  className={`${
                    !isEmpty(selectedTeam) && selectedTeam.id === data.id ? 'active' : ''
                  }`}
                >
                  <a onClick={() => this._handleClick(data)}>{data.name}</a>
                  <div className="team-btn">
                    <EditSvg onClick={() => this.handleEdit(data)} className="edit-btn" />
                    <Popconfirm
                      getPopupContainer={() => this.props.takeRef.current}
                      title="Are you sure delete this team?"
                      onConfirm={e => {
                        e.stopPropagation();
                        this.handleDelete(data);
                      }}
                      onCancel={e => {
                        e.stopPropagation();
                      }}
                      okText="YES"
                      cancelText="NO"
                    >
                      <DeleteSvg className="delete-btn" />
                    </Popconfirm>
                  </div>
                </li>
              ))}
            </ul>
          )}
          {/* Members */}
          <hr style={{ border: '0.711458px solid #CACACA' }} />
          <div className="admin-member-label-wrapper">
            <strong className="menu-title">
              MEMBER {!isEmpty(memberTeams) && `(${memberTeams.length})`}
            </strong>
          </div>
          {!isEmpty(memberTeams) && (
            <ul className="team-nav main-page">
              {memberTeams.filter(this._searchFilter()).map((data, index) => (
                <li
                  key={index}
                  className={`${
                    !isEmpty(selectedTeam) && selectedTeam.id === data.id ? 'active' : ''
                  }`}
                >
                  <a onClick={() => this._handleClick(data)}>{data.name}</a>
                  <div className="team-btn"></div>
                </li>
              ))}
            </ul>
          )}
          <Modal
            visible={visible}
            onCancel={this.handleCancel}
            footer={null}
            title="Edit Team"
            className="custom-primary-modal"
            onOk={this.handleOk}
            getContainer={() => document.querySelector('.team-left-menu')}
            centered
          >
            <div className="main-content">
              <Row>
                <Col md={24}>{this.ModalForm()}</Col>
              </Row>
            </div>
          </Modal>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  teams: state.team.teams,
  setEdit: state.team.setEdit
});
const mapDispatchToProps = dispatch => ({
  deleteTeam: payload => dispatch(deleteTeam(payload)),
  openEdit: payload => dispatch(openEdit(payload)),
  updateTeam: payload => dispatch(updateTeam(payload))
});
export default connect(mapStateToProps, mapDispatchToProps)(AllTeam);
