import React, { Component } from 'react';
import { MiddleSection, MaxContainer, LoginImgBox, MapBox, SignUpImgBox } from './style';
import loginPageLightImg from 'assets/images/login-page-image.svg';
// import loginPageImg from 'assets/images/Gophr_Homepage.png';
// import loginPageImg from 'assets/images/Gophr_Illustration.png';
import loginPageImg from 'assets/images/gophr-main-image.svg';
// import loginPageLightImg from 'assets/images/gophr-about.png';
class AuthWrapper extends Component {
  render() {
    const { children, page } = this.props;
    return (
      <MiddleSection>
        <LoginImgBox>
          <img src={loginPageImg} alt="" />
        </LoginImgBox>
        <MaxContainer>{children}</MaxContainer>
      </MiddleSection>
    );
  }
}

export default AuthWrapper;
