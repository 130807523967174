import styled from 'styled-components';
import { size } from 'App/device';

const LoginDiv = styled.div`
  .login-box-main {
    background: #fff;
    box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    max-width: 500px;
    width: 100%;
    padding: 60px 50px 40px 50px;
    @media ${size['desktop-md-max']} {
      max-width: 470px;
      padding: 35px 40px 30px 40px;
    }
    @media ${size['desktop-sm-max']} {
      max-width: 410px;
      padding: 30px 35px 25px 35px;
    }
    @media ${size['tablet-max']} {
      margin: 0 auto;
    }
    @media ${size['tablet-max']} {
      padding: 30px;
    }
    @media ${size['mobile-md-max']} {
      padding: 20px;
    }
    h2 {
      font-family: ${props => props.theme.primaryFont}, sans-serif;
      margin: 0 0 10px 0;
      // font-size: 38px;
      font-size: 30px;
      @media ${size['laptop-max']} {
        font-size: 28px;
      }
      @media ${size['tablet-max']} {
        font-size: 24px;
      }
    }
    p {
      font-size: 15px;
      line-height: 21px;
      margin: 0;
      color: #707070;
      letter-spacing: 0.025em;
      @media ${size['desktop-sm-max']} {
        font-size: 15px;
        line-height: 20px;
      }
      @media ${size['tablet-max']} {
        font-size: 14px;
      }
    }
    .btn-main {
      display: flex;
      button {
        margin-left: auto;
      }
      padding-top: 28px;
      @media ${size['mobile-md-max']} {
        flex-wrap: wrap;
      }
      .btn-next {
        margin-top: auto;
        @media ${size['mobile-md-max']} {
          margin-top: 10px;
          width: 100%;
        }
      }
    }
  }
  p {
    letter-spacing: 0.025em;
  }
`;

const FormStyling = styled.div`
  .ant-form-item-control {
    line-height: 30px;
  }
  .form-styling {
    margin-top: 20px;
    .checkbox1 {
      @media ${size['desktop-sm-max']} {
        font-size: 16px;
      }
    }
  }
  .checkbox1 {
    .ant-checkbox-inner {
      top: 0;
      // left: 0;
      // height: 16px;
      // width: 16px;
      background: transparent;
      border: 1.42px solid #d9d9d9;
      border-radius: 5px;
    }
    .ant-checkbox-checked .ant-checkbox-inner::after {
      // border-color: #000;
    }
    .ant-checkbox + span {
      display: inline-block;
      position: relative;
      text-align: left;
      font-size: 15px;
      color: #8a8a8a;
      line-height: 16px;
      cursor: pointer;
      padding-left: 12px;
      margin: 0;
      @media ${size['desktop-sm-max']} {
        font-size: 16px;
      }
      @media ${size['mobile-md-max']} {
        font-size: 12px;
      }
    }
  }
  input[type='radio'],
  input[type='checkbox'] {
    box-sizing: border-box;
    padding: 0;
  }
  .socail-link-custom {
    margin: 0;
  }

  .forgot-password-text {
    align-items: center;
    @media ${size['tablet-max']} {
      flex-wrap: wrap;
    }
  }
  .ml-auto,
  .mx-auto {
    margin-left: auto !important;
  }
  .forgot-password-link {
    font-size: 15px;
    line-height: 18px;
    letter-spacing: normal;
    font-family: ${props => props.theme.primaryFont}, sans-serif;
    font-weight: 500;
    @media ${size['desktop-sm-max']} {
      margin-top: 0px;
    }
    @media ${size['desktop-md-max']} {
      margin-top: 0px;
    }
    @media ${size['tablet-max']} {
      margin-left: 0px !important;
    }
    a {
      color: #49465d;
      font-weight: 600;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  .align-center {
    text-align: center;
  }
  button,
  [type='button'],
  [type='reset'],
  [type='submit'] {
    -webkit-appearance: button;
  }
  .btn-big-wrap {
    display: flex;
    justify-content: flex-end;
    padding-top: 28px;
    flex-wrap: wrap;
    button {
      & + button {
        margin-left: 10px;
      }
      @media ${size['desktop-sm-max']} {
        width: 100% !important;
        & + button {
        width: 100%;
          margin-left: 0px;
          margin-top: 10px;
        }
      }
    }
  }
`;

export { LoginDiv, FormStyling };
