import DatePicker from 'components/DatePicker';
import CustomDateTimePicker from 'components/DateTimePicker';
import Dropdown from 'components/Dropdown';
import FormButton from 'components/FormButton';
import FormCheckbox from 'components/FormCheckbox';
import FormInput from 'components/FormInput';
import FormNewInput from 'components/FormNewInput';
import FormNumericInput from 'components/FormNumericInput';
import FormReadOnlyInput from 'components/FormReadOnlyInput';
import FormTextarea from 'components/FormTextarea';
import InputIcon from 'components/InputIcon';
import InuptWIthDropdown from 'components/InuptWIthDropdown';
import Label from 'components/Label';
import PasswordInput from 'components/PasswordInput';
import Popover from 'components/Popover';
import RadioButtonGroup from 'components/RadioButtonGroup';
import ReviewDropdown from 'components/ReviewDropdown';
import SameDateTimePicker from 'components/SameDayDateTimePicker';
import Select, { SelectOption } from 'components/Select';
import Switch from 'components/Switch';
import Tabs, { TabPane } from 'components/Tab';
import React from 'react';
import { FormGroup, FormItem, FormWrapper } from './style';

const FieldInput = ({ field, form: { touched, errors }, ...props }) => (
  <>
    <FormNewInput {...field} {...props} isError={touched[field.name] && errors[field.name] ? true : false} />
    {touched[field.name] && errors[field.name] && <div className="form-error">{errors[field.name]}</div>}
  </>
);

export {
  InputIcon,
  SelectOption,
  Switch,
  Popover,
  PasswordInput,
  Tabs,
  TabPane,
  FormItem,
  Label,
  Select,
  DatePicker,
  Dropdown,
  FormGroup,
  FormWrapper,
  FormInput,
  FormNewInput,
  FieldInput,
  FormNumericInput,
  FormReadOnlyInput,
  FormButton,
  FormTextarea,
  FormCheckbox,
  RadioButtonGroup,
  CustomDateTimePicker,
  ReviewDropdown,
  InuptWIthDropdown,
  SameDateTimePicker
};
