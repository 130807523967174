import { Form, notification } from 'antd';
import { FormValidation } from 'App/AppConstant';
import BreadCrumb from 'components/BreadCrumb';
import { FormButton, FormGroup, FormItem, FormNewInput as Input } from 'components/Form';
import { FormStyling, LoginDiv } from 'components/LoginBox/style';
import { Formik } from 'formik';
import { onError } from 'modules/errorHandler';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { sendEmail, verifyEmailAndLogin } from 'redux/signup/actions';
import * as Yup from 'yup';
import { SignupConstant } from './constant';

const signupValidationSchema = Yup.object().shape({
  code: Yup.string().required(FormValidation.codeRequired)
});
class Verification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      code: ''
    };
  }
  componentDidMount() {
    try {
      this.setState({
        code: this.props.codeProps
      });
    } catch (error) {
      onError(error, 'Verification Component', 'set props error');
    }
  }
  componentDidUpdate(prevProps) {
    try {
      let { codeProps, emailVerification } = this.props;
      if (codeProps !== prevProps.codeProps) {
        this.setState({ code: codeProps });
      }
      if (emailVerification !== prevProps.emailVerification) {
        if (emailVerification) {
          notification['success']({
            message: 'Verification Success',
            description: emailVerification
          });
        }
      }
    } catch (error) {
      console.dir(error, 'componentDidUpdate error');
      onError(error, 'SignUP UserData', 'Lifecycle error');
    }
  }
  componentDidCatch(error) {
    onError(error, 'SignUP Verification', 'Component render error');
  }
  handleSubmit = async (values, { setSubmitting }) => {
    try {
      await this.props.verifyEmailAndLogin({
        email: this.props.emailProps,
        hash: values.code
      });
    } catch (error) {
      onError(error, 'SignUP Verification', 'Handle submit error');
    }
  };
  resendCode = () => {
    this.props.sendEmail({ email: this.props.emailProps });
  };
  render() {
    const { code } = this.state;
    return (
      <LoginDiv>
        <div className="login-box-main">
          <BreadCrumb active="signup" />
          <h2>{SignupConstant.oneFinal}</h2>
          <p>{SignupConstant.sentCode}</p>
          <FormStyling>
            <Formik
              initialValues={{
                code
              }}
              validationSchema={signupValidationSchema}
              onSubmit={this.handleSubmit}
              enableReinitialize
            >
              {({ values, errors, touched, handleChange, handleSubmit }) => (
                <Form onSubmit={handleSubmit}>
                  <FormItem>
                    <div className="form-styling">
                      <FormGroup>
                        <Input
                          label={SignupConstant.codeInput}
                          placeholder="Required"
                          value={values.code}
                          handleChange={handleChange}
                          name={SignupConstant.code}
                          isCode={true}
                          isError={errors.code && touched.code ? true : false}
                        />
                        {errors.code && touched.code ? <div className="form-error">{errors.code}</div> : null}
                      </FormGroup>
                      <FormGroup>
                        <p className="problem-text">{SignupConstant.contactUs}</p>
                      </FormGroup>

                      <div className="btn-big-wrap">
                        <FormButton
                          color="secondary"
                          onClick={() => this.resendCode()}
                          className="new-btn reverse-btn red-hover"
                          style={{ width: 'auto' }}
                        >
                          {SignupConstant.resendCode}
                        </FormButton>
                        <FormButton htmlType="submit" className="new-btn login-btn">
                          {SignupConstant.createProfile}
                        </FormButton>
                      </div>
                    </div>
                  </FormItem>
                </Form>
              )}
            </Formik>
          </FormStyling>
        </div>
      </LoginDiv>
    );
  }
}

const mapStateToProps = state => ({
  emailVerification: state.signup.emailVerification
});
const mapDispatchToProps = dispatch => ({
  verifyEmailAndLogin: payload => dispatch(verifyEmailAndLogin(payload)),
  sendEmail: payload => dispatch(sendEmail(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(Verification);
