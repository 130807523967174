import styled from 'styled-components';
import { size } from 'App/device';
import { borderRadius } from 'App/mixins';
const FormWrapper = styled.div`
  padding: 0;
  margin: 0;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
  input {
    width: 100%;
    text-overflow: ellipsis;
    display: block;
    width: 100%;
    line-height: 1.5;
    margin: 0;
    -webkit-appearance: none;
    background: #fff;
    ${borderRadius('0px')};
    height: 40px;
    font-size: 18px;
    font-weight: 400;
    padding: 10px 14px;
    color: ${props => props.theme.primaryColor};
    font-family: ${props => props.theme.secondaryFont}, sans-serif;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    &.form-control {
      border: 0.1rem solid #cecece;
    }
    &.custom-input {
      padding-left: 20px;
    }
    ::placeholder {
      color: ${props => props.theme.primaryColor};
    }
    ::-ms-input-placeholder {
      color: ${props => props.theme.primaryColor};
    }
    :hover,
    :focus {
      outline: none;
    }
    @media ${size['desktop-sm-max']} {
      font-size: 16px;
      height: 38px;
      padding: 10px 14px;
    }
    @media ${size['tablet-max']} {
      font-size: 14px;
    }
    @media ${size['desktop-mm-min']} {
      // height: 36px;
    }
  }
  &.error-wrapper {
    input.form-control {
      box-shadow: 0px 0px 5px #d6181a !important;
      border: 1px solid #d6181a !important;
    }
    input.custom-input,
    input.inc-input {
      box-shadow: none !important;
      border-radius: 0px !important;
      // border: none !important;
    }
  }
  input[readonly] {
    box-shadow: none;
    // background: #f7f7f7 !important;
    border: 0 !important;
    padding-left: 0;
    font-family: ${props => props.theme.secondaryFont};
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 19px;
  }
`;

export { FormWrapper };
