import styled from 'styled-components';
import { size } from 'App/device';
import { borderRadius } from 'App/mixins';

const FormWrapper = styled.div`
  padding: 0;
  margin: 0;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
  .courier-img {
    cursor: pointer;
    width: 70px;
    height: 70px;
    border-radius: 50%;
  }
  .eta-info {
    padding: 20px;
    background: #00c68d;
    color: #fff;
    font-family: 'Montserrat';
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    border-radius: 5px;
  }
  input {
    background: #fff;
    font-size: 18px;
    font-weight: 300;
    padding: 10px 30px;
    box-shadow: 10px 10px 25px rgba(0, 0, 0, 0.1);
    display: block;
    width: 100%;
    height: 42px;
    line-height: 1.5;
    background-clip: padding-box;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    ${borderRadius('0px')};
    color: ${props => props.theme.primaryColor};
    font-family: ${props => props.theme.secondaryFont}, sans-serif;
    border: 0.1rem solid ${props => props.theme.blackColor};
    &:first-child {
      input {
        border-right: 1px solid #e7e7e7;
      }
    }
    @media ${size['desktop-sm-max']} {
      &:first-child {
        //box-shadow: none;
        border-radius: 0;
      }
    }
    @media ${size['desktop-sm-max']} {
      font-size: 14px;
      height: 38px;
      padding: 10px 14px;
    }
    @media ${size['laptop-max']} {
      height: 36px;
      font-size: 14px;
    }
  }
  .error-input {
    box-shadow: 0px 0px 5px #d6181a;
    border: 1px solid #d6181a;
  }
`;

export { FormWrapper };
