import { size } from 'App/device';
import { borderRadius } from 'App/mixins';
import styled from 'styled-components';
const FormWrapper = styled.div`
  padding: 0;
  margin: 0;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
  position: relative;
  // margin-bottom: 1.5rem;
  input {
    background: #fff;
    border: 0;
    height: 50px;
    font-size: 15px;
    font-weight: 600;
    width: 100%;
    color: #979797;
    font-family: ${(props) => props.theme.secondaryFont}, sans-serif;
    color: #323232;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    &.form-control {
      border: 0.1rem solid #cecece;
    }
    &.custom-input {
      padding-left: 20px;
    }
    ::placeholder {
      color: ${(props) => props.theme.primaryColor};
    }
    ::-ms-input-placeholder {
      color: ${(props) => props.theme.primaryColor};
    }
    :hover,
    :focus {
      outline: none;
    }
    @media ${size['desktop-sm-max']} {
      font-size: 14px;
      height: 44px;
      padding-left: 5px;
    }
  }
  &.errorwrapper {
    input.form-control {
      border: none !important;
      border-bottom: 1px solid #d6181a !important;
      // box-shadow: none !important;
      border-right: 10px solid white !important;
      border-left: 10px solid white !important;
      &.addTeam {
        border-bottom: 1px solid #d6181a !important;
        border-right: 10px solid white !important;
        border-left: 10px solid white !important;
      }
    }
    input.custom-input,
    input.inc-input {
      box-shadow: none !important;
      border-radius: 0px !important;
      // border: none !important;
    }
  }
  input[readonly] {
    box-shadow: none;
    background: #f7f7f7 !important;
  }
  .form-control-placeholder {
    color: ${(props) => props.theme.labelColor} !important;
    line-height: 14px;
    margin-bottom: 5px;
    display: block;
    font-family: 'Montserrat';
    font-size: 12px;
    margin-bottom: 0;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    &.refrence {
      top: -25px;
      transform: translate3d(0, 40%, 0);
      color: #8f48ff;
      &.show {
        top: 0;
        /* color: #49465d; */
      }
    }
  }
  input.form-control {
    padding-bottom: 0;
    border-bottom: 1px solid #d1d1d1 !important;
    border: none;
    padding-left: 0;
    &.booking {
      padding-right: 0 !important;
    }
    &.addTeam {
      border-bottom: 1px solid #dddddd !important;
      border-right: 10px solid white !important;
      border-left: 10px solid white !important;
    }
    &:disabled {
      border-bottom: 0 solid #d1d1d1 !important;
    }
  }
  .form-control:focus + .form-control-placeholder {
    // transform: translate3d(0, -100%, 0);
    // opacity: 1;
    // top: 0px;
    // color: #8f48ff;
    // width: 100%;
    // font-size: 12px;
  }
  .form-control:focus {
    &.addTeam {
      border-bottom: 1px solid #8f48ff !important;
      border-right: 10px solid white !important;
      border-left: 10px solid white !important;
    }
    border-bottom: 1px solid #8f48ff !important;
    &::-webkit-input-placeholder {
      color: transparent;
    }

    &::-moz-placeholder {
      /* Firefox 19+ */
      color: transparent;
    }

    &:-ms-input-placeholder {
      color: transparent;
    }
  }
  &.show {
    .title {
      // transform: translate3d(0, -100%, 0);
      // opacity: 1 !important;
      // font-family: Opensans;
      // width: 100%;
      // font-size: 12px;
      // color: rgb(136 135 142);
    }
    &.refrence {
      top: 0;
    }
  }
  input.placeInput {
    border: none !important;
    border-bottom: 0.1rem solid #cecece !important;
    &:focus {
      border-bottom: 1px solid #8f48ff !important;
    }
  }
`;

const TextAreaWrapper = styled.div`
  textarea {
    ${borderRadius('0px')};
    height: 100px;
    font-size: 16px;
    color: #49465d;
    font-weight: 400;
    padding-left: 10px;
    border-bottom: 0.1rem solid #cecece !important;
    border-right: 10px solid white !important;
    border: none;
    &:focus {
      border-bottom: 1px solid #8f48ff !important;
      &::-webkit-input-placeholder {
        color: transparent;
      }
      &::-moz-placeholder {
        /* Firefox 19+ */
        color: transparent;
      }
      &:-ms-input-placeholder {
        color: transparent;
      }
    }
  }
  .form-group {
    margin-bottom: 15px;
    &.show {
      .label {
        transform: translate3d(0, -100%, 0);
        opacity: 1 !important;
      }
    }
    &.focus {
      .label {
        transform: translate3d(0, -100%, 0);
        opacity: 1 !important;
        color: #8f48ff;
      }
    }
    &.text-area {
      .label {
        position: absolute;
        transition: all 300ms;
        opacity: 0.5;
        margin-bottom: -15px;
        top: 0;
      }
    }
  }
`;

export { FormWrapper, TextAreaWrapper };
