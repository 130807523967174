const ForgotPasswordConstant = {
  login: 'Login',
  signUp: 'Sign Up',
  title: 'Forgot Password?',
  subTitle: 'Forgot your password? Don’t worry, give us your account email, and we’ll send you an email to recover your account',
  cancelText: 'Cancel',
  verifyText: 'Verify',
  gophrCourier: 'GophrCourier',
  footerYear: '2020',
  email: 'E-mail',
  codeInput: 'Code input',
  codeButton: 'Send My Code',
  problemText: 'Problem? Contact us at gophrsupport@mail.com',
};

export { ForgotPasswordConstant };
