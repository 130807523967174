import React, { Component } from 'react';
import { FormWrapper, CheckIcon } from './style';
import hidePasswordIcon from 'assets/images/hide-password-icon.png';
import showPasswordIcon from 'assets/images/show-password-icon.png';
import checkIcon from 'assets/images/check-icon.svg';
import uncheckIcon from 'assets/images/uncheck-icon.svg';
import { FormWrapper as InputArea } from 'components/FormNewInput/style';

class PasswordInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      status: 'password'
    };
  }
  handleStatus = () => {
    const { status } = this.state;
    if (status === 'text') {
      this.setState({
        status: 'password'
      });
    } else {
      this.setState({
        status: 'text'
      });
    }
  };
  render() {
    const {
      placeholder,
      name,
      isCode,
      value,
      isError,
      handleChange,
      tabIndex,
      className,
      label
    } = this.props;
    const { status } = this.state;
    let icon = hidePasswordIcon;
    let iconActive = showPasswordIcon;
    if (isCode) {
      icon = uncheckIcon;
      iconActive = checkIcon;
    }
    return (
      <FormWrapper className={`${isCode ? 'code-type' : ''} ${isError ? 'error-wrapper' : ''}`}>
        <InputArea className={`${'form-group'} ${value ? 'show' : ''}`}>
          <label className="form-control-placeholder title">{label}</label>
          <CheckIcon
            tabIndex="-1"
            type="checkbox"
            onClick={this.handleStatus}
            className="password-icon"
            iconBackground={icon}
            iconBackgroundActive={iconActive}
          />
          <input
            tabIndex={tabIndex}
            type={status}
            placeholder={placeholder}
            className={`form-control ${className ? className : ''}`}
            value={value}
            name={name}
            onChange={handleChange}
          />
        </InputArea>
      </FormWrapper>
    );
  }
}
PasswordInput.defaultProps = {
  isError: false,
  placeholder: 'Password',
  className: '',
  icon: hidePasswordIcon,
  tabIndex: '1',
  isCode: false,
  iconActive: showPasswordIcon
};

export default PasswordInput;
