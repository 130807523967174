import styled from 'styled-components';
import { size } from 'App/device';
import { transition, borderRadius } from 'App/mixins';

const Wrapper = styled.div`
  .Tabs-page-name {
    padding: 12px 30px 12px;
    background: #8f48ff;
    border-radius: 5px 5px 0px 0px;
    p {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: bold;
      margin-bottom: 0;
      line-height: 17px;
      color: #ffffff;
    }
  }
  .ant-tabs {
    .ant-tabs-bar {
      padding: 8px 30px 8px 0;
      border-bottom: none;
      background: #ffffff;
      border-radius: 7.82604px;
      margin: 0;
      @media ${size['laptop-md-max']} {
        padding: 0 20px;
      }
      @media ${size['tablet-max']} {
        padding: 0 10px;
      }
      @media ${size['mobile-md-max']} {
        padding: 0 3px;
      }
    }
    .ant-tabs-nav-container,
    .ant-tabs-nav-scroll,
    .ant-tabs-nav-wrap {
      overflow: visible;
      @media ${size['mobile-md-max']} {
        // overflow: hidden;
      }
    }
    .ant-tabs-nav.ant-tabs-nav-animated {
      // float: left !important;
    }
    .ant-tabs-nav {
      display: block;
      padding-top: 12px;
      .ant-tabs-ink-bar {
        opacity: 0;
        .ant-tabs-tab {
          margin: 0;
        }
      }
      .ant-tabs-tab-active.ant-tabs-tab.favourites-tab {
        display: none !important;
      }
      .ant-tabs-tab-active.ant-tabs-tab.pickup-tab {
        background: #00c68d !important;
        color: #fff !important;
      }
      .ant-tabs-tab-active.ant-tabs-tab.dropoff-tab {
        background: #e8286b !important;
        color: #fff !important;
      }
      .ant-tabs-tab {
        color: #110c23;
        font-family: ${props => props.theme.primaryFont}, sans-serif;
        font-weight: 500;
        font-size: 12px;
        letter-spacing: 0.05em;
        padding: 12px 0;
        border: 0;
        background: #fff;
        line-height: 18px;
        margin: 0 25px 0 0;
        margin-bottom: 1px;
        text-align: center;
        border-radius: 5px;
        // box-shadow: 0px 5px 25px rgba(0, 0, 0, 0.1);
        &.nextday-tab,
        &.shift-tab {
          // border-radius: 5px;
          font-size: 12px;
          ::after {
            background: #8f48ff;
          }
        }
        &.main-right-btns {
          position: absolute;
          right: 0;
        }
        &.remove {
          opacity: 0;
        }
        ${transition(0.4)};
        &:hover {
          background: #fff;
          color: #8f48ff;
          &.pickup-tab {
            background: #00c68d !important;
            color: #fff !important;
          }
          &.dropoff-tab {
            background: #e8286b !important;
            color: #fff !important;
          }
        }
        @media ${size['desktop-md-max']} {
          // margin-left: 15px;
          // padding: 16px 18px;
          font-size: 12px;
          min-width: 135px;
        }
        @media ${size['desktop-mm-max']} {
          // margin-left: 10px;
          // padding: 16px 15px;
          min-width: 1px;
        }
        @media ${size['laptop-md-max']} {
          // padding: 16px 8px;
        }
        @media ${size['tablet-md-max']} {
          color: #868686;
          background: transparent;
          box-shadow: none;
          padding: 10px 5px 17px 5px;
          min-width: auto;
          font-size: 13px;
          line-height: 15px;
          -webkit-letter-spacing: 0.05em;
          -moz-letter-spacing: 0.05em;
          -ms-letter-spacing: 0.05em;
          letter-spacing: 0.05em;
        }
        @media ${size['tablet-max']} {
          margin-left: 5px;
          margin-right: 05px !important;
          min-width: 1px;
          font-size: 11px;
        }
        &.ant-tabs-tab-active {
          // background: #8f48ff;
          // color: #fff;
          position: relative;
          &:after {
            content: '';
            background: #8f48ff;
            height: 4px;
            ${borderRadius('30px')};
            ${transition(0.4)};
            bottom: 1px;
            z-index: 2;
            position: absolute;
            right: 0;
            left: 0;
            width: 100%;
          }
          &.pickup-tab {
            background: #00c68d !important;
            color: #fff !important;
          }
          &.dropoff-tab {
            background: #e8286b !important;
            color: #fff !important;
          }
          @media ${size['tablet-md-max']} {
            color: #49465d;
            background: transparent;
            position: relative;
            font-weight: bold;
            &:after {
              content: '';
              // background: #8f48ff;
              height: 4px;
              ${borderRadius('30px')};
              ${transition(0.4)};
              bottom: 1px;
              z-index: 2;
              position: absolute;
              right: 0;
              left: 0;
              width: 100%;
            }
          }
        }
      }
      .ant-tabs-tab-disabled {
        background: #f7f7f7;
        color: #49465d;
        &:hover {
          background: #f7f7f7;
          color: #49465d;
        }
      }
    }
    .ant-tabs-tab:fourth-child {
      padding: 0;
      min-width: unset;
    }
  }
  .booking-tab .ant-tabs-nav .ant-tabs-tab {
    background: #8f48ff;
    color: #fff;
  }
`;

export { Wrapper };
