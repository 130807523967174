import { size } from 'App/device';
import { borderRadius } from 'App/mixins';
import styled from 'styled-components';

const FormWrapper = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
  background: #fff;
  ${borderRadius('0')};
  // border: 1px solid #d9d9d9;
  // box-shadow: 10px 10px 25px rgba(0, 0, 0, 0.1);
  .pin-right-space {
    margin-right: 5px;
  }
  .dt-tm-row {
    width: 100%;
    .first-col {
      padding: 0 !important;
    }
    .ant-col {
      padding-left: 10px;
      padding-top: 1px;
    }
  }
  .extra-drops {
    display: inline-block;
    width: 100%;
    padding-top: 30px;
  }
  button.ant-btn {
    width: 100%;
    border-radius: 0;
    border: 0;
    font-size: 15px;
    height: 46px;
    line-height: 46px;
    color: #323232;
    font-weight: 400;
    box-shadow: none;
    text-align: left;
    font-family: ${props => props.theme.secondaryFont}, sans-serif;
    background: #fff !important;
    border-bottom: 1px solid #9d9d9d;
    padding-left: 0;
  }
  .bottom-div {
    &.top {
      margin-top: 10%;
    }
    margin-top: 2%;
    width: 100%;
    .ant-col {
      padding-left: 20px;
      display: block;
      cursor: pointer;
      .sm-ad-txt {
        display: inline-block;
        text-align: left;
      }
      i {
        &:first-child {
          margin-right: 10px;
        }
        &:last-child {
          width: 30px;
        }
        height: 20px;
        display: inline-block;
        background-size: 13px auto;
        margin-left: 0 !important;
      }
    }
  }
  .dash {
    line-height: 3;
    margin-left: 10px;
  }
  .ant-calendar-picker-container {
    z-index: 1000;
    top: 46px;
  }
  .custom-datepicker {
    /* width: 23%; */
    /* border-radius: 4px; */
    /* margin-right: 10px; */
    border-bottom: 1px solid #9d9d9d;
    @media ${size['desktop-sm-max']} {
      width: 21%;
    }
    @media ${size['mobile-sm-max']} {
      width: 72%;
    }
    & .ant-calendar-picker-icon {
      right: unset;
      position: absolute;
      /* width: 60px; */
      z-index: 0;
      top: 44%;
      svg {
        color: #828097;
        width: 20px;
        height: 20px;
      }
    }
    &.ant-calendar-picker {
      & .ant-calendar-picker-input {
        /* padding-left: 45px !important; */
        background: none !important;
        box-shadow: none !important;
        padding-right: 40px !important;
        position: relative;
        display: block;
        width: 100%;
        line-height: 1.5;
        margin: 0;
        -webkit-appearance: none;
        background: #fff;
        border: 0;
        border-radius: 5px;
        -webkit-border-radius: 5px;
        height: 58px;
        font-size: 18px;
        font-weight: 400;
        padding: 10px 30px;
        font-family: ${props => props.theme.secondaryFont}, sans-serif;
        box-shadow: 10px 10px 25px rgba(0, 0, 0, 0.1);
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        color: #828097;
        &::-webkit-input-placeholder {
          color: #828097;
          opacity: 1;
        }
        &:-moz-placeholder {
          color: #828097;
          opacity: 1;
        }
        &::-moz-placeholder {
          color: #828097;
          opacity: 1;
        }
        &:-ms-input-placeholder {
          color: #828097;
          i.las.la-angle-down.ant-time-picker-clock-icon {
            opacity: 1;
          }
        }
        :hover,
        :focus {
          outline: none;
          box-shadow: 10px 10px 25px rgba(0, 0, 0, 0.1);
        }
        &.ant-input {
          &[readonly] {
            padding-right: 0 !important;
            font-size: 15px;
            height: 46px;
            line-height: 46px;
            color: #323232;
            font-weight: 400;
            box-shadow: none;
            background: #fff !important;
          }
        }
        @media ${size['desktop-sm-max']} {
          font-size: 16px;
          height: 44px;
          padding: 10px 20px;
        }
        @media ${size['tablet-max']} {
          font-size: 14px;
        }
      }
    }
  }
  .custom-datepicker-dropdown {
    & .ant-calendar {
      background: #fff;
      box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15);
      border-radius: 5px;
      -webkit-border-radius: 5px;
      -moz-border-radius: 5px;
      padding: 30px;
      border: 0;
      font-family: ${props => props.theme.secondaryFont}, sans-serif;
      color: #49465d;
      // text-align: center;
      // font-size: 18px;
      // font-weight: 500;
      font-size: 15px;
      width: auto;
      & .ant-calendar-header {
        border-bottom: 0px;
        height: 30px;
        & a.ant-calendar-prev-year-btn,
        a.ant-calendar-next-year-btn {
          display: none;
        }
        & .ant-calendar-prev-month-btn::before,
        .ant-calendar-prev-month-btn::after,
        .ant-calendar-next-month-btn::before,
        .ant-calendar-next-month-btn::after {
          width: 12px;
          height: 12px;
        }
        & .ant-calendar-prev-month-btn:hover::before,
        .ant-calendar-prev-month-btn:hover::after,
        .ant-calendar-next-month-btn:hover::before,
        .ant-calendar-next-month-btn:hover::after {
          border-color: white;
        }
        & .ant-calendar-prev-month-btn::before:hover {
          color: rgb(255, 255, 255);
        }
        & .ant-calendar-my-select {
          & .ant-calendar-month-select,
          .ant-calendar-year-select {
            //month select
          }
        }
      }
      & .ant-calendar-body {
        & .ant-calendar-column-header {
          & .ant-calendar-column-header-inner {
            font-weight: 500;
          }
        }
        & .ant-calendar-tbody {
          & .ant-calendar-date {
            width: 35px;
            height: 35px;
            margin: 0 auto;
            padding: 8px;
            color: rgba(0, 0, 0, 0.65);
            line-height: normal;
            text-align: center;
            background: transparent;
            border: 1px solid transparent;
            border-radius: 4px;
            -webkit-transition: background 0.3s ease;
            transition: background 0.3s ease;
            display: table-cell;
            position: relative;
            border: none;
          }
          & .ant-calendar-last-month-cell {
            & .ant-calendar-date {
              color: #828097;
              opacity: 0.5;
              // color: #777777;
              // font-weight: normal;
              // font-size: 15px;
            }
          }
          & .ant-calendar-cell {
            padding: 0px;
            font-weight: 500;
            border-radius: 4px;
            &.ant-calendar-disabled-cell {
              color: #828097;
              opacity: 0.5;
            }
          }
          & .ant-calendar-cell:not(.ant-calendar-last-month-cell):not(.ant-calendar-next-month-btn-day) {
            & .ant-calendar-date {
              color: #777777;
              font-weight: normal;
              font-size: 15px;
            }
          }
          & .ant-calendar-cell:hover {
            background: #ffa100;
            & .ant-calendar-date {
              color: #fff !important;
            }
            & .ant-calendar-date:hover {
              box-shadow: none;
            }
          }
          & .ant-calendar-selected-day {
            background: #ffa100;
            z-index: 10;
            position: relative;

            & .ant-calendar-date {
              background: #ffa100;
              border-color: #ffa100;
              color: #fff !important;
              font-weight: bold !important;
              font-size: 15px !important;
              &:after {
                content: '';
                position: absolute;
                background: #e27c17;
                box-shadow: 0px 4px 4px rgba(226, 124, 23, 0.25);
                border-radius: 4px;
                width: 35px;
                height: 35px;
                left: 3px;
                top: 3px;
                z-index: -11;
              }
            }
            & .ant-calendar-date:hover {
              box-shadow: none;
            }
          }

          & .ant-calendar-next-month-btn-day {
            & .ant-calendar-date {
              color: #828097;
              opacity: 0.5;
            }
          }
          & .ant-calendar-date:hover {
            box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
            border-radius: 4px;
            -webkit-border-radius: 4px;
            -moz-border-radius: 5px;
            background: #ffa100;
            color: #fff;
          }
        }
      }
      & .ant-calendar-footer {
        display: none;
      }
    }
    .ant-calendar-input-wrap {
      display: none;
    }
    .ant-calendar-disabled-cell.ant-calendar-today .ant-calendar-date::before {
      border: 0;
      top: 6px;
    }
  }
  .custom-timepicker {
    height: 46px;
    line-height: 46px;
    width: 14.6%;
    margin-left: 26px;
    border-radius: 4px;
    cursor: pointer;
    input {
      ::placeholder {
        color: #828097;
        opacity: 1;
      }
      ::-ms-input-placeholder {
        color: #828097;
        opacity: 1;
      }
      ::-webkit-input-placeholder {
        color: #828097;
        opacity: 1;
      }
      ::-moz-placeholder {
        color: #828097;
        opacity: 1;
      }
    }
    .ant-time-picker-input {
      padding: 10px 0 10px 10px;
      color: #323232;
      font-size: 15px;
      cursor: pointer;
      padding: 0;
      height: 46px;
      border: 0;
      border: 1px solid #d9d9d9;
      text-align: center;
      &:focus {
        box-shadow: none;
      }
      @media ${size['mobile-md-max']} {
        left: -30px;
      }
    }
    .ant-time-picker-icon {
      right: 12px;
      @media ${size['laptop-md-max']} {
        right: 0px;
      }
      @media ${size['mobile-sm-max']} {
        right: 18px;
      }
    }
    .ant-time-picker-clock-icon {
      display: none;
    }
  }
  .hide {
    display: none;
  }
  .custom-time-dropdown {
    left: 1.84375px !important;
    top: 50px !important;
    .ant-time-picker-panel-input-wrap {
      display: none;
    }
    .ant-time-picker-panel-addon {
      button.ant-btn.ant-btn-primary.ant-btn-sm {
        border: 1px solid #8f48ff !important;
        color: black;
        background: #fff;
      }
    }
    .ant-time-picker-panel-combobox {
      width: 100px;
    }
    .ant-time-picker-panel-select {
      width: 50px;
      height: 180px;
      ul {
        padding: 0 0 0;
        width: 100%;
        li {
          &:hover {
            color: #fff;
            background: #ffa100;
          }
        }
      }
      &::-webkit-scrollbar {
        width: 0.5em;
      }
      &::-webkit-scrollbar-thumb {
        height: 88px;
        width: 8px;
        border-radius: 10px;
        background: #ffa100;
      }
    }
  }
  .pickupDateDiv {
    box-shadow: none;
    width: 20%;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 45px;
    background: none;
    padding: 0;
    min-width: 70px;
  }
  .carrierAvailabilityDiv {
    box-shadow: none;
    background: none;
    padding: 0;
    font-size: 18px;
    font-weight: 300;
    color: #979797;
    min-width: 70px;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 45px;
    width: 135px;
    @media ${size['desktop-mm-max']} {
      right: 40px;
      font-size: 16px;
    }
    @media ${size['tablet-max']} {
      font-size: 14px;
    }
    .ant-select-no-arrow .ant-select-selection__rendered {
      margin: 0px;
      line-height: 58px;
    }
    .ant-select-dropdown {
      min-width: 200px;
    }
  }
  .carrierAvailabilityDiv,
  .pickupDateDiv {
    .select-custom {
      padding: 0 !important;
    }
    .ant-select-selection__placeholder {
      color: #979797 !important;
      text-decoration: none !important;
      text-align: center;
      font-size: 18px;
      font-weight: 300;
    }
    .dropdown-custom ul li {
      padding-left: 14px;
    }
  }
  .suffix-wrapper {
    .dots-icon {
      max-height: 58px;
      border: 0;
      background: none;
      padding: 0;
      height: 100%;
      font-size: 22px;
      color: #8f48ff;
      letter-spacing: 0;
      position: absolute;
      right: 5px;
      z-index: 1;
      top: 3px;
      bottom: 0;
      @media ${size['desktop-sm-max']} {
        max-height: 42px;
      }
      @media ${size['tablet-max']} {
        max-height: 42px;
      }
      &:active,
      &:focus {
        outline: none;
      }
    }
  }
`;

export { FormWrapper };
