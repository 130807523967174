import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Header, BgWrapper } from './style';
import logoShapeBG from 'assets/images/logo-shape1.svg';
import logo1 from 'assets/images/logo1.svg';
class AuthHeader extends Component {
  render() {
    const { isBgImage, bgImage } = this.props;
    return (
      <>
        {isBgImage && (
          <BgWrapper>
            <img src={bgImage} alt="" />
          </BgWrapper>
        )}
        <Header>
          <nav className="navbar navbar-expand-md navbar-dark bg-dark">
            <h1 className="site-logo">
              <Link className="navbar-brand" to="/login">
                <img src={logo1} alt="Gophr Courier" />
              </Link>
            </h1>
          </nav>
        </Header>
      </>
    );
  }
}
AuthHeader.defaultProps = {
  isBgImage: false,
  bgImage: logoShapeBG
};

export default AuthHeader;
