import React from 'react';
import Slider, { createSliderWithTooltip } from 'rc-slider';
import 'rc-slider/assets/index.css';
import moment from 'moment';
const Range = createSliderWithTooltip(Slider.Range);

const TimeSlider = ({ value, min, max, onSliderChange, onSliderAfterChange }) => {

    return (
        <Range
            style={{ 'margin': '40px 0px 16px 0px' }}
            value={value}
            min={min}
            max={max}
            onChange={onSliderChange}
            onAfterChange={onSliderAfterChange}
            step={1800}
            pushable={10800}
            tipFormatter={value => {
                const date = new Date(value * 1000);
                return <span>{`${moment(date).format('HH:mm')}`}</span>;
            }}
            railStyle={{
                height: 1,
                backgroundColor: '#5a5a5a'
            }}
            trackStyle={{
                background: 'none'
            }}
            tipProps={{ visible: true }}
        />
    )
};

export default TimeSlider;