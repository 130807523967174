import styled from 'styled-components';
import { size } from 'App/device';
import starIcon from 'assets/images/star-icon.svg';
import arrowIcon from 'assets/images/dropdown-arrow.svg';
import starDarkIcon from 'assets/images/star-dark-icon.svg';
import { borderRadius, customScroller } from 'App/mixins';

const FormWrapper = styled.div`
  position: relative;
  span{
    font-weight: 600;
  }
  .selected-address-parcel-wrap {
    font-weight: 600;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
    background: #fff;
    ${borderRadius('0px')};
    border-bottom: 0.1rem solid #cecece;
    color: ${props => props.theme.primaryColor};
    font-family: 'Montserrat';
    height: 40px;
    font-size: 12.8062px;
    padding: 10px 36px;
    transition: all 0.15s ease-in-out;
    line-height: 1;
    display: block;
    width: 100%;
    margin: 0;
    padding-right: 26px;
    position: relative;
    &:after {
      position: absolute !important;
      left: initial !important;
      right: 5px !important;
      content: '';
      background: url(${arrowIcon}) no-repeat center;
      width: 9px;
      height: 8px;
      top: 20px;
      background-size: 100% auto;
      position: absolute;
      border: 0;
      display: inline-block;
      vertical-align: middle;
      float: right;
      position: relative;
      font-family: 'Line Awesome Free';
      font-weight: 900;
      // @media ${size['desktop-sm-max']} {
      //   top: -20px !important;
      // }
    }
    @media ${size['mobile-sm-max']} {
      padding-right: 0;
    }
  }
  .selected-address-parcel-wrap::focus {
    border-bottom: 0.1rem solid #8f48ff;
  }
  .focus {
    border-bottom: 0.1rem solid #8f48ff;
  }
  .location-title {
    padding: 0px 40px 0px 40px;
    font-size: 13px;
    letter-spacing: 0.05em;
    font-weight: 500;
    color: #49465d;
    position: relative;
    margin: 27px 0;
    .title {
      font-size: 15px;
      padding-top: 5px;
    }
  }
  .ant-dropdown.ant-dropdown-placement-bottomCenter {
    width: 100%;
    min-width: 1px !important;
  }
  label.add-all-address-parcel-wrapper-checkbox.ant-checkbox-wrapper {
    display: flex !important;
  }
  span {
    max-width: 100% !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
  }
  span.ant-checkbox {
    overflow: visible !important;
  }
  .ant-dropdown {
    background: #fff;
    box-shadow: 10px 10px 25px rgba(0, 0, 0, 0.1);
    ${borderRadius('10px')};
    border: 0;
    padding: 20px;
    margin: 0;
    .ant-dropdown-menu {
      -webkit-box-shadow: 0 !important;
      box-shadow: 0 !important;
    }
    .d-title {
      display: block;
      font-weight: 600;
      font-size: 15px;
      line-height: 14px;
      color: ${props => props.theme.primaryColor};
    }
    .add-all-address-parcel-wrapper-checkbox {
      width: 100%;
      .ant-checkbox-inner {
        width: 20px;
        height: 20px;
      }
      span:not(.ant-checkbox) {
        font-size: 15px;
        font-weight: 600;
        max-width: 100%;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding-right: 10px;
        padding-left: 10px;
        color: ${props => props.theme.primaryColor};
      }
    }
    ul {
      padding: 0;
      max-height: 188px;
      overflow: auto;
      ${customScroller()};
      .ant-checkbox-group {
        width: 100%;
        flex-direction: column;
        .ant-checkbox-wrapper {
          display: flex;
          align-items: center;
          justify-content: end;
          .ant-checkbox-input {
            padding: 0 !important;
          }
          .ant-checkbox-inner {
            width: 20px;
            height: 20px;
          }
          &:hover {
            background: #fafafa;
          }
        }

        .ant-checkbox-wrapper + .ant-checkbox-wrapper {
          margin-left: 0px;
        }
      }
      .list {
        padding: 13px 10px 13px 5px;
        margin: 0;
        list-style: none;
        font-weight: normal;
        font-size: 18px;
        line-height: 20px;
        color: #818181;
        font-weight: 600 !important;
        -webkit-flex: flex;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        transition: all 0.4s ease;
        -webkit-transition: all 0.4s ease;
        -moz-transition: all 0.4s ease;
        position: relative;
        :hover {
          background: #fafafa;
        }
        @media ${size['tablet-max']} {
          padding: 12px 12px;
        }
        a {
          // color: #818181;
          display: flex;
          justify-content: space-between;
          @media ${size['tablet-max']} {
            width: 100%;
            .ant-radio-wrapper .ant-radio-inner {
              top: 0px !important;
              input {
                padding-left: 0px !important;
              }
            }
          }
          &.dropdown-item {
            width: 100%;
            & > span {
              font-size: 15px;
              font-weight: 600 !important;
              max-width: 100%;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              padding-right: 10px;
              color: ${props => props.theme.primaryColor};
            }
          }
          i.star-icon {
            position: absolute;
            top: 50%;
            bottom: 0;
            right: 50px;
            transform: translate(0, -50%);
            -webkit-transform: translate(0, -50%);
            -moz-transform: translate(0, -50%);
            -ms-transform: translate(0, -50%);
            background: #fff url(${starIcon}) no-repeat center;
            background-size: 100% auto;
            margin-left: auto;
            width: 24px;
            height: 24px;
          }
          .light {
            color: #49465d;
            font-weight: 600;
          }
        }
        &.not-selectable {
          display: inline-flex;
          justify-content: end;
          width: 100%;
          a.dropdown-item {
            width: initial;
          }
        }
      }
      li.dark-active {
        a {
          color: #444444;
          i.star-icon {
            background: url(${starDarkIcon}) no-repeat center;
            background-size: 100% auto;
          }
        }
      }
    }
  }
  .ant-dropdown-menu-item:hover,
  .ant-dropdown-menu-submenu-title:hover {
    background-color: #fafafa;
  }
  .dropinputDiv {
    margin: 15px 0;
    .line {
      left: 9%;
      width: 440px;
      @media ${size['tablet-max']} {
        display: none;
      }
    }
  }
`;
export { FormWrapper };
