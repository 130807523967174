import * as actions from './constant';

const initialState = {
  theme: 'light',
  screenWidth: window.innerWidth,
  googleRef: null,
  loading: false,
  isLoading: 0,
  error: null,
  isTCSAccept: true,
  publicManifestData: {},
  publicLocationData: {},
  rateCourierData: {},
  getPublicJob: {},
  getPublicParcel: {}
};

const app = (state = initialState, action) => {
  switch (action.type) {
    case actions.CHANGE_THEME:
      return {
        ...state,
        theme: action.payload
      };
    case actions.GOOGLE_INITIALIZED:
      return {
        ...state,
        googleRef: action.payload
      };
    case actions.SCREEN_WIDTH_CHANGE:
      return {
        ...state,
        screenWidth: action.payload
      };
    case actions.LOADER_STATUS:
      return {
        ...state,
        loading: action.payload
      };
    case actions.INCREMENT_LOADER:
      return {
        ...state,
        isLoading: state.isLoading + 1
      };
    case actions.DECREMENT_LOADER:
      return {
        ...state,
        isLoading: state.isLoading - 1
      };
    case actions.ERROR_HANDLER:
      return {
        ...state,
        error: action.payload.message
      };
    case actions.ERROR_EMPTY:
      return {
        ...state,
        error: null
      };
    case actions.PENDING_TERMS:
      return {
        ...state,
        isTCSAccept: action.payload
      };
    case actions.ACCEPT_TERMS:
      return {
        ...state,
        isTCSAccept: action.payload
      };
    case actions.PUBLIC_COURIER_MANIFEST:
      return {
        ...state,
        publicManifestData: action.payload
      };
    case actions.PUBLIC_COURIER_LOCATION:
      return {
        ...state,
        publicLocationData: action.payload
      };
    case actions.PUBLIC_COURIER_DETAILS:
      return {
        ...state,
        publicLocationData: action.payload
      };
    case actions.GET_PUBLIC_JOB:
      return {
        ...state,
        getPublicJob: action.payload
      };
    case actions.RATE_COURIER_FEEDBACK_PUBLIC:
      return {
        ...state,
        rateCourierData: action.payload
      };
    case actions.GET_PUBLIC_PARCEL:
      return {
        ...state,
        getPublicParcel: action.payload
      };
    default:
      return state;
  }
};
export default app;