import logoBlack from 'assets/images/logo-black.svg';

/* Note:- https://css-tricks.com/whats-deal-declaring-font-properties-font-face/ -> Need to change from link */
const lightTheme = {
  backgroundColor: '#fff',
  footerFontColor: '#110C23',
  fontLightColor: '#828097',
  inputBackgroundColor: '#fff',
  inputActiveBgColor: '#8F48FF',
  selectFontColor: '#828097',
  selectBg: '#fff',
  logo: logoBlack,
  primaryColor: '#49465D',
  secondaryColor: '#8f48ff',
  redShadeColor1: '#E8286B',
  fourthColor: '#e406ff',
  fifthColor: '#404040',
  sixthColor: '#9552ff',
  labelColor: '#816E9B',
  greenShadeColor1: '#cacd47',
  orangeShadeColor1: '#FFA100',
  greyColor: '#fafafa',
  greyShadeColor1: '#c9c9c9',
  greyShadeColor2: '#f8f8f8',
  greyPinkShadeColor1: '#D9C0FF',
  pinkColor1: '#be24ff',
  dangerColor: '#d6181a',
  blackColor: '#000',
  loginColor: '#979797',
  primaryFont: 'Montserrat',
  fontColor: '#323232',
  primaryMediumFont: 'MontserratMedium',
  primaryBoldFont: 'MontserratBold',
  secondaryFont: 'OpenSans',
  secondarySemiBoldFont: 'OpenSansSemiBold',
  secondaryBoldFont: 'OpenSansBold',
  primaryButtonColor: '#00C68D',
  primarygreenButtonColor: '#00835D',
};

export { lightTheme };
