import { Menu, Popover, Skeleton } from 'antd';
import { MenuLink } from 'App/AppConstant';
import fullLogo from 'assets/images/logo1.svg';
import defaultImage from 'assets/images/profile-no-image.png';
import LoginComponent from 'components/LoginComponent';
import isEmpty from 'lodash.isempty';
import { onError } from 'modules/errorHandler';
import { getAuthToken } from 'modules/helper';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { changeJobType } from 'redux/booking/actions';
import { logout } from 'redux/login/actions';
import { Header, ProfilePicture } from './style';

class BookingHeader extends Component {
  state = {
    isHamClicked: false,
    visibility: false,
    visibleChange: false,
    visible: true,
  };
  handleHamClick = (e) => {
    e.preventDefault();
    this.setState((prevState) => ({
      isHamClicked: !prevState.isHamClicked,
    }));
  };
  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
    this.handleScroll();
  }
  componentDidUpdate(prevProps) {
    if (this.props.isAuthenticated !== prevProps.isAuthenticated) {
      this.closePopover();
    }
  }
  componentDidCatch(error) {
    onError(error, 'Booking Header', 'Component Render Error');
  }
  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }
  handleScroll = () => {
    const currentScrollPos = window.pageYOffset;
    const visible = currentScrollPos < 50;

    this.setState({
      visible,
    });
  };
  redirectChanges = (type) => {
    if (type === 'Booking') {
      this.props.changeJobType(0);
    }
  };
  closePopover = () => {
    this.setState({ visibility: false });
  };
  handleLogout = () => {
    const { visibility } = this.state;
    this.props.logout();
    this.setState({ visibility: !visibility });
  };
  visibleChange = () => {
    const { visibility } = this.state;
    this.setState({ visibility: !visibility });
  };
  render() {
    const styleLogout = {
      position: 'absolute',
      left: '20px',
      top: '25px',
      borderRadius: '5px',
      boxShadow: '8px 8px 16px rgba(0,0,0,0.1)',
    };
    const { visible } = this.state;
    const Logout = () => (
      <Menu style={styleLogout}>
        <Menu.Item>
          <Link to="/profile">Profile</Link>
        </Menu.Item>
        <Menu.Item onClick={this.handleLogout}>Logout</Menu.Item>
      </Menu>
    );
    let DropComponent = getAuthToken() ? Logout() : <LoginComponent close={this.closePopover} />;
    const { active, userData, showSkeleton, classNameProps } = this.props;
    const { isHamClicked, visibility } = this.state;
    return (
      <Header className={classNameProps}>
        <nav className={visible ? '' : 'nav-scale-down'}>
          {showSkeleton && <Skeleton active />}
          {!showSkeleton && (
            <>
              <h1 className="site-logo">
                <Link className="w-100" to="/" onClick={() => this.props.changeJobType(0)}>
                  <img src={fullLogo} alt="" />
                </Link>
              </h1>
              {/* {configVar.ENVIRONMENT == 'development' && (
                <div style={{ margin: '0 0 -10px 10px' }}>
                  <strong>{`DEV - ${configVar.FRONTEND_VERSION}`}</strong>
                </div>
              )} */}
              <div className={`collapse navbar-collapse ${isHamClicked ? 'show' : ''}`} id="header-menu">
                <ul className="navbar-nav">
                  {MenuLink.map((pathName, index) => {
                    if (pathName.name === "Teams" && process.env.REACT_APP_ENABLE_TEAMS === 'true') {
                      return <li key={index} className={`nav-item ${pathName.name === active ? 'active' : ''}`}>
                        <Link className="nav-link" to={pathName.path} onClick={() => this.redirectChanges(pathName.name)}>
                          {pathName.name}
                        </Link>
                      </li>
                    } else if (pathName.name !== 'Teams') {
                      return <li key={index} className={`nav-item ${pathName.name === active ? 'active' : ''}`}>
                        <Link className="nav-link" to={pathName.path} onClick={() => this.redirectChanges(pathName.name)}>
                          {pathName.name}
                        </Link>
                      </li>
                    }
                  })}
                </ul>
              </div>
              <div className="navbar-right">
                <button className="navbar-toggler" type="button" onClick={this.handleHamClick}>
                  <span className="navbar-toggler-icon"></span>
                </button>
                <div className="profile-menu" id="drowndown-container-nav">
                  <Popover
                    visible={visibility}
                    onVisibleChange={this.visibleChange}
                    content={DropComponent}
                    getPopupContainer={() => document.getElementById('drowndown-container-nav')}
                    trigger={['click']}
                    placement="bottom"
                    overlayClassName={'dropdown-header-wrapper'}
                  >
                    <div className="dropdown-menu">
                      <a className="dropdown-toggle">
                        {!isEmpty(userData) ? (
                          <strong>
                            <small>{userData.person_name}</small>
                            {!isEmpty(userData.is_registered_company) && userData.is_registered_company === '1' ? 'BUSINESS' : 'PERSONAL'}
                          </strong>
                        ) : (
                          ''
                        )}
                        <ProfilePicture profilePicutre={userData.photo || defaultImage} />
                      </a>
                    </div>
                  </Popover>
                </div>
              </div>
            </>
          )}
        </nav>
      </Header>
    );
  }
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.login.isAuthenticated,
  userData: state.profileUpdate.userData,
});
const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(logout()),
  changeJobType: (payload) => dispatch(changeJobType(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BookingHeader);
