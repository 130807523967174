import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Form } from 'antd';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { login } from 'redux/login/actions';
import { FormValidation } from 'App/AppConstant';
import { onError } from 'modules/errorHandler';
import { LoginWrapper } from './style';
import { Label, FormGroup, FormButton as Button, FormInput as Input } from 'components/Form';
import { LoginConstant } from './constant';

const loginValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email(FormValidation.emailInvalid)
    .required(FormValidation.emailRequired),
  password: Yup.string()
    .min(6, FormValidation.passwordMin)
    .required(FormValidation.passwordRequired)
});
class LoginComponent extends Component {
  componentDidCatch(error) {
    onError(error, 'Login', 'Component render error');
  }
  handleSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      let data = {
        email: values.email,
        password: values.password
      };
      setSubmitting(false);
      this.props.login(data);
      await this.props.close();
    } catch (error) {
      console.dir(error, 'handle error');
      onError(error, 'login', 'handle submit error');
    }
  };
  render() {
    return (
      <LoginWrapper>
        <h5>{LoginConstant.loginHeader}</h5>
        <p>
          {LoginConstant.notProfile} <Link to="/register">{LoginConstant.signUp}</Link>
        </p>
        <div className="form-styling2">
          <Formik
            initialValues={{
              email: '',
              password: ''
            }}
            validationSchema={loginValidationSchema}
            onSubmit={this.handleSubmit}
          >
            {({ values, errors, touched, handleChange, handleSubmit }) => (
              <Form onSubmit={handleSubmit}>
                <FormGroup className="form-group">
                  <Label title={LoginConstant.email} />
                  <Input
                    type="email"
                    className="form-control"
                    placeholder={LoginConstant.emailplaceholder}
                    value={values.email}
                    handleChange={handleChange}
                    name="email"
                  />
                  {errors.email && touched.email ? (
                    <div className="form-error">{errors.email}</div>
                  ) : null}
                </FormGroup>
                <FormGroup className="form-group">
                  <Label title={LoginConstant.password} />
                  <Input
                    type="password"
                    className="form-control"
                    placeholder={LoginConstant.passwordplaceholder}
                    value={values.password}
                    handleChange={handleChange}
                    name="password"
                  />
                  {errors.password && touched.password ? (
                    <div className="form-error">{errors.password}</div>
                  ) : null}
                </FormGroup>
                <div className="forgot-password-link align-center">
                  <Link to="/forgot-password">{LoginConstant.forgotPassword}</Link>
                </div>
                <div className="btn-main align-center">
                  <Button htmlType="submit" className="btn gray-btn">
                    {LoginConstant.logIn}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </LoginWrapper>
    );
  }
}
const mapStateToProps = state => ({
  isAuthenticated: state.login.isAuthenticated
});
const mapDispatchToProps = dispatch => ({
  login: payload => dispatch(login(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginComponent);
