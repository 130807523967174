import styled from 'styled-components';
import { size } from 'App/device';

const FormWrapper = styled.div`
  .ant-popover {
    .ant-popover-content {
      .ant-popover-arrow {
        display: none;
      }
    }
  }
`;

export { FormWrapper };
