import styled from 'styled-components';
import { size } from 'App/device';

const FormWrapper = styled.div`
  border: none;
  border-width: 1px 1px 1px 0;
  background: #fff;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
  border-radius: 0 30px 30px 0;
  // padding: 0 15px 0 10px;
  font-size: 15px;
  min-width: 44px;
  // @media ${size['desktop-md-max']} {
  //   min-width: 95px;
  // }
  // @media ${size['desktop-sm-max']} {
  //   min-width: 100px;
  // }
  // @media ${size['laptop-md-max']} {
  //   min-width: 85px;
  // }
  ::after {
    display: none;
  }
  &.setborder {
    border: 1px solid rgba(198, 198, 198, 0.5);
  }
  .ant-dropdown {
    position: absolute;
    //left: 0px !important;
    will-change: transform;
    padding: 10px 15px;
    border: 0;
    background: #fff;
    color: #979797;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15);
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    min-width: 104px;
    & .ant-dropdown-menu {
      background-color: transparent;
      box-shadow: none;
      & .ant-dropdown-menu-item {
        -webkit-border-radius: 5px;
        padding: 0px;
        a {
          background-color: transparent;
          color: #979797;
          padding: 0 10px;
          line-height: 35px;
          -webkit-border-radius: 5px;
          text-align: center;
          width: 100%;
          clear: both;
          font-weight: 400;
          white-space: nowrap;
          background-color: transparent;
          border: 0;
          margin: 0px;
        }
      }
      & .ant-dropdown-menu-item:hover {
        background: #ffa100;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
      }
    }
  }
  a {
    line-height: 46px;
    text-decoration: none !important;
    text-align: center;
    color: #49465d;
  }
  &.light-input-wrapper {
    border: none;
    border-radius: 0px 30px 30px 0px;
    border-width: 1px 1px 1px 0;
    background: #fff;
    &.setborder {
      border: 1px solid rgba(198, 198, 198, 0.5);
    }
    .form-dropdown {
      a {
        color: #49465d;
      }
      .ant-dropdown {
        background: #fff;
        color: #49465d;
      }
    }
  }
`;
export { FormWrapper };
