import styled from 'styled-components';
import { size } from 'App/device';

const ChangePasswordWrapper = styled.div`
  position: relative;
  height: 100vh;
`;

const ChangePasswordDiv = styled.div`
  .login-box-main {
    background: #fff;
    box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    max-width: 500px;
    width: 100%;
    padding: 62px 50px 40px 50px;
    margin-bottom: 20px;
    @media ${size['desktop-md-max']} {
      max-width: 470px;
      padding: 55px 40px 30px 40px;
    }
    @media ${size['desktop-sm-max']} {
      max-width: 410px;
      padding: 40px 35px 25px 35px;
    }
    @media ${size['tablet-max']} {
      margin: 0 auto;
    }
    h2 {
      color: ${props => props.theme.primaryColor};
      margin: 0 0 10px 0;
      // font-size: 40px;
      font-size: 30px;
      font-weight: bold;
      line-height: normal;
      font-family: ${props => props.theme.primaryFont}, sans-serif;
      color: #49465d;
      @media ${size['desktop-md-max']} {
        font-size: 36px;
      }
      @media ${size['desktop-sm-max']} {
        font-size: 30px;
      }
      @media ${size['laptop-max']} {
        font-size: 28px;
      }
      @media ${size['tablet-max']} {
        font-size: 24px;
      }
    }
    p {
      font-size: 18px;
      line-height: 22px;
      margin: 0;
      color: #707070;
      letter-spacing: 0.025em;
      @media ${size['desktop-sm-max']} {
        font-size: 16px;
        line-height: 20px;
      }
      @media ${size['tablet-max']} {
        font-size: 14px;
      }
    }
  }
`;

const FormStyling = styled.div`
  .form-styling {
    margin-top: 32px;
    .form-group {
      position: relative;
      padding: 0;
      /* margin-bottom: 30px; */
      @media ${size['desktop-sm-max']} {
        margin-bottom: 20px;
      }
      input {
        @media ${size['tablet-max']} {
          font-size: 14px;
        }
        @media ${size['desktop-sm-max']} {
          font-size: 16px;
          height: 42px;
          padding: 10px 14px;
        }
      }
      input[type='checkbox'] {
        cursor: pointer;
      }
    }
    .checkbox1 {
      @media ${size['desktop-sm-max']} {
        font-size: 16px;
      }
    }
    .sendmycode-text {
      background: transparent;
      outline: none;
      border: none;
      &:focus {
        outline: none;
      }
      position: absolute;
      right: 25px;
      bottom: 12px;
      font-size: 15px;
      font-weight: bold;
      line-height: normal;
      color: #ffa100;
      @media ${size['desktop-sm-max']} {
        right: 20px;
        bottom: 13px;
        font-size: 14px;
      }
      @media ${size['tablet-max']} {
        right: 20px;
        bottom: 14px;
        font-size: 12px;
      }
    }
    .problem-text {
      font-size: 15px;
      font-weight: 500;
      line-height: 18px;
      color: #828097;
      @media ${size['desktop-sm-max']} {
        font-size: 13px;
        padding-top: 0;
        line-height: 20px;
      }
      @media ${size['tablet-max']} {
        font-size: 14px;
      }
    }
    .btn-main {
    display: flex;
    justify-content: flex-end;
    padding-top: 28px;
    flex-wrap: wrap;
      button + button {
        margin-left: 10px;
        @media ${size['mobile-md-max']} {
          margin: 10px 0 0;
        }
      }
    }
  }
  .forgot-pass-btn {
    @media ${size['desktop-sm-max']} {
      button {
        width: 100%;
        & + button {
          margin-top: 5px;
          margin-left: 0;
        }
      }
    }
  }
`;
export { ChangePasswordWrapper, ChangePasswordDiv, FormStyling };
