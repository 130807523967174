import * as actions from './constant';
const initialState = {
  isPaymentVerified: false,
  cards: [],
  paymentMethods: [],
  payments: [],
  error: false,
  loading: false,
  message: false
};

const payment = (state = initialState, action) => {
  switch (action.type) {
    case actions.GETPAYMENTMETHOD_INITIATED:
      return {
        ...state,
        paymentMethods: [],
        message: false,
        error: false,
        loading: true
      };
    case actions.GETPAYMENTMETHOD_SUCCESS:
      return {
        ...state,
        paymentMethods: action.payload,
        loading: false,
        error: false,
        message: 'Payment Methods'
      };
    case actions.GETPAYMENTMETHOD_ERROR:
      return {
        ...state,
        error: true,
        paymentMethods: [],
        message: action.error
      };
    case actions.ADDPAYMENT_INITIATED:
      return {
        ...state,
        message: false,
        error: false,
        loading: true
      };
    case actions.ADDPAYMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        message: 'Add Payment Method'
      };
    case actions.ADDPAYMENT_ERROR:
      return {
        ...state,
        error: true,
        message: action.error
      };
    case actions.GETCREDITCARDS_INITIATED:
      return {
        ...state,
        cards: [],
        message: false,
        error: false,
        loading: true
      };
    case actions.GETCREDITCARDS_SUCCESS:
      return {
        ...state,
        cards: action.payload,
        loading: false,
        error: false,
        message: 'Cards'
      };
    case actions.GETCREDITCARDS_ERROR:
      return {
        ...state,
        error: true,
        cards: [],
        message: action.error
      };
    case actions.DELETECREDITCARD_INITIATED:
      return {
        ...state,
        message: false,
        error: false,
        loading: true
      };
    case actions.DELETECREDITCARD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        message: 'Deleted Card'
      };
    case actions.DELETECREDITCARD_ERROR:
      return {
        ...state,
        error: true,
        loading: false,
        message: action.error
      };
    case actions.GETPAYMENTS_INITIATED:
      return {
        ...state,
        payments: [],
        message: false,
        error: false,
        loading: true
      };
    case actions.GETPAYMENTS_SUCCESS:
      return {
        ...state,
        payments: action.payload,
        loading: false,
        error: false,
        message: 'Payments Successful'
      };
    case actions.GETPAYMENTS_ERROR:
      return {
        ...state,
        error: true,
        payments: [],
        message: action.error
      };
    case actions.GETINVOICES_INITIATED:
      return {
        ...state,
        invoices: [],
        message: false,
        error: false,
        loading: true
      };
    case actions.GETINVOICES_SUCCESS:
      return {
        ...state,
        invoices: action.payload,
        loading: false,
        error: false,
        message: 'Invoices Successful'
      };
    case actions.GETINVOICES_ERROR:
      return {
        ...state,
        error: true,
        invoices: [],
        message: action.error
      };
    default:
      return state;
  }
};
export default payment;