import React, { Component } from 'react';
import { FormWrapper } from './style';

class Label extends Component {
  render() {
    const { title, isRequired, className, style, otherLables } = this.props;
    return (
      <FormWrapper style={style ? style : { display: `${otherLables && 'inline-block'}` }}>
        <label className={className}>{`${title}${isRequired ? ' *' : ''}`}</label>
      </FormWrapper>
    );
  }
}
Label.defaultProps = {
  title: 'Label',
  className: '',
  otherLables: false,
  isRequired: false
};
export default Label;
