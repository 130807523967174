import React, { Component } from 'react';
import { Wrapper } from './style';
import isEmpty from 'lodash.isempty';
import { Tabs } from 'antd';
export const TabPane = Tabs.TabPane;
class CustomTabs extends Component {
  render() {
    const { children, ...props } = this.props;
    return (
      <Wrapper>
        {/* {!isEmpty(props.title) && (
          <div className="Tabs-page-name">
            <p>{props.title}</p>
          </div>
        )} */}
        <Tabs {...props} animated={false}>
          {children}
        </Tabs>
      </Wrapper>
    );
  }
}
CustomTabs.defaultProps = {};
export default CustomTabs;
