import { Modal, notification } from 'antd';
import { push } from 'connected-react-router';
import { axiosAuthPost, axiosPost } from 'modules/Axios';
import { apiConstant } from 'modules/config';
import { profileClear } from 'redux/profileUpdate/actions';
import * as actions from './constant';

export const changeTheme = payload => dispatch => {
  dispatch({
    type: actions.CHANGE_THEME,
    payload
  });
};

// ANCHOR - Google Instance Action
export const setGoogleReference = payload => dispatch => {
  dispatch({
    type: actions.GOOGLE_INITIALIZED,
    payload
  });
};

// ANCHOR - Loader Action
export const loader = payload => dispatch => {
  if (payload) {
    dispatch({
      type: actions.INCREMENT_LOADER
    });
  } else {
    dispatch({
      type: actions.DECREMENT_LOADER
    });
  }
  dispatch({
    type: actions.LOADER_STATUS,
    payload
  });
};

// ANCHOR - Handle The Errors Action
export const errorHandler = (payload, requestPayload = {}) => dispatch => {
  let message = '';
  if (payload.error.code === 'ERROR_ACCESS_DENIED') {
    dispatch(push('/booking'));
    message = payload.error.message;
  } else if (payload.error.code === 'ERROR_NOT_AUTHORIZED') {
    message = payload.error.message;
    dispatch(profileClear());
    sessionStorage.clear();
    localStorage.clear();
    dispatch(push('/login'));
  } else if (payload.error.code === 'ERROR_NEW_APP_REQUIRED') {
    message = payload.error.code;
  } else {
    message = payload.error.message;
  }
  if (payload.error.code === 'ERROR_JOB_FINISHED') {
    message = '';
  }
  if (message) {
    Modal.error({
      title: 'Error',
      content: message,
      className: 'modal-custom-error',
      centered: true,
      onOk() {
        if (payload.error.code === 'ERROR_QUOTE_EXPIRED') {
          dispatch(push(`/marketplace/${requestPayload.id}`));
        }
      }
    });
  }
  dispatch({
    type: actions.ERROR_HANDLER,
    payload: { message }
  });
};

// ANCHOR - Empty The Errors Action
export const errorEmpty = () => dispatch => {
  dispatch({
    type: actions.ERROR_EMPTY
  });
};

// ANCHOR - Redirect Login Action
export const redirectLogin = () => async dispatch => {
  localStorage.clear();
  sessionStorage.clear();
  await dispatch({
    type: actions.REDIRECT_LOGIN
  });
  dispatch(push('/'));
};

// ANCHOR - Screen Resize Action
export const screenWidthChange = payload => dispatch => {
  dispatch({
    type: actions.SCREEN_WIDTH_CHANGE,
    payload
  });
};

// ANCHOR - Pending Terms and Condition Action
export const pendingTCS = () => dispatch => {
  dispatch({
    type: actions.PENDING_TERMS,
    payload: false
  });
};

// ANCHOR - Accept Terms and Condition Action
export const acceptTerms = () => async dispatch => {
  try {
    dispatch(loader(true));
    let response = await axiosAuthPost(apiConstant.ACCEPT_TCS, {});
    if (response.success)
      dispatch({
        type: actions.ACCEPT_TERMS,
        payload: true
      });
  } catch (error) {
    console.log(error);
  }
  dispatch(loader(false));
};

export const publicCourierManifest = payload => async dispatch => {
  try {
    let response = await axiosPost(apiConstant.GET_PUBLIC_COURIER_MANIFEST, payload);
    if (response.success)
      dispatch({
        type: actions.PUBLIC_COURIER_MANIFEST,
        payload: response.data
      });
  } catch (error) {
    console.log(error);
  }
};

export const publicCourierLocation = payload => async dispatch => {
  try {
    let response = await axiosPost(apiConstant.GET_PUBLIC_COURIER_LOCATION, payload);
    if (response.success)
      dispatch({
        type: actions.PUBLIC_COURIER_LOCATION,
        payload: response.data
      });
  } catch (error) {
    console.log(error);
  }
};
export const publicCourierDetails = payload => async dispatch => {
  try {
    let response = await axiosPost(apiConstant.GET_PUBLIC_COURIER_DETAILS, payload);
    if (response.success)
      dispatch({
        type: actions.PUBLIC_COURIER_DETAILS,
        payload: response.data
      });
  } catch (error) {
    console.log(error);
  }
};
export const getPublicJob = payload => async dispatch => {
  try {
    dispatch(loader(true));
    let response = await axiosPost(apiConstant.GET_JOB_PUBLIC, payload);
    if (response.success)
      dispatch({
        type: actions.GET_PUBLIC_JOB,
        payload: response.data
      });
    dispatch(loader(false));
  } catch (error) {
    dispatch(loader(false));
    console.log(error);
  }
};

export const getPublicParcel = payload => async dispatch => {
  try {
    dispatch(loader(true));
    let response = await axiosPost(apiConstant.GET_PARCELS_PUBLIC, payload);
    if (response.success)
      dispatch({
        type: actions.GET_PUBLIC_PARCEL,
        payload: response.data
      });
    dispatch(loader(false));
  } catch (error) {
    dispatch(loader(false));
    console.log(error);
  }
};

export const rateCourierPublic = payload => async dispatch => {
  try {
    dispatch(loader(true));
    let response = await axiosPost(apiConstant.RATE_COURIER_PUBLIC, payload);
    if (response.success) {
      dispatch({
        type: actions.RATE_COURIER_FEEDBACK_PUBLIC,
        payload: response.data
      });
      notification['success']({
        message: 'Your job feedback was sent successfully!',
        duration: 5
      });
    }
    dispatch(loader(false));
  } catch (error) {
    dispatch(loader(false));
    console.log(error);
  }
};
