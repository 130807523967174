import styled from 'styled-components';
import { size } from 'App/device';
import { borderRadius } from 'App/mixins';
const FormWrapper = styled.div`
  padding: 0;
  margin: 0;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
  position: relative;
  // margin-bottom: 1.5rem;
  input {
    border: none;
    display: block;
    width: 100%;
    line-height: 1.5;
    margin: 0;
    -webkit-appearance: none;
    background: #fff;
    ${borderRadius('0px')};
    height: 40px;
    font-size: 16px;
    font-weight: 600;
    padding: 10px 14px;
    padding-left: 5px;
    color: ${props => props.theme.primaryColor};
    font-family: ${props => props.theme.secondaryFont}, sans-serif;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    &.form-control {
      border: 0.1rem solid #cecece;
    }
    &.custom-input {
      padding-left: 20px;
    }
    ::placeholder {
      color: ${props => props.theme.primaryColor};
    }
    ::-ms-input-placeholder {
      color: ${props => props.theme.primaryColor};
    }
    :hover,
    :focus {
      outline: none;
    }
    @media ${size['desktop-sm-max']} {
      font-size: 16px;
      height: 38px;
      padding: 10px 14px;
      padding-left: 5px;
    }
    @media ${size['tablet-max']} {
      font-size: 14px;
    }
    @media ${size['desktop-mm-min']} {
      // height: 36px;
    }
  }
  &.errorwrapper {
    input.form-control {
      border: none !important;
      border-bottom: 1px solid #d6181a !important;
      box-shadow: none !important;
      border-right: 10px solid white !important;
      border-left: 10px solid white !important;
      &.addTeam {
        border-bottom: 1px solid #d6181a !important;
        border-right: 10px solid white !important;
        border-left: 10px solid white !important;
      }
    }
    input.custom-input,
    input.inc-input {
      box-shadow: none !important;
      border-radius: 0px !important;
      // border: none !important;
    }
  }
  input[readonly] {
    box-shadow: none;
    background: #f7f7f7 !important;
  }
  .form-control-placeholder {
    position: absolute;
    top: 0;
    transition: all 150ms;
    opacity: 0;
    display: block;
    font-size: 0;
    font-weight: 400;
    color: #49465d;
    font-family: 'OpenSans';
    -webkit-letter-spacing: 0.05em;
    -moz-letter-spacing: 0.05em;
    -ms-letter-spacing: 0.05em;
    letter-spacing: 0.05em;
    line-height: 15px;
    margin-bottom: 8px;
    margin-top: 12px;
    width: 0;
    // text-transform: uppercase;
    @media ${size['desktop-sm-max']} {
      margin-top: 0;
      line-height: 2px;
    }
    &.refrence {
      top: -25px;
      transform: translate3d(0, 40%, 0);
      color: #8f48ff;
      &.show {
        top: 0;
        color: #49465d;
      }
    }
  }
  input.form-control {
    padding-bottom: 0;
    border-bottom: 0.1rem solid #cecece !important;
    border-right: 10px solid white !important;
    border: none;
    padding-left: 0;
    &.booking {
      padding-right: 0 !important;
    }
    &.addTeam {
      border-bottom: 1px solid #dddddd !important;
      border-right: 10px solid white !important;
      border-left: 10px solid white !important;
    }
  }
  .form-control:focus + .form-control-placeholder {
    transform: translate3d(0, -100%, 0);
    opacity: 1;
    top: 0px;
    color: #8f48ff;
    width: 100%;
    font-size: 12px;
  }
  .form-control:focus {
    &.addTeam {
      border-bottom: 1px solid #8f48ff !important;
      border-right: 10px solid white !important;
      border-left: 10px solid white !important;
    }
    border-bottom: 1px solid #8f48ff !important;
    &::-webkit-input-placeholder {
      color: transparent;
    }

    &::-moz-placeholder {
      /* Firefox 19+ */
      color: transparent;
    }

    &:-ms-input-placeholder {
      color: transparent;
    }
  }
  &.show {
    .title {
      transform: translate3d(0, -100%, 0);
      opacity: 1 !important;
      font-family: 'OpenSans';
      width: 100%;
      font-size: 12px;
      color: rgb(136 135 142);
    }
    &.refrence {
      top: 0;
    }
  }
  input.placeInput {
    border: none !important;
    border-bottom: 0.1rem solid #cecece !important;
    &:focus {
      border-bottom: 1px solid #8f48ff !important;
    }
  }
`;

const TextAreaWrapper = styled.div`
  textarea {
    ${borderRadius('0px')};
    height: 100px;
    font-size: 16px;
    color: #49465d;
    font-weight: 600;
    padding-left: 10px;
    border-bottom: 0.1rem solid #cecece !important;
    border-right: 10px solid white !important;
    border: none;
    &:focus {
      border-bottom: 1px solid #8f48ff !important;
      &::-webkit-input-placeholder {
        color: transparent;
      }
      &::-moz-placeholder {
        /* Firefox 19+ */
        color: transparent;
      }
      &:-ms-input-placeholder {
        color: transparent;
      }
    }
  }
  .form-group {
    margin-bottom: 15px;
    &.show {
      .label {
        transform: translate3d(0, -100%, 0);
        opacity: 1 !important;
      }
    }
    &.focus {
      .label {
        transform: translate3d(0, -100%, 0);
        opacity: 1 !important;
        color: #8f48ff;
      }
    }
    &.text-area {
      .label {
        position: absolute;
        transition: all 300ms;
        opacity: 0.5;
        margin-bottom: -15px;
        top: 0;
      }
    }
  }
`;

export { FormWrapper, TextAreaWrapper };
