import { size } from 'App/device';
import { transition } from 'App/mixins';
import profileDropdownArrow from 'assets/images/profile-dropdown-arrow.svg';
import styled from 'styled-components';

const Header = styled.header`
  background: #fff;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 4;
  display: block;
  font-family: ${(props) => props.theme.primaryFont}, sans-serif;
  &.blur-body {
    filter: blur(2px);
  }
  nav {
    .ant-skeleton-content .ant-skeleton-paragraph > li {
      margin-top: 1px;
      height: 5px;
    }
    h3.ant-skeleton-title {
      display: none;
    }
    padding: 0 50px;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25);
    position: relative;
    display: flex;
    align-items: center;
    ${transition(0.2)};
    min-height: 62px;
    &.nav-scale-down {
      min-height: 65px;
    }
    @media (min-width: 1200px) {
      flex-flow: row nowrap;
      justify-content: flex-start;
    }
    @media ${size['desktop-md-max']} {
      padding: 0 40px;
    }
    @media ${size['desktop-sm-max']} {
      padding: 0 20px;
    }
    @media ${size['laptop-max']} {
      min-height: 65px;
    }
    @media ${size['tablet-max']} {
      padding: 0 15px;
    }
    .site-logo {
      margin: 0;
      min-width: 36px;
      min-height: 40px;
      display: flex;
      display: -webkit-flex;
      align-items: center;
      a {
        margin: 0;
        max-width: 36px;
        display: block;
        padding-top: 0.3125rem;
        padding-bottom: 0.3125rem;
        font-size: 1.25rem;
        line-height: inherit;
        white-space: nowrap;
        &.w-100 {
          max-width: 100% !important;
        }
        img {
          display: block;
          max-width: 100%;
          height: auto;
          border: none;
          outline: none;
          vertical-align: top;
        }
      }
    }
    .navbar-collapse {
      flex-basis: 100%;
      flex-grow: 1;
      align-items: center;
      &.collapse:not(.show) {
        display: none;
      }
      @media (min-width: 1200px) {
        display: flex !important;
        flex-basis: auto;
      }
      @media ${size['laptop-max']} {
        position: absolute;
        top: 100%;
        background: #fff;
        left: 15px;
        right: 15px;
        margin-top: 10px;
        padding: 15px;
        border-radius: 5px;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        box-shadow: 8px 8px 16px rgba(0, 0, 0, 0.1);
      }
      .navbar-nav {
        display: flex;
        margin-bottom: 0;
        list-style: none;
        flex-direction: column;
        margin-right: auto !important;
        padding: 0;
        margin-left: 10px;
        @media (min-width: 1200px) {
          flex-direction: row;
        }
        li {
          padding: 0;
          list-style: none;
          margin: 0px;
          margin-left: 50px;
          @media ${size['laptop-md-max']} {
            margin-left: 30px;
          }
          @media ${size['laptop-max']} {
            margin: 0;
            padding: 10px 0;
          }
          &.active {
            a {
              color: ${(props) => props.theme.primaryColor};
              font-weight: 600;
              font-size: 18px;
            }
          }
          a {
            font-size: 16px;
            line-height: 18px;
            color: #707070;
            padding: 0;
            margin: 0;
          }
        }
      }
    }
    .navbar-right {
      display: flex;
      display: -webkit-flex;
      align-items: center;
      margin-left: auto;
      button.navbar-toggler {
        border: 1px solid #110c23;
        width: 40px;
        height: 40px;
        margin: 0 20px 0 0;
        padding: 0 10px;
        position: relative;
        font-size: 1.25rem;
        line-height: 1;
        border-radius: 0.25rem;
        background-color: transparent;
        &:focus,
        &:hover,
        &:active {
          outline: none;
          box-shadow: none;
        }
        @media (min-width: 1200px) {
          display: none;
        }
        @media ${size['tablet-max']} {
          width: 36px;
          height: 36px;
        }
        .navbar-toggler-icon {
          vertical-align: middle;
          content: '';
          height: 2px;
          background: #110c23;
          top: 50%;
          position: absolute;
          left: 8px;
          right: 8px;
          transform: translate(0, -50%);
          width: auto;
          &:before {
            position: absolute;
            content: '';
            left: 0;
            right: 0;
            height: 2px;
            background: #110c23;
            bottom: -6px;
          }
          &:after {
            position: absolute;
            content: '';
            left: 0;
            right: 0;
            height: 2px;
            background: #110c23;
            top: -6px;
          }
        }
      }
      .profile-menu {
        display: flex;
        margin-left: auto;
        .dropdown-menu {
          cursor: pointer;
          &:after {
            border: 0;
            position: relative;
            top: 4px;
            margin-left: 15px;
            background: url(${profileDropdownArrow}) no-repeat center;
            background-size: 10px auto;
            width: 10px;
            height: 10px;
            vertical-align: 0.255em;
            content: '';
            display: inline-block;
          }
          a.dropdown-toggle {
            color: #000;
            font-size: 14px;
            white-space: nowrap;
            strong {
              display: inline-block;
              vertical-align: middle;
              color: #110c23;
              margin-right: 15px;
              font-family: ${(props) => props.theme.primaryFont}
              font-size: 12px;
              font-weight: bold;
              line-height: 20px;
              text-align: right;
              @media ${size['mobile-md-max']} {
                font-size: 14px;
                font-weight: 500;
              }
              small {
                display: block;
                font-size: 15px;
                font-family: ${(props) => props.theme.secondaryFont}
                line-height: 124.5%;
                text-transform: uppercase;
                font-weight: 400;
                @media ${size['mobile-md-max']} {
                  font-size: 12px;
                }
              }
            }
          }
        }
        .dropdown-header-wrapper {
          .ant-popover-arrow {
            display: none;
          }
          .ant-popover-inner-content {
            padding: 0;
            height: calc(100vh - 228px);
            max-height: calc(100vh - 240px);
            min-height: 1px;
            div {
              padding: 0;
              height: calc(100vh - 228px);
              max-height: calc(100vh - 240px);
              min-height: 1px;
            }
          }
        }
      }
    }
  }
`;

const ProfilePicture = styled.div`
  background-image: url(${(props) => props.profilePicutre});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 40px;
  height: 40px;
  display: inline-block;
  vertical-align: middle;
  border-radius: 50%;
`;
export { Header, ProfilePicture };
