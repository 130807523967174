import { size } from 'App/device';
import { borderRadius, transition } from 'App/mixins';
import styled from 'styled-components';

const Button = styled.button`
  background: ${(props) => props.bgcolor.background};
  border: 1px solid ${(props) => props.theme.blackColor};
  display: inline-block;
  font-family: ${(props) => props.theme.primaryFont}, sans-serif;
  font-weight: 500;
  position: relative;
  padding: 0px 20px;
  box-shadow: 5px 5px 20px ${(props) => props.bgcolor.boxShadow};
  height: 62px;
  line-height: 62px;
  min-width: 180px;
  color: ${(props) => props.theme.blackColor};
  letter-spacing: 0;
  font-size: 15px;
  text-transform: uppercase;
  ${transition(0.4)};
  ${borderRadius('0px')};
  :hover,
  :focus {
    outline: none;
    background: #fff;
    color: ${(props) => props.bgcolor.background};
    border: 1px solid ${(props) => props.theme.background};
  }
  @media ${size['desktop-sm-max']} {
    min-width: 130px;
    height: 44px;
    line-height: 44px;
    font-size: 14px;
  }
  @media ${size['desktop-mm-min']} {
    height: 50px;
    line-height: 50px;
    min-width: 150px;
  }
  @media ${size['mobile-md-max']} {
    width: 100%;
  }

  &.payments-btn {
    width: 131.3px;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    font-family: ${(props) => props.theme.primaryFont}, sans-serif;
    border: none;
    letter-spacing: 0;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    border-radius: 5px;
    color: #00c68d;
    border: 1px solid #00c68d;
    background: #fff;
    box-shadow: none;
  }
  &.new-btn {
    width: 216px;
    height: 50px;
    line-height: 40px;
    border-radius: 4.26875px;
    font-family: ${(props) => props.theme.primaryFont}, sans-serif;
    border: none;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0;
    background: ${(props) => props.theme.primarygreenButtonColor};
    color: #fff;
    box-shadow: none;
    &:disabled {
      color: #626262;
      background: #ddd;
      border: 1px solid #ddd;
      cursor: not-allowed;
      &:hover {
        color: #adadad !important;
        background: #ddd !important;
        border: 1px solid #ddd;
      }
    }
    &:hover {
      color: ${(props) => props.theme.primarygreenButtonColor};
      border: 1px solid ${(props) => props.theme.primarygreenButtonColor};
      background: #fff;
      box-shadow: none;
    }
    &.add-payments {
      width: 100%;
      height: 40px;
      line-height: 40px;
    }
    &.custom {
      &:disabled {
        color: #626262;
        background: #ddd;
        box-shadow: none;
        path {
          stroke: #adadad !important;
        }
      }
      &:hover {
        path {
          stroke: ${(props) => props.theme.primarygreenButtonColor};
        }
      }
    }
    &.left {
      margin-left: 8px;
    }
    &.add-favourite {
      height: 40px;
      line-height: 40px;
      position: absolute;
      right: 100%;
    }
    &.add-teams {
      width: 158.66px;
      height: 39.84px;
      float: right;
      line-height: 39.84px;
    }
    &.green-box {
      border: 1px solid ${(props) => props.theme.primarygreenButtonColor} !important;
    }
    &.purple-box {
      border: 1px solid #8f48ff !important;
    }
    &.red-hover {
      &:hover {
        border: 1px solid #e8286b !important;
      }
    }
    &.green-btn {
      background: ${(props) => props.theme.primarygreenButtonColor} !important;
      color: #fff !important;
    }
    &.new-btn-purple {
      background: #8f48ff;
      color: #fff;
      box-shadow: none;
    }
    &.reverse-btn {
      background: #fff;
      color: black;
      stroke: black;
      min-width: auto;
      border-radius: 4.26875px;
      border: 0.711458px solid rgba(223, 223, 223, 0.75);
      // width: auto;
      path {
        stroke: black;
      }
      box-shadow: none;
    }
    &.new-btn-simple {
      background: #fff;
      border: 0.711458px solid rgba(223, 223, 223, 0.75);
      color: black;
      &:disabled {
        box-shadow: none !important;
        color: #626262;
        background: #ddd;
        &:hover {
          color: #adadad !important;
          background: #ddd;
        }
      }
      &:hover {
        color: black !important;
      }
    }
    @media ${size['mobile-md-max']} {
      width: 100%;
    }
    &.menu-btn {
      width: auto;
      height: auto;
      font-weight: 400;
    }
  }
  &.parcel-cube {
    padding: 0 8px !important;
  }
  &.new-btn-white {
    background: #fff;
    color: ${(props) => props.theme.sixthColor};
    box-shadow: 0px 100px 80px rgba(0, 0, 0, 0.07), 0px 41.7776px 33.4221px rgba(0, 0, 0, 0.0406743),
      0px 22.3363px 17.869px rgba(0, 0, 0, 0.0259982), 0px 12.5216px 10.0172px rgba(0, 0, 0, 0.0160298),
      0px 6.6501px 5.32008px rgba(0, 0, 0, 0.0088394), 0px 2.76726px 2.21381px rgba(0, 0, 0, 0.00363399);
  }
  &.login-btn {
    background: #ffa100;
    box-shadow: none !important;
    width: 165px;
    &:focus,
    &:hover {
      color: #ffa100 !important;
      background: #fff !important;
      border: 1px solid #ffa100 !important;
    }
    &:disabled {
      color: #adadad;
      background: #ddd;
      box-shadow: none;
    }
    @media ${size['laptop-md-max']} {
      width: 196px;
    }
  }
  &.show-all-invoice {
    height: 40px;
    line-height: 40px;
    position: absolute;
    right: 30%;
  }
  &.show-all-invoice-payments {
    width: 100%;
    height: 40px;
    line-height: 40px;
    margin-right: 10px;
    width: 180px !important;
  }
`;

export { Button };

