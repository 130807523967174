import styled from 'styled-components';
import { size } from 'App/device';

const FormWrapper = styled.div`
  @media ${size['desktop-sm-max']} {
    font-size: 16px;
  }
  &.move-center-checkbox
    {
      margin: 10px 0;
    }
  &.proof-form-control {
    label.ant-checkbox-wrapper {
      span {
        margin: 0;
        font-family: ${props => props.theme.primaryFont}, sans-serif;
        font-weight: 600;
        display: inline-block;
        font-size: inherit;
        color: rgba(0, 0, 0, 0.65);
      }
    }
  }
  .ant-checkbox-inner {
    top: 0;
    // left: 0;
    // height: 16px;
    // width: 16px;
    background: transparent;
    border-radius: 5px;
    border: 1.42px solid #d9d9d9;
  }
  .ant-checkbox-checked .ant-checkbox-inner::after {
    // border-color: #000;
  }
  .ant-checkbox + span {
    display: inline-block;
    position: relative;
    text-align: left;
    font-size: 15px;
    color: #49465D;
    line-height: 16px;
    cursor: pointer;
    padding-left: 12px;
    margin: 0;
    @media ${size['desktop-sm-max']} {
      font-size: 16px;
    }
  }
  input[type='radio'],
  input[type='checkbox'] {
    box-sizing: border-box;
    padding: 0;
  }
`;

export { FormWrapper };
