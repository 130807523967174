import styled from 'styled-components';
import { size } from 'App/device';
const MiddleSection = styled.div`
  position: relative;
  margin: 0;
`;

const MaxContainer = styled.div`
  max-width: 1400px;
  width: 100%;
  margin: 0 auto;
  @media ${size['desktop-md-max']} {
    max-width: 1300px;
  }
  @media ${size['desktop-mm-max']} {
    max-width: 1250px;
  }
  @media ${size['desktop-sm-max']} {
    max-width: 1200px;
    padding: 0 5%;
  }
  @media ${size['laptop-max']} {
    padding: 0 6%;
  }
  @media ${size['tablet-max']} {
    padding: 0 15px;
  }
`;

const LoginImgBox = styled.div`
    position: fixed;
    bottom: 0px;
    right: 3%;
    width: 88%;
  margin-top: 0;
  @media ${size['desktop-md-max']} {
    max-width: 100%;
    margin-top: -60px;
  }
  @media ${size['desktop-sm-max']} {
    max-width: 100%;
    margin-top: 10px;
    right: 2%;
  }
  @media ${size['laptop-sm-max']} {
    max-width: 450px;
    margin-top: 100px;
  }
  @media ${size['laptop-max']} {
    max-width: 52%;
    margin-top: 65px;
    display: none;
  }
  @media ${size['tablet-md-max']} {
    max-width: 315px;
    margin-top: 120px;
    display: none;
  }
  @media ${size['tablet-max']} {
    position: static;
    margin: 20px auto;
    padding: 0 20px;
    display: none;
  }
  img {
    display: inline-block;
    vertical-align: top;
    height: auto;
    max-width: 100%;
    height: auto;
    border: none;
    outline: none;
  }
`;
const SignUpImgBox = styled.div`
  position: absolute;
  top: 0;
  right: 7%;
  margin-top: -70px;
  @media ${size['desktop-md-max']} {
    max-width: 715px;
    margin-top: -60px;
  }
  @media ${size['desktop-sm-max']} {
    max-width: 500px;
    margin-top: -65px;
    right: 5%;
  }
  @media ${size['laptop-max']} {
    max-width: 340px;
    margin-top: 50px;
  }
  @media ${size['tablet-md-max']} {
    position: static;
    margin: 20px auto;
    padding: 0 20px;
    display: none;
  }
  @media ${size['tablet-md-max']} {
    position: static;
    margin: 20px auto;
    padding: 0 20px;
    display: none;
  }
  img {
    display: inline-block;
    vertical-align: top;
    height: auto;
    max-width: 100%;
    height: auto;
    border: none;
    outline: none;
  }
`;
const MapBox = styled.div`
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  width: 40%;
  iframe {
    width: 100%;
    height: 100%;
    display: block;
  }
  @media ${size['tablet-md-max']} {
    position: static;
    width: 100%;
    height: 400px;
  }
  @media ${size['tablet-max']} {
    height: 300px;
  }
`;

export { MiddleSection, MaxContainer, LoginImgBox, MapBox, SignUpImgBox };
