import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormWrapper } from './style';
class InputIcon extends Component {
  render() {
    const {
      placeholder,
      name,
      value,
      handleChange,
      isText,
      type,
      isError,
      children,
      icon,
      activeIcon,
      isActive
    } = this.props;
    return (
      <FormWrapper>
        {isText ? children : null}
        <input
          placeholder={placeholder}
          type={type}
          className={`form-control ${isError ? 'error-input' : ''}`}
          value={value}
          name={name}
          onChange={handleChange}
        />
      </FormWrapper>
    );
  }
}
InputIcon.defaultProps = {
  isError: false,
  placeholder: null,
  isText: false,
  type: 'text'
};

const mapStateToProps = state => ({
  appTheme: state.app.theme
});

export default connect(mapStateToProps, {})(InputIcon);
