import React, { Component } from 'react';

import { FormWrapper } from './style';

class FormTextarea extends Component {
  render() {
    const { placeholder, name, value, type, handleChange, label, ...props } = this.props;
    return (
      <FormWrapper className={`${value ? 'show' : ''}`}>
        <label className="form-control-placeholder title">{label}</label>
        <textarea
          type={type}
          placeholder={placeholder}
          className="form-control"
          value={value}
          name={name}
          onChange={handleChange}
          {...props}
        />
      </FormWrapper>
    );
  }
}
FormTextarea.defaultProps = {
  placeholder: ''
};
export default FormTextarea;
