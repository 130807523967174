import { push } from 'connected-react-router';
import isEqual from 'lodash.isequal';
import { axiosAuthPost } from 'modules/Axios';
import { apiConstant } from 'modules/config';
import { loader } from 'redux/app/actions';
import store from 'redux/store';
import * as actions from './constant';

// ANCHOR - Add Shift
export const addShift = payload => async dispatch => {
  dispatch(loader(true));
  try {
    dispatch({ type: actions.ADDSHIFT_INITIATED });
    let response = await axiosAuthPost(apiConstant.ADD_SHIFT, payload);
    if (!response.success) {
      dispatch({
        type: actions.ADDSHIFT_ERROR,
        error: response.error.message
      });
    } else {
      let arealocation = {
        lat: response.data.start_location_lat,
        lng: response.data.start_location_lng
      };
      dispatch({
        type: actions.ADDSHIFT_SUCCESS,
        payload: response.data
      });
      dispatch(setArealocation(arealocation));

      dispatch(
        push({
          pathname: `/booking-shift/${response.data.id}`,
          state: { type: 'editLocation' }
        })
      );
    }
  } catch (error) {
    dispatch({
      type: actions.ADDSHIFT_ERROR,
      error
    });
  }
  dispatch(loader(false));
};

export const updateShift = payload => async dispatch => {
  payload.area_radius ? dispatch(loader(false)) : dispatch(loader(true));
  try {
    dispatch({ type: actions.UPDATESHIFT_INITIATED });
    let response = await axiosAuthPost(apiConstant.UPDATE_SHIFT, payload);
    if (!response.success) {
      dispatch({
        type: actions.UPDATESHIFT_ERROR,
        error: response.error.message
      });
    } else {
      let { id, ...updateShiftData } = payload;

      dispatch({
        type: actions.UPDATESHIFT_SUCCESS,
        payload: updateShiftData
      });

      // Area Location Set
      if (payload.area_lat || payload.area_lng) {
        dispatch(setArealocation({ lat: payload.area_lat, lng: payload.area_lng }));
      } else {
        // Geo Location Set
        let geolocation = {
          lat: response.data.start_location_lat,
          lng: response.data.start_location_lng
        };
        dispatch(setGeolocation(geolocation));
        if (!payload.area_radius) {
          dispatch(setArealocation(geolocation));
          dispatch(showNotification(geolocation));
        }
      }
    }
  } catch (error) {
    dispatch({
      type: actions.UPDATESHIFT_ERROR,
      error
    });
  }
  dispatch(loader(false));
};

// ANCHOR - Get Shift Deteails
export const getShift = payload => async dispatch => {
  dispatch(loader(true));
  try {
    //dispatch({ type: actions.GETSHIFT_INITIATED });
    let response = await axiosAuthPost(apiConstant.GET_SHIFT, payload);
    if (!response.success) {
      dispatch({
        type: actions.GETSHIFT_ERROR,
        error: response.error.message
      });
    } else {
      let { start_location_lat, start_location_lng, area_lat, area_lng } = response.data.shift_data;
      if (typeof start_location_lat === 'string') {
        start_location_lat = parseFloat(start_location_lat);
      }
      if (typeof start_location_lng === 'string') {
        start_location_lng = parseFloat(start_location_lng);
      }
      dispatch(setShiftId(payload.id));
      if (!isEqual(store.getState().shift.shiftDetails, response.data)) {
        dispatch({
          type: actions.GETSHIFT_SUCCESS,
          payload: response.data
        });
      }
      if (
        !isEqual(store.getState().shift.shiftData, response.data.shift_data) &&
        !isEqual(store.getState().shift.shiftRiders, response.data.shifts_riders)
      ) {
        dispatch({
          type: actions.SET_SHIFT_DATA,
          payload: response
        });
      }
      dispatch(setGeolocation({ lat: start_location_lat, lng: start_location_lng }));
      if (area_lat || area_lng) {
        if (typeof area_lat === 'string') {
          area_lat = parseFloat(area_lat);
        }
        if (typeof area_lng === 'string') {
          area_lng = parseFloat(area_lng);
        }
        dispatch(setArealocation({ lat: area_lat, lng: area_lng }));
      } else {
        dispatch(setArealocation({ lat: start_location_lat, lng: start_location_lng }));
      }
    }
  } catch (error) {
    dispatch({
      type: actions.GETSHIFT_ERROR,
      error
    });
  }
  dispatch(loader(false));
};

// ANCHOR - Get Shift Deteails
export const getShiftProcess = payload => async dispatch => {
  try {
    //dispatch({ type: actions.GETSHIFT_INITIATED });
    let response = await axiosAuthPost(apiConstant.GET_SHIFT, payload);
    if (!response.success) {
      dispatch({
        type: actions.GETSHIFT_ERROR,
        error: response.error.message
      });
    } else {
      let { start_location_lat, start_location_lng, area_lat, area_lng } = response.data.shift_data;
      if (typeof start_location_lat === 'string') {
        start_location_lat = parseFloat(start_location_lat);
      }
      if (typeof start_location_lng === 'string') {
        start_location_lng = parseFloat(start_location_lng);
      }
      dispatch(setShiftId(payload.id));
      if (!isEqual(store.getState().shift.shiftDetails, response.data)) {
        dispatch({
          type: actions.GETSHIFT_SUCCESS,
          payload: response.data
        });
      }
      if (
        !isEqual(store.getState().shift.shiftData, response.data.shift_data) &&
        !isEqual(store.getState().shift.shiftRiders, response.data.shifts_riders)
      ) {
        dispatch({
          type: actions.SET_SHIFT_DATA,
          payload: response
        });
      }
      dispatch(setGeolocation({ lat: start_location_lat, lng: start_location_lng }));
      if (area_lat || area_lng) {
        if (typeof area_lat === 'string') {
          area_lat = parseFloat(area_lat);
        }
        if (typeof area_lng === 'string') {
          area_lng = parseFloat(area_lng);
        }
        dispatch(setArealocation({ lat: area_lat, lng: area_lng }));
      } else {
        dispatch(setArealocation({ lat: start_location_lat, lng: start_location_lng }));
      }
    }
  } catch (error) {
    dispatch({
      type: actions.GETSHIFT_ERROR,
      error
    });
  }
};

export const cancelShiftJob = (payload, isRedirect = true) => async dispatch => {
  try {
    let status = false;
    dispatch(loader(true));
    dispatch({ type: actions.CANCELSHIFTJOB_INITIATED });
    let response = await axiosAuthPost(apiConstant.CANCEL_SHIFT_JOB, payload);
    if (!response.success) {
      dispatch({
        type: actions.CANCELSHIFTJOB_ERROR,
        error: response.error.message
      });
    } else {
      await dispatch({
        type: actions.CANCELSHIFTJOB_SUCCESS,
        payload: response.data
      });
      status = true;
      if (isRedirect) {
        dispatch(
          push({
            pathname: '/booking-shift'
          })
        );
      }
    }
    dispatch(loader(false));
    return status;
  } catch (error) {
    dispatch(loader(false));
    dispatch({
      type: actions.CANCELSHIFTJOB_ERROR,
      error
    });
  }
};

export const removeShiftDrafts = (id, shiftJobs) => async dispatch => {
  let newJobs = JSON.parse(JSON.stringify(shiftJobs));
  if (newJobs.list) {
    newJobs = newJobs.list.filter(job => job.id !== id).filter(job => job.multi_job_id !== id);
  } else if (newJobs) {
    newJobs = newJobs.filter(job => job.id !== id).filter(job => job.multi_job_id !== id);
  }
  dispatch({
    type: actions.REMOVESHIFTDRAFT,
    payload: newJobs
  });
};

// ANCHOR - Get Shift Quote Deteails
export const getShiftQuote = payload => async dispatch => {
  dispatch(loader(true));
  try {
    dispatch({ type: actions.GETSHIFTQUOTE_INITIATED });
    let response = await axiosAuthPost(apiConstant.GET_SHIFT_QUOTE, payload);
    if (!response.success) {
      dispatch({
        type: actions.GETSHIFTQUOTE_ERROR,
        error: response.error.message
      });
    } else {
      dispatch({
        type: actions.GETSHIFTQUOTE_SUCCESS,
        payload: response
      });
    }
  } catch (error) {
    dispatch({
      type: actions.GETSHIFTQUOTE_ERROR,
      error
    });
  }
  dispatch(loader(false));
};

// ANCHOR - Clear Shift State
export const clearShift = () => async dispatch => {
  await dispatch({
    type: actions.CLEAR_SHIFT
  });
};

// ANCHOR - Set Shift Id
export const setShiftId = payload => async dispatch => {
  await dispatch({
    type: actions.SET_SHIFTID,
    payload
  });
};

// ANCHOR - Set Geolocation
export const setGeolocation = payload => async dispatch => {
  await dispatch({
    type: actions.SET_GEOLOCATION,
    payload
  });
};

// ANCHOR - Set Arealocation
export const setArealocation = payload => async dispatch => {
  await dispatch({
    type: actions.SET_AREALOCATION,
    payload
  });
};

export const startTime = payload => async dispatch => {
  await dispatch({
    type: 'startTime',
    payload
  });
};
export const startDate = payload => async dispatch => {
  await dispatch({
    type: 'startDate',
    payload
  });
};
export const startTimeOpen = payload => async dispatch => {
  await dispatch({
    type: 'STARTTIMEOPEN',
    payload
  });
};
export const endTimeOpen = payload => async dispatch => {
  await dispatch({
    type: 'ENDTIMEOPEN',
    payload
  });
};
export const isClicked = payload => async dispatch => {
  dispatch({
    type: 'ButtonClicked',
    payload
  });
};
export const showNotification = payload => async dispatch => {
  dispatch({
    type: 'isNotification',
    payload
  });
};
export const getShiftjobs = payload => async dispatch => {
  try {
    dispatch(loader(true));
    dispatch({ type: actions.GET_SHIFTJOB_INITIATED });
    let response = await axiosAuthPost(apiConstant.GET_SHIFTJOB, payload);
    if (!response.success) {
      dispatch({
        type: actions.GET_SHIFTJOB_ERROR,
        error: response.error.message
      });
    } else {
      dispatch({
        type: actions.GET_SHIFTJOB_SUCCESS,
        payload: response.data
      });
    }
    dispatch(loader(false));
  } catch (error) {
    dispatch(loader(false));
    dispatch({
      type: actions.GET_SHIFTJOB_ERROR,
      error
    });
  }
};

// ANCHOR - Validate Shift
export const validateShift = payload => async dispatch => {
  dispatch(loader(true));
  try {
    dispatch({ type: actions.VALIDATESHIFT_INITIATED });
    let response = await axiosAuthPost(apiConstant.VALIDATE_SHIFT, { id: payload.id });
    if (!response.success) {
      dispatch({
        type: actions.VALIDATESHIFT_ERROR,
        error: response.error.message
      });
    } else {
      dispatch({
        type: actions.VALIDATESHIFT_SUCCESS,
        payload: response
      });

      if (payload.path === 'booking-shift') {
        dispatch(isClicked(true));
      } else if (payload.path === 'shift-payment') {
        dispatch(confirmPayment(payload.paymentData));
      }
    }
  } catch (error) {
    dispatch({
      type: actions.VALIDATESHIFT_ERROR,
      error
    });
  }
  dispatch(loader(false));
};

export const confirmPayment = payload => async dispatch => {
  try {
    dispatch(loader(true));
    dispatch({ type: actions.CONFIRM_PAYMENT_INITIATED });
    let response = await axiosAuthPost(apiConstant.CONFIRM_PAYMENT, payload);
    if (!response.success) {
      dispatch({
        type: actions.CONFIRM_PAYMENT_ERROR,
        error: response.error.message
      });
    } else {
      await dispatch({
        type: actions.CONFIRM_PAYMENT_SUCCESS,
        payload: response
      });
      if (response.data.is_scheduled === 1) {
        dispatch(
          push({
            pathname: '/job-boards',
            state: 'shifts',
            tabSwitch: '2'
          })
        );
      } else {
        dispatch(
          push({
            pathname: '/job-boards',
            state: 'shifts',
            tabSwitch: '3'
          })
        );
      }
    }
  } catch (error) {
    dispatch({
      type: actions.CONFIRM_PAYMENT_ERROR,
      error
    });
  } finally {
    dispatch(loader(false));
  }
};

// ANCHOR - GetCourierLocation
export const getCourierLocation = payload => async dispatch => {
  try {
    //dispatch({ type: actions.GET_COURIER_LOCATION_INITIATED });
    let response = await axiosAuthPost(apiConstant.GET_COURIER_LOCATION, payload);
    if (!response.success) {
      dispatch({
        type: actions.GET_COURIER_LOCATION_ERROR,
        error: response.error.message
      });
    } else {
      if (!isEqual(store.getState().shift.courierLocation, response.data)) {
        dispatch({
          type: actions.GET_COURIER_LOCATION_SUCCESS,
          payload: response.data
        });
      }
    }
  } catch (error) {
    dispatch({
      type: actions.GET_COURIER_LOCATION_ERROR,
      error
    });
  }
};
