import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormWrapper } from './style';
import { Modal, Row, Col, Form } from 'antd';
import { FormValidation } from 'App/AppConstant';
import {
  FormButton as Button,
  FormGroup,
  Label,
  FormInput as Input,
  FormTextarea,
  FormCheckbox
} from 'components/Form';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { TermsModalWrapper } from './style';
import { acceptTerms } from 'redux/app/actions';

const termsSchema = Yup.object().shape({
  termscondition: Yup.bool().oneOf([true], FormValidation.termsConditionRequired),
  privacypolicy: Yup.bool().oneOf([true], FormValidation.privacyPolicyRequired)
});

class TermsModal extends Component {
  state = { visible: false };
  componentDidMount() {
    const { isTCSAccept } = this.props;
    this.setState({
      visible: !isTCSAccept
    });
  }
  componentDidUpdate(prevProps) {
    const { isTCSAccept } = this.props;
    if (isTCSAccept !== prevProps.isTCSAccept) {
      this.setState({
        visible: !isTCSAccept
      });
    }
  }
  handleSubmit = () => {
    this.props.acceptTerms();
  };
  ModalForm = () => {
    return (
      <Formik
        initialValues={{
          termscondition: false,
          privacypolicy: false
        }}
        validationSchema={termsSchema}
        enableReinitialize
        onSubmit={this.handleSubmit}
      >
        {({ values, errors, touched, setFieldValue, handleSubmit }) => (
          <Form onSubmit={handleSubmit} noValidate>
            <div className="dislay-content">
              <Row>
                <div>
                  <FormGroup className="form-group">
                    <div className="input-wrapper">
                      <FormCheckbox
                        name="termscondition"
                        text={
                          <span>
                            I accept the &nbsp;
                            <a
                              className="termsCondition"
                              href="https://uk.gophr.com/terms-and-conditions/"
                              target="__blank"
                            >
                              Terms and Conditions
                            </a>
                          </span>
                        }
                        checked={values.termscondition}
                        handleChange={e => {
                          setFieldValue('termscondition', e.target.checked);
                        }}
                        isError={errors.termscondition && touched.termscondition ? true : false}
                      />
                      {errors.termscondition && touched.termscondition ? (
                        <div className="form-error">{errors.termscondition}</div>
                      ) : null}
                      <FormCheckbox
                        name="privacypolicy"
                        text={
                          <span>
                            I accept the &nbsp;
                            <a
                              className="termsCondition"
                              href="https://uk.gophr.com/privacy-policy/"
                              target="__blank"
                            >
                              Privacy Policy
                            </a>
                          </span>
                        }
                        checked={values.privacypolicy}
                        handleChange={e => {
                          setFieldValue('privacypolicy', e.target.checked);
                        }}
                        isError={errors.privacypolicy && touched.privacypolicy ? true : false}
                      />
                      {errors.privacypolicy && touched.privacypolicy ? (
                        <div className="form-error">{errors.privacypolicy}</div>
                      ) : null}
                    </div>
                  </FormGroup>
                </div>
              </Row>
              <Row className="btn-grp">
                <Button
                  color="secondary"
                  htmlType="submit"
                  className={`btn-grey ${'new-btn-style'}`}
                  style={{ borderRadius: 0 }}
                >
                  SAVE
                </Button>
              </Row>
            </div>
          </Form>
        )}
      </Formik>
    );
  };
  render() {
    const { visible } = this.state;
    return (
      <TermsModalWrapper>
        <div className="terms-modal">
          <Modal
            visible={visible}
            closable={false}
            footer={null}
            getContainer={() => document.querySelector('.terms-modal')}
            title="Terms and Conditions"
            centered
          >
            <div className="main-content">
              <Row>
                <Col md={24}>{this.ModalForm()}</Col>
              </Row>
            </div>
          </Modal>
        </div>
      </TermsModalWrapper>
    );
  }
}

const mapStateToProps = state => ({
  isTCSAccept: state.app.isTCSAccept
});
const mapDispatchToProps = dispatch => ({
  acceptTerms: () => dispatch(acceptTerms())
});

export default connect(mapStateToProps, mapDispatchToProps)(TermsModal);
