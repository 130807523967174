import { Col, Form, Row, Tooltip } from 'antd';
import {
  AddressConstant,
  AppValidation,
  DefaultCoordinate,
  FormValidation,
  HandleAddressTypes,
  handleNumberBlur,
  handlew3wBlur
} from 'App/AppConstant';
import { InputWrapper } from 'components/Booking/style';
import BreadCrumb from 'components/BreadCrumb';
import {
  FormButton,
  FormGroup,
  FormNewInput as Input,
  FormTextarea,
  FormWrapper,
  Label,
  RadioButtonGroup as RadioButton
} from 'components/Form';
import GoogleAutoComplete from 'components/GoogleAutoComplete';
import MapMarker from 'components/MapMarker';
import { Formik } from 'formik';
import isEmpty from 'lodash.isempty';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { profileUpdate } from 'redux/profileUpdate/actions';
import * as Yup from 'yup';
import { profileType, SignupConstant } from './constant';
import { AddressContactStyling, SignupDiv } from './style';

const AddressSchema = Yup.object().shape({
  address1: Yup.string().required(FormValidation.streetNumberRequired),
  postcode: Yup.string().required(FormValidation.passcodeRequired),
  phone_number: Yup.string()
    .test({
      message: FormValidation.phoneNumberMinimum,
      test: value => value == null || (value.charAt(0) === '1' ? value.length >= 9 : value.length === 10)
    })
    .test({
      message: FormValidation.numberUkValidate,
      test: value => {
        if (value) {
          value = '0' + value;
          if (AppValidation.ukNumber.test(value)) {
            return true;
          }
          return false;
        }
        return true;
      }
    }),
  mobile_number: Yup.string()
    .required(FormValidation.mobileNumberRequired)
    .test({
      message: FormValidation.mobileNumberMinimum,
      test: value => value == null || (value.charAt(0) === '1' ? value.length >= 9 : value.length === 10)
    })
    .test({
      message: FormValidation.numberUkValidate,
      test: value => {
        value = '0' + value;
        if (AppValidation.ukNumber.test(value)) {
          return true;
        }
        return false;
      }
    }),
  city: Yup.string().required(FormValidation.cityRequired),
  country_code: Yup.string().required(FormValidation.countryRequired)
});

class Address extends Component {
  state = {
    tipType: 'text',
    mapReset: false,
    mapDiv: 'displaynone',
    addressbtn: 'displayblock',
    location: '',
    focusedName: '',
    googleinput: ''
  };
  componentDidMount() {
    this.setState({ ...this.props.postData });
  }
  handleSubmit = async (values, { setSubmitting }) => {
    let postData = {};
    HandleAddressTypes.map(field => {
      if (field !== 'location_lat' && field !== 'location_lng' && field !== 'person_name' && field !== 'email') {
        postData[field] = values[field];
      }
    });
    postData.is_registered_company = values.isPersonalProfile === 1 ? 0 : 1;

    if (postData.is_registered_company === 1) {
      postData.billing_company_number = values.billing_company_number;
      postData.billing_vat_number = values.billing_vat_number;
    }

    await this.props.profileUpdate(postData);
  };

  handleResetMap = () => {
    this.setState(prevState => ({ mapReset: !prevState.mapReset }));
  };

  handleNextClick = (validateForm, setTouched) => {
    validateForm().then(validation => {
      if (isEmpty(validation))
        this.setState({
          mapDiv: 'displayblock',
          addressbtn: 'displaynone'
        });
      else setTouched(validation);
    });
  };

  handleGoogleResult = (setFieldValue, validateForm, setTouched, data, currentValues) => {
    if (!isEmpty(data)) {
      data = JSON.parse(JSON.stringify(data));
      if (data.isFav) {
        delete data.isFav;
      }
      Object.keys(data).map(key => {
        if (key !== 'person_name' && HandleAddressTypes.includes(key)) setFieldValue(key, data[key]);
      });

      // Custom Validation Check Formik
      validateForm().then(validation => setTouched(validation));
    }
  };

  handleTipFocus = () => {
    this.setState({
      tipType: 'textarea'
    });
  };

  handleUserType = (setFieldValue, value) => {
    if (value === 1) {
      setFieldValue('billing_company_number', '');
      setFieldValue('billing_vat_number', '');
    }
    setFieldValue('isPersonalProfile', value);
  };

  handleFocus = e => {
    this.setState({ focusedName: e });
  };
  removeFocus = () => {
    this.setState({ focusedName: '' });
  };
  inputChange = e => {
    this.setState({ googleinput: e });
  };

  render() {
    let { tipType, mapReset, mapDiv, addressbtn, focusedName, googleinput } = this.state;
    return (
      <SignupDiv>
        <AddressContactStyling>
          <Row>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} className="addressDiv">
              <Formik
                initialValues={{
                  isPersonalProfile: 1,
                  address1: '',
                  address2: '',
                  city: '',
                  postcode: '',
                  country_code: 'GB',
                  mobile_number: '',
                  phone_number: '',
                  company_name: '',
                  w3w: '',
                  tips_how_to_find: '',
                  billing_company_number: '',
                  billing_vat_number: ''
                }}
                validationSchema={AddressSchema}
                onSubmit={this.handleSubmit}
                enableReinitialize
              >
                {({ values, errors, touched, validateForm, setTouched, submitForm, setFieldValue, handleChange, handleSubmit, onBlur }) => (
                  <Form onSubmit={handleSubmit} className="form-main-wrapper" autoComplete="off">
                    <Row type="flex">
                      <Col xs={24} sm={24} md={24} lg={24} xl={14} xxl={14} className="addressDiv">
                        <div className="login-box-main">
                          <BreadCrumb active="signup" />
                          <h2>{values.isPersonalProfile === 2 ? SignupConstant.businessProfile : SignupConstant.personalProfile}</h2>
                          <p>{SignupConstant.subTitleSignup}</p>
                          <div className="form-styling2">
                            <div className="steps-dots">
                              <i className="fill-color"></i>
                              <i className={`${mapDiv === 'displayblock' ? 'fill-color' : ''}`}></i>
                            </div>
                            <Row gutter={20}>
                              <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                                <FormGroup style={{ marginTop: '6px' }}>
                                  <div className="d-flex">
                                    <div className="form-group full-width profile-label-wrapper" style={{ width: '100%' }}>
                                      <Label title={SignupConstant.profile} />
                                      <div className="d-flex profile-radio-btn">
                                        <RadioButton
                                          data={profileType}
                                          value={values.isPersonalProfile}
                                          name="isPersonalProfile"
                                          handleType={value => this.handleUserType(setFieldValue, value)}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </FormGroup>
                              </Col>
                              <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                                <InputWrapper>
                                  <FormGroup className={`${'form-group'} `}>
                                    <Label className="label" title={AddressConstant.addressLookup} />
                                    <GoogleAutoComplete
                                      placeholder={'Search for address'}
                                      isFavRequired={false}
                                      isProfileRequired={false}
                                      clearAutoComplete={false}
                                      resetAutoComplete={this.resetAutoComplete}
                                      locationChange={data =>
                                        this.handleGoogleResult(setFieldValue, validateForm, setTouched, data, values)
                                      }
                                      onFocus={() => this.handleFocus('GoogleAutoComplete')}
                                      onBlur={this.removeFocus}
                                      onChange={e => this.inputChange(e)}
                                    />
                                  </FormGroup>
                                </InputWrapper>
                              </Col>
                            </Row>
                            <Row gutter={20}>
                              <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12} className="top">
                                <FormGroup className="form-group">
                                  <Input
                                    value={values.address1}
                                    handleChange={handleChange}
                                    name="address1"
                                    isRequired={true}
                                    label={AddressConstant.streetNumber}
                                    placeholder={'Required'}
                                    isError={errors.address1 && touched.address1 ? true : false}
                                  />
                                  {errors.address1 && touched.address1 ? <div className="form-error">{errors.address1}</div> : null}
                                </FormGroup>
                              </Col>
                              <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12} className="top">
                                <FormGroup>
                                  <div className="form-group">
                                    <Input
                                      value={values.address2}
                                      handleChange={handleChange}
                                      name="address2"
                                      label={AddressConstant.buildingFloor}
                                      placeholder={''}
                                    />
                                  </div>
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row gutter={20}>
                              <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12} className="top">
                                <FormGroup>
                                  <div className="form-group">
                                    <Input
                                      value={values.city}
                                      name="city"
                                      handleChange={handleChange}
                                      isRequired={true}
                                      label={AddressConstant.city}
                                      placeholder={'Required'}
                                      isError={errors.city && touched.city ? true : false}
                                    />
                                    {errors.city && touched.city && values.city.length < 1 ? (
                                      <div className="form-error">{errors.city}</div>
                                    ) : null}
                                  </div>
                                </FormGroup>
                              </Col>
                              <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12} className="top">
                                <FormGroup>
                                  <div className="form-group">
                                    <Input
                                      value={values.postcode}
                                      handleChange={handleChange}
                                      name="postcode"
                                      isRequired={true}
                                      label={AddressConstant.postcode}
                                      placeholder={'Required'}
                                      isError={errors.postcode && touched.postcode ? true : false}
                                    />
                                    {errors.postcode && touched.postcode ? <div className="form-error">{errors.postcode}</div> : null}
                                  </div>
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row gutter={20}>
                              <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12} className="top">
                                <FormGroup>
                                  <div className="form-group">
                                    <div className="phone-field d-flex">
                                      <span className="phone-country-code">+44</span>
                                      <Input
                                        type="tel"
                                        className="form-control"
                                        handleChange={handleChange}
                                        onBlur={event => handleNumberBlur(setFieldValue, event)}
                                        name="mobile_number"
                                        isRequired={true}
                                        label={AddressConstant.mobileNumber}
                                        value={values.mobile_number}
                                        placeholder={'Required'}
                                        isError={errors.mobile_number && touched.mobile_number ? true : false}
                                      />
                                    </div>
                                    {errors.mobile_number && touched.mobile_number ? (
                                      <div className="form-error">{errors.mobile_number}</div>
                                    ) : null}
                                  </div>
                                </FormGroup>
                              </Col>
                              <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12} className="top">
                                <FormGroup>
                                  <div className="form-group">
                                    <div className="phone-field d-flex">
                                      <span className="phone-country-code">+44</span>
                                      <Input
                                        type="tel"
                                        className="form-control"
                                        handleChange={handleChange}
                                        label={AddressConstant.phoneNumber}
                                        placeholder={''}
                                        onBlur={event => handleNumberBlur(setFieldValue, event)}
                                        name="phone_number"
                                        value={values.phone_number}
                                        isError={errors.phone_number && touched.phone_number ? true : false}
                                      />
                                    </div>
                                    {errors.phone_number && touched.phone_number ? (
                                      <div className="form-error">{errors.phone_number}</div>
                                    ) : null}
                                  </div>
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row gutter={20}>
                              <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} className="top">
                                <FormGroup>
                                  <div className="form-group">
                                    <Input
                                      value={values.company_name}
                                      name="company_name"
                                      handleChange={handleChange}
                                      label={AddressConstant.companyName}
                                      placeholder={''}
                                    />
                                  </div>
                                </FormGroup>
                              </Col>
                            </Row>
                            {values.isPersonalProfile === 2 && (
                              <Row gutter={20}>
                                <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12} className="top">
                                  <FormGroup>
                                    <div className="form-group">
                                      <Input
                                        value={values.billing_company_number}
                                        name="billing_company_number"
                                        handleChange={handleChange}
                                        label={`${AddressConstant.registrationNumber}`}
                                        placeholder={''}
                                      />
                                    </div>
                                  </FormGroup>
                                </Col>
                                <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12} className="top">
                                  <FormGroup>
                                    <div className="form-group">
                                      <Input
                                        value={values.billing_vat_number}
                                        name="billing_vat_number"
                                        handleChange={handleChange}
                                        label={`${AddressConstant.vatNumber}`}
                                        placeholder={''}
                                      />
                                    </div>
                                  </FormGroup>
                                </Col>
                              </Row>
                            )}
                            <Row gutter={20}>
                              <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}></Col>
                              <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                                <FormGroup>
                                  <div className="btn-main d-flex">
                                    <div className={`ml-auto btnDiv ${addressbtn}`}>
                                      <FormButton
                                        htmlType="button"
                                        className="btn purple-btn print-label"
                                        onClick={() => this.handleNextClick(validateForm, setTouched)}
                                      >
                                        {SignupConstant.buttonText}
                                      </FormButton>
                                    </div>
                                  </div>
                                </FormGroup>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={24} xl={10} xxl={10} className="addressDiv top">
                        {mapDiv === 'displayblock' ? (
                          <div className={`map-viewer ${mapDiv}`} id="header">
                            <div className="right-map-div">
                              <div className="map-view">
                                <MapMarker
                                  isReset={mapReset}
                                  isBound={false}
                                  markerPoints={[
                                    {
                                      latitude: values.location_lat || DefaultCoordinate.lat,
                                      longitude: values.location_ng || DefaultCoordinate.lng
                                    }
                                  ]}
                                />
                              </div>
                              <div className="confirm-view-div">
                                <FormButton htmlType="button" className="btn purple-btn print-label" onClick={this.handleResetMap}>
                                  {SignupConstant.resetMap}
                                </FormButton>
                              </div>
                              <FormWrapper className="wordsDiv top">
                                <i className="arrow-icon" onClick={() => window.open(`https://what3words.com/${values.w3w}`, '_blank')} />
                                <Tooltip
                                  placement="top"
                                  getPopupContainer={() => document.getElementById('header')}
                                  title={
                                    <div className="custom-tooltip-inner">
                                      <strong>You will be redirected to w3w web.</strong>
                                    </div>
                                  }
                                >
                                  <i className="w3w-info" />
                                </Tooltip>
                                <Input
                                  type="text"
                                  className="form-control"
                                  handleChange={e => {
                                    handleChange(e);
                                    handlew3wBlur(setFieldValue, e);
                                  }}
                                  name="w3w"
                                  label={AddressConstant.w3w}
                                  placeholder={''}
                                  value={values.w3w}
                                />
                              </FormWrapper>
                              <FormWrapper className="top">
                                {tipType === 'text' ? (
                                  <Input
                                    type="text"
                                    className="form-control"
                                    handleChange={handleChange}
                                    onBlur={onBlur}
                                    onFocus={this.handleTipFocus}
                                    name="tips_how_to_find"
                                    label={AddressConstant.tips}
                                    placeholder={''}
                                    value={values.tips_how_to_find}
                                  />
                                ) : (
                                  <FormTextarea
                                    value={values.tips_how_to_find}
                                    handleChange={handleChange}
                                    name="tips_how_to_find"
                                    onBlur={onBlur}
                                    label={AddressConstant.tips}
                                    placeholder={''}
                                  />
                                )}
                              </FormWrapper>
                              <i className="label-address">
                                {!isEmpty(values.tips_how_to_find) ? `${values.tips_how_to_find.length}/500` : '0/500'}
                              </i>
                              <div className="progress">
                                <div className="progress-bar progress-bar-pink "></div>
                              </div>
                              <Row style={{ marginTop: 30 }}>
                                <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}></Col>
                                <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                                  <FormGroup>
                                    <div className="btn-main d-flex">
                                      <div className="ml-auto btnDiv">
                                        <FormButton htmlType="submit" className="btn purple-btn print-label">
                                          {SignupConstant.buttonText}
                                        </FormButton>
                                      </div>
                                    </div>
                                  </FormGroup>
                                </Col>
                              </Row>
                            </div>
                          </div>
                        ) : null}
                      </Col>
                    </Row>
                  </Form>
                )}
              </Formik>
            </Col>
          </Row>
        </AddressContactStyling>
      </SignupDiv>
    );
  }
}
const mapStateToProps = state => ({
  googleRef: state.app.googleRef
});
const mapDispatchToProps = dispatch => ({
  profileUpdate: payload => dispatch(profileUpdate(payload))
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Address));
