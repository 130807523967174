import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Menu, Dropdown, Icon } from 'antd';
import { FormWrapper } from './style';

class CustomDropdown extends Component {
  handleChange = ele => {
    const { name } = this.props;
    this.props.setFieldValue(name, ele);
  };
  list = data => {
    let UI = data.map((element, key) => (
      <Menu.Item key={key + 1}>
        <a href="#">{element}</a>
      </Menu.Item>
    ));
    return <Menu>{UI}</Menu>;
  };
  render() {
    const { isBorder, data, iconType, defaultValue, dropDownClass, id, disabled } = this.props;
    let dropClass = dropDownClass + (isBorder === true ? ' setborder' : '');
    return (
      <FormWrapper className={dropClass}>
        <div id={id} className="form-dropdown">
          <Dropdown
            disabled={disabled}
            overlay={this.list(data)}
            trigger={['click']}
            getPopupContainer={() => document.getElementById(id)}
          >
            <a className="ant-dropdown-link">
              {defaultValue ? defaultValue : +44}
              <Icon type={iconType} />
            </a>
          </Dropdown>
        </div>
      </FormWrapper>
    );
  }
}
CustomDropdown.defaultProps = {
  id: 'form-dropdown',
  placeholder: null,
  isText: false,
  iconType: 'caret-down',
  disabled: false
};
CustomDropdown.defaultValue = {};
const mapStateToProps = state => ({});
export default connect(mapStateToProps, {})(CustomDropdown);
