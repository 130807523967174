import styled from 'styled-components';
import { size } from 'App/device';
import { borderRadius } from 'App/mixins';
import arrowIcon from 'assets/images/arrow-right-large-icon.svg';
import arrowIconPurple from 'assets/images/arrow-purple-right-large-icon.svg';
import infoIcon from 'assets/images/info-icon.svg';

const SignupDiv = styled.div`
  .login-box-main {
    background: #fff;
    box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.15);
    ${borderRadius('10px')};
    max-width: 565px;
    width: 100%;
    padding: 62px 50px 40px 50px;
    margin-bottom: 20px;
    @media ${size['desktop-md-max']} {
      // max-width: 470px;
      padding: 57px 40px 30px 40px;
    }
    @media ${size['desktop-sm-max']} {
      // max-width: 410px;
      padding: 40px 35px 25px 35px;
    }
    @media ${size['tablet-max']} {
      padding: 30px;
      margin: 0 auto 20px auto;
    }
    h2 {
      font-family: ${props => props.theme.primaryFont}, sans-serif;
      margin: 0 0 10px 0;
      // font-size: 38px;
      font-size: 30px;
      @media ${size['desktop-md-max']} {
        font-size: 36px;
      }
      @media ${size['desktop-sm-max']} {
        font-size: 30px;
      }
      @media ${size['laptop-max']} {
        font-size: 28px;
      }
      @media ${size['tablet-max']} {
        font-size: 24px;
      }
    }
    p {
      font-size: 18px;
      line-height: 22px;
      margin: 0;
      color: #707070;
      @media ${size['desktop-sm-max']} {
        font-size: 16px;
        line-height: 20px;
      }
      @media ${size['tablet-max']} {
        font-size: 14px;
      }
    }
    .btn-main {
      padding-top: 10px;
      @media ${size['mobile-md-max']} {
        flex-wrap: wrap;
        display: block !important;
      }
    }
    .d-flex {
      display: flex;
    }
    .btnDiv {
      @media ${size['tablet-max']} {
        margin: 0 62px 0 62px;
      }
    }
  }
  p {
    font-size: 15px;
    line-height: 22px;
    color: #606060;
    letter-spacing: 0.025em;
  }
  .form-styling2 {
    position: relative;
    margin-top: 32px;
    .custom-row .form-group {
      margin-bottom: 10px;
      @media ${size['desktop-sm-max']} {
        margin-bottom: 0px;
      }
      @media ${size['tablet-md-max']} {
        margin-bottom: 5px;
      }
    }
    .phone-field {
      .title {
        left: -45px;
      }
    }
    .checkbox-list {
      position: relative;
      padding: 0;
      margin: 15px 0;
      @media ${size['tablet-md-max']} {
        margin: 10px 0;
      }
      @media ${size['mobile-md-max']} {
        .ant-checkbox-wrapper {
          margin-bottom: 15px;
          display: flex;
        }
      }
      div:last-child {
        margin-bottom: 10px;
      }
      div {
        margin-top: -4px;
      }
      div:first-child {
        margin-top: 10px;
      }
    }
  }
`;

const FormStyling = styled.div`
  .form-styling2 {
    .custom-row {
      .ant-col {
        .first-input {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }
        .second-input {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }
        .error-wrapper {
          .first-input {
            border-top-right-radius: 0 !important;
            border-bottom-right-radius: 0 !important;
          }
          .second-input {
            border-top-left-radius: 0 !important;
            border-bottom-left-radius: 0 !important;
          }
        }
      }
    }
    .social-links {
      margin: 0 0 10px 0;
      a:hover {
        background: #ffa100;
      }
    }
  }
`;

const AddressContactStyling = styled.div`
  .form-main-wrapper {
    width: 100%;
    display: flex;
    .ant-row-flex {
      width: 100%;
    }
  }
  .phone-field {
    input {
      padding-left: 33px;
    }
  }
  .login-box-main {
    width: 100%;
    max-width: 100%;
    z-index: 1;
    position: relative;
    margin-bottom: 20px;
    @media ${size['desktop-md-max']} {
      max-width: 780px;
      padding: 57px 40px 30px 40px;
    }
    @media ${size['desktop-sm-max']} {
      max-width: 650px;
      padding: 40px 35px 25px 35px;
    }
    @media ${size['laptop-max']} {
      max-width: 100%;
    }
    @media ${size['tablet-max']} {
      padding: 30px;
      margin: 0 auto 20px auto;
    }
  }
  .form-styling2 {
    .btn-main {
      margin-bottom: 10px;
      flex-wrap: wrap;
      @media ${size['tablet-max']} {
        margin-bottom: 0;
        padding: 0;
      }
      @media ${size['mobile-md-max']} {
        flex-wrap: wrap;
      }
      .ml-auto {
        button {
          @media ${size['mobile-md-max']} {
            width: 100%;
          }
          @media ${size['desktop-sm-max']} {
            min-width: 130px;
            height: 44px;
            line-height: 44px;
            font-size: 14px;
          }
          @media ${size['tablet-max']} {
            min-width: 135px;
          }
          @media ${size['mobile-md-max']} {
            width: 100%;
          }
        }
        button + button {
          margin-left: 18px;
          @media ${size['mobile-md-max']} {
            margin: 10px 0 0;
          }
        }
      }
      .text {
        margin: 0 10px 0 10px;

        @media ${size['desktop-sm-max']} {
          font-size: 14px;
        }
        @media ${size['mobile-md-max']} {
          font-size: 10px;
        }
      }
      .text:first-child {
        margin: 0 12px 0 0;
      }
      .switch.switch-light {
        border: 0;
        box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.15);
      }
    }
    .dropdown-custom-profile {
      .dropdown-main {
        width: 25%;
        input {
          padding: 10px 14px;
        }
      }
    }
  }
  @media ${size['desktop-sm-max']} {
    margin-bottom: 20px;
  }
  .steps-dots {
    margin-top: -1px;
    position: absolute;
    top: -95px;
    right: 0;
    @media ${size['desktop-sm-max']} {
      top: -85px;
    }
    @media ${size['tablet-max']} {
      display: none;
    }
    i {
      width: 11px;
      height: 11px;
      border-radius: 50%;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      border: 2px solid #ffbe2e;
      display: inline-block;
      margin-left: 8px;
    }
    + i {
      margin-left: 6px;
    }
    i.fill-color {
      background: #bc25fe;
      border-color: #bc25fe;
    }
  }
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
  }
  input[type='number'] {
    -moz-appearance: textfield;
  }
  .profile-label-wrapper {
    label {
      display: block;
      font-family: 'Montserrat', sans-serif;
      font-size: 12px;
      margin-bottom: 0;
      font-weight: 600;
      text-transform: uppercase;
      color: #816e9b !important;
      line-height: 14px;
    }
  }
  .profile-radio-btn {
    height: 20px;
    align-items: center;
    padding-top: 7px;
    border-bottom: 0.1rem solid #cecece !important;
    padding-left: 35px;
    padding-bottom: 41px;
    .ant-radio-group {
      position: relative;
      top: 10px;
    }
    .ant-radio-group .ant-radio-wrapper {
      margin-top: 18px;
    }
    @media ${size['desktop-sm-max']} {
      height: 48px;
      padding: 0 0 0 25px;
      .ant-radio-group .ant-radio-wrapper {
        margin-top: 0;
      }
    }
  }
  .ant-radio-group .ant-radio-wrapper {
    + .ant-radio-wrapper {
      margin-left: 27px !important;
      @media ${size['desktop-md-max']} {
        margin-left: 0 !important;
      }
    }
    padding-left: 25px;
    span.ant-radio + * {
      font-weight: 300;
      color: #75747b;
    }
    .ant-radio {
      top: 1px;
    }
    .ant-radio-inner:after {
      width: 12px;
      height: 12px;
    }
    .ant-radio,
    .ant-radio-inner {
      width: 16px;
      height: 16px;
    }
  }
  .ant-radio-group .ant-radio-wrapper-checked span.ant-radio + * {
    font-weight: 400;
    color: #75747b;
  }
  .map-viewer {
    .ant-tooltip {
      max-width: 300px;
    }
    .ant-tooltip-inner {
      background: #8f48ff;
      padding: 15px;
      .custom-tooltip-inner font {
        text-transform: none;
        font-weight: 300;
      }
      strong {
        text-transform: none;
        font-weight: 500;
      }
    }
    z-index: 1;
    position: relative;
    margin-bottom: 20px;
    background: #fff;
    box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    width: 100%;
    padding: 62px 50px 40px 50px;
    margin-bottom: 20px;
    @media ${size['tablet-md-max']} {
      width: 100%;
    }
    .d-flex {
      input {
        border-radius: 0 5px 5px 0;
      }
    }
    .confirm-view-div {
      display: flex;
      flex-direction: row-reverse;
      padding: 10px 0;
      .confirm-radio-btn {
        width: 22%;
        margin-left: auto;
        margin-right: 50px;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 24%;
        @media ${size['desktop-md-max']} {
          //bottom: 27.5%;
        }
      }
      button {
        min-width: 130px;
        height: 44px;
        line-height: 44px;
        font-size: 14px;
      }
    }
    .map-section {
      margin-left: 85%;
      @media ${size['desktop-md-max']} {
        margin-left: 77%;
      }
      @media ${size['mobile-md-max']} {
        margin-left: 60%;
      }
      button {
        background: #ffffff;
        box-shadow: 10px 10px 25px rgba(0, 0, 0, 0.1);
        height: 46px;
        line-height: 40px;
        color: black;
        font-weight: 500;
        min-width: 195px;
        bottom: 2rem;
        :first-child {
          min-width: 50px;
          padding: 0;
          margin-left: 9rem;
          bottom: 3rem;
          @media ${size['desktop-md-max']} {
            margin-left: 7rem;
          }
          @media ${size['desktop-md-max']} {
            margin-left: 8rem;
          }
          @media ${size['mobile-md-max']} {
            margin-left: 10.4rem;
            max-width: 10px;
          }
        }
      }
      img {
        height: 16px;
        width: 16px;
      }
    }
    .right-map-div {
      .map-view {
        width: 100%;
        height: 465px;
        position: relative;
      }
      @media ${size['desktop-md-max']} {
        // margin-top: 92.9%;
      }
      .btn-main.mb-min {
        margin-top: 42px;
      }

      .label-address {
        position: absolute;
        display: flex;
        text-align: center;
        width: 110px;
        font-weight: 500;
        font-size: 13px;
        letter-spacing: 0.05em;
        color: #49465d;
        font-style: normal;
        z-index: 1;
        height: 58px;
        left: 82%;
        padding-top: 7px;
        @media ${size['desktop-md-max']} {
          left: 85%;
        }
        @media ${size['desktop-mm-max']} {
          // line-height: 44px;
        }
      }
      .progress {
        position: absolute;
        background: #fafafa;
        width: 170px;
        height: 10px;
        border-radius: 10px;
        -webkit-border-radius: 10px;
        display: flex;
        overflow: hidden;
        font-size: 0.75rem;
        margin: 12px 0;
        .progress-bar {
          justify-content: center;
          overflow: hidden;
          color: #fff;
          text-align: center;
          white-space: nowrap;
          flex-direction: column;
          -webkit-box-pack: center;
          -webkit-justify-content: center;
          display: flex;
          width: 100%;
          background: #fca100;
          border-radius: 10px;
          -webkit-border-radius: 10px;
        }
      }
    }
  }
  .login-box-main {
    z-index: 2;
  }
  .mb-min {
    margin-bottom: -61px !important;
  }
  .addressDiv {
    display: flex;
    @media ${size['tablet-md-max']} {
      display: block;
    }
  }
  .top {
    padding-top: 10px;
    @media ${size['desktop-sm-max']} {
      padding-top: 0;
    }
  }
  .ant-radio-group .ant-radio-wrapper span.ant-radio + * {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .displaynone {
    display: none;
  }
  .displayblock {
    display: block;
  }
  .wordsDiv {
    margin-bottom: 20px;
    .info {
      position: absolute;
      // right: 0;
      left: 105px;
      width: 52px;
      height: 42px;
      top: -14px;
      background: url(${infoIcon}) no-repeat center;
      background-size: 100% auto;
      vertical-align: middle;
      margin-left: auto;
      cursor: pointer;
      background-size: 13px auto;
      display: block;
      z-index: 1;
    }
  }
`;

export { SignupDiv, FormStyling, AddressContactStyling };
