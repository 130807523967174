export const configVar = {
  FRONTEND_VERSION: process.env.REACT_APP_FRONTEND_VERSION || '2.0.20200626.1.5',
  APP_TYPE: 5,
  ENVIRONMENT: process.env.REACT_APP_TYPE,
  BASE_URL: process.env.REACT_APP_BACKEND_URL,
};

export const apiConstant = {
  PUBLIC_LOG_EVENT: 'public/log-event',
  AUTH_LOGIN: 'authorization/login',
  AUTH_REGISTER: 'registration/register',
  AUTH_LOGOUT: 'authorization/logout',
  AUTH_SOCIAL_SIGNIN: 'authorization/social-signin?',
  VERIFY_AND_LOGIN: 'authorization/verify-email-and-login',
  PROFILE_UPDATE: 'customer-status/profile-update',
  GET_SESSION: 'authorization/get-session',
  GET_PROFILE: 'customer-status/get-profile',
  GET_SECONDARY_PROFILE: 'customer-status/get-secondary-profile',
  SECONDARY_PROFILE_UPDATE: 'customer-status/secondary-profile-update',
  PROFILE_PICTURE_UPDATE: 'customer-status/upload-profile-photo',
  GET_GEOCODE: 'customer-status/geocoding',
  SEND_EMAIL: 'registration/send-email-verification-again',
  GET_JOB: 'customer-job/get-job',
  ADD_JOB: 'customer-job/add-job',
  UPDATE_JOB: 'customer-job/update-job',
  UPLOAD_CSV: 'customer-job/upload-jobs-csv',
  CANCEL_JOB: 'customer-job/cancel-job',
  CONVERT_MULTI_JOB: 'customer-job/convert-to-multijob',
  GET_QUOTA: 'customer-job/get-a-quote',
  ADD_FAVOURITE: 'favourites/add',
  EDIT_FAVOURITE: 'favourites/update',
  GET_FAVOURITES: 'favourites/get-all',
  DELETE_FAVOURITES: 'favourites/delete-many',
  ADD_PARCEL: 'customer-job/add-parcel',
  ADD_ITEM: 'customer-job/add-item',
  GET_NEXTDAY_ESTIMATE: 'customer-job/get-next-day-estimate',
  GET_PARCELS: 'customer-job/get-parcels',
  UPDATE_PARCELS: 'customer-job/update-parcel',
  DELETE_PARCELS: 'customer-job/delete-parcel',
  GET_NEXTDAYQUOTE: 'customer-job/get-next-day-marketplace',
  SET_NEXTDAYSERVICE: 'customer-job/set-next-day-service',
  GET_PARCELSTORES: 'customer-job/get-parcel-stores',
  UPDATE_ITEM: 'customer-job/update-item',
  DELETE_ITEM: 'customer-job/delete-item',
  CONVERT_SAME_NEXT_DAY: 'customer-job/convert-same-next-day',
  GET_CURRENTJOB: 'customer-jobs/get-current-jobs',
  GET_JOBS_HISTORY: 'customer-jobs/get-jobs-history',
  GET_PAYMENT_METHOD: 'customer-job/get-payment-methods',
  ADD_PAYMENT: 'customer-status/judopay-ui-get-url',
  GET_CREDIT_CARDS: 'customer-status/get-credit-cards',
  DELETE_CREDIT_CARD: 'customer-status/delete-credit-card',
  SET_DEFAULT_CREDIT_CARD: 'customer-status/set-default-credit-card',
  SET_DECLINED_CREDIT_CARD: 'customer-status/set-credit-card-for-declined-payments',
  CONVERT_BUSINESS: 'customer-status/convert-to-business-account',
  GET_PAYMENTS: 'customer-status/get-payments',
  GET_INVOICES: 'customer-status/get-invoices',
  PRIVATE_GETCOURIERS_AROUND_ME: 'customer-status/get-couriers-around-me',
  PUBLIC_GETCOURIERS_AROUND_ME: 'public/get-couriers-around-me',
  OPTIMISE_SEQUENCE: 'customer-job/optimise-sequence',
  CHANGE_SEQUENCE: 'customer-job/change-sequence',
  GET_WORKING_HOURS: 'public/get-working-hours',
  DOWNLOAD_CSV: 'customer-jobs/download-jobs-history',
  BOOK_COURIER: 'customer-job/book-a-courier',
  VALIDATE_JOB: 'customer-job/validate-job',
  BOOK_COURIER_PAYMENT: 'customer-job/confirm-payment-book-a-courier',
  GET_TEAMS: 'team-accounts/get-teams-and-members',
  ADD_TEAM: 'team-accounts/create-team',
  DELETE_TEAM: 'team-accounts/remove-team',
  UPDATE_TEAM: 'team-accounts/rename-team',
  INVITE_MEMBER: 'team-accounts/invite-member',
  DELETE_MEMBER: 'team-accounts/remove-member',
  REINVITE_MEMBER: 'team-accounts/reinvite-member',
  ACCEPT_TCS: 'customer-status/accept-tcs',
  ADD_SHIFT: 'customer-shift/add-shift',
  GET_SHIFT: 'customer-shift/get-shift',
  GET_SHIFT_QUOTE: 'customer-shift/get-shift-quote',
  UPDATE_SHIFT: 'customer-shift/update-shift',
  GET_SHIFTJOB: 'customer-shifts/get-current-shifts',
  GET_SHIFT_HISTORY: 'customer-shifts/get-shifts-history',
  VALIDATE_SHIFT: 'customer-shift/validate-shift',
  CONFIRM_PAYMENT: 'customer-shift/confirm-payment',
  CANCEL_SHIFT_JOB: 'customer-shift/cancel-shift',
  GET_COURIER_LOCATION: 'customer-shift/get-courier-location',
  GET_PUBLIC_COURIER_LOCATION: 'public-tracker/get-courier-location',
  GET_PUBLIC_COURIER_MANIFEST: 'public-tracker/get-courier-manifest',
  GET_PUBLIC_COURIER_DETAILS: 'public-tracker/get-courier-details',
  GET_JOB_COURIER_LOCATION: 'customer-job/get-courier-location',
  GET_JOB_COURIER_MANIFEST: 'customer-job/get-courier-manifest',
  RATE_COURIER: 'customer-job/rate-courier',
  RATE_COURIER_PUBLIC: 'public-tracker/rate-courier',
  GET_JOB_PUBLIC: 'public-tracker/get-job',
  GET_PARCELS_PUBLIC: 'public-tracker/get-parcels',
  GET_SAME_DAY_LABELS: 'customer-job/get-same-day-labels',
  GET_LABEL_ZIP_FILE: 'customer-job/get-label-zip-file',
};
