import * as actions from './constant';
const initialState = {
  error: false,
  emailVerification: false,
  isRegistered: false,
  loading: false,
  signupAddressForm: false,
  message: false
};

const signup = (state = initialState, action) => {
  switch (action.type) {
    case actions.SIGNUP_INITIATED:
      return {
        ...state,
        isRegistered: false,
        message: false,
        error: false,
        loading: true
      };
    case actions.SIGNUP_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        isRegistered: true,
        message: 'User is Authenticated'
      };
    case actions.SIGNUP_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        isRegistered: false,
        message: action.error
      };
    case actions.SEND_EMAIL_SUCCESS_INITIATED:
      return {
        ...state,
        loading: true
      };
    case actions.SEND_EMAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        emailVerification: action.payload
      };
    case actions.SEND_EMAIL_CLEAR:
      return {
        ...state,
        loading: false,
        emailVerification: false
      };
    case actions.VERIFY_INITIATED:
      return {
        ...state,
        message: false,
        error: false,
        loading: true
      };
    case actions.VERIFY_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        isVerified: true,
        emailVerification: action.payload
      };
    case actions.VERIFY_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        isVerified: false,
        message: action.error
      };
    default:
      return state;
  }
};
export default signup;