import styled from 'styled-components';
import { size } from 'App/device';
import { borderRadius, transition } from 'App/mixins';
import searchIconDark from 'assets/images/search-dark-icon.svg';

const TeamWrapper = styled.div`
  .team-main-content {
    position: relative;
    @media ${size['tablet-md-max']} {
      .left-block,
      .right-block {
        flex: auto;
        max-width: 100%;
        width: 100%;
      }
    }
    /* input: focus {
      border: none !important;
      border-bottom: 1px solid #8f48ff !important;
    }
    input {
      border: none !important;
    } */
    .right-block {
      .ant-card,
      .ant-card-body {
        height: 100%;
      }
      @media ${size['tablet-md-max']} {
        margin-top: 40px;
      }
    }
    @media ${size['tablet-max']} {
      padding: 0;
    }
    .left-block {
      .team-left-box {
        .team-p-l-main {
          padding-bottom: 15px;
          justify-content: space-between;
          .image-box{
            padding-top: 5%;
          }
          .team-count-main {
            padding-left: 15px;
            padding-top: 5%;
          .team-count {
            margin-left: 25px;
            span {
              font-family: 'Montserrat';
              text-align: center;
              font-weight: 600;
              font-size: 18px;
              line-height: 124.5%;
              color: #49465D;
              display: block;
            }
            strong {
              font-family: 'Montserrat';
              font-style: normal;
              font-weight: 600;
              font-size: 12px;
              text-transform: uppercase;
              color: #49465D;
              display: block;
            }
          }
        }
          .btn.purple-btn {
            // font-size: 13px;
          }
          @media ${size['laptop-max']} {
            display: block !important;
          }
          @media ${size['tablet-md-max']} {
            display: flex !important;
          }
          @media ${size['mobile-md-max']} {
            display: block !important;
          }
          .btn.purple-btn {
            min-width: 115px;
            @media ${size['laptop-max']} {
              margin-top: 15px;
              left: 25%;
              width: 50%;
            }
            @media ${size['tablet-md-max']} {
              margin-top: 0;
              left: 0;
              width: 0;
            }
            @media ${size['mobile-md-max']} {
              margin-top: 15px;
              left: 25%;
              width: 50%;
            }
          }
        }
        .admin-member-label-wrapper {
          padding: 10px 0 20px 0;
        }
        .menu-title {
          font-weight: bold;
          font-size: 12px;
          line-height: 11px;
          letter-spacing: 0.05em;
          color: #49465D;
          font-family: 'Montserrat';
        }
        .team-nav {
          margin: 0px -44px;
          @media ${size['mobile-md-max']} {
            margin: 0px -25px;
          }
          padding: 0px;
          li {
            padding: 0;
            margin: 0;
            list-style: none;
            ${transition(0.4)};
            ${borderRadius('5px')};
            a {
              text-decoration: none;
              // color: ${props => props.theme.primaryColor};
              // font-size: 16px;
              padding: 15px 35px;
              // font-weight: 600;
              // width: 100%;
              font-family: 'Montserrat';
              font-style: normal;
              font-weight: 500;
              font-size: 12px;
              line-height: 16px;
              color: #49465D;
            }
            &.active {
              background: #8f48ff;
              a {
                font-weight: bold;
                color: #fff;
              }
            }
          }
          &.main-page{
            margin: 0 -32px;
            li {
              &.active {
                background: #F7F7F7;
                a{
                  color: ${props => props.theme.primaryColor};
                }
              }
            }
          }
        }
      }
    }
    .ant-card {
      .ant-card-head {
        display: flex;
        background: ${props => props.theme.greyColor};
        border: 0;
        padding: 15px 35px;
        position: relative;
        align-items: center;
        ${borderRadius('9px 9px 0px 0px')};
        @media ${size['desktop-mm-max']} {
          padding: 10px 25px;
        }
        @media ${size['tablet-max']} {
          padding: 10px 20px;
        }
        .ant-card-head-wrapper {
          width: 100%;
          .ant-card-head-title {
            padding: 0;
            .card-header {
              .panel-next-day-text {
                font-size: 10px;
                line-height: 12px;
                letter-spacing: 0.105em;
                color: ${props => props.theme.secondaryColor};
                font-weight: 400;
                margin-top: 7px;
              }
            }
          }
        }
      }
      .ant-card-body {
        padding: 35px 35px 45px 35px;
        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
        border-radius: 0 0 10px 10px;
        flex: 1 1 auto;
        min-height: 1px;
        @media ${size['desktop-mm-min']} {
          padding: 20px 35px 25px 30px;
        }
        @media ${size['desktop-md-min']} {
          padding: 30px 35px 30px 30px;
        }
        @media ${size['desktop-mm-max']} {
          padding: 25px 25px 30px 25px;
        }
        @media ${size['tablet-max']} {
          padding: 20px 20px 20px 20px;
          margin-top: -15px;
        }
      }
    }
    .right-block {
      .ant-card-body {
        @media ${size['laptop-max']} {
          padding: 25px 15px 30px 15px;
        }
      }
    }
  }
  /* Team right Header */
  .team-right-header {
    padding: 0px 0px 20px 0px;
    // padding: 0px 60px 40px 75px;
    @media ${size['desktop-sm-max']} {
      padding: 0px 25px 60px 25px;
    }
    @media ${size['laptop-max']} {
      padding: 20px 10px 40px 10px;
    }
    @media ${size['tablet-max']} {
      padding: 30px 30px 30px 35px;
    }
    .left-block {
      width: 50%;
      margin-bottom: 10px;
      @media ${size['tablet-max']} {
        margin-right: 20px;
      }
    }
    @media ${size['tablet-md-max']} {
      padding: 30px 10px 40px 10px;
    }
    @media ${size['tablet-max']} {
      display: block;
    }
    .team-search-box {
      input {
        padding: 0 60px 0 20px;
      }
      .search-btn {
        left: auto;
        right: 0;
      }
    }
    .no-email {
      right: 50%;
      @media ${size['tablet-max']} {
        right: 0%;
      }
    }
  }
  /* Team right Listing */
  .teams-col-list {
    padding: 0px 0px 60px 0px;
    // padding: 0px 60px 60px 75px;
    @media ${size['desktop-sm-max']} {
      padding: 0px 25px 40px 25px;
    }
    @media ${size['laptop-max']} {
      padding: 0px 10px 40px 10px;
    }
    @media ${size['tablet-md-max']} {
      padding: 0px 10px 10px 10px;
    }
    // overflow: auto;
    max-height: 500px;
    &::-webkit-scrollbar {
      width: 8px;
      height: 8px;
    }
    &::-webkit-scrollbar-thumb {
      background: #8f48ff;
      ${borderRadius('10px')};
    }
    &::-webkit-scrollbar-track {
      box-shadow: none;
      ${borderRadius('10px')};
    }
    .team-number{
      font-size: 15px;
    }
    .teams-item-inner {
      margin-top: 5px;
      align-items: center;
      padding: 20px;
      ${transition(0.4)};
      &:hover {
        background: #f3f3f3;
      }
      .image-box {
        width: 56px;
        height: 56px;
        min-width: 56px;
        overflow: hidden;
        margin-right: 25px;
        @media ${size['desktop-sm-max']} {
          margin-right: 10px;
        }
        img {
          margin: auto;
          width: 59px;
          height: 59px;
          object-fit: cover;
          object-position: center;
          display: block;
          border-radius: 50px;
        }
      }
      .teambox-content {
        .pending-user-email {
          white-space: nowrap; 
          width: 150px; 
          overflow: hidden;
          text-overflow: ellipsis;
          cursor: pointer;
        }
        h6 {
          font-family: 'Montserrat';
          font-style: normal;
          font-weight: bold;
          font-size: 12px;
          line-height: 20px;
          text-align: center;
          color: #49465D;
        }
        p {
          margin: 0;
          line-height: 21px;
          font-family: 'Montserrat';
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          text-align: center;
          letter-spacing: 0.05em;
          color: #49465D;
        }
      }
    }
    .pending-user-list {
      font-size: 15px;
      margin-top: 20px;
    }
  }
  /* edit and delete button */
  .edit-team,
  .delete-team {
    font-size: 12px;
    background: #00ff9f;
    color: black;
    font-family: ${props => props.theme.primaryFont}, sans-serif;
    font-weight: 700;
    border: 1px solid black;
  }
  .edit-team {
    margin-left: 125px;
  }
  .delete-team {
    margin-left: 25px;
  }
  .mt-40 {
    margin-top: 40px;
    @media ${size['desktop-md-min']} {
      margin-top: 20px;
    }
    @media ${size['desktop-mm-min']} {
      margin-top: 20px;
    }
  }
  /* Need to be removed */
  .add-team-input {
    width: 100%;
    height: 46px;
    background: #fff;
    box-shadow: 4px 4px 20px rgba(109, 109, 109, 0.15);
    border-radius: 7px;
    -webkit-border-radius: 7px;
    -moz-border-radius: 7px;
    border: 0;
    padding: 0 20px 0 10px;
    font-weight: bold;
    margin-bottom: 30px;
  }
  .edit-btn,
  .delete-btn {
    width: 20px;
    margin-left: 7px;
    height: 20px;
    cursor: pointer;
  }
  .team-nav {
    li {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .team-btn {
        width: 25%;
        .edit-btn {
          margin-right: 5px;
        }
        .delete-team {
          margin-right: 20px;
          background-color: #e82868;
        }
      }
      &.active {
        .edit-btn,
        .delete-btn {
          // path {
          //   fill: #fff;
          // }
        }
      }
      @media ${size['desktop-mm-max']} and (min-width:1023px) {
        .team-btn {
          width: 30%;
        }
      }​
    }
  }
  .teams-item-inner {
    .delete-btn {
      margin-right: 10px;
      @media ${size['laptop-max']} {
        margin-right: 0px;
        margin-left: auto;
      }
    }
  }
  .reset-link {
    font-size: 14px;
    color: ${props => props.theme.primaryColor};
    font-weight: bold;
  }
  @media ${size['mobile-sm-max']} {
    .ant-tooltip-inner {
      padding: 15px 5px;
      font-size: 13px;
    }
  }
  .team-main-content {
    .team-detail-wrapper-card {
      hr {
        border: 0.711458px solid #CACACA;
        margin-bottom: 20px;
      }
      &.ant-card {
        .ant-card-head {
          padding: 15px 54px 15px 30px;
          background: #8f48ff;
          box-shadow: 0px 4px 20px rgba(0,0,0,0.1);
          min-height: 41px;
          height: 41px;
          .ant-card-head-wrapper {
            .ant-card-head-title {
              text-transform: uppercase;
              font-family: 'Montserrat';
              font-style: normal;
              font-weight: 600;
              font-size: 16px;
              line-height: 17px;
              color: #FFFFFF;
            }
          }
        }
      }
    }
  }
`;
const SearchWrapper = styled.div`
  position: relative;
  margin: 20px 0;
  &.team-search-box.left {
    margin: 0;
    width: 48%;
    margin-right: 10px;
    @media ${size['tablet-max']} {
      margin-top: 10px;
      margin-bottom: 10px;
      width: 100%;
    }
    input {
      width: 100%;
      height: 45px;
      border-radius: 4.26875px;
      border: 0.711458px solid rgba(223,223,223,0.75) !important;
      background: #ffffff;
      padding: 0 40px 0 20px;
    }
    .search-btn {
      border: 0;
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      width: 60px;
      height: 45px;
      padding: 0;
      background: url(${searchIconDark}) no-repeat center;
    }
  }
  &.team-search-box.right {
    margin: 0;
    input {
      width: 100%;
      height: 45px;
      background: #fff;
      -moz-border-radius: 7px;
      border-radius: 4.26875px;
      border: 0.711458px solid rgba(223,223,223,0.75) !important;
      padding: 0 60px 0 20px;
    }
    .search-btn {
      border: 0;
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      width: 60px;
      height: 45px;
      padding: 0;
      background: url(${searchIconDark}) no-repeat center;
    }
  }
  &.team-search-box.email-wrapper {
    /* input {
      border-right: 10px solid white !important;
      border-left: 10px solid white !important;
      padding: 0 40px 0 20px;
    } */
  }
  .email-btn {
    width: 25px;
    height: 40px;
    fill: #000;
    position: absolute;
    top: 3px;
    right: 10px;
    cursor: pointer;
  }
`;
export { TeamWrapper, SearchWrapper };
