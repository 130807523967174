export const ADDSHIFT_INITIATED = 'ADDSHIFT_INITIATED';
export const ADDSHIFT_SUCCESS = 'ADDSHIFT_SUCCESS';
export const ADDSHIFT_ERROR = 'ADDSHIFT_ERROR';

export const UPDATESHIFT_INITIATED = 'UPDATESHIFT_INITIATED';
export const UPDATESHIFT_SUCCESS = 'UPDATESHIFT_SUCCESS';
export const UPDATESHIFT_ERROR = 'UPDATESHIFT_ERROR';

export const GETSHIFT_INITIATED = 'GETSHIFT_INITIATED';
export const GETSHIFT_SUCCESS = 'GETSHIFT_SUCCESS';
export const GETSHIFT_ERROR = 'GETSHIFT_ERROR';

export const GETSHIFTQUOTE_INITIATED = 'GETSHIFTQUOTE_INITIATED';
export const GETSHIFTQUOTE_SUCCESS = 'GETSHIFTQUOTE_SUCCESS';
export const GETSHIFTQUOTE_ERROR = 'GETSHIFTQUOTE_ERROR';

export const CLEAR_SHIFT = 'CLEAR_SHIFT';
export const SET_SHIFTID = 'SET_SHIFTID';
export const SET_GEOLOCATION = 'SET_GEOLOCATION';
export const SET_AREALOCATION = 'SET_AREALOCATION';
export const GET_SHIFTJOB_INITIATED = 'GET_SHIFTJOB_INITIATED';
export const GET_SHIFTJOB_ERROR = 'GET_SHIFTJOB_ERROR';
export const GET_SHIFTJOB_SUCCESS = 'GET_SHIFTJOB_SUCCESS';

export const VALIDATESHIFT_INITIATED = 'VALIDATESHIFT_INITIATED';
export const VALIDATESHIFT_ERROR = 'VALIDATESHIFT_ERROR';
export const VALIDATESHIFT_SUCCESS = 'VALIDATESHIFT_SUCCESS';

export const CONFIRM_PAYMENT_INITIATED = 'CONFIRM_PAYMENT_INITIATED';
export const CONFIRM_PAYMENT_ERROR = 'CONFIRM_PAYMENT_ERROR';
export const CONFIRM_PAYMENT_SUCCESS = 'CONFIRM_PAYMENT_SUCCESS';
export const SET_SHIFT_DATA = 'SET_SHIFT_DATA';

export const GET_COURIER_LOCATION_INITIATED = 'GET_COURIER_LOCATION_INITIATED';
export const GET_COURIER_LOCATION_ERROR = 'GET_COURIER_LOCATION_ERROR';
export const GET_COURIER_LOCATION_SUCCESS = 'GET_COURIER_LOCATION_SUCCESS';

export const CANCELSHIFTJOB_INITIATED = 'CANCELSHIFTJOB_INITIATED';
export const CANCELSHIFTJOB_SUCCESS = 'CANCELSHIFTJOB_SUCCESS';
export const CANCELSHIFTJOB_ERROR = 'CANCELSHIFTJOB_ERROR';
export const REMOVESHIFTDRAFT = 'REMOVESHIFTDRAFT';
export const STARTTIMEOPEN = 'STARTTIMEOPEN';
export const ENDTIMEOPEN = 'ENDTIMEOPEN';
