import * as actions from './constant';
const initialState = {
  history: [],
  shifthistory: [],
  samehistory: [],
  filteredHistory: [],
  error: false,
  loading: false,
  message: false,
  page: 1,
  shiftPage: 1,
  samePage: 1,
  isFiltered: false,
  isShiftFiltered: false,
  isSameFiltered: false,
  shiftFilterOption: {},
  filterOption: {},
  sameFilterOption: {}
};

const jobHistory = (state = initialState, action) => {
  switch (action.type) {
    case actions.GETCURRENTHISTORY_INITIATED:
      return {
        ...state,
        history: [],
        message: false,
        error: false,
        loading: true,
        isFiltered: false
      };
    case actions.GETCURRENTHISTORY_SUCCESS:
      return {
        ...state,
        history: action.payload,
        loading: false,
        error: false,
        message: 'job history',
        isFiltered: false
      };
    case actions.GETCURRENTHISTORY_ERROR:
      return {
        ...state,
        error: true,
        history: [],
        message: action.error
      };
    case actions.GETSHIFTHISTORY_INITIATED:
      return {
        ...state,
        shifthistory: [],
        isFiltered: false,
        message: false,
        error: false,
        loading: true,
      };
    case actions.GETSHIFTHISTORY_SUCCESS:
      return {
        ...state,
        shifthistory: action.payload,
        loading: false,
        error: false,
        message: 'shift history',
        isFiltered: false
      };
    case actions.GETSHIFTHISTORY_ERROR:
      return {
        ...state,
        error: true,
        shifthistory: [],
        message: action.error
      };
    case actions.GETFILTEREDSHIFTHISTORY_SUCCESS:
      return {
        ...state,
        shifthistory: action.payload,
        loading: false,
        error: false,
        message: 'shift history',
        isShiftFiltered: true,
        shiftFilterOption: action.shiftFilterOption
      };
    case actions.GETFILTEREDHISTORY_SUCCESS:
      return {
        ...state,
        history: action.payload,
        loading: false,
        error: false,
        message: 'job history',
        isFiltered: true,
        filterOption: action.filterOption
      };
    case actions.SET_QUERY_OPTIONS:
      return {
        ...state,
        page: action.payload
      };
    case actions.SHIFT_QUERY_OPTIONS:
      return {
        ...state,
        shiftPage: action.payload
      };
    case actions.GETSAMEDAYHISTORY_INITIATED:
      return {
        ...state,
        samehistory: [],
        message: false,
        error: false,
        loading: true,
        isSameFiltered: false
      };
    case actions.GETSAMEDAYHISTORY_SUCCESS:
      return {
        ...state,
        samehistory: action.payload,
        loading: false,
        error: false,
        message: 'sameday history',
        isSameFiltered: false
      };
    case actions.GETSAMEDAYFILTEREDHISTORY_SUCCESS:
      return {
        ...state,
        samehistory: action.payload,
        loading: false,
        error: false,
        message: 'sameday history',
        isSameFiltered: true,
        sameFilterOption: action.filterOption
      };
    case actions.GETSAMEDAYHISTORY_ERROR:
      return {
        ...state,
        error: true,
        samehistory: [],
        message: action.error
      };
    case actions.GETINVOICEHISTORY_ERROR:
      return {
        ...state,
        error: true,
        message: action.error
      }
    default:
      return state;
  }
};
export default jobHistory;