import { push } from 'connected-react-router';
import { axiosAuthPost, axiosCsvAuthPost } from 'modules/Axios';
import { apiConstant } from 'modules/config';
import { loader } from 'redux/app/actions';
import * as actions from './constant';

export const profileUpdate = payload => async dispatch => {
  try {
    dispatch(loader(true));
    dispatch({ type: actions.PROFILE_INITIATED });
    let data = {};
    if (sessionStorage.signupForm) {
      let sessionData = JSON.parse(sessionStorage.signupForm);
      data = {
        ...payload,
        firstName: sessionData.firstName,
        lastName: sessionData.lastName
      };
    } else {
      data = {
        ...payload
      };
    }
    let response = await axiosAuthPost(apiConstant.PROFILE_UPDATE, data);
    if (!response.success) {
      dispatch({
        type: actions.PROFILE_ERROR,
        error: response.error.message
      });
    } else {
      dispatch({
        type: actions.PROFILE_SUCCESS,
        payload: response
      });
      sessionStorage.clear();
      sessionStorage.setItem('isNewUser', true);
      dispatch(
        push({
          pathname: '/booking'
        })
      );
      dispatch(getUserData());
    }
  } catch (error) {
    console.log(error, 'action catch');
    dispatch({
      type: actions.PROFILE_ERROR,
      error
    });
  }
  dispatch(loader(false));
};
export const profileSettingUpdate = payload => async dispatch => {
  let status = false;
  try {
    dispatch(loader(true));
    dispatch({ type: actions.PROFILE_INITIATED });
    let response = await axiosAuthPost(apiConstant.PROFILE_UPDATE, payload);
    if (!response.success) {
      await dispatch({
        type: actions.PROFILE_ERROR,
        error: response.error.message
      });
    } else {
      await dispatch({
        type: actions.PROFILE_SUCCESS,
        payload: response
      });
      status = true;
    }
  } catch (error) {
    console.log(error, 'action catch');
    dispatch({
      type: actions.PROFILE_ERROR,
      error
    });
  } finally {
    dispatch(loader(false));
    return status;
  }
};
export const getUserData = () => async dispatch => {
  let status = false;
  try {
    dispatch(loader(true));
    await dispatch({ type: actions.USERDATA_INITIATED });
    let response = await axiosAuthPost(apiConstant.GET_PROFILE, {});
    if (response.success) {
      sessionStorage.setItem(
        'userData',
        JSON.stringify({
          ...response.data
        })
      );
      await dispatch({
        type: actions.USERDATA_SUCCESS,
        payload: response.data
      });
      status = true;
    } else {
      sessionStorage.removeItem('userData');
      await dispatch({
        type: actions.USERDATA_ERROR,
        error: response.error.message
      });
    }
  } catch (error) {
    console.log(error, 'catch errror');
  } finally {
    dispatch(loader(false));
    return status;
  }
};
export const getUserSecondaryData = () => async dispatch => {
  try {
    await dispatch({ type: actions.SECONDARY_USERDATA_INITIATED });
    let response = await axiosAuthPost(apiConstant.GET_SECONDARY_PROFILE, {});
    if (response.success) {
      //sessionStorage.setItem('userSecondaryData',JSON.stringify({...response.data}));
      await dispatch({
        type: actions.SECONDARY_USERDATA_SUCCESS,
        payload: response.data
      });
    } else {
      sessionStorage.removeItem('userSecondaryData');
      await dispatch({
        type: actions.SECONDARY_USERDATA_ERROR,
        error: response.error.message
      });
    }
  } catch (error) {
    console.log(error, 'catch errror');
  }
};
export const SecondaryProfileUpdate = payload => async dispatch => {
  let status = false;
  try {
    dispatch({ type: actions.SECONDARY_PROFILE_INITIATED });
    let response = await axiosAuthPost(apiConstant.SECONDARY_PROFILE_UPDATE, payload);
    if (!response.success) {
      dispatch({
        type: actions.SECONDARY_PROFILE_ERROR,
        error: response.error.message
      });
    } else {
      await dispatch({
        type: actions.SECONDARY_PROFILE_SUCCESS,
        payload: response
      });
      status = true;
    }
  } catch (error) {
    console.log(error, 'action catch');
    dispatch({
      type: actions.SECONDARY_PROFILE_ERROR,
      error
    });
  } finally {
    return status;
  }
};

export const convertBusinessProfile = () => async dispatch => {
  try {
    dispatch(loader(true));
    let response = await axiosAuthPost(apiConstant.CONVERT_BUSINESS, {});
    if (!response.success) {
      dispatch({
        type: actions.SECONDARY_PROFILE_ERROR,
        error: response.error.message
      });
    } else {
      await dispatch(getUserData());
      await dispatch(getUserSecondaryData());
    }
    dispatch(loader(false));
  } catch (error) {
    dispatch(loader(false));
    console.log(error, 'action catch');
    dispatch({
      type: actions.SECONDARY_PROFILE_ERROR,
      error
    });
  }
};

export const userDataCheck = () => async dispatch => {
  try {
    await dispatch(getUserData());
  } catch (error) {
    console.log(error, 'catch errror');
  }
};
export const profileClear = () => async dispatch => {
  try {
    await dispatch({ type: actions.PROFILE_CLEAR });
  } catch (error) {
    console.log(error, 'catch errror');
  }
};
export const uploadProfilePhoto = payload => async dispatch => {
  try {
    dispatch(loader(true));
    dispatch({ type: actions.PROFILE_PICTURE_UPLOAD_INITIATED });
    let response = await axiosCsvAuthPost(apiConstant.PROFILE_PICTURE_UPDATE, payload, true);
    if (!response.data.success) {
      dispatch({
        type: actions.PROFILE_PICTURE_UPLOAD_ERROR,
        error: response.data.error.message
      });
    } else {
      dispatch({
        type: actions.PROFILE_PICTURE_UPLOAD_SUCCESS,
        payload: response
      });
      await dispatch(getUserData());
      await dispatch(getUserSecondaryData());
    }
    dispatch(loader(false));
  } catch (error) {
    console.log(error, 'catch errror');
  }
};
