import styled from 'styled-components';
import { size } from 'App/device';

const Wrapper = styled.nav`
  &.breadcrumb-main {
    padding-bottom: 15px;
    @media ${size['desktop-md-max']} {
      padding-bottom: 35px;
    }
    @media ${size['desktop-sm-max']} {
      padding-bottom: 35px;
    }
    .breadcrumb {
      margin: 0;
      padding: 0;
      background: none;
      list-style: none;
      display: flex;
      flex-wrap: wrap;
      padding: 0;
      list-style: none;
      border-radius: 0.25rem;
      .breadcrumb-item {
        padding: 0;
        list-style: none;
        position: relative;
        margin: 0px;
        a {
          // font-size: 24px;
          font-size: 20px;
          line-height: 20px;
          color: #c8c8c8;
          text-transform: none;
          @media ${size['desktop-sm-max']} {
            font-size: 20px;
            line-height: 22px;
          }
          @media ${size['tablet-max']} {
            font-size: 18px;
            line-height: 20px;
          }
          :hover,
          :focus {
            text-decoration: none;
            color: #49465d;
          }
        }
        // :before {
        //   height: 5px;
        //   content: '';
        //   width: 47px;
        //   position: absolute;
        //   left: 0;
        //   bottom: -15px;
        //   background: #49465d;
        //   opacity: 0;
        // }
        // :after {
        //   height: 5px;
        //   content: '';
        //   width: 25px;
        //   position: absolute;
        //   left: 0;
        //   bottom: -25px;
        //   background: #49465d;
        //   opacity: 0;
        // }
        + .breadcrumb-item {
          margin-left: 20px;
        }
      }
      .active {
        color: #6c757d;
        a {
          font-weight: bold;
          color: #49465d;
        }
        // :before {
        //   box-sizing: border-box;
        //   height: 5px;
        //   content: '';
        //   width: 47px;
        //   position: absolute;
        //   left: 0;
        //   bottom: -15px;
        //   background: #49465d;
        //   opacity: 0;
        // }
        :before,
        :after {
          opacity: 1;
        }
      }
    }
  }
`;

export { Wrapper };
