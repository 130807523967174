import { size } from 'App/device';
import { borderRadius, transition } from 'App/mixins';
import arrowRight from 'assets/images/arrow-right.svg';
import UploadCloudIcon from 'assets/images/booking/cloud-upload.svg';
import BikeHover from 'assets/images/breadcrumb-icons/bike-hover.svg';
import BikeCompleted from 'assets/images/breadcrumb-icons/bike-nav-completed.svg';
import Bike from 'assets/images/breadcrumb-icons/bike.svg';
import BookNavCheck from 'assets/images/breadcrumb-icons/book-nav-check-icon.svg';
import BookNav6 from 'assets/images/breadcrumb-icons/book-nav-icon-6.svg';
import BookNavCompleted1 from 'assets/images/breadcrumb-icons/book-nav-icon1-completed.svg';
import BookNavHover1 from 'assets/images/breadcrumb-icons/book-nav-icon1-hover.svg';
import BookNav1 from 'assets/images/breadcrumb-icons/book-nav-icon1.svg';
import BookNavCompleted2 from 'assets/images/breadcrumb-icons/book-nav-icon2-completed.svg';
import BookNavHover2 from 'assets/images/breadcrumb-icons/book-nav-icon2-hover.svg';
import BookNav2 from 'assets/images/breadcrumb-icons/book-nav-icon2.svg';
import BookNavCompleted3 from 'assets/images/breadcrumb-icons/book-nav-icon3-completed.svg';
import BookNavHover3 from 'assets/images/breadcrumb-icons/book-nav-icon3-hover.svg';
import BookNav3 from 'assets/images/breadcrumb-icons/book-nav-icon3.svg';
import BookNavCompleted5 from 'assets/images/breadcrumb-icons/book-nav-icon5-completed.svg';
import BookNavHover5 from 'assets/images/breadcrumb-icons/book-nav-icon5-hover.svg';
import BookNav5 from 'assets/images/breadcrumb-icons/book-nav-icon5.svg';
import BookNavCompleted6 from 'assets/images/breadcrumb-icons/book-nav-icon6-completed.svg';
import BookNavHover6 from 'assets/images/breadcrumb-icons/book-nav-icon6-hover.svg';
import BookNavCompleted7 from 'assets/images/breadcrumb-icons/book-nav-icon7-completed.svg';
import BookNavHover7 from 'assets/images/breadcrumb-icons/book-nav-icon7-hover.svg';
import BookNav7 from 'assets/images/breadcrumb-icons/book-nav-icon7.svg';
import carHover from 'assets/images/breadcrumb-icons/car-hover.svg';
import carCompleted from 'assets/images/breadcrumb-icons/car-nav-completed.svg';
import car from 'assets/images/breadcrumb-icons/car.svg';
import cargoBikeHover from 'assets/images/breadcrumb-icons/cargoBike-hover.svg';
import cargoBikeCompleted from 'assets/images/breadcrumb-icons/cargoBike-nav-completed.svg';
import cargoBike from 'assets/images/breadcrumb-icons/cargoBike.svg';
import largevanHover from 'assets/images/breadcrumb-icons/largevan-hover.svg';
import largevanCompleted from 'assets/images/breadcrumb-icons/largevan-nav-completed.svg';
import largevan from 'assets/images/breadcrumb-icons/largevan.svg';
import MapIconHover from 'assets/images/breadcrumb-icons/map-hover.svg';
import MapIconCompleted from 'assets/images/breadcrumb-icons/map-nav-completed.svg';
import MapIcon from 'assets/images/breadcrumb-icons/map.svg';
import motorcycleHover from 'assets/images/breadcrumb-icons/motorcycle-hover.svg';
import motorcycleCompleted from 'assets/images/breadcrumb-icons/motorcycle-nav-completed.svg';
import motorcycle from 'assets/images/breadcrumb-icons/motorcycle.svg';
import BookNavHover4 from 'assets/images/breadcrumb-icons/new-truck-hover.svg';
import BookNavCompleted4 from 'assets/images/breadcrumb-icons/new-truck-nav-completed.svg';
import BookNav4 from 'assets/images/breadcrumb-icons/new-truck.svg';
import smallvanHover from 'assets/images/breadcrumb-icons/smallvan-hover.svg';
import smallvanCompleted from 'assets/images/breadcrumb-icons/smallvan-nav-completed.svg';
import smallVan from 'assets/images/breadcrumb-icons/smallvan.svg';
import VehicleIconHover from 'assets/images/breadcrumb-icons/vehicle-icon-hover.svg';
import VehicleIconCompleted from 'assets/images/breadcrumb-icons/vehicle-icon-nav-completed.svg';
import VehicleIcon from 'assets/images/breadcrumb-icons/vehicle-icon.svg';
import downArrowGrey from 'assets/images/down-arrow-grey.svg';
import downArrow from 'assets/images/down-arrow.svg';
import editPen from 'assets/images/edit-pen.svg';
import tabPoundIcon from 'assets/images/pound-icon.svg';
import refrenceIcon from 'assets/images/refrence-icon-latest.svg';
import routeFilterIcon from 'assets/images/route-filter-icon.svg';
import starPurpleIcon from 'assets/images/star-purple-icon.svg';
import starWhiteIcon from 'assets/images/star-white-icon.svg';
import styled from 'styled-components';

const AddressWrapper = styled.div`
  .booking-csv-upload-btn {
    position: absolute;
    right: 10px;
    top: 10px;
    @media ${size['mobile-md-max']} {
      position: static;
      margin-top: 10px;
    }
    button {
      background: transparent;
      border: none;
      font-weight: 600;
      font-size: 13px;
      letter-spacing: 0.05em;
      color: #49465d;
      padding-right: 35px;
      &:after {
        position: absolute;
        content: '';
        left: auto;
        // right: 0;
        top: 50%;
        background: url(${UploadCloudIcon}) no-repeat center;
        background-size: 100% auto;
        width: 30px;
        height: 30px;
        -webkit-transform: translate(0, -50%);
        -ms-transform: translate(0, -50%);
        transform: translate(0, -50%);
        @media ${size['tablet-max']} {
          top: 60%;
        }
        @media ${size['mobile-md-max']} {
          top: 68%;
        }
        @media ${size['mobile-sm-max']} {
          top: 75%;
        }
      }
    }
    .upload-csv-text {
      margin-right: 10px;
    }
  }
  .ant-card-body {
    .wait-and-return-box {
      margin-top: 20px;
      span {
        font-family: 'Montserrat';
        font-weight: 600;
        font-size: 12px;
        line-height: 15px;
        letter-spacing: 0.05em;
        color: #816e9b;
      }
      .ant-checkbox-inner {
        border-radius: 5px;
        border: 1.42px solid #9d9d9d;
      }
    }
    .booking-address-btn-grp {
      .down-arow-btn {
        width: 54px;
        height: 54px;
        border: 0;
        box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.1);
        ${transition(0.4)};
        ${borderRadius('50%')};
        padding: 0;
        background: #fff url(${downArrowGrey}) no-repeat center;
        background-size: 10px auto;
        @media ${size['desktop-mm-max']} {
          background-size: 8px auto;
        }
        @media ${size['tablet-max']} {
          width: 45px;
          height: 45px;
        }
        @media ${size['mobile-md-max']} {
          width: 39px;
          height: 39px;
        }

        &.up-icon {
          background: #fff url(${downArrow}) no-repeat center;
          transform: rotate(-180deg);
        }
      }

      .booking-address-cta-btns {
        display: flex;
        align-items: center;

        .add-fav-btn {
          background-color: ${(props) => props.theme.secondaryColor};
          background-size: 15px auto;
          background-repeat: no-repeat;
          background-position: center;
          border: 0.1rem solid ${(props) => props.theme.secondaryColor};
          &:not([disabled]):hover {
            background-color: #fff;
            border: 0.1rem solid ${(props) => props.theme.secondaryColor};
          }

          min-width: 50px;
          background-image: url(${starWhiteIcon});

          &:hover {
            background-image: url(${starPurpleIcon});
          }

          &.disabled {
            border: 1px solid #c4c4c4;
            background-color: #c4c4c4;

            &:hover {
              background-image: url(${starWhiteIcon});
              background-color: #c4c4c4;
              border: 1px solid #c4c4c4;
            }

            button {
              display: none;
            }
          }

          @media ${size['desktop-mm-max']} {
            background-size: 13px auto;
          }
        }
      }
      div {
        button.purple-btn {
          margin-left: 20px;
        }

        .custom-alert-sucess {
          margin-bottom: 0;
          margin-right: 10px;
        }
      }
    }
  }
  .countrylbl {
    margin-bottom: 0 !important;
  }
  .text-area-form-input-grp {
    @media ${size['desktop-sm-max']} {
      margin-top: 15px !important;
    }
  }
  .three-dots {
    position: relative;
    .dots-icon {
      border: 0;
      background: none;
      padding: 0;
      height: 100%;
      font-size: 22px;
      color: #8f48ff;
      letter-spacing: 0;
      position: absolute;
      right: 35px;
      z-index: 1;
      top: 0;
      bottom: 0;
      @media ${size['desktop-mm-max']} {
        right: 30px;
      }
    }
  }
  .with-map-icon.three-dots .dots-icon {
    right: 20px;
    @media ${size['desktop-mm-max']} {
      right: 15px;
    }
  }

  .custom-address-main .field-title {
    margin: 14px 0 -13px 0 !important;
  }

  .select2-container {
    box-sizing: border-box;
    display: inline-block;
    margin: 0;
    position: relative;
    vertical-align: middle;
  }
  .with-map-icon .map-icon {
    right: 10px;
    @media ${size['desktop-mm-max']} {
      right: 10px;
    }
  }
  input[readonly] {
    height: 40px !important;
    background: #fff !important;
  }
  input {
    color: #49465d !important;
    font-weight: 600 !important;
  }
  .error-wrapper {
    input.form-control {
      box-shadow: 0px 0px 5px #d6181a !important;
      border-radius: 0px !important;
      border: 1px solid #d6181a !important;
    }
  }
  .top-space-0 {
    @media ${size['desktop-md-max']} {
      margin: 0 !important;
    }
  }

  .color-original {
    background-color: #c9c9c9 !important;
    border-radius: 50px !important;
    border: 0 !important;
    &:hover {
      background-color: #d90fff !important;
    }
  }

  .map-arrow-icon {
    position: relative;
    float: right;
    width: 30px;
    height: 10px;
    top: 46px;
    vertical-align: middle;
    cursor: pointer;
    display: block;
    color: ${(props) => props.theme.secondaryColor};
    background: url(${arrowRight}) no-repeat center;
    background-size: 14px auto;
    z-index: 1;
  }
`;

const BookingDetailsWrapper = styled.div`
  position: relative;
  &.slide{
    position: absolute;
    right: calc(100%);
  }
  &.booking-detail-main-wrapper {
    width: 100%;
    .card-custom {
      .ant-card-head {
        padding: 12px 30px;
        .card-header {
            h5.card-title {
              font-size: 16px;
            }
        }
      }
    }
  }

  .ant-tabs-bar.ant-tabs-top-bar {
    @media ${size['laptop-md-max']} {
      padding: 0 10px !important;
    }
  }

  /* .pulse-animation {
    animation: pulse-animation 3s;
    margin-bottom: 10px !important;
  }

  @keyframes pulse-animation {
    0% {
      box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);
      background: #8f48ff21;
    }
    100% {
      box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
      background:#8f48ff21;
    } */
  }
  .ant-tabs-nav.ant-tabs-nav-animated div:first-child {
    display: flex;
  }
  .ant-tabs-nav.ant-tabs-nav-animated .ant-tabs-tab {
    @media ${size['desktop-md-max']} {
      margin-right: 15px !important;
    }
    @media ${size['laptop-sm-max']} {
      padding-right: 10px !important;
      padding-left: 10px !important;
      margin-right: 10px !important;
      font-size: 10px !important;
    }
    @media ${size['mobile-md-max']} {
      padding-right: 1px !important;
      margin-right: 5px !important;
      padding-left: 1px !important;
    }
  }
  .ant-tabs-nav-wrap {
    overflow: hidden !important;
    .ant-tabs-nav-scroll {
      margin-top: 0px;
      // padding-top: 20px;
      overflow: hidden !important;
      @media ${size['desktop-md-min']} {
        padding-top: 0px;
      }
      .ant-tabs-nav {
        & > div {
          &:first-child {
            margin-left: 10px;
          }
        }
      }
    }
  }

  .middle-field {
    .form-group {
      width: unset;
      margin: 30px 0 0 0;
      padding-bottom: 10px;
    }
  }

  .ant-dropdown {
    ul {
      transform: none !important;
      right: 0;
      left: auto !important;
      top: 30px !important;
      width: 285px !important;
      background: #fff;
      box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
      border-radius: 7px;
      -webkit-border-radius: 7px;
      -moz-border-radius: 7px;
      border: 0;
      padding: 10px 0;
      margin: 0;
      list-style-type: none;
      padding-left: 0;
      &.location-item-menus {
        width: 100% !important;
        .ant-menu-item {
          align-items: baseline !important;
        }
      }
      li.ant-menu-item:hover {
        background: #fafafa;
        color: rgba(0, 0, 0, 0.65);
        i.ant-menu-submenu-arrow::after,
        i.ant-menu-submenu-arrow::before {
          background: rgba(0, 0, 0, 0.65);
        }
      }
      li {
        padding: 12px 35px !important;
        font-weight: normal;
        font-size: 18px;
        line-height: 20px;
        :hover {
          background: #fafafa;
          font-weight: 700 !important;
          .parcel-toggle-btn{
            background: #E8286B;
          }
          i.ant-menu-submenu-arrow {
            transform: rotate(180deg);
            color: rgba(0, 0, 0, 0.65);
          }
        }
        &.ant-menu-submenu:first-child {
          padding: 0 !important;
        }
        .ant-menu-submenu-title {
          font-weight: normal;
          font-size: 18px;
          padding: 0 35px !important;
          &:hover {
            color: rgba(0, 0, 0, 0.65);
            i.ant-menu-submenu-arrow::after,
            i.ant-menu-submenu-arrow::before {
              background: rgba(0, 0, 0, 0.65);
            }
          }
        }
      }
    }
  }
  .ant-card-head{
    z-index: 2;
  }
  .ant-card-body {
    // max-height: 600px;
    // overflow: auto;
    ::-webkit-scrollbar {
      width: 5px;
      height: 5px;
    }
    ::-webkit-scrollbar-track {
      box-shadow: none;
      border-radius: 10px;
      -webkit-border-radius: 10px;
      -moz-border-radius: 10px;
    }
    ::-webkit-scrollbar-thumb {
      background: #8f48ff;
      border-radius: 10px;
      -webkit-border-radius: 10px;
      -moz-border-radius: 10px;
    }
    ::-webkit-scrollbar-thumb:hover {
      background: #8f48ff;
    }
  }
.side-grp-btn {
  display: flex;
  align-items: center;
  position: relative;
}
.map-icon-head {
  display: flex;
  position: absolute;
  top: -56px;
  background: ${(props) => props.theme.primaryColor};
  ${borderRadius('6px')};
  ${transition(0.4)};
  border: none;
  padding: 7px;
  right: 0;
  svg {
    display: inline-block;
    vertical-align: middle;
  }
  .map-text {
    margin-left: 7px;
    padding-top: 2px;
    color: white !important;
  }
  @media ${size['laptop-max']} {
    top: -40px;
  }
  &.map-purple-bg {
    background: ${(props) => props.theme.secondaryColor};
  }
}
.map-popover-main {
  left: 0.172px !important;
  top: -10px !important;
  border-radius: 8px;
  width: 100%;
  .ant-popover-inner-content  {
     padding: 0;
     height: calc(100vh - 228px);
    max-height: calc(100vh - 240px);
    min-height: 1px;

    div{
      padding: 0;
    height: calc(100vh - 228px);
    max-height: calc(100vh - 240px);
    min-height: 1px;
    }
  }
}
`;

const RoutePreviewShifts = styled.div`
  height: calc(100vh - 275px);
  position: relative;
  div {
    border-radius: 10px;
  }
`;

const RoutePreview = styled.div`
  height: calc(100vh - 205px);
  div {
    border-radius: 10px;
  }
  .map-wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    transform: translateX(calc(-105%));
    z-index: 99999;
  }
`;

const RouteParcelStorePreview = styled.div`
  height: calc(100vh - 275px);
  position: relative;
  border-radius: 0px 0px 9px 9px;
  -webkit-border-radius: 0px 0px 9px 9px;
`;
const RoutePreviewSameDay = styled.div`
  height: 250px;
  position: relative;
  border-radius: 0px 0px 9px 9px;
  -webkit-border-radius: 0px 0px 9px 9px;
  &.full-height {
    height: calc(100vh - 150px);
  }
`;

const InputWrapper = styled.div`
  input {
    border: none !important;
    border-bottom: 0.1rem solid #cecece !important;
  }
  input:focus {
    border-bottom: 1px solid #8f48ff !important;
  }
  .focus,
  .show {
    .ant-select-selection__placeholder {
      display: none !important;
    }
  }
  .ant-select-selection__placeholder {
    font-weight: 400;
    font-size: 16px;
    color: #323232 !important;
  }
  .error-wrapper input {
    border: none !important;
    border-bottom: 1px solid #d6181a !important;
    box-shadow: none !important;
    border-right: 10px solid white !important;
  }
  .form-group {
    // margin-bottom: 15px;
    .label {
      opacity: 1;
      transition: all 300ms;
      margin-bottom: 0 !important;
      display: block;
      font-family: 'Montserrat', sans-serif;
      font-size: 12px;
      margin-bottom: 0;
      font-weight: 600;
      text-transform: uppercase;
      color: #816e9b !important;
      line-height: 14px;
    }
    &.show {
      .label {
        opacity: 1 !important;
      }
    }
    &.focus {
      .label {
        // transform: translate3d(0, 0, 0);
        opacity: 1 !important;
        color: #8f48ff !important;
      }
    }
  }
`;

const RoutePreviewDiv = styled.div`
  width: 100%;
  .popover-route-container {
    > div {
      top: -15px !important;
      left: -6px !important;
      position: relative;
    }
    .ant-popover.ant-popover-placement-top {
      padding-right: 38px;
    }
    .ant-popover-inner-content {
      padding: 0;
      height: calc(100vh - 228px);
      max-height: calc(100vh - 240px);
      min-height: 1px;

      div {
        padding: 0;
        height: calc(100vh - 228px);
        max-height: calc(100vh - 240px);
        min-height: 1px;
      }
    }
    .ant-popover-title {
      border-radius: 9px 9px 0px 0px;
      -webkit-border-radius: 9px 9px 0px 0px;
      -moz-border-radius: 9px 9px 0px 0px;
      padding: 17px 35px;
      border: 0;
      position: relative;
      align-items: center;
      background: #d90fff;
      .card-header {
        h5 {
          margin: 0;
          color: #fff;
          font-size: 22px;
          line-height: 26px;
          font-weight: 500;
        }
        .route-filter-icon {
          width: 35px;
          height: 14px;
          background: url(${routeFilterIcon}) no-repeat center;
          background-size: 100% auto;
          display: block;
          position: relative;
          z-index: 9;
          cursor: pointer;
        }
      }
    }
  }
`;

const BookingParcelsItemsWrapper = styled.div`
  .size-name {
    font-size: 16px !important;
    margin-bottom: 0;
    font-weight: 600;
    font-family: 'OpenSans', sans-serif;
  }
  .custom-size {
    font-size: 16px !important;
  }
  .size-dropdown {
    width: 350px !important;
    top: 172px !important;
    left: 311.766px !important;
    & ul {
      max-height: 328px;
    }
    @media ${size['desktop-sm-max']} {
      left: 294.766px !important;
    }
    .dropdown-section {
      & ul {
        max-height: 328px;
      }
    }
  }
  .dropdown-custom {
    background: ${(props) => props.theme.backgroundColor};
    color: ${(props) => props.theme.primaryColor};
    & ul {
      // max-height: 328px;
      padding: 0px 10px;
      &::-webkit-scrollbar {
        width: 0.5em;
      }
      &::-webkit-scrollbar-thumb {
        height: 88px;
        width: 8px;
        border-radius: 10px;
        background: #ffa100;
      }
      li {
        margin: 0;
        padding: 10px 0;
        position: relative;
        text-align: left;
        font-size: 15px;
        color: #fff;
        line-height: 18px;
        cursor: pointer;
        padding-left: 15px;
        padding-right: 10px;
        margin: 0;
        border-radius: 5px;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        @media ${size['desktop-sm-max']} {
          padding: 5px 6px;
        }
        color: ${(props) => props.theme.selectFontColor};
        &.ant-select-dropdown-menu-item-selected {
          background-color: rgba(255, 161, 0, 0.68);
          // opacity: 0.5;
          margin-bottom: 3px;
          margin-top: 3px;
          color: #fff;
          font-weight: 500;
          .anticon svg {
            display: inline-block;
          }
        }
        &.ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled) {
          background-color: #ffa100;
          color: #fff;
        }
        &.ant-select-dropdown-menu-item:hover:not(.ant-select-dropdown-menu-item-disabled) {
          background-color: #ffa100;
          color: #fff;
        }
        .anticon svg {
          display: none;
        }
      }
    }
  }
  .info-parcel-icon {
    &.tooltip-icon {
      margin-left: auto;
      top: -1px;
    }
  }
  label {
    letter-spacing: 0.05em;
    @media ${size['desktop-mm-max']} {
      font-size: 12px;
      line-height: 14px;
    }
  }
  .bookingparcel {
    .ant-row {
      margin-left: -30px !important;
      margin-right: -30px !important;
      @media ${size['desktop-mm-max']} {
        margin-left: -15px !important;
        margin-right: -15px !important;
      }
    }
    .ant-col {
      padding-left: 30px !important;
      padding-right: 30px !important;
      @media ${size['desktop-mm-max']} {
        padding-left: 15px !important;
        padding-right: 15px !important;
      }
    }
    .field-with-icon {
      padding-left: 0px;
      margin-top: 3px;
      input {
        padding-left: 30px;
      }
      .form-control-placeholder {
        padding-bottom: 8px;
        @media ${size['desktop-sm-max']} {
          padding-bottom: 0px;
        }
      }
    }
    .form-group {
      &.shift {
        min-width: 50% !important;
        margin: 0px 0 0;
        margin-top: 30px;
        @media ${size['desktop-sm-max']} {
          margin-top: 15px;
        }
        .select-parcel-wrapper {
          // padding-top: 2px;
          // margin-top: 6px;
          .select-custom.teams {
            // min-height: 34px;
            height: 50px;
            .ant-select-selection__rendered {
              .dropdown-item {
                padding-top: 0px !important;
              }
            }
          }
        }
        .ant-input-number {
          border: 0;
          .ant-input-number-input-wrap input {
            border-color: #d9d9d9;
          }
        }
        .label {
          // position: absolute !important;
          // top: 2px !important;
          // transition: all 300ms !important;
          // opacity: 0.5 !important;
          // margin-bottom: 0 !important;
          font-size: 12px !important;
          color: #816e9b !important;
          line-height: 14px !important;
          margin-bottom: 5px !important;
          display: block !important;
          font-family: 'Montserrat', sans-serif !important;
          font-weight: 600 !important;
          text-transform: uppercase !important;
          letter-spacing: 0.05em !important;
        }
        .vehicle {
          border-bottom: 0.1rem solid #cecece !important;
          border: none;
          overflow: auto !important;
          .ant-select-selection__placeholder {
            // top: 70%;
            display: block !important;
          }
        }
      }
      &.fullwidth {
        max-width: 100%;
        .form-group {
          max-width: 100%;
        }
      }
      &.focus {
        .label {
          //font-size: 75%;
          // transform: translate3d(0, -100%, 0);
          opacity: 1 !important;
          color: #8f48ff;
        }
        .vehicle {
          border-bottom: 1px solid #8f48ff !important;
          .ant-select-selection__placeholder {
            color: transparent;
          }
        }
      }
      &.show {
        .label {
          //font-size: 75%;
          // transform: translate3d(0, -100%, 0);
          opacity: 1 !important;
        }
        .vehicle {
          .ant-select-selection__placeholder {
            display: none !important;
          }
        }
      }

      .error-wrapper {
        border-radius: 0 !important;
        border: none !important;
        border-bottom: 1px solid #d6181a !important;
        box-shadow: none !important;
      }
    }
  }
  .three-fields-group {
    flex-wrap: wrap;
    margin: 0 -8px;
    .form-group {
      min-width: 38%;
      max-width: 38%;
      flex-basis: 38%;
      padding-left: 8px;
      padding-right: 8px;
      .item-select {
        .select-custom.teams.form-control.vehicle.ant-select.ant-select-enabled.ant-select-no-arrow {
          height: 50px !important;
        }
      }
      &.shift {
        min-width: 50% !important;
        .ant-input-number {
          border: 0;
          .ant-input-number-input-wrap input {
            border-color: #d9d9d9;
          }
        }
        .label {
          padding-bottom: 8px !important;
          top: 2px !important;
          opacity: 1 !important;
        }
        .vehicle {
          border-bottom: 0.1rem solid #cecece !important;
          border: none;
          overflow: auto !important;
          .ant-select-selection__placeholder {
            top: 70%;
            display: block !important;
          }
        }
        .vehicles {
          padding-left: 35px !important;
        }
        .ant-select-selection__choice__remove {
          z-index: 1;
        }
      }
      &.fullwidth {
        max-width: 100%;
        .form-group {
          max-width: 100%;
          .title {
            padding-bottom: 8px;
          }
        }
      }
      &.focus {
        .label {
          //font-size: 75%;
          transform: translate3d(0, -100%, 0);
          opacity: 1 !important;
          color: #8f48ff;
        }
        .vehicle {
          border-bottom: 1px solid #8f48ff !important;
          .ant-select-selection__placeholder {
            color: transparent;
          }
        }
      }
      &.show {
        .label {
          //font-size: 75%;
          transform: translate3d(0, -100%, 0);
          opacity: 1 !important;
        }
        .vehicle {
          .ant-select-selection__placeholder {
            display: none !important;
          }
        }
      }

      .error-wrapper {
        border: none !important;
        border-bottom: 1px solid #d6181a !important;
        box-shadow: none !important;
      }
      @media ${size['desktop-mm-max']} {
        /* margin: 20px 0 0; */
      }
      @media ${size['tablet-max']} {
        display: block !important;
        min-width: 100%;
        max-width: 100%;
        flex-basis: 100%;
      }
      .amount-textbox {
        position: absolute;
        top: 62%;
        bottom: 0;
        padding: 5px;
        right: 22px;
        background: #f4f4f4;
        width: 42px;
        height: 42px;
        text-align: center;
        transform: translate(0, -50%);
        -webkit-transform: translate(0, -50%);
        -moz-transform: translate(0, -50%);
        -ms-transform: translate(0, -50%);
        box-shadow: none;
        color: #49465d;
        font-weight: 400;
        border: 0;
        border-radius: 5px;
        -webkit-border-radius: 5px;
        margin: 0;
        font-size: 18px;
        @media ${size['desktop-mm-max']} {
          width: 34px;
          height: 34px;
          right: 17px;
        }
        @media ${size['desktop-sm-max']} {
          font-size: 14px;
          top: 64%;
        }
        @media ${size['tablet-max']} {
          top: 66%;
        }
      }
    }
    .form-group .select-custom.ant-select.vehicle {
      overflow: auto !important;
    }
    .small-field {
      min-width: 24%;
      max-width: 24%;
      flex-basis: 24%;
      @media ${size['tablet-max']} {
        min-width: 100%;
        max-width: 100%;
        flex-basis: 100%;
      }
    }

    /* Vehcile Select Style */
    .vehicle-select {
      .vechile-icon {
        .placeholder-icon {
          width: 25px !important;
        }
      }
      .select-custom {
        padding-left: 56px !important;
      }
    }
    @media ${size['desktop-mm-max']} {
      .vehicle-select {
        margin-top: 10px;
      }
    }
  }

  input {
    padding-left: 52px;
    color: #49465d;
  }
  .reference-icon {
    background: url(${refrenceIcon}) no-repeat center;
    z-index: 1;
    margin-top: 12px;
    background-size: 100% auto;
    width: 16px;
    height: 18px;
    display: block;
    position: absolute;
    top: 48%;
    transform: translate(0, -50%);
  }
  i.value-icon.false {
    background-size: 100% auto !important;
  }
  .value-icon {
    z-index: 1;
    margin-top: 12px;
    background-size: 100% auto !important;
    width: 16px;
    height: 18px;
    display: block;
    position: absolute;
    top: 45%;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    background: url(${tabPoundIcon}) no-repeat center;
  }
  .field-info strong {
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    letter-spacing: 0.05em;
    color: #aeaeae;
  }
  .tooltip-icon {
    display: inline-block;
    vertical-align: middle;
    position: relative;
    margin: 7px 0 0 10px;
  }
  .middle-field {
    display: inline-block;
    top: -20px;
    left: 19px;
    margin-bottom: 25px;
    @media ${size['mobile-md-max']} {
      top: 35px;
      left: 0px;
      margin-bottom: 52px;
    }
  }

  .add-item-btn {
    margin-top: 20px;
  }
  .btn-grp-parcels {
    width: 100%;
    margin: 0;
    padding: 0;
    opacity: 0;
    @media ${size['mobile-md-max']} {
      padding-left: 2px;
      display: block !important;
    }
    @media ${size['tablet-max']} {
      display: block !important;
    }
    .parcel-action-btns {
      button {
        &:first-child {
          margin-right: 15px;
        }
      }
      @media ${size['mobile-md-max']} {
        margin-left: 0 !important;
        margin-top: 20px;
        .btn.cancel-btn {
          margin-bottom: 10px;
          line-height: 45px;
          height: 45px;
        }
      }
      @media ${size['tablet-max']} {
        margin-top: 20px;
      }
    }
  }
  .btn {
    @media ${size['mobile-md-max']} {
      font-size: 12px;
      line-height: 45px;
      height: 45px;
      min-width: 115px;
    }
  }
  .btn.purple-btn {
    @media ${size['mobile-md-max']} {
      line-height: 45px;
      height: 45px;
      min-width: 119px;
    }
  }
`;

const ProgressWrapper = styled.div`
  width: 100%;
  .ant-row {
    .booking-nav-progress {
      margin-bottom: 25px;
      border: 0;
      padding: 0;
      display: flex;
      margin-left: auto !important;
      padding-left: 5px;
      padding-right: 5px;
      margin: 0px;
      .ant-steps.ant-steps-horizontal.ant-steps-label-horizontal {
        position: absolute;
        top: 53px;
        width: 100%;
        @media ${size['desktop-md-max']} {
          width: 56%;
        }
        @media ${size['laptop-sm-max']} {
          width: 65%;
        }
        @media ${size['tablet-md-max']} {
          width: 27%;
        }
        @media ${size['tablet-max']} {
          width: 35%;
        }
      }
      .ant-steps.ant-steps-horizontal.ant-steps-small.ant-steps-label-horizontal {
        padding-left: 5px;
        margin-top: 5px;
      }
      .ant-steps-item-icon {
        width: 19px;
        height: 19px;
        margin-right: 0;
        border-color: #be24ff;
        background-color: white;
      }
      .ant-steps-item-title::after {
        top: 10px;
        left: 0;
        background-color: #be24ff;
      }
      span.ant-steps-icon {
        vertical-align: 2px;
        color: #be24ff;
      }
      i.anticon.anticon-check.ant-steps-finish-icon {
        color: #fff;
      }
      .ant-steps-item.ant-steps-item-finish {
        .ant-steps-item-container {
          .ant-steps-item-icon {
            background: ${(props) => props.theme.secondaryColor};
            border-color: ${(props) => props.theme.secondaryColor};
          }
        }
      }
      .ant-steps-item.ant-steps-item-wait {
        margin-right: 0;
      }
      .ant-steps-item.ant-steps-item-process.ant-steps-item-active {
        margin-right: 0;
      }
      i.anticon.anticon-check.ant-steps-finish-icon {
        // display: none;
      }
      .ant-steps-item.ant-steps-item-finish {
        margin-right: 0;
      }
      .ant-progress.ant-progress-line.ant-progress-status-success.ant-progress-show-info.ant-progress-default {
        position: absolute;
        top: 53px;
        width: 45%;
        // width: 81%;
        font-size: 14px;
        @media ${size['desktop-md-max']} {
          width: 50%;
          // width: 92%;
        }
      }
      .ant-progress.ant-progress-line.ant-progress-status-normal.ant-progress-show-info.ant-progress-default {
        position: absolute;
        top: 53px;
        width: 54%;
        // width: 87%;
        font-size: 14px;
        @media ${size['desktop-md-max']} {
          width: 59%;
          // width: 100%;
        }
      }
      span.ant-progress-text {
        display: none;
      }
      .ant-progress-bg {
        background-color: #be24ff;
      }
      a.nav-link.active.sameDay {
        // width: 51px;
        // height: 51px;
        background-color: ${(props) => props.theme.secondaryColor} !important;
      }
      .nav-item {
        padding: 0;
        margin: 0;
        list-style: none;
        .nav-link {
          &.sameDay {
            &:before {
              display: none;
            }
          }
          width: 28px;
          height: 28px;
          padding: 0;
          border: 0;
          -webkit-border-radius: 50%;
          position: relative;
          display: block;
          background-color: #bbbbbb;
          @media ${size['mobile-md-max']} {
            width: 35px;
            height: 35px;
          }
          &:before {
            position: absolute;
            left: -50px;
            width: 52px;
            height: 2px;
            background: #bbbbbb;
            content: '';
            top: 50%;
            cursor: pointer;
            transform: translate(0, -50%);
            -webkit-transform: translate(0, -50%);
            @media ${size['mobile-md-max']} {
              left: -29px;
              width: 30px;
            }
          }
          &.active {
            background-color: ${(props) => props.theme.secondaryColor};
            &:before {
              background-color: ${(props) => props.theme.secondaryColor};
            }
          }
          .check-icon-progress {
            position: absolute;
            right: -21px;
            top: -14px;
            font-size: 28px;
            color: ${(props) => props.theme.primaryButtonColor};
          }
          svg {
            position: absolute;
            // left: 12px;
            // top: 9px;
            left: 7px;
            top: 2px;
            width: 15px;
            @media ${size['mobile-md-max']} {
              left: 7px;
              top: 6px;
            }
          }
          .truck-icon {
            width: 19px;
            height: 24px;
            // left: 11px;
            left: 5px;
            .st0 {
              stroke: #fff;
            }
            @media ${size['mobile-md-max']} {
              left: 6px;
            }
          }

          .payment-icon {
            width: 19px;
            height: 24px;
            left: 5px;
            path {
              stroke: #fff;
            }
            @media ${size['mobile-md-max']} {
              left: 6px;
            }
          }
        }
        .TITLE {
          position: absolute;
          top: 32px;
          left: 40px;
          font-family: 'Montserrat';
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 17px;
          letter-spacing: 0.06em;
          color: #8f48ff;
        }
        &:first-child {
          .nav-link {
            &:before {
              content: none;
            }
          }
        }
        &:not(:first-child) {
          margin-left: 123px;
          @media ${size['mobile-md-max']} {
            margin-left: 24px;
          }
        }
      }
    }
    .ant-steps.ant-steps-horizontal.ant-steps-label-horizontal {
      top: 53px;
      width: 100%;
      @media ${size['desktop-md-max']} {
        width: 56%;
      }
      @media ${size['laptop-sm-max']} {
        width: 65%;
      }
      @media ${size['tablet-md-max']} {
        width: 27%;
      }
      @media ${size['tablet-max']} {
        width: 35%;
      }
    }
    .ant-steps.ant-steps-horizontal.ant-steps-small.ant-steps-label-horizontal {
      padding-left: 5px;
      margin-top: 5px;
    }
    .ant-steps-item-icon {
      width: 19px;
      height: 19px;
      margin-right: 0;
      border-color: #be24ff;
      background-color: white;
    }
    .ant-steps-item-title::after {
      top: 10px;
      left: 0;
      background-color: #be24ff;
    }
    span.ant-steps-icon {
      vertical-align: 2px;
      color: #be24ff;
    }
    i.anticon.anticon-check.ant-steps-finish-icon {
      color: #fff;
    }
    .ant-steps-item.ant-steps-item-finish {
      .ant-steps-item-container {
        .ant-steps-item-icon {
          background: ${(props) => props.theme.secondaryColor};
          border-color: ${(props) => props.theme.secondaryColor};
        }
      }
    }
    .ant-steps-item.ant-steps-item-wait {
      margin-right: 0;
    }
    .ant-steps-item.ant-steps-item-process.ant-steps-item-active {
      margin-right: 0;
    }
    i.anticon.anticon-check.ant-steps-finish-icon {
      // display: none;
    }
    .ant-steps-item.ant-steps-item-finish {
      margin-right: 0;
    }
    .ant-steps.ant-steps-horizontal.ant-steps-label-vertical.ant-steps-dot {
      margin-top: 20px;
      padding-left: 7px;
      position: relative;
      width: 100%;
      @media ${size['desktop-md-max']} {
        width: 56%;
      }
      @media ${size['laptop-sm-max']} {
        width: 65%;
      }
      @media ${size['tablet-md-max']} {
        width: 27%;
      }
      @media ${size['tablet-max']} {
        width: 35%;
      }
    }
    .ant-steps.ant-steps-horizontal.ant-steps-small.ant-steps-label-horizontal {
      padding-left: 10px;
      padding-right: 10px;
      margin: 10px 0;
    }
    .ant-steps-item-content {
      width: 0;
    }
    .ant-steps-item-icon {
      margin: 0;
    }
    .ant-steps-dot .ant-steps-item-process .ant-steps-item-icon,
    .ant-steps-dot.ant-steps-small .ant-steps-item-process .ant-steps-item-icon {
      width: 19px;
      height: 19px;
      line-height: 19px;
      span.ant-steps-icon-dot {
        top: -6px;
      }
    }
    .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
      background-color: ${(props) => props.theme.secondaryColor};
    }
    .ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
      background-color: ${(props) => props.theme.secondaryColor};
    }
    .ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
      background-color: ${(props) => props.theme.secondaryColor};
    }
    .ant-steps-item-tail {
      margin: 0;
      ::after {
        width: calc(100%) !important;
        margin-left: 8px !important;
        background-color: ${(props) => props.theme.secondaryColor};
      }
    }
  }
  .ant-tooltip-inner {
    background: ${(props) => props.theme.secondaryColor};
    padding: 12px;
    strong {
      font-weight: 500;
    }
  }
  .ant-tooltip-arrow::before {
    background-color: ${(props) => props.theme.secondaryColor};
  }
`;

const BookingProgressStyleWrapper = styled.div`
  .booking-breadcrumbs-nav {
    margin-bottom: 15px;
    margin-top: 5px;
    border-bottom: 1px solid ${(props) => props.theme.secondaryColor};
    ul {
      padding: 0;
      margin: 0;
      display: flex;
      flex-wrap: wrap;
      margin-right: -1px;
    }
    li {
      &.custom-width {
        min-width: 16.9%;
        max-width: 16.9%;
        flex-basis: 16.9%;
        span {
          margin-left: 5px;
          font-size: 14px;
        }
        @media ${size['desktop-sm-max']} {
          min-width: 16.9%;
          max-width: 16.9%;
          flex-basis: 16.9%;
        }
      }
      display: inline-block;
      min-width: 19.7%;
      max-width: 19.7%;
      flex-basis: 19.7%;
      padding-bottom: 15px;
      position: relative;
      &:not(.active) {
        top: 13px;
        @media ${size['desktop-sm-max']} {
          top: 0;
        }
      }
      a {
        display: inline-block;
        font-family: ${(props) => props.theme.secondaryFont}, sans-serif;
        font-size: 15px;
        letter-spacing: 0.06em;
        color: ${(props) => props.theme.secondaryColor};
        text-transform: uppercase;
        font-weight: 600;
        &:hover {
          text-decoration: none;
        }
      }
      i {
        width: 38px;
        height: 38px;
        ${borderRadius('50%')}
        background: #DDDDDD;
        display: inline-block;
        position: relative;
        vertical-align: middle;
        &:after {
          position: absolute;
          left: 15px;
          bottom: -18px;
          width: 6px;
          height: 6px;
          background: ${(props) => props.theme.secondaryColor};
          content: '';
          ${borderRadius('50%')}
        }
        &:before {
          position: absolute;
          left: 22px;
          bottom: -16px;
          width: 4px;
          height: 4px;
          background: #fff;
          content: '';
          ${borderRadius('50%')}
          z-index: 1;
          opacity: 0;
        }
        &.bnav-icon1 {
          background: #dddddd url(${BookNav1}) no-repeat center;
          background-size: 23px auto;
        }
        &.bnav-icon2 {
          background: #dddddd url(${BookNav2}) no-repeat center;
          background-size: 15px auto;
        }
        &.bnav-icon3 {
          background: #dddddd url(${BookNav3}) no-repeat center;
          background-size: 16px auto;
        }
        &.bnav-icon4 {
          background: #dddddd url(${BookNav4}) no-repeat center;
          background-size: 20px auto;
        }
        &.route-icon {
          background: #dddddd url(${MapIcon}) no-repeat center;
          background-size: 21px auto;
        }
        &.vehicle-icon {
          background: #dddddd url(${VehicleIcon}) no-repeat center;
          background-size: 21px auto;
        }
        &.bike {
          background: #dddddd url(${Bike}) no-repeat center;
          background-size: 26px auto;
        }
        &.cargo-bike {
          background: #dddddd url(${cargoBike}) no-repeat center;
          background-size: 31px auto;
        }
        &.motor-cycle {
          background: #dddddd url(${motorcycle}) no-repeat center;
          background-size: 31px auto;
        }
        &.car {
          background: #dddddd url(${car}) no-repeat center;
          background-size: 31px auto;
        }
        &.small-van {
          background: #dddddd url(${smallVan}) no-repeat center;
          background-size: 31px auto;
        }
        &.large-van {
          background: #dddddd url(${largevan}) no-repeat center;
          background-size: 31px auto;
        }
        &.bnav-icon5 {
          background: #dddddd url(${BookNav5}) no-repeat center;
          background-size: 18px auto;
        }
        &.bnav-icon6 {
          background: #dddddd url(${BookNav6}) no-repeat center;
          background-size: 18px auto;
        }
        &.bnav-icon7 {
          background: #dddddd url(${BookNav7}) no-repeat center;
          background-size: 18px auto;
        }
      }
      span {
        display: inline-block;
        margin-left: 10px;
        vertical-align: middle;
        ${'' /* display: none; */}
        color: #dddddd
      }
      &:after {
        position: absolute;
        left: 18px;
        bottom: -2px;
        content: '';
        height: 4px;
        background: ${(props) => props.theme.secondaryColor};
        width: 100%;
        z-index: -1;
        opacity: 0;
      }
      &:first-child {
        margin-left: -15px;
        text-align: left;
        &.active {
          &:after {
            width: 0%;
            opacity: 1;
          }
          &.done {
            &:after {
              width: 100%;
            }
          }
        }
      }
      &:last-child {
        text-align: right;
        margin-right: -15px;
        text-align: right;
        min-width: 5%;
        max-width: 5%;
        flex-basis: 5%;
        span {
          margin-left: 0;
          top: 16px;
          right: 50px;
          position: absolute;
        }
        a {
          flex-direction: row-reverse;
        }
        i {
          &:after {
            left: initial;
            right: 15px;
          }
          &:before {
            left: 24px;
            right: 14px;
          }
        }
        &.active {
          &:after {
            width: 35%;
            opacity: 1;
          }
        }
      }
      &.active {
        /* margin-top: -13px; */
        &.done {
          &:after {
            opacity: 1;
          }
        }
        span {
          display: inline-block;
          color: ${(props) => props.theme.secondaryColor};
        }
        i {
          width: 50px;
          height: 51px;
          &.bnav-icon1 {
            background: ${(props) => props.theme.secondaryColor} url(${BookNavHover1}) no-repeat center;
            background-size: 23px auto;
          }
          &.bnav-icon2 {
            background: ${(props) => props.theme.secondaryColor} url(${BookNavHover2}) no-repeat center;
            background-size: 20px auto;
          }
          &.bnav-icon3 {
            background: ${(props) => props.theme.secondaryColor} url(${BookNavHover3}) no-repeat center;
            background-size: 22px auto;
          }
          &.bnav-icon4 {
            background: ${(props) => props.theme.secondaryColor} url(${BookNavHover4}) no-repeat center;
            background-size: 26px auto;
          }
          &.route-icon {
            background: ${(props) => props.theme.secondaryColor} url(${MapIconHover}) no-repeat center;
            background-size: 26px auto;
          }
          &.vehicle-icon {
            background: ${(props) => props.theme.secondaryColor} url(${VehicleIconHover}) no-repeat center;
            background-size: 26px auto;
          }
          &.bike {
            background: ${(props) => props.theme.secondaryColor} url(${BikeHover}) no-repeat center;
            background-size: 34px auto;
          }
          &.motor-cycle {
            background: ${(props) => props.theme.secondaryColor} url(${motorcycleHover}) no-repeat center;
            background-size: 36px auto;
          }
          &.cargo-bike {
            background: ${(props) => props.theme.secondaryColor} url(${cargoBikeHover}) no-repeat center;
            background-size: 36px auto;
          }
          &.car {
            background: ${(props) => props.theme.secondaryColor} url(${carHover}) no-repeat center;
            background-size: 36px auto;
          }
          &.small-van {
            background: ${(props) => props.theme.secondaryColor} url(${smallvanHover}) no-repeat center;
            background-size: 36px auto;
          }
          &.large-van {
            background: ${(props) => props.theme.secondaryColor} url(${largevanHover}) no-repeat center;
            background-size: 36px auto;
          }
          &.bnav-icon5 {
            background: ${(props) => props.theme.secondaryColor} url(${BookNavHover5}) no-repeat center;
            background-size: 24px auto;
          }
          &.bnav-icon6 {
            background: ${(props) => props.theme.secondaryColor} url(${BookNavHover6}) no-repeat center;
            background-size: 24px auto;
          }
          &.bnav-icon7 {
            background: ${(props) => props.theme.secondaryColor} url(${BookNavHover7}) no-repeat center;
            background-size: 24px auto;
          }
          &:after {
            width: 18px;
            height: 18px;
            bottom: -23px;
          }
          &:before {
            opacity: 1;
          }
        }
      }
      &.third-tag {
        &:after {
          left: 20px;
        }
      }
      &.completed {
        margin-top: 0px;
        &:after {
          bottom: 10px;
        }
        i {
          &.bnav-icon1 {
            background: ${(props) => props.theme.greyPinkShadeColor1} url(${BookNavCompleted1}) no-repeat center;
            background-size: 17px auto;
          }
          &.bnav-icon2 {
            background: ${(props) => props.theme.greyPinkShadeColor1} url(${BookNavCompleted2}) no-repeat center;
            background-size: 15px auto;
          }
          &.bnav-icon3 {
            background: ${(props) => props.theme.greyPinkShadeColor1} url(${BookNavCompleted3}) no-repeat center;
            background-size: 16px auto;
          }
          &.bnav-icon4 {
            background: ${(props) => props.theme.greyPinkShadeColor1} url(${BookNavCompleted4}) no-repeat center;
            background-size: 21px auto;
          }
          &.route-icon {
            background: ${(props) => props.theme.greyPinkShadeColor1} url(${MapIconCompleted}) no-repeat center;
            background-size: 21px auto;
          }
          &.vehicle-icon {
            background: ${(props) => props.theme.greyPinkShadeColor1} url(${VehicleIconCompleted}) no-repeat center;
            background-size: 21px auto;
          }
          &.bike {
            background: ${(props) => props.theme.greyPinkShadeColor1} url(${BikeCompleted}) no-repeat center;
            background-size: 34px auto;
          }
          &.cargo-bike {
            background: ${(props) => props.theme.greyPinkShadeColor1} url(${cargoBikeCompleted}) no-repeat center;
            background-size: 36px auto;
          }
          &.motor-cycle {
            background: ${(props) => props.theme.greyPinkShadeColor1} url(${motorcycleCompleted}) no-repeat center;
            background-size: 36px auto;
          }
          &.car {
            background: ${(props) => props.theme.greyPinkShadeColor1} url(${carCompleted}) no-repeat center;
            background-size: 36px auto;
          }
          &.small-van {
            background: ${(props) => props.theme.greyPinkShadeColor1} url(${smallvanCompleted}) no-repeat center;
            background-size: 36px auto;
          }
          &.large-van {
            background: ${(props) => props.theme.greyPinkShadeColor1} url(${largevanCompleted}) no-repeat center;
            background-size: 36px auto;
          }
          &.bnav-icon5 {
            background: ${(props) => props.theme.greyPinkShadeColor1} url(${BookNavCompleted5}) no-repeat center;
            background-size: 18px auto;
          }
          &.bnav-icon6 {
            background: ${(props) => props.theme.greyPinkShadeColor1} url(${BookNavCompleted6}) no-repeat center;
            background-size: 18px auto;
          }
          &.bnav-icon7 {
            background: ${(props) => props.theme.greyPinkShadeColor1} url(${BookNavCompleted7}) no-repeat center;
            background-size: 18px auto;
          }

          &:after {
            width: 18px;
            height: 18px;
            bottom: -23px;
            left: 10px;
            background: ${(props) => props.theme.inputActiveBgColor};
          }
          &:before {
            opacity: 1;
            bottom: -29px;
            left: 14px;
            background: url(${BookNavCheck}) no-repeat center;
            background-size: 100% auto;
            width: 10px;
            height: 10px;
            bottom: -19.5px;
          }
        }
        &:after {
          width: 100%;
          opacity: 1;
        }
      }
      &.custom {
        margin-top: 0px;
        &:after {
          bottom: 10px;
        }
        i {
          &.bnav-icon2 {
            background: ${(props) => props.theme.pinkColor1} url(${BookNavHover2}) no-repeat center;
            background-size: 15px auto;
          }
          &.bnav-icon4 {
            background: ${(props) => props.theme.pinkColor1} url(${BookNavHover4}) no-repeat center;
            background-size: 21px auto;
          }
          &.vehicle-icon {
            background: ${(props) => props.theme.pinkColor1} url(${VehicleIconHover}) no-repeat center;
            background-size: 21px auto;
          }
          &.bike {
            background: ${(props) => props.theme.pinkColor1} url(${BikeHover}) no-repeat center;
            background-size: 30px auto;
          }
          &.cargo-bike {
            background: ${(props) => props.theme.secondaryColor} url(${cargoBikeHover}) no-repeat center;
            background-size: 30px auto;
          }
          &.motor-cycle {
            background: ${(props) => props.theme.secondaryColor} url(${motorcycleHover}) no-repeat center;
            background-size: 30px auto;
          }
          &.car {
            background: ${(props) => props.theme.secondaryColor} url(${carHover}) no-repeat center;
            background-size: 30px auto;
          }
          &.small-van {
            background: ${(props) => props.theme.secondaryColor} url(${smallvanHover}) no-repeat center;
            background-size: 30px auto;
          }
          &.large-van {
            background: ${(props) => props.theme.secondaryColor} url(${largevanHover}) no-repeat center;
            background-size: 30px auto;
          }
          &.bnav-icon3 {
            background: ${(props) => props.theme.pinkColor1} url(${BookNavHover3}) no-repeat center;
            background-size: 16px auto;
          }
          &.route-icon {
            background: ${(props) => props.theme.pinkColor1} url(${MapIconHover}) no-repeat center;
            background-size: 21px auto;
          }
          &.bnav-icon5 {
            background: ${(props) => props.theme.pinkColor1} url(${BookNavHover5}) no-repeat center;
            background-size: 21px auto;
          }
          &:after {
            width: 18px;
            height: 18px;
            bottom: -23px;
            left: 10px;
            background: ${(props) => props.theme.pinkColor1};
          }
          &:before {
            opacity: 1;
            bottom: -29px;
            left: 14px;
            background: url(${BookNavCheck}) no-repeat center;
            background-size: 100% auto;
            width: 10px;
            height: 10px;
            bottom: -19.5px;
          }
        }
        &:after {
          width: 0;
          opacity: 1;
        }
      }
    }
  }
  /* 1399 */
  @media ${size['desktop-sm-max']} {
    .booking-breadcrumbs-nav {
      ul {
        margin-right: -5px;
      }
      li {
        min-width: 19.4%;
        max-width: 19.4%;
        flex-basis: 19.4%;
        span {
          margin-left: 10px;
          font-size: 12px;
        }
        i {
          &:after {
            bottom: -18px;
            left: 9px;
          }
          &:before {
            bottom: -18px;
            left: 16px;
          }
        }
        &:not(.active) {
          i {
            &:after {
              left: 16px;
            }
          }
          &:last-child {
            &:after {
              height: 0px;
            }
            i {
              &:after {
                left: 19px;
              }
            }
          }
        }
        &:first-child {
          margin-left: -10px;
        }
        &:last-child {
          i {
            &:after {
              left: 12px;
            }
            &:before {
              left: 19px;
              right: 15px;
            }
          }
          &:after {
            width: 0 !important;
          }
        }
        &.completed,
        &.active {
          margin-top: -2px;
          i {
            width: 40px;
            height: 40px;
            &:after {
              bottom: -24px;
            }
          }
        }
        &.completed {
          &:after {
            bottom: -2px;
          }
          i {
            &:before {
              left: 13px;
              bottom: -19.5px;
            }
            &:after {
              left: 9px;
            }
          }
        }

        &.custom {
          &:not(.active) i:after {
            left: 10px;
          }
        }

        &.custom-width {
          min-width: 16.4%;
          max-width: 16.4%;
          flex-basis: 16.4%;
          span {
            margin-left: 3px;
            font-size: 11px;
          }
        }
      }
    }
  }
  /* 1199 */
  @media ${size['laptop-max']} {
    .booking-breadcrumbs-nav {
      li {
        span {
          margin-left: 10px;
          font-size: 15px;
        }
        &.custom-width {
          span {
            margin-left: 10px;
            font-size: 15px;
          }
        }
      }
    }
  }
  /* 991 */
  @media ${size['tablet-md-max']} {
    .booking-breadcrumbs-nav {
      li {
        min-width: 19.5%;
        max-width: 19.5%;
        flex-basis: 19.4%;

        i {
          &:after {
            bottom: -18px;
            left: 9px;
          }
          &:before {
            bottom: -18px;
            left: 16px;
          }
        }
        &:not(.active) {
          i {
            &:after {
              left: 16px;
            }
          }
          &:last-child {
            &:after {
              height: 0px;
            }
          }
        }
        &:first-child {
          margin-left: -10px;
        }
        &:last-child {
          margin-right: -10px;
          i {
            &:after {
              left: 21px;
            }
            &:before {
              right: 15px;
            }
          }
        }
        &.completed,
        &.active {
          margin-top: -2px;
          i {
            width: 40px;
            height: 40px;
            &:after {
              bottom: -24px;
            }
          }
        }
        &.completed {
          &:after {
            bottom: -2px;
          }
          i {
            &:before {
              left: 13px;
              bottom: -19.5px;
            }
            &:after {
              left: 9px;
            }
          }
        }
        &.custom-width {
          span {
            margin-left: 5px;
            font-size: 12px;
          }
        }
      }
    }
  }
  /* 767 */
  @media ${size['tablet-max']} {
    .booking-breadcrumbs-nav {
      li {
        min-width: 19.5%;
        max-width: 19.5%;
        flex-basis: 19.5%;
        span {
          display: none;
        }
        &.active {
          span {
            display: none;
          }
        }
        &:first-child {
          margin-left: -10px;
        }
        &:last-child {
          margin-right: 0;
          i {
            margin-right: -35px;
            &:after {
              right: 12px;
            }
          }
        }
      }
    }
  }
`;
const BookingCSVWrapper = styled.div`
  position: relative;
  .ant-card .ant-card-body {
    margin-top: 0;
  }
`;

const OverViewActionButtonWrapper = styled.div`
  position: relative;
  margin: 26.5px 2px !important;
  .collpase-all-parcel-icon-wrap {
    color: white;
    position: absolute;
    top: -56px;
    right: 82px;
    height: 38px;
    background: ${(props) => props.theme.primaryColor};
    ${borderRadius('6px')};
    ${transition(0.4)};
    border: none;
    padding: 7px;
    svg {
      display: inline-block;
      vertical-align: middle;
      path {
        stroke: #fff;
      }
    }
    @media ${size['laptop-max']} {
      top: -40px;
    }
    &.collpase-parcel-purple-bg {
      background: ${(props) => props.theme.secondaryColor};
    }
    .map-cube {
      margin-right: 6px;
    }
  }
  .filter {
    right: 90px;
  }
  .map {
    right: 7px;
  }
`;

const VehicleImage = styled.img`
  margin: auto;
  width: ${(props) => props.width};
`;
const VehicleSelectionWrapper = styled.div`
  /* height: 165px; */
  left: 1096px;
  top: 560px;
  /* @media ${size['desktop-sm-max']} {
    height: 140px;
  } */
  &.jobTracker {
    font-weight: 500;
    text-transform: capitalize;
    span {
      font-weight: 500;
      text-transform: capitalize;
    }
  }
  span {
    font-family: ${(props) => props.theme.primaryFont};
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    margin: 10px !important;
    line-height: 15px;
    text-transform: uppercase;
    color: #323232;
  }
  p {
    font-family: ${(props) => props.theme.secondaryFont};
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    margin: 10px !important;
    line-height: 20px;
    color: #323232;
  }
  .vehicle-data-wrapper {
    margin-top: 10px;
    display: block;
    div {
      display: inline-block;
      & + div {
        float: right;
        text-transform: none;
      }
    }
    .date-time {
      span,
      p {
        margin-right: 0 !important;
        margin-left: 0 !important;
      }
    }
  }
  .vehicle-group {
    display: flex;
    overflow: auto;
    /* cursor: pointer; */
    .vehicle-link {
      background: #fff;
      border-radius: 4.26868px;
      border: 1px solid #d1d1d1;
      position: absolute;
      right: 0;
      font-weight: 600;
      font-size: 12px;
      color: #323232;
      text-transform: uppercase;
      height: 46px;
      line-height: 46px;
      display: inline-block;
      text-align: center;
      width: auto;
      padding: 0 20px;
      min-width: 1px;
      @media ${size['desktop-sm-max']} {
        margin-top: -8px;
      }
      &:hover {
        border: 1px solid #00c68d;
      }
      .new-edit-icon {
        display: inline-block;
        vertical-align: middle;
        background: url(${editPen}) no-repeat center;
        background-size: 100% auto;
        width: 19px;
        height: 19px;
        margin: -2px 5px 0 0;
        transition: all 0.4s ease;
      }
    }
    .edit-btn-pen {
      width: 15px;
      height: 20px;
      position: absolute;
      right: 30px;
      cursor: pointer;
    }
    .edit-btn {
      width: 20px;
      height: 20px;
      position: absolute;
      right: 30px;
      cursor: pointer;
      path {
        fill: #000;
      }
    }
    .vehicles {
      height: 27px;
      left: 1148px;
      top: 453px;
      margin: auto;
      content: '';
      clear: both;
      display: table;
      padding-left: 0;
      cursor: pointer;
      @media ${size['desktop-md-max']} {
        margin-right: 30px;
      }
      @media (max-width: 1360px) {
        margin-right: 23px;
      }
      &.details-side {
        margin-right: 45px;
      }
      p {
        text-align: center;
        font-family: ${(props) => props.theme.secondaryFont};
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 12px;
        letter-spacing: 0.05em;
        color: #49465d;
        margin-left: 0;
        margin-right: 0;
      }
      li.border.d-flex {
        width: 70px;
        height: 70px;
        left: 1129px;
        text-align: center;
        margin: auto;
        align-content: center;
        top: 424px;
        &[disabled] {
          cursor: not-allowed;
        }
      }
      .border {
        border-radius: 96px;
        background: #be24ff;
        border: 5px solid #be24ff;
        &[disabled] {
          background: #d1d1d1;
          border: 5px solid #d1d1d1;
        }
      }
      .active {
        background: #8f48ff;
        border: 5px solid #8f48ff;
      }
      .vehicle-img {
        margin: auto;
        width: 100%;
      }
      .vehicle-name {
        font-family: 'Montserrat';
        font-weight: 600;
        width: 100%;
        margin-left: 0 !important;
      }
    }
    .vehicles:first-child {
      margin-left: 0;
    }
    .vehicles:last-child {
      margin-right: 0;
    }
  }
  .ant-tooltip-inner {
    background: #be24ff;
    padding: 12px;
    strong {
      font-weight: 500;
    }
  }
  .ant-tooltip-arrow::before {
    background-color: #be24ff;
  }
`;

const OrderPreviewWrapper = styled.div`
  position: relative;
  transform: translateX(0);
  ${transition(0.5)};
  &.slide{
  transform: translateX(calc(-105%));
  z-index: 99999;
  }
  &.booking-detail-main-wrapper {
    z-index: 3;
    width: 100%;
    .card-custom {
      .ant-card-head {
        padding: 12px 30px;
        .card-header {
            h5.card-title {
              font-size: 16px;
            }
        }
      }
    }
  }
  .vehicle-selection-order-preivew-header {
    .right-panel.fill-panel {
      min-width: 1px;
    text-align: right;
    padding-right: 0;
    display: block;
    line-height: 30px;
    width: fit-content;
    }
  }

  .ant-tabs-bar.ant-tabs-top-bar {
    @media ${size['laptop-md-max']} {
      padding: 0 10px !important;
    }
  }

  /* .pulse-animation {
    animation: pulse-animation 3s;
    margin-bottom: 10px !important;
  }

  @keyframes pulse-animation {
    0% {
      box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);
      background: #8f48ff21;
    }
    100% {
      box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
      background:#8f48ff21;
    }
  } */
  .ant-tabs-nav.ant-tabs-nav-animated div:first-child {
    display: flex;
  }
  .ant-tabs-nav.ant-tabs-nav-animated .ant-tabs-tab {
    @media ${size['desktop-md-max']} {
      margin-right: 15px !important;
    }
    @media ${size['laptop-sm-max']} {
      padding-right: 10px !important;
      padding-left: 10px !important;
      margin-right: 10px !important;
      font-size: 10px !important;
    }
    @media ${size['mobile-md-max']} {
      padding-right: 1px !important;
      margin-right: 5px !important;
      padding-left: 1px !important;
    }
  }
  .ant-tabs-nav-wrap {
    overflow: hidden !important;
    .ant-tabs-nav-scroll {
      margin-top: 0px;
      // padding-top: 20px;
      overflow: hidden !important;
      @media ${size['desktop-md-min']} {
        padding-top: 0px;
      }
      .ant-tabs-nav {
        & > div {
          &:first-child {
            margin-left: 10px;
          }
        }
      }
    }
  }

  .middle-field {
    .form-group {
      width: unset;
      margin: 30px 0 0 0;
      padding-bottom: 10px;
    }
  }

  .ant-dropdown {
    ul {
      transform: none !important;
      right: 0;
      left: auto !important;
      top: 30px !important;
      width: 285px !important;
      background: #fff;
      box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
      border-radius: 7px;
      -webkit-border-radius: 7px;
      -moz-border-radius: 7px;
      border: 0;
      padding: 10px 0;
      margin: 0;
      list-style-type: none;
      padding-left: 0;
      &.location-item-menus {
        width: 100% !important;
        .ant-menu-item {
          align-items: baseline !important;
        }
      }
      li.ant-menu-item:hover {
        background: #fafafa;
        font-weight: 700 !important;
        color: rgba(0, 0, 0, 0.65);
        i.ant-menu-submenu-arrow::after,
        i.ant-menu-submenu-arrow::before {
          background: rgba(0, 0, 0, 0.65);
        }
      }
      li {
        padding: 12px 35px !important;
        font-weight: normal;
        font-size: 18px;
        line-height: 20px;
        :hover {
          background: #fafafa;
          font-weight: 700 !important;
          .parcel-toggle-btn{
            background: #E8286B;
          }
          i.ant-menu-submenu-arrow {
            transform: rotate(180deg);
            color: rgba(0, 0, 0, 0.65);
          }
        }
        &.ant-menu-submenu:first-child {
          padding: 0 !important;
        }
        .ant-menu-submenu-title {
          font-weight: normal;
          font-size: 18px;
          padding: 0 35px !important;
          &:hover {
            color: rgba(0, 0, 0, 0.65);
            i.ant-menu-submenu-arrow::after,
            i.ant-menu-submenu-arrow::before {
              background: rgba(0, 0, 0, 0.65);
            }
          }
        }
      }
    }
  }
  .ant-card-head{
    z-index: 2;
  }
  .ant-card-body {
    // max-height: 600px;
    // overflow: auto;
    padding: 30px 30px 0 30px !important;
    ::-webkit-scrollbar {
      width: 5px;
      height: 5px;
    }
    ::-webkit-scrollbar-track {
      box-shadow: none;
      border-radius: 10px;
      -webkit-border-radius: 10px;
      -moz-border-radius: 10px;
    }
    ::-webkit-scrollbar-thumb {
      background: #8f48ff;
      border-radius: 10px;
      -webkit-border-radius: 10px;
      -moz-border-radius: 10px;
    }
    ::-webkit-scrollbar-thumb:hover {
      background: #8f48ff;
    }
  }
}
.side-grp-btn {
  display: flex;
  align-items: center;
  position: relative;
}
.map-icon-head {
  display: flex;
  position: absolute;
  top: -56px;
  background: ${(props) => props.theme.primaryColor};
  ${borderRadius('6px')};
  ${transition(0.4)};
  border: none;
  padding: 7px;
  right: 0;
  svg {
    display: inline-block;
    vertical-align: middle;
  }
  .map-text {
    margin-left: 7px;
    padding-top: 2px;
    color: white !important;
  }
  @media ${size['laptop-max']} {
    top: -40px;
  }
  &.map-purple-bg {
    background: ${(props) => props.theme.secondaryColor};
  }
}
.map-popover-main {
  left: 0.172px !important;
  top: -10px !important;
  border-radius: 8px;
  width: 100%;
  .ant-popover-inner-content  {
     padding: 0;
     height: calc(100vh - 228px);
    max-height: calc(100vh - 240px);
    min-height: 1px;

    div{
      padding: 0;
    height: calc(100vh - 228px);
    max-height: calc(100vh - 240px);
    min-height: 1px;
    }
  }
}
`;

const EmptyBlock = styled.div`
  height: 75px;
`;

export {
  AddressWrapper,
  BookingProgressStyleWrapper,
  RoutePreviewSameDay,
  RouteParcelStorePreview,
  RoutePreviewShifts,
  OrderPreviewWrapper,
  BookingCSVWrapper,
  BookingDetailsWrapper,
  OverViewActionButtonWrapper,
  RoutePreviewDiv,
  InputWrapper,
  RoutePreview,
  BookingParcelsItemsWrapper,
  VehicleSelectionWrapper,
  VehicleImage,
  ProgressWrapper,
  EmptyBlock,
};

