import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { BreadCrumbConstant } from './constant';
import { Wrapper } from './style';
class BreadCrumb extends Component {
  render() {
    const { active } = this.props;
    return (
      <Wrapper className="breadcrumb-main" aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className={`breadcrumb-item ${active === 'login' ? 'active' : ''}`}>
            <Link to="/login">{BreadCrumbConstant.login}</Link>
          </li>
          {/* <li className={`breadcrumb-item ${active === 'signup' ? 'active' : ''}`}>
            <Link to="/register">{BreadCrumbConstant.signUp}</Link>
          </li> */}
        </ol>
      </Wrapper>
    );
  }
}
BreadCrumb.defaultProps = {
  active: 'login',
};
export default BreadCrumb;
