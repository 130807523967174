import styled from 'styled-components';
import { size } from 'App/device';

const FormWrapper = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
  border-radius: 30px 0 0 30px;
  background: #4a465d;
  .input-group-prepend {
    display: flex;
    width: 55px;
    border-right: 1px solid #5f5c72;
    background: #4a465d;
    z-index: 2;
    border-radius: 30px 0 0 30px;
    margin: 0 !important;
    .input-group-text {
      display: flex;
      align-items: center;
      padding: 0.375rem 0.75rem;
      margin-bottom: 0;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5;
      color: #495057;
      text-align: center;
      white-space: nowrap;
      background-color: #e9ecef;
      border-radius: 0.25rem;
      width: 100%;
      background: none;
      border: 0;
      align-content: center;
      justify-content: center;
      .input-icon {
        width: 20px;
        height: 26px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100% auto;
      }
    }
  }
  .ant-calendar-picker-container {
    z-index: 1000;
    top: 46px;
  }
  .custom-datepicker {
    width: 100%;
    & .ant-calendar-picker-icon,
    .ant-calendar-picker-clear {
      display: none;
    }
    & .ant-calendar-picker-input {
      display: block;
      width: 100%;
      line-height: 1.5;
      margin: 0;
      -webkit-appearance: none;
      background: #fff;
      border: 0;
      border-radius: 5px;
      -webkit-border-radius: 5px;
      height: 58px;
      font-size: 18px;
      font-weight: 300;
      padding: 10px 30px;
      color: #979797;
      font-family: ${props => props.theme.primaryFont}, sans-serif;
      box-shadow: 10px 10px 25px rgba(0, 0, 0, 0.1);
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      ::placeholder {
        color: #979797;
      }
      :-ms-input-placeholder {
        color: #979797;
      }
      ::-ms-input-placeholder {
        color: #979797;
      }
      :hover,
      :focus {
        outline: none;
        box-shadow: 10px 10px 25px rgba(0, 0, 0, 0.1);
      }
      @media ${size['desktop-sm-max']} {
        font-size: 16px;
        height: 44px;
        padding: 10px 20px;
      }
      @media ${size['tablet-max']} {
        font-size: 14px;
      }
    }
  }
  .custom-datepicker-dropdown {
    & .ant-calendar {
      background: #fff;
      box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15);
      border-radius: 5px;
      -webkit-border-radius: 5px;
      -moz-border-radius: 5px;
      padding: 30px;
      border: 0;
      font-family: ${props => props.theme.secondaryFont}, sans-serif;
      color: #49465d;
      font-size: 15px;
      width: auto;
      & .ant-calendar-header {
        border-bottom: 0px;
        height: 30px;
        & a.ant-calendar-prev-year-btn,
        a.ant-calendar-next-year-btn {
          display: none;
        }
        & .ant-calendar-prev-month-btn::before,
        .ant-calendar-prev-month-btn::after,
        .ant-calendar-next-month-btn::before,
        .ant-calendar-next-month-btn::after {
          width: 12px;
          height: 12px;
        }
        & .ant-calendar-prev-month-btn:hover::before,
        .ant-calendar-prev-month-btn:hover::after,
        .ant-calendar-next-month-btn:hover::before,
        .ant-calendar-next-month-btn:hover::after {
          border-color: white;
        }
        & .ant-calendar-prev-month-btn::before:hover {
          color: rgb(255, 255, 255);
        }
        & .ant-calendar-my-select {
          & .ant-calendar-month-select,
          .ant-calendar-year-select {
            //month select
          }
        }
      }
      & .ant-calendar-body {
        & .ant-calendar-column-header {
          & .ant-calendar-column-header-inner {
            font-weight: 500;
          }
        }
        & .ant-calendar-tbody {
          & .ant-calendar-last-month-cell {
            & .ant-calendar-date {
              color: #828097;
              opacity: 0.5;
              // color: #777777;
              // font-weight: normal;
              // font-size: 15px;
            }
          }
          & .ant-calendar-date {
            width: 35px;
            height: 35px;
            margin: 0 auto;
            padding: 8px;
            color: rgba(0, 0, 0, 0.65);
            line-height: normal;
            text-align: center;
            background: transparent;
            border: 1px solid transparent;
            border-radius: 4px;
            -webkit-transition: background 0.3s ease;
            transition: background 0.3s ease;
            display: table-cell;
            position: relative;
            border: none;
          }
          & .ant-calendar-cell {
            padding: 0px;
            font-weight: 500;
            border-radius: 4px;
          }
          &
            .ant-calendar-cell:not(.ant-calendar-last-month-cell):not(.ant-calendar-next-month-btn-day) {
            & .ant-calendar-date {
              color: #777777;
              font-weight: normal;
              font-size: 15px;
            }
          }
          & .ant-calendar-cell:hover {
            background: #ffa100;
            & .ant-calendar-date {
              color: #fff !important;
            }
            & .ant-calendar-date:hover {
              box-shadow: none;
            }
          }
          & .ant-calendar-selected-day {
            background: #ffa100;
            z-index: 10;
            position: relative;
            & .ant-calendar-date {
              background: #ffa100;
              border-color: #ffa100;
              color: #fff !important;
              font-weight: bold !important;
              font-size: 15px !important;
              &:after {
                content: '';
                position: absolute;
                background: #e27c17;
                box-shadow: 0px 4px 4px rgba(226, 124, 23, 0.25);
                border-radius: 4px;
                width: 35px;
                height: 35px;
                left: 3px;
                top: 3px;
                z-index: -11;
              }
            }
            & .ant-calendar-date:hover {
              box-shadow: none;
              border-radius: 4px;
              -webkit-border-radius: 4px;
            }
          }

          & .ant-calendar-next-month-btn-day {
            & .ant-calendar-date {
              color: #828097;
              opacity: 0.5;
            }
          }
          & .ant-calendar-date:hover {
            box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
            border-radius: 5px;
            -webkit-border-radius: 5px;
            -moz-border-radius: 5px;
            background: #ffa100;
            color: #fff;
          }
        }
      }
      & .ant-calendar-footer {
        display: none;
      }
    }
    .ant-calendar-input-wrap {
      display: none;
    }
  }
  &.light-input-wrapper {
    background: #fff;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    // border-radius: 30px 0 0 30px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
    .input-group-prepend {
      border-right: 1px solid rgba(198, 198, 198, 0.5);
    }
    .custom-datepicker {
      .ant-calendar-picker-input {
        background: #fff;
        color: #828097;
        &::-webkit-input-placeholder {
          color: #828097;
          opacity: 1;
        }
        &:-moz-placeholder {
          color: #828097;
          opacity: 1;
        }
        &::-moz-placeholder {
          color: #828097;
          opacity: 1;
        }
        &:-ms-input-placeholder {
          color: #828097;
          opacity: 1;
        }
      }
    }
  }
`;

export { FormWrapper };
