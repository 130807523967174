export const CHANGE_THEME = 'CHANGE_THEME';
export const GOOGLE_INITIALIZED = 'GOOGLE_INITIALIZED';
export const ERROR_HANDLER = 'ERROR_HANDLER';
export const ERROR_NOT_AUTHORIZED = 'ERROR_NOT_AUTHORIZED';
export const ERROR_EMPTY = 'ERROR_EMPTY';
export const REDIRECT_LOGIN = 'REDIRECT_LOGIN';
export const LOADER_STATUS = 'LOADER_STATUS';
export const INCREMENT_LOADER = 'INCREMENT_LOADER';
export const DECREMENT_LOADER = 'DECREMENT_LOADER';
export const SCREEN_WIDTH_CHANGE = 'SCREEN_WIDTH_CHANGE';
export const PENDING_TERMS = 'PENDING_TERMS';
export const ACCEPT_TERMS = 'ACCEPT_TERMS';
export const PUBLIC_COURIER_MANIFEST = 'PUBLIC_COURIER_MANIFEST';
export const PUBLIC_COURIER_LOCATION = 'PUBLIC_COURIER_LOCATION';
export const PUBLIC_COURIER_DETAILS = 'PUBLIC_COURIER_DETAILS';
export const GET_PUBLIC_JOB = 'GET_PUBLIC_JOB';
export const GET_PUBLIC_PARCEL = 'GET_PUBLIC_PARCEL';
export const RATE_COURIER_FEEDBACK_PUBLIC = 'RATE_COURIER_FEEDBACK_PUBLIC';
