import styled from 'styled-components';
import dropdown from 'assets/images/dropdown-arrow.svg';
import { size } from 'App/device';
import { borderRadius } from 'App/mixins';

const FormWrapper = styled.div`
  display: block;
  width: 100%;
  background: #fff;
  border: 0;
  line-height: 46px;
  position: relative;
  cursor: pointer;
  line-height: 1.5;
  margin: 0;
  -webkit-appearance: none;
  font-size: 18px;
  font-weight: 300;
  #form-select {
    ${borderRadius('0px')};
    color: ${props => props.theme.primaryColor};
    font-family: ${props => props.theme.secondaryFont}, sans-serif;
    height: 40px;
    display: contents;
    @media ${size['desktop-sm-max']} {
      height: 38px;
    }
  }
  &.error-wrapper {
    box-shadow: 0px 0px 5px #d6181a !important;
    border-radius: 0px !important;
    border: 1px solid #d6181a !important;
  }
  @media ${size['desktop-sm-max']} {
    font-size: 16px;
  }
  @media ${size['desktop-mm-min']} {
    height: auto;
  }
  @media ${size['tablet-max']} {
    font-size: 14px;
  }
  .weight-dropdown {
    // display: block !important;
  }
  .select-custom {
    display: block;
    width: 100%;
    height: 100%;
    padding: 0 10px 0 10px;
    background: transparent;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    -o-appearance: none;
    position: relative;
    z-index: 2;
    cursor: pointer;
    border: 1.5px solid #D1D1D1;
    border-radius: 5px;
    color: #fff;
    & .ant-select-selection {
      color: #49465d;
      background-color: transparent;
      border: none;
      box-shadow: none;
      height: 100%;
      &:focus,
      &:active {
        box-shadow: none;
      }
      &:after {
        border: none;
        background: url(${dropdown}) no-repeat center;
        background-size: 100% auto;
        width: 9px;
        height: 8px;
        position: absolute;
        content: '';
        right: 0px;
        top: 50%;
        z-index: 1;
        transform: translate(0, -50%);
        -webkit-transform: translate(0, -50%);
        -moz-transform: translate(0, -50%);
        -ms-transform: translate(0, -50%);
      }
      & .ant-select-selection__rendered {
        height: 100%;
        .custom-size {
          padding-top: 10px;
          @media ${size['laptop-sm-max']} {
            padding-top: 5px;
          }
          @media ${size['tablet-max']} {
            padding-top: 0;
          }
        }
        .dropdown-item {
          @media ${size['desktop-mm-max']} {
            padding-top: 5px !important;
          }
          @media ${size['laptop-max']} {
            margin-top: 0 !important;
          }
          @media ${size['laptop-sm-max']} {
            margin-top: -5px !important;
          }
        }
        & .ant-select-selection__placeholder {
          color: #9e9ca9;
          -webkit-font-smoothing: auto;
          font-weight: 500;
        }
        & .ant-select-selection-selected-value {
          display: block;
          opacity: 1;
          font-family: 'Montserrat';
          font-style: normal;
          font-weight: 600 !important;
          font-size: 14px !important;
          letter-spacing: 0.06em;
          color: #444444 !important;
          line-height: 2.7 !important;
          display: flex;
          height: 100%;
          font-size: 18px;
          font-weight: 300;
          color: ${props => props.theme.primaryColor};
          align-items: center;
          @media ${size['desktop-sm-max']} {
            font-size: 16px;
          }
          @media ${size['tablet-max']} {
            font-size: 14px;
            padding: 5px 0;
            // line-height: 1;
          }
          p {
            color: #49465d;
            margin: 0;
            display: flex;
            height: 100%;
            align-items: center;
          }
          & p:last-child {
            display: none;
          }
          .size-name {
            display: none;
          }
        }
      }
    }
  }
  .size-name {
    font-size: 16px !important;
    margin-bottom: 0;
  }
  .custom-size {
    font-size: 16px !important;
    display: block !important;
  }
  .size-dropdown {
    width: 400px !important;
    left: 24.766px !important;
    @media ${size['desktop-sm-max']} {
      left: 14.766px !important;
    }
  }
  .dropdown-custom {
    background: ${props => props.theme.backgroundColor};
    color: ${props => props.theme.primaryColor};
    & ul {
      // max-height: 328px;
      padding: 0px 10px;
      &::-webkit-scrollbar {
        width: 0.5em;
      }
      &::-webkit-scrollbar-thumb {
        height: 88px;
        width: 8px;
        border-radius: 10px;
        background: #ffa100;
      }
      li {
        margin: 0;
        padding: 10px 0;
        position: relative;
        text-align: left;
        font-size: 15px;
        color: #fff;
        line-height: 18px;
        cursor: pointer;
        padding-left: 15px;
        padding-right: 10px;
        margin: 0;
        border-radius: 5px;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        color: ${props => props.theme.selectFontColor};
        @media ${size['desktop-sm-max']} {
          padding: 5px 6px;
        }
        &.ant-select-dropdown-menu-item-selected {
          background-color: rgba(255, 161, 0, 0.68);
          // opacity: 0.5;
          margin-bottom: 3px;
          margin-top: 3px;
          color: #fff;
          font-weight: 500;
          .anticon svg {
            display: inline-block;
          }
        }
        &.ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled) {
          background-color: #ffa100;
          color: #fff;
        }
        &.ant-select-dropdown-menu-item:hover:not(.ant-select-dropdown-menu-item-disabled) {
          background-color: #ffa100;
          color: #fff;
        }
        .anticon svg {
          display: none;
        }
      }
    }
  }
`;
export { FormWrapper };
