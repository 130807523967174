import React, { Component } from 'react';
import { Form, Row, Col, Spin, notification } from 'antd';
import { Formik } from 'formik';
import qs from 'qs';
import * as Yup from 'yup';
import isEmpty from 'lodash.isempty';
import logoShapeDark from 'assets/images/logo-shape-dark.svg';
import { connect } from 'react-redux';
import { changePassword } from 'redux/changePassword/actions';
import AuthHeader from 'components/AuthHeader';
import AuthWrapper from 'components/AuthWrapper';
import Footer from 'components/Footer';
import { onError } from 'modules/errorHandler';
import { FormButton as Button, FormItem, PasswordInput, FormGroup, Label } from 'components/Form';
import { FormValidation } from 'App/AppConstant';
import { ChangeConstant } from './constant';
import { ChangePasswordWrapper, ChangePasswordDiv, FormStyling } from './changePassword.style';
const confirmSchema = Yup.object().shape({
  password: Yup.string()
    .min(6, FormValidation.passwordMin)
    .required(FormValidation.passwordRequired),
  code: Yup.string().required(FormValidation.codeRequired),
  confirm_password: Yup.string()
    .min(6, FormValidation.passwordMin)
    .required(FormValidation.passwordRequired)
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
});
class ChangePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      urlCode: '',
      email: '',
      codeHide: true
    };
  }
  componentDidMount() {
    let { codeHide } = this.state;
    let prefixed = qs.parse(this.props.history.location.search, {
      ignoreQueryPrefix: true
    });
    if (!isEmpty(this.props.history.location) && !isEmpty(this.props.history.location.state)) {
      codeHide = false;
    }
    this.setState({
      urlCode: codeHide ? prefixed.code : this.props.history.location.state.code,
      email: prefixed.email,
      codeHide
    });
  }
  componentDidUpdate(prevProps) {
    if (this.props.isChangePassword !== prevProps.isChangePassword) {
      if (this.props.isChangePassword) {
        notification['success']({
          message: 'Password Changed'
        });
        this.props.history.push('/login');
      }
    }
  }
  componentDidCatch(error) {
    onError(error, 'ChangePassword', 'Component render error');
  }
  handleSubmit = (values, { setSubmitting }) => {
    var data = {
      email: values.email,
      password: values.password,
      hash: values.code
    };
    setSubmitting(false);
    this.props.changePassword(data);
  };
  render() {
    const { urlCode, email, codeHide } = this.state;
    const { loading } = this.props;
    return (
      <Spin spinning={loading} size="large">
        <ChangePasswordWrapper>
          <AuthHeader bgImage={logoShapeDark} />
          <AuthWrapper>
            <ChangePasswordDiv>
              <Row>
                <Col xs={24} sm={24} md={24} lg={10} xl={10} xxl={10}>
                  <div className="login-box-main">
                    <h2>{ChangeConstant.title}</h2>
                    <p>{ChangeConstant.subTitle}</p>
                    <FormStyling>
                      <Formik
                        initialValues={{
                          password: '',
                          code: urlCode,
                          email: email,
                          confirm_password: ''
                        }}
                        validationSchema={confirmSchema}
                        onSubmit={this.handleSubmit}
                        enableReinitialize
                      >
                        {({ values, errors, touched, handleChange, handleSubmit }) => (
                          <Form onSubmit={handleSubmit}>
                            <FormItem>
                              <div className="form-styling">
                                <FormGroup>
                                  <PasswordInput
                                    label={ChangeConstant.newPassword}
                                    className="password-normal-input"
                                    placeholder=""
                                    name="password"
                                    type="password"
                                    value={values.password}
                                    handleChange={handleChange}
                                    isCode={true}
                                    isError={errors.password && touched.password ? true : false}
                                  />
                                  {errors.password && touched.password ? (
                                    <div className="form-error">{errors.password}</div>
                                  ) : null}
                                </FormGroup>
                                <FormGroup>
                                  <PasswordInput
                                    label={ChangeConstant.confirm_password}
                                    className="password-normal-input"
                                    placeholder=""
                                    name="confirm_password"
                                    type="password"
                                    value={values.confirm_password}
                                    handleChange={handleChange}
                                    isCode={true}
                                    isError={
                                      errors.confirm_password && touched.confirm_password
                                        ? true
                                        : false
                                    }
                                  />

                                  {errors.confirm_password && touched.confirm_password ? (
                                    <div className="form-error">{errors.confirm_password}</div>
                                  ) : null}
                                </FormGroup>
                                {!codeHide && (
                                  <FormGroup>
                                    <PasswordInput
                                      label={ChangeConstant.codeInput}
                                      placeholder=""
                                      type="password"
                                      value={values.code}
                                      handleChange={handleChange}
                                      name="code"
                                      isCode={true}
                                      isError={errors.code && touched.code ? true : false}
                                    />
                                    {errors.code && touched.code ? (
                                      <div className="form-error">{errors.code}</div>
                                    ) : null}
                                  </FormGroup>
                                )}
                                <FormGroup>
                                  <p className="problem-text">{ChangeConstant.problemText}</p>
                                </FormGroup>
                                <div className="btn-main">
                                  <Button htmlType="submit" className="new-btn login-btn">
                                    {ChangeConstant.buttonText}
                                  </Button>
                                </div>
                              </div>
                            </FormItem>
                          </Form>
                        )}
                      </Formik>
                    </FormStyling>
                    </div>
                </Col>
              </Row>
            </ChangePasswordDiv>
            <Footer />
          </AuthWrapper>
        </ChangePasswordWrapper>
      </Spin>
    );
  }
}

const mapStateToProps = state => ({
  isChangePassword: state.changePassword.isChangePassword,
  loading: state.changePassword.loading,
  error: state.changePassword.error,
  message: state.changePassword.message
});
const mapDispatchToProps = dispatch => ({
  changePassword: payload => dispatch(changePassword(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);
