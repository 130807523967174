import isEmpty from 'lodash.isempty';
import React, { Component } from 'react';
import { FormWrapper } from './style';

class FormInput extends Component {
  render() {
    const {
      handleChange,
      disabled,
      className,
      classNameWrapper,
      isError,
      label,
      value,
      labelClassName,
      isRequired,
      id,
      ref,
      tabIndex,
      tooltip,
      ...props
    } = this.props;
    return (
      <FormWrapper className={`${classNameWrapper} ${'form-group'} ${isError ? 'errorwrapper' : ''} ${value ? 'show' : ''}`}>
        <label className={`form-control-placeholder title ${labelClassName}  ${value ? 'show' : ''}`}>
          {`${!isEmpty(label) ? label : ''} ${isRequired ? ' *' : ''}`} {tooltip ? tooltip : ''}
        </label>
        <input
          className={`form-control ${className}`}
          disabled={disabled ? true : false}
          onChange={handleChange}
          value={value}
          id={id}
          ref={ref}
          tabIndex={tabIndex}
          {...props}
        />
      </FormWrapper>
    );
  }
}
FormInput.defaultProps = {
  isError: false,
  placeholder: '',
  tabIndex: '1',
  maxLength: 128,
  className: '',
  classNameWrapper: '',
  labelClassName: '',
  readOnly: false,
};
export default FormInput;
