import PageLoader from 'components/PageLoader';
import PublicTracker from 'components/PublicTracker';
import Splash from 'components/Splash';
import ChangePassword from 'container/ChangePassword';
import Favourites from 'container/Favourites';
import ForgotPassword from 'container/ForgotPassword';
// import History from 'container/History';
// import JobBoards from 'container/JobBoards';
import Login from 'container/Login';
// import SignUp from 'container/Signup';
import SignupProfile from 'container/SignupProfile';
import Teams from 'container/Teams';
import Verification from 'container/Verification';
import { getAuthToken } from 'modules/helper';
import React, { Component, lazy, Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

/** Code-Splitting -> The Build will have more chunks of js files. */

// const Login = lazy(() => import('container/Login'));
// const ForgotPassword = lazy(() => import('container/ForgotPassword'));
// const SignUp = lazy(() => import('container/Signup'));
// const BookingV1 = lazy(() => import('container/BookingV1'));
// const ChangePassword = lazy(() => import('container/ChangePassword'));
// const Account = lazy(() => import('container/Account'));
// const Splash = lazy(() => import('components/Splash'));
const SameDayBooking = lazy(() => import('container/SameDay'));
const Shifts = lazy(() => import('container/Shifts'));
const History = lazy(() => import('container/History'));
const JobBoards = lazy(() => import('container/JobBoards'));
const Payments = lazy(() => import('container/Payments'));
const PaymentShift = lazy(() => import('container/PaymentShift'));
const PaymentsTabs = lazy(() => import('container/PaymentsTab'));
const ReviewOrder = lazy(() => import('container/ReviewOrder'));
const ReviewShift = lazy(() => import('container/ReviewShift'));
const Settings = lazy(() => import('container/Settings'));

const routes = [
  {
    path: '/login',
    exact: true,
    redirect: true,
    AuthRoute: false,
    component: Login,
  },
  // {
  //   path: '/register',
  //   exact: true,
  //   AuthRoute: false,
  //   redirect: true,
  //   component: SignUp
  // },
  {
    path: '/register-profile',
    exact: true,
    redirect: true,
    AuthRoute: true,
    component: SignupProfile,
  },
  {
    path: '/verification',
    exact: true,
    redirect: true,
    AuthRoute: false,
    component: Verification,
  },
  {
    path: '/forgot-password',
    exact: true,
    redirect: true,
    AuthRoute: false,
    component: ForgotPassword,
  },
  {
    path: '/change-password',
    exact: true,
    redirect: true,
    AuthRoute: false,
    component: ChangePassword,
  },
  {
    path: '/profile',
    exact: true,
    redirect: true,
    AuthRoute: true,
    component: Settings,
  },
  {
    path: '/splash',
    exact: true,
    AuthRoute: true,
    redirect: true,
    component: Splash,
  },
  {
    path: '/booking',
    exact: true,
    redirect: true,
    AuthRoute: true,
    component: SameDayBooking,
  },
  {
    path: '/booking/:jobId',
    exact: true,
    redirect: true,
    AuthRoute: true,
    component: SameDayBooking,
  },
  {
    path: '/booking-shift',
    exact: true,
    AuthRoute: true,
    redirect: true,
    component: Shifts,
  },
  {
    path: '/booking-shift/:shiftId',
    exact: true,
    redirect: true,
    AuthRoute: true,
    component: Shifts,
  },
  {
    path: '/history',
    exact: true,
    redirect: true,
    AuthRoute: true,
    component: History,
  },
  {
    path: '/marketplace/:jobId',
    exact: true,
    redirect: true,
    AuthRoute: true,
    component: ReviewOrder,
  },
  {
    path: '/shift-review-order/:shiftId',
    exact: true,
    redirect: true,
    AuthRoute: true,
    component: ReviewShift,
  },
  {
    path: '/job-boards',
    exact: true,
    redirect: true,
    AuthRoute: true,
    component: JobBoards,
  },
  {
    path: '/payments',
    exact: true,
    redirect: true,
    AuthRoute: true,
    component: PaymentsTabs,
  },
  {
    path: '/confirm-shift-booking/:jobId',
    exact: true,
    redirect: true,
    AuthRoute: true,
    component: PaymentShift,
  },
  {
    path: '/confirm-booking/:jobId',
    exact: true,
    redirect: true,
    AuthRoute: true,
    component: Payments,
  },
  {
    path: '/favourites',
    exact: true,
    redirect: true,
    AuthRoute: true,
    component: Favourites,
  },
  {
    path: '/teams',
    exact: true,
    redirect: true,
    AuthRoute: true,
    component: Teams,
  },
  {
    path: '/tracking/:jobId/:hashId/:locationType',
    exact: true,
    AuthRoute: false,
    redirect: false,
    component: PublicTracker,
  },
  // {
  //   path: '/tracking/:jobId/:hashId/',
  //   exact: true,
  //   AuthRoute: false,
  //   redirect: false,
  //   component: PublicTracker,
  // },
];
const PrivateRoute = ({ component: Component, ...rest }) => {
  if (getAuthToken()) {
    return <Route {...rest} render={(props) => <Component {...props} />} />;
  } else {
    return <Redirect to="/" />;
  }
};
const RestrictedRoute = ({ component: Component, publicAccess, ...rest }) => {
  if (rest.redirect) {
    if (getAuthToken()) {
      return <Route {...rest} render={() => <Redirect to="/booking" />} />;
    } else {
      if (publicAccess) {
        return <Redirect to="/login" />;
      }
      return <Route {...rest} render={(props) => <Component {...props} />} />;
    }
  } else {
    return <Route {...rest} render={(props) => <Component {...props} />} />;
  }
};
class Routes extends Component {
  render() {
    return (
      <Suspense fallback={<PageLoader />}>
        <Switch>
          <Route path="/" exact={true} render={() => <Redirect to="/login" />} />
          {routes.map((route, index) => {
            return !route.AuthRoute ? <RestrictedRoute {...route} key={index} /> : <PrivateRoute {...route} key={index} />;
          })}
          <Route render={(props) => <h1>404 Page</h1>} />
        </Switch>
      </Suspense>
    );
  }
}

export default Routes;
