import styled from 'styled-components';
import { size } from 'App/device';
const BgWrapper = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  img {
    vertical-align: top;
    border: none;
    outline: none;
    max-width: 100%;
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center right;
    display: block;
  }
`;

const Header = styled.header`
  position: static;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
  .navbar {
    position: relative;
    display: flex;
    align-items: center;
    flex-flow: row nowrap;
    justify-content: flex-start;
    min-height: 112px;
    padding: 0 125px;
    @media ${size['desktop-md-max']} {
      min-height: 112px;
      padding: 0 70px;
    }
    @media ${size['desktop-sm-max']} {
      min-height: 130px;
      padding: 0 60px;
    }
    @media ${size['laptop-max']} {
      min-height: 100px;
      padding: 0 20px;
    }
    @media ${size['tablet-max']} {
      min-height: 85px;
    }
    .site-logo {
      margin: 0;
      display: flex;
      display: -webkit-flex;
      width: 175px;
      min-height: 36px;
      margin-top: 10px;
      align-items: center;
      .navbar-brand {
        margin: 0;
        padding: 0;
        max-width: 100%;
        display: block;
      }
    }
  }
`;

export { Header, BgWrapper };
