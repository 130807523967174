import React from 'react';
import { connect } from 'react-redux';
import { Spin } from 'antd';
import { checkSession } from 'redux/login/actions';
import { getAuthToken } from 'modules/helper';
import { ReactComponent as LogoPurple } from 'assets/images/logo-shape-purple.svg';
export default function(Component) {
  class CheckHOC extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        loading: true
      };
    }
    async componentDidMount() {
      const { checkSession } = this.props;
      this.setState({
        loading: false
      });
      if (getAuthToken()) {
        await checkSession();
      }
    }
    render() {
      const { loading } = this.state;
      return (
        <Spin
          spinning={loading}
          size="large"
          className="spinner-custom"
          indicator={
            <>
              <div className="loader-custom"></div>
              <LogoPurple />
            </>
          }
        >
          <Component />
        </Spin>
      );
    }
  }
  const mapStateToProps = state => ({});
  const mapStateToDispatch = dispatch => ({
    checkSession: () => dispatch(checkSession())
  });
  return connect(mapStateToProps, mapStateToDispatch)(CheckHOC);
}
